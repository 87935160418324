
import BaseService from './BaseService'
import { verifiableSectionUrls } from './urls'

class QuestionService {
    static fetchSections = () => BaseService.getRequest(verifiableSectionUrls.ALL_SECTIONS, true);
    static deleteSections = (data) => BaseService.deleteRequest(verifiableSectionUrls.DELETE_SECTIONS, data, true);
    static updateRequestVerifiableSections = (candidate_id, data) => (
        BaseService.putRequest(
            verifiableSectionUrls.UPDATE_REQUEST_VERIFIABLE_SECTIONS(
                candidate_id
            ),
            data, true
        )
    )
    static updateSection = (data, id) => BaseService.putRequest(
        verifiableSectionUrls.UPDATE_SECTION(id),
        data, true
    )

    static createSection = (data) => BaseService.postRequest(
        verifiableSectionUrls.ALL_SECTIONS,
        data, true
    )


}

export default QuestionService;