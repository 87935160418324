import {
  Button, Checkbox, Dropdown,
  DropdownItem, DropdownToggle, Spinner,
  spinnerSize, TextInput, Switch,
} from '@patternfly/react-core';
import { CloseIcon, EllipsisVIcon } from '@patternfly/react-icons';
import { AccordionContext, ListEmpty } from 'components';
import { ConfirmDialog } from 'dialogs';
import { useAccordionData, useNotificationData } from 'hooks';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { VerifiableSectionService } from 'services';
import styles from './VerifiableSection.module.scss';

export interface VerifiableSectionProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const VerifiableSectionComponent: React.FC<VerifiableSectionProps> = props => {
  const { setBottomComponent } = useContext(AccordionContext)
  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  const centinelRef = useRef<any>(null)
  const [selectedSections, setSelectedSections] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [itemMenuOpen, setItemMenuOpen] = useState<string>('')
  const [toDelete, setToDelete] = useState<string[]>([])
  const { new_notification } = useNotificationData()
  const [openNewSection, setOpenNewSection] = useState<boolean>(false)
  const [titleNewSection, setTitleNewSection] = useState<string>('')
  const [titleEnNewSection, setTitleEnNewSection] = useState<string>('')
  const [priceNewSection, setPriceNewSection] = useState<string>('0')
  const [is_active, setIsActive] = useState<boolean>(true)
  const [toUpdate, setToUpdate] = useState<any>()

  React.useEffect(() => {
    resetAccordion({
      title: formatMessage({ id: "verifiable_section:all_sections" }),
      sub_title: ""
    })
    fetchSections()
    return () => {
      resetAccordion({
        title: '',
        sub_title: '',
      })
      setBottomComponent(<></>)
    }
  }, [])

  useEffect(() => {
    setBottomComponent(
      <div className={styles.top}>
        <Button
          onClick={_ => setOpenNewSection(true)}
        >
          <FormattedMessage id="verfiable_section:new_section" />
        </Button>
        {selectedSections.length > 0 && (
          <Button
            onClick={i => {
              setToDelete(selectedSections)
              setSelectedSections([])
            }}
            variant={"secondary"}
          >
            <CloseIcon />
            <FormattedMessage id="shared:delete" />
          </Button>
        )}
      </div>
    )
  }, [selectedSections])

  const [sections, setSections] = useState<any[]>([])

  const dropdownItems = section => [

    <DropdownItem
      key={'modify'}
      component="button"
      onClick={_ => {
        setToUpdate(section)
        section.name_fr ? setTitleNewSection(section.name_fr): setTitleNewSection(section.name)
        setTitleEnNewSection(section.name_en)
        setPriceNewSection(section.amount)
        setIsActive(section.is_active)
        setOpenNewSection(true)
      }}
    >
      <FormattedMessage id="shared:update" />
    </DropdownItem>,

    <DropdownItem
      key={'delete'}
      component="button"
      onClick={_ => setToDelete([section.id])}
    >
      <FormattedMessage id="shared:delete" />
    </DropdownItem>,

  ]

  const handleSelectSection = id => change => {
    if (change) {
      setSelectedSections([...selectedSections, id])
    } else {
      setSelectedSections(selectedSections.filter(_ => _ !== id))
    }
  }

  const onSelect = id => event => {
    setItemMenuOpen('')
  }

  const ItemMenu = ({ section }) => (
    <Dropdown
      onSelect={onSelect(section.id)}
      toggle={
        <DropdownToggle
          toggleIndicator={null}
          onToggle={_ => {
            setItemMenuOpen(_ ? section.id : '')
          }}
          aria-label="Applications" id={section.id}
        >
          <EllipsisVIcon />
        </DropdownToggle>
      }
      isOpen={itemMenuOpen === section.id}
      isPlain
      dropdownItems={dropdownItems(section)}
    />
  )

  const SectionItem = ({ section }) => (
    <li className={styles.item}>
      <Checkbox
        id="check-8"
        onChange={handleSelectSection(section.id)}
        className={styles.checkbox}
        isChecked={selectedSections.includes(section.id)}
      />
      <span
        className={styles.item__title}
      >
        {section.name}
      </span>

      { Boolean(section.amount) && (
        <span
          className={styles.item__price}
        >
          <FormattedMessage id="shared:price" /> : ${section.amount}
        </span>
      )}

      <ItemMenu section={section} />
    </li>
  )

  const createUpdateSection = () => {
    setLoading(true)

    let section: {
      amount: string | number, 
      name: string,
      name_en: string,
      id?: string,
      is_active: boolean
    } = {
      name: titleNewSection,
      name_en: titleEnNewSection,
      amount: priceNewSection,
      is_active,
    }


    let method;
    if (Boolean(toUpdate)) {
      section.id = toUpdate.id
      method = VerifiableSectionService.updateSection(section, section.id)
    } else {
      method = VerifiableSectionService.createSection(section)
    }


    method
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          setLoading(false)
          const {
            message, data: newSection
          } = await response.json()
          setSections([
            newSection,
            ...sections.filter(
              ({ id }) => ![(toUpdate || []).id].includes(id)
            )
          ])
          setToUpdate(undefined)
          setOpenNewSection(false)
          setTitleNewSection('')
          setTitleEnNewSection('')
          new_notification({
            message,
            title: formatMessage({ id: "shared:success" }),
            variant: 'success'
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })
          setLoading(false)
        }
      })
  }

  const deleteSections = () => {
    setLoading(true)

    VerifiableSectionService.deleteSections({ section_ids: toDelete })
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          setLoading(false)
          const { message } = await response.json()

          setSections(sections.filter(({ id }) => !toDelete.includes(id)))
          setToDelete([])
          setSelectedSections([])
          new_notification({
            message,
            title: formatMessage({ id: "shared:success" }),
            variant: 'success'
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })
          setLoading(false)
        }
      })
  }

  const fetchSections = () => {
    setLoading(true)
    VerifiableSectionService.fetchSections()
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const results = await response.json()
          setSections(
            results.map(
              ({ name, name_en, name_fr, id, is_active, amount }) => ({ is_active, amount, name, name_en, name_fr, id })
            )
          )
          setLoading(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })
          setLoading(false)
        }
      })
  }

  return (
    <ul className={styles.container} >
      <ConfirmDialog
        isModalOpen={openNewSection}
        onAction={createUpdateSection}
        onModalClose={() => {
          setOpenNewSection(false)
          setTitleNewSection('')
          setTitleEnNewSection('')
          setPriceNewSection('0')
          setToUpdate(undefined)
        }}
        loading={loading}
        disabled={titleNewSection === "" || loading}
        actionText={toUpdate ? "shared:update_section" : "shared:create_section"}
        title_message={toUpdate ? "verifiable_section:update_section" : "verifiable_section:new_section"}
        confirmation_message="shared:creation_section_prompt"
      >
        <div className={styles.field}>
          <span className={styles.title}>
            <FormattedMessage id="verifiable_section:name_fr" />
            <span className={styles.warning}>*</span>
          </span>
          <TextInput
            onChange={setTitleNewSection}
            value={titleNewSection}
          />
        </div>
        <div className={styles.field}>
          <span className={styles.title}>
            <FormattedMessage id="verifiable_section:name_en" />
          </span>
          <TextInput
            onChange={setTitleEnNewSection}
            value={titleEnNewSection}
          />
        </div>
        <div className={styles.field}>
          <span className={styles.title}>
            <FormattedMessage id="shared:price" />
            <span className={styles.warning}>*</span>
          </span>
          <TextInput
            onChange={setPriceNewSection}
            value={priceNewSection}
            type="number"
          />
        </div>
        <div className={styles.field}>
          <Switch
            id="simple-switch"
            label={formatMessage({ id: "shared:is_active" })}
            isChecked={is_active}
            onChange={setIsActive}
          />
        </div>
      </ConfirmDialog>

      <ConfirmDialog
        isModalOpen={toDelete.length > 0}
        title_message={"verifiable_section:title_delete"}
        onAction={deleteSections}
        onModalClose={() => setToDelete([])}
        loading={loading}
        actionText="shared:delete"
        confirmation_message="verifiable_section:delete_prompt"
      />


      {sections.map(section => (
        <SectionItem section={section} />
      ))}

      {
        sections.length < 1 && !loading && (
          <ListEmpty />
        )
      }

      <div className={styles.loader} ref={centinelRef}>
        {loading &&
          <Spinner size={spinnerSize.md} />
        }
      </div>

    </ul>
  );
}

export {
  VerifiableSectionComponent as VerifiableSectionPage,
};

