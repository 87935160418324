import React, { useState, useEffect, } from 'react'
import { AppStore, IProvince, IUser } from 'stores'
import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'
import { UtilitiesService } from 'services'
import { useNotificationData } from 'hooks'
import { useIntl } from 'react-intl'

export const useCompanyUserData = (company_id) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [fetchError, setFetchError] = useState<boolean>(false)
    const [users, setUsers] = useState<IUser[]>([])

    const { new_notification } = useNotificationData()

    useEffect(() => {
        if (!Boolean(company_id)) { return }
        fetchUsers(company_id)
    }, [company_id])

    const fetchUsers = (company_id) => {
        setLoading(true)
        UtilitiesService.list_company_users(company_id)
            .catch(err => {
                new_notification({
                    title: 'Error getting users',
                    variant: 'error',
                    message: formatMessage({ id: 'shared:err_get_user' }),
                })
                setLoading(false)
                setFetchError(true)
            })
            .then(async response => {
                if ([200, 201].includes(response.status)) {

                    const payload = await response.json()
                    setUsers(payload)

                    setLoading(false)

                } else if ([404, 401, 500].includes(response.status)) {
                    const { message } = await response.json()
                    new_notification({
                        title: 'error fetching users',
                        message,
                        variant: 'error',
                    })
                    setLoading(false)
                    setFetchError(true)
                } else {
                    new_notification({
                        title: 'error fetching users',
                        message: formatMessage({ id: 'shared:err_get_user' }),
                        variant: 'error',
                    })
                    setLoading(false)
                    setFetchError(true)
                }
            })

    }

    return { loading, users }

}