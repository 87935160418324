import { TextInputProps } from "@patternfly/react-core";
import { IFormsType, ICategoryType, IQuestionType } from "stores";
export type { IFormsType, ICategoryType, IQuestionType }



export interface FieldType extends TextInputProps {
  name: string;
  type: "text" | 'number' | 'date';
  label: string;
  label_en?: string;
  multiline: boolean;
  select_multi?: boolean;
  rows: number;
  required: boolean;
  select: boolean;
  options?: any[];
  switch?: boolean; //Un champ de type switch 
  halfWidth?: boolean; //Pour afficher deux champs dans la meme ligne
  verify?: string; //Le champs ne s'affichera que quand un autre un de ce nom est validé. Pour gerer par exemple l'affichage de description quand le switch description est juste
}

export interface OptionType {
  name: string;
  value: string;
}


export const tab_categories: ICategoryType[] = [
  {
    id: '1',
    title: 'Categorie 1',
    title_en: 'Category 1',
    questions_counts: 2,
    is_global: true,
    description: 'description here',
  },
  {
    id: '2',
    title: "Categorie 2",
    title_en: "Category 2",
    questions_counts: 1,
    is_global: true,
    description: 'description here',
  },
  {
    id: '3',
    title: 'Categorie 3',
    title_en: 'Category 3',
    questions_counts: 7,
    is_global: true,
    description: 'description here',
  },
  {
    id: '4',
    title: 'Categorie 4',
    title_en: 'Category 4',
    questions_counts: 8,
    is_global: true,
    description: 'description here',
  },
  {
    id: '5',
    title: 'Categorie 5',
    title_en: 'Category 5',
    questions_counts: 5,
    is_global: true,
    description: 'description here',
  }
]

export const tab_questions: IQuestionType[] = [
  {
    id: '1',
    value: 'Lorem ipsum dolor sit amet, consetetur',
    description: "",
    type: 'Radio'
  },
  {
    id: '2',
    value: 'Si je Lorem ipsum dolor sit amet, consetetur ?',
    description: "",
    type: 'email'
  }
]

export const form_fields: FieldType[] = [
  {
    name: "name",
    type: "text",
    label: "verifiable_section:name_fr",
    multiline: false,
    rows: 1,
    required: true,
    select: false,
  },
  {
    name: "name_en",
    type: "text",
    label: "verifiable_section:name_en",
    multiline: false,
    rows: 1,
    required: false,
    select: false,
  },
  {
    name: "description",
    type: "text",
    label: "shared:description",
    multiline: false,
    rows: 4,
    required: false,
    select: false,
    switch: false,
    halfWidth: false,
  },
  {
    name: "public",
    type: "text",
    label: "shared:public",
    multiline: false,
    rows: 1,
    required: false,
    select: false,
    switch: true,
    halfWidth: false,
  },
  {
    name: "is_standard",
    type: "text",
    label: "shared:is_standard_label",
    multiline: false,
    rows: 1,
    required: false,
    select: false,
    switch: true,
    halfWidth: true,
  },
];

export const categories_fields: FieldType[] = [
  {
    name: "name",
    type: "text",
    label: "verifiable_section:name_fr",
    multiline: false,
    rows: 1,
    required: true,
    select: false,
  },
  {
    name: "name_en",
    type: "text",
    label: "verifiable_section:name_en",
    multiline: false,
    rows: 1,
    required: false,
    select: false,
  },
  {
    name: "description",
    type: "text",
    label: "shared:description",
    multiline: false,
    rows: 2,
    required: false,
    select: false,
    switch: false,
    halfWidth: false,
  },
  {
    name: "is_global",
    type: "text",
    label: "shared:is_public",
    multiline: false,
    rows: 1,
    required: false,
    select: false,
    switch: true,
    halfWidth: true,
  },
];

export const questions_fields: FieldType[] = [
  {
    name: "value",
    type: "text",
    label: "verifiable_section:name_fr",
    multiline: false,
    rows: 1,
    required: true,
    select: false
  },
  {
    name: "title_en",
    type: "text",
    label: "verifiable_section:name_en",
    multiline: false,
    rows: 1,
    required: false,
    select: false
  },
  {
    name: "type",
    type: "text",
    label: "shared:type",
    multiline: false,
    rows: 1,
    required: true,
    select: true,
    options: ["text", "email", "date", "textarea", "select", "radio", "checkbox"]
  },
  {
    name: "required",
    type: "text",
    label: "shared:mandatory",
    multiline: false,
    rows: 1,
    required: false,
    select: false,
    switch: true,
    halfWidth: true,
  },
  {
    name: "add_description",
    type: "text",
    label: "shared:description",
    multiline: false,
    rows: 1,
    required: false,
    select: false,
    switch: true,
    halfWidth: true,
  },
  {
    name: "description",
    type: "text",
    label: "shared:description",
    multiline: false,
    rows: 2,
    required: false,
    select: false,
    switch: false,
    halfWidth: false,
    verify: 'add_description'
  },
  {
    name: "fullwidth",
    type: "text",
    label: "shared:fullwidth",
    multiline: false,
    rows: 1,
    required: false,
    select: false,
    switch: true,
    halfWidth: false,
  }
]