import React, { useState, useEffect } from 'react'
import styles from './CategoryFilter.module.scss'
import {
  Modal, Button, TextInput,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
import { useUserData, useProvinceData, useFolderStatuses } from 'hooks';

export interface CategoryFilterProps {
  readonly renderTrigger: any;
  readonly onDone: any;
}

export interface ICompany {
  questions_count: string,
  forms_counts: string,
  name: string,
}

const validationSchema = yup.object().shape({
  questions_count: yup.number(),
  forms_counts: yup.number(),
  title: yup.string(),
})

export const CategoryFilter: React.FC<CategoryFilterProps> = ({
  renderTrigger,
  onDone,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [loading] = useState<boolean>(false)
  // const { loading: loadingGroups, message: messageGroups, groups } = useQueryGroups()
  const { users } = useUserData()
  const { provinces } = useProvinceData()
  const { folderStatus } = useFolderStatuses()

  const onFilter = (data) => {
    let province
    if (data.province) province = provinces.find(prov => prov.name === data.province)!.id
    let contact_id
    if (data.contact_id) contact_id = users.find(user => user.username === data.contact_id)!.id
    let folder_status
    if (data.folder_status) folder_status = folderStatus.find(status => status.name === data.folder_status)!.id
    onDone({ ...data, province, folder_status, contact_id })
    handleModalToggle()
  }

  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    handleReset,
    errors,
    handleSubmit,
  } = useFormik<ICompany>({
    validationSchema,
    onSubmit: onFilter,
    initialValues: {
      questions_count: '',
      forms_counts: '',
      name: '',
    }
  })

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  };


  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: "shared:categories_filter" })}
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        //isFooterLeftAligned
        className={styles.modal_root}
      >
        <form
          className={styles.content}
          onSubmit={handleSubmit}
        >
          <label className={styles.field}>
            <span
              className={errors.name && touched.name ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.name && touched.name ? errors.name : "shared:name"}
              />
            </span>
            <TextInput
              name='name'
              value={values.name}
              type="text"
              //css={{}}
              onChange={text => setFieldValue('name', text)}
              onBlur={handleBlur}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.questions_count && touched.questions_count ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.questions_count && touched.questions_count ? errors.questions_count : "shared:questions_count"}
              />
            </span>
            <TextInput
              name='questions_count'
              value={values.questions_count}
              type="number"
              //css={{}}
              onChange={text => setFieldValue('questions_count', text)}
              onBlur={handleBlur}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.forms_counts && touched.forms_counts ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.forms_counts && touched.forms_counts ? errors.forms_counts : "shared:forms_counts"}
              />
            </span>
            <TextInput
              name={'forms_counts'}
              value={values.forms_counts}
              onChange={text => setFieldValue('forms_counts', text)}
              onBlur={handleBlur}
              type="number"
              //css={{}}
              aria-label="text input example"
            />
          </label>

          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={e => {
                handleReset(e)
                onDone(undefined)
                handleModalToggle()
              }}
              type="button"
            >
              <FormattedMessage id="shared:cancel_filter" />
            </button>
            <Button
              // isDisabled={Object.keys(touched).length < 1}
              type="submit"
            >
              <FormattedMessage id="shared:filter" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
