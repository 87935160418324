import * as React from 'react';
import styles from './Notification.module.scss';
import IconNewCandidateTransfer from 'assets/imgs/Icon_candidate_transfer_notification.png'
import IconNewArchive from 'assets/imgs/Icon_archive_notification.png'
import IconNewDemand from 'assets/imgs/Icon_demand_notification.png'
import IconNewCandidate from 'assets/imgs/Icon_candidate_notification.png'
import IconNewCompany from 'assets/imgs/Icon_company_notification.png'
import {
    NotificationTransfer,
    NotificationArchive,
    NotificationRequest,
    NotificationCandidateStatus,
    NotificationNewCompany
} from 'dialogs';

import {
    Dropdown,
    DropdownItem,
} from '@patternfly/react-core';
import { useUserRoleData, useWebSocketData, useWebSocketIOData } from 'hooks';
import io from 'socket.io-client'
import { FormattedMessage } from 'react-intl';

export interface NotificationProps {
    readonly setisKebabDropdownOpen?: any; // To close the 'three dots' (on mobile)
    readonly setOneModalOpen?: any // To know if a modal is open or not for close/maintain open the three dots after a click(mobile)
}

const NotificationComponent: React.FC<NotificationProps> = props => {

    const [isOpen, setIsOpen] = React.useState<boolean>(false) // Open/Close dropdown notifications
    const [toggleModal, setToggleModal] = React.useState<boolean>(false) // Open / Close modal(one notification detail)
    const [clickedId, setTClickedId] = React.useState<string>('') // Which element has been clicked in the notification dropdown
    const { currentRole } = useUserRoleData()
    const {
        notifications, onReadNotification,
    } = useWebSocketIOData(
        currentRole !== "Contact"
    )
    // const {
    //     notifications, onReadNotification,
    // } = useWebSocketData(
    //     currentRole !== "Contact"
    // )
    const onSelect = event => {
        if (props.setOneModalOpen)
            props.setOneModalOpen(isOpen)
        setIsOpen(!isOpen)
    };
    const dropdownItems = [
        <DropdownItem
            className={styles.dropdown}
            key="total"
            component="button"
        >
            <span className={styles.dropdown_total}>
                Vous avez {notifications.length} notification(s)
            </span>
        </DropdownItem>,

        <DropdownItem
            className={styles.dropdown}
            key="candidate_transfer"
            component="button"
            onClick={
                () => {
                    setToggleModal(!toggleModal);
                    setTClickedId("candidate_transfer");
                }
            }
        >
            <img alt="" src={IconNewCandidateTransfer} />
            <span className={styles.dropdown_item_text}>
                Nouveau Transfert de candidats
            </span>
            <span className={styles.number}>{3}</span>
        </DropdownItem>,
        
        <DropdownItem
            className={styles.dropdown}
            key="archive"
            component="button"
            onClick={
                () => {
                    setToggleModal(!toggleModal);
                    setTClickedId("archive")
                }
            }
        >
            <img alt="" src={IconNewArchive} />
            <span className={styles.dropdown_item_text}>
                Nouvel Archivage
            </span>
            <span className={styles.number}>{3}</span>
        </DropdownItem>,
        
        <DropdownItem
            className={styles.dropdown}
            key="candidat_status"
            component="button"
            onClick={
                () => {
                    setToggleModal(!toggleModal);
                    setTClickedId("candidat_status")
                }
            }
        >
            <img alt="" src={IconNewCandidate} />
            <span className={styles.dropdown_item_text}>
                Nouveau Statut de candidat
            </span>
            <span className={styles.number}>{3}</span>
        </DropdownItem>,
        
        <DropdownItem
            className={styles.dropdown}
            key="new_company"
            component="button"
            onClick={
                () => {
                    setToggleModal(!toggleModal);
                    setTClickedId("new_company")
                }
            }
        >
            <img alt="" src={IconNewCompany} />
            <span className={styles.dropdown_item_text}>
                Nouvelle compagnie
            </span>
            <span className={styles.number}>{3}</span>
        </DropdownItem>
    
    ]

    if (notifications.length > 0) {
        dropdownItems.push(
            <DropdownItem
                className={styles.dropdown}
                key="new_request"
                component="button"
                onClick={
                    () => {
                        setToggleModal(!toggleModal);
                        setTClickedId("new_request")
                    }
                }
            >
                <img alt="" src={IconNewDemand} />
                <span className={styles.dropdown_item_text}>
                    <FormattedMessage id="verification:new_request_verification" />
                </span>
                <span className={styles.number}>
                    {notifications.length}
                </span>
            </DropdownItem>
        )
    }

    return (
        <>
            <NotificationRequest
                clickedId={clickedId}
                notifications={notifications}
                onReadNotification={onReadNotification}
                onClose={() => setTClickedId('')}
                setisKebabDropdownOpen={props.setisKebabDropdownOpen}
                isOpen={clickedId === "new_request"}
            />

            <Dropdown
                position="right"
                onSelect={onSelect}
                // @ts-ignore
                toggle={
                    // @ts-ignore
                    props.children(
                        setIsOpen,
                        notifications.length
                    )
                }
                isOpen={isOpen}
                isPlain
                dropdownItems={dropdownItems}
                className={'color_black'}
            />

            {
                clickedId === "candidate_transfer" ? (
                    <NotificationTransfer
                        clickedId={clickedId}
                        setisKebabDropdownOpen={
                            props.setisKebabDropdownOpen
                        }
                    />
                ) :
                    clickedId === "archive" ? (
                        <NotificationArchive
                            clickedId={clickedId}
                            setisKebabDropdownOpen={
                                props.setisKebabDropdownOpen
                            }
                        />
                    ) :
                        clickedId === "candidat_status" ? (
                            <NotificationCandidateStatus
                                clickedId={clickedId}
                                setisKebabDropdownOpen={
                                    props.setisKebabDropdownOpen
                                }
                            />
                        ) :
                            clickedId === "new_company" ? (
                                <NotificationNewCompany
                                    clickedId={clickedId}
                                    setisKebabDropdownOpen={
                                        props.setisKebabDropdownOpen
                                    }
                                />
                            ) :
                                null
            }
        </>
    );
}

export {
    NotificationComponent,
}
