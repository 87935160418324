import * as React from 'react';
import styles from './CandidateForm.module.scss'
import { CustomForm } from 'components/forms/CustomForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { Button, Spinner, spinnerSize } from '@patternfly/react-core';

export interface CandidateFormProps {
  readonly fields: any[];
  readonly questions: any;
  readonly onSave: any;
  readonly loading?: boolean;
  readonly setLoading?: (_: boolean) => void;
  readonly switchEditingToViewMode?: any;
  readonly onlyEditingMode?: boolean;
  readonly minimalist?: boolean;//not show cancel/save button twice
  readonly name: string;
}

export const CandidateForm: React.FC<CandidateFormProps> = ({
  fields: fieldsArray,
  questions,
  name,
  onSave,
  loading = false,
  setLoading,
  minimalist = false,
  onlyEditingMode = false,
  switchEditingToViewMode = () => { },
}) => {

  //Pour soummettre le formulaire values,actions
  const onSubmit = (values, actions) => {
    onSave(values, actions.setSubmitting, true)
  }


  const { formatMessage } = useIntl()

  let validationSchema: any = {}
  let initialValues: any = {}

  fieldsArray.flatMap(({ fields }) => fields).forEach(field => {
    if (field.required) {
      validationSchema[field.name] = yup.string().required("Champs requis!")
    }
    initialValues[field.name] = (questions && questions[field.name]) ? questions[field.name] : '';
    if (['select', 'radio', 'checkbox'].includes(questions.type)) {
      initialValues[field.name] = []
    }
  })


  const formikValues = useFormik<{}>({
    enableReinitialize: true,
    validationSchema: yup.object().shape(validationSchema),
    onSubmit: (values, actions) => onSubmit(values, actions),
    initialValues: {
      ...initialValues,
      description: "",
      add_description: false,
      halfWidth: false
    }
  });

  const onPartialSubmit = () => onSave(
    formikValues.values, setLoading!, false
  )

  return (
    <form
      className={styles.form_wrapper}
      onSubmit={formikValues.handleSubmit}>
      <div className={styles.header}>
        <span className={styles.title}>{formatMessage({ id: name })}</span>
        {!minimalist && (
          <div className={styles.actions}>

            {!onlyEditingMode && (
              <Button
                variant="tertiary"
                onClick={e => {
                  formikValues.handleReset(e)
                  switchEditingToViewMode()
                }}
              >
                <FormattedMessage id="shared:cancel" />
              </Button>
            )}

            <Button
              variant="secondary"
              onClick={onPartialSubmit}
              isDisabled={formikValues.isSubmitting || loading}
            >
              <FormattedMessage id="shared:save" />
            </Button>

            <Button
              type="submit"
              isDisabled={
                formikValues.isSubmitting || loading
                || Object.keys(formikValues.errors).length > 0
              }
            >
              <FormattedMessage id="shared:save_complete" />
              {formikValues.isSubmitting && (<Spinner size={spinnerSize.md} />)}
            </Button>

          </div>
        )}
      </div>
      {fieldsArray.map(
        ({ catName, fields }) => (
          <>
            <span className={styles.category}>{formatMessage({ id: catName })}</span>
            <div
              className={styles.custom_form}
            >

              <CustomForm
                fields={fields}
                {...formikValues}
                IsInpreviewMode={true}
              />

            </div>
          </>
        ))}
      <div className={styles.footer}>
        <div className={styles.actions}>
          {!onlyEditingMode && (
            <Button
              variant="tertiary"
              onClick={e => {
                formikValues.handleReset(e)
                switchEditingToViewMode()
              }}
            >
              <FormattedMessage id="shared:cancel" />
            </Button>
          )}
          {!minimalist && (
            <Button
              variant="secondary"
              onClick={onPartialSubmit}
              isDisabled={formikValues.isSubmitting || loading}
            >
              <FormattedMessage id="shared:save" />
            </Button>
          )}
          <Button
            type="submit"
            isDisabled={
              formikValues.isSubmitting || loading
              || Object.keys(formikValues.errors).length > 0
            }
          >
            <FormattedMessage id="shared:save_complete" />
            {formikValues.isSubmitting && (<Spinner size={spinnerSize.md} />)}
          </Button>
        </div>
      </div>
    </form>
  );
}
