import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './Transfer.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, TextInput, Spinner, spinnerSize, Checkbox } from '@patternfly/react-core';
import { TransferModal } from 'dialogs';
import { Table, TableHeader, TableBody, TableVariant, SortByDirection } from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { ReactComponent as StatusArchivedIcon } from 'assets/imgs/status_archived.svg'
import { ReactComponent as StatusLockedIcon } from 'assets/imgs/status_locked.svg'
import { ReactComponent as StatusOpenIcon } from 'assets/imgs/status_open.svg'
import { ReactComponent as StatusLoadingIcon } from 'assets/imgs/status_loading.svg'
import { ReactComponent as StatusFaxReceivedIcon } from 'assets/imgs/status_fax_received.svg'
import clsx from 'classnames';
import { RouteComponentProps } from 'react-router-dom'
import { CandidateService } from 'services'
import { useCandidatesData } from 'hooks'
import { ICandidate } from 'stores';
import { ListEmpty } from 'components';

export interface TransferProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const TransferComponent: React.FC<TransferProps> = ({
  match: { url, },
  history,
  ...props
}) => {
  let {
    resetCandidates, appendCandidates,
    start: storeStart, limit: storeLimit, 
    candidates: storeCandidates, total: storeTotal,
  } = useCandidatesData()
  const [observerTriggerState, setObserverTriggerState] = useState<any>({})
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false)
  const centinelRef = useRef<any>(null)

  //En tete du tableau
  const table_header_columns = [
    ' ',
    { title: formatMessage({ id: 'routes.candidates' }), sortable: false },
    formatMessage({ id: 'shared:contact' }),
    formatMessage({ id: 'shared:your_folder' }),
    formatMessage({ id: 'shared:NAS' }),
    formatMessage({ id: 'shared:status' }),
  ]

  const actions = [
  ]


  const translateStatus = (folder_status: number) => {
    let returnVal = 'locked'

    if (folder_status === 5) returnVal = 'archived_and_closed'
    else if (folder_status === 4) returnVal = 'archived'
    else if (folder_status === 0) returnVal = 'open'
    else if (folder_status === 3) returnVal = 'locked'
    else if (folder_status === 1) returnVal = 'loading'
    else if (folder_status === 2) returnVal = 'fax_received'

    return returnVal
  }

  //Pour Afficher le status
  const parseStatus = (status) => {
    let StatusIcon = StatusLockedIcon
    switch (status) {
      case 5:
        StatusIcon = StatusLockedIcon
        break;
      case 4:
        StatusIcon = StatusArchivedIcon
        break;
      case 0:
        StatusIcon = StatusOpenIcon
        break;
      case 3:
        StatusIcon = StatusLockedIcon
        break;
      case 1:
        StatusIcon = StatusLoadingIcon
        break;
      case 2:
        StatusIcon = StatusFaxReceivedIcon
        break;
    }
    return (
      <div
        className={clsx(
          styles.status_box,
          styles[status],
        )}
      >
        <StatusIcon /> &nbsp;  &nbsp;
        <span>{formatMessage({ id: `shared:${status}` })}</span>
      </div>
    )
  }

  //Exemple de contenu du tableau
  const [stateCandidates, setStateCandidates] = React.useState<any[]>([])
  const [selectedCandidatesIds, setSelectedCandidtesIds] = useState<string[]>([])
  const [searchString, setSearchString] = React.useState<string>('')
  const [sortBy, setSortBy] = React.useState<any>({})
  const { resetAccordion } = useAccordionData()
  const [_, setSearchStart] = useState<number>(0)
  const [__, setSearchTotal] = useState<number>(0)
  const [searching] = useState<boolean>(false)
  const { new_notification } = useNotificationData()
  let timeout;

  //Pour trier
  const onSort = (_event, index, direction) => {
    const sortedRows = stateCandidates.sort((a, b) => (a[index] < b[index] ? -1 : a[index] > b[index] ? 1 : 0));
    setSortBy({
      index,
      direction
    })
    setStateCandidates(direction === SortByDirection.asc ? sortedRows : sortedRows.reverse())
  }

  useEffect(() => {
    resetAccordion({ title: formatMessage({ id: 'candidate:candidate_list' }) })
    configureInfiniteScroll()
  }, [])

  useEffect(() => {
    if (storeCandidates && storeCandidates.length > 0 && !searching) {
      // const candidatesParsed = parseCandidates(storeCandidates)
      setStateCandidates(storeCandidates)
    }
  }, [storeCandidates])

  const fetchCandidates = (paramStart: number, paramSearching: boolean) => {
    setLoading(true)
    const suffix = `?start=${paramStart}&limit=${storeLimit}`
    CandidateService.list_candidates(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { results, start, total, limit } = await response.json()
          if (!paramSearching) { //Not searching
            if (start < 1) {
              resetCandidates({ data: results, start, total, limit, })
            }
            else {
              appendCandidates({ data: results, start, limit, total })
            }
          } else { //Searching 
            // const candidatesParsed = parseCandidates(results)

            setStateCandidates([...(start > 0 ? stateCandidates : []), ...results])
            setSearchTotal(total)
            setSearchStart(start)
          }
        } else if ([400, 500, 422, 401, 409, 404].includes(response.status)) {

        } else {

        }
      })
  }

  const handleSelectCandidate = candidate_id => {
    const idx = selectedCandidatesIds.findIndex(id => id === candidate_id)
    if (idx === -1) {
      setSelectedCandidtesIds([...selectedCandidatesIds, candidate_id])
      return
    }
    const newList = [...selectedCandidatesIds]
    newList.splice(idx, 1)
    setSelectedCandidtesIds(newList)

  }

  const parseCandidates = (data: ICandidate[]) => {
    return data.map(datum => ([
      <React.Fragment>
        <Checkbox
          id="e"
          onClick={i => handleSelectCandidate(datum.id)}
          isChecked={selectedCandidatesIds.includes(datum.id)}
        />
      </React.Fragment>,
      `${datum.firstname} ${datum.lastname}`,
      `${datum.contact_name}`,
      datum.adress,
      datum.phone_number,
      <span>
        {parseStatus(translateStatus(+datum.folder_status))}
      </span>,
      // <span>
      //   <Link to={`${url}/${datum.id}`} className={styles.link}>
      //     <FormattedMessage id="shared:more_details" />
      //   </Link>
      // </span>
    ]))
  }

  const onNextPage = () => {
    if ((storeTotal !== 0 && storeCandidates.length >= storeTotal) || searchString !== '') { return }
    console.log({ storeStart, storeTotal })
    fetchCandidates(storeTotal === 0 ? 0 : storeStart + storeLimit, false)
    // setStart(storeStart + storeLimit)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeCandidates, storeStart])

  useEffect(() => {
    // console.log({ storeTotal, storeCandidates, storeStart })
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  const currentElemenLength = () => {
    return searchString !== '' ?
      storeCandidates
        .filter(
          e => `${e.email} ${e.firstname} ${e.lastname}`.indexOf(searchString) !== -1
        ).length
      : stateCandidates.length
  }

  return (
    <div className={styles.wrapper} >
      <div className={styles.content}>
        <div className={styles.toolbar}>
          <TextInput
            placeholder={formatMessage({ id: "shared:search" })}
            className={styles.search}
            value={searchString}
            type="search"
            onChange={setSearchString}
            aria-label="search text input"
          />
          {/**Modal pour selection des contacts */}
          <TransferModal
            onDone={(...ids:string[]) => {
              setStateCandidates(stateCandidates.filter(({contact_id}) => !ids.includes(contact_id)))
            }}
            selectedCandidateIds={selectedCandidatesIds}
            setSelectedCandidateIds={id => handleSelectCandidate(id)}
            renderTrigger={trigger => (
              <Button
                onClick={trigger}
                className={styles.btn_filled}
                isDisabled={selectedCandidatesIds.length == 0}
              >
                <FormattedMessage id="routes.transfer" />
              </Button>
            )}
          />
          {/**Affichage du Nombre delement selectionee */}
          <div className={styles.selections}>
            {selectedCandidatesIds.length} &nbsp;
            <FormattedMessage id="transfer:element_selected" />
          </div>
        </div>
        {/**Tableau */}
        {
          currentElemenLength() === 0 && !loading && (
            <ListEmpty />
          )}
        {currentElemenLength() > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            onSort={onSort}
            cells={table_header_columns}
            borders={false}
            rows={parseCandidates(
              searchString !== '' ?
                storeCandidates.filter(
                  e => `${e.email} ${e.firstname} ${e.lastname}`
                    .indexOf(searchString) !== -1
                )
                : stateCandidates
            )}
            actions={actions}
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}
        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{`
          ${(searchString !== '' ?
          storeCandidates.filter(
            e => `${e.email} ${e.firstname} ${e.lastname}`
              .indexOf(searchString) !== -1
          )
          : stateCandidates).length} 
          of ${storeTotal}`}</span>
        </div>
      </div>
    </div>
  );
}

export {
  TransferComponent as TransferPage,
}
