import React, { useState, useEffect, } from 'react'
import { AppStore, IProvince, IUser } from 'stores'
import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'
import { UtilitiesService } from 'services'
import { useNotificationData } from 'hooks'
import { useIntl } from 'react-intl';


export const useUserData = () => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [fetchError, setFetchError] = useState<boolean>(false)
    const [users, setUsers] = useState<IUser[]>([])

    const trigger = new Subject<boolean>()
    const { resetUsers, users$ } = AppStore
    const { new_notification } = useNotificationData()

    useEffect(() => {
        users$.pipe(
            take(1)
        ).subscribe(
            ({ data: storeUsers }) => {
                if (storeUsers.length === 0 && !loading && !fetchError) {
                    fetchUsers()
                    return
                }
                setUsers(storeUsers)
            }
        )

        return () => trigger.next(false)
    })

    const fetchUsers = () => {
        setLoading(true)
        UtilitiesService.list_users()
            .catch(err => {
                new_notification({
                    title: formatMessage({ id: 'shared:user_error' }),
                    variant: 'error',
                    message: formatMessage({ id: 'shared:err_get_user' }),
                })
                setLoading(false)
                setFetchError(true)
            })
            .then(async response => {
                if ([200, 201].includes(response.status)) {

                    const { data: payload } = await response.json()
                    resetUsers({ data: payload })
                    setUsers(payload)

                    setLoading(false)

                } else if ([404, 401, 500].includes(response.status)) {
                    const { message } = await response.json()
                    new_notification({
                        title: formatMessage({ id: 'shared:user_error' }),
                        message,
                        variant: 'error',
                    })
                    setLoading(false)
                    setFetchError(true)
                } else {
                    new_notification({
                        title: formatMessage({ id: 'shared:user_error' }),
                        message: formatMessage({ id: 'shared:err_get_user' }),
                        variant: 'error',
                    })
                    setLoading(false)
                    setFetchError(true)
                }
            })

    }

    return { loading, users }

}