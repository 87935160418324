import React, { useState, useEffect } from 'react';
import styles from './CandidateDetails.module.scss';
import { useAccordionData, useNotificationData, useCandidateData, useUserInfoData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { TextInput, Button, Spinner, spinnerSize, Tooltip } from '@patternfly/react-core'
import { ReactComponent as IconAdd } from 'assets/imgs/icon_add.svg'
import { ReactComponent as IconArchive } from 'assets/imgs/icon_archive.svg'
import { ReactComponent as IconClose } from 'assets/imgs/icon_close.svg'
import clsx from 'classnames'
import { CandidateForm, FormSelectorComponent, ListEmpty, AccordionContext, SendFormByEmail } from 'components'
import { RouteComponentProps } from 'react-router-dom'
import { FormService } from 'services';
import { NewCandidate, UpdateRequestServices } from 'dialogs';
import { ICandidate } from "stores"
import { ILoginInfo } from 'stores/auth.store';
import { CheckIcon, MailBulkIcon } from '@patternfly/react-icons';
import { useLanguageData } from 'hooks'

export interface CandidateDetailsProps extends RouteComponentProps {
}

export interface FormField {
  name: string,
  name_en: string,
  value: string | number | undefined,
  fieldName: string,
}



const CandidateDetailsComponent: React.FC<CandidateDetailsProps> = ({
  history, match,
  ...props
}) => {

  const { language } = useLanguageData()
  const [can, setCan] = useState<string[]>(history.location.pathname.split('/'))
  const [can_id, setCan_id] = useState<string>(can[can.length - 1])
  const [demandDetails, setDemandDetails] = useState<any>()
  const [demandFiles, setDemandFiles] = useState<any>()
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  let {
    candidate: storeCad,
  } = useCandidateData(can_id)

  const [
    standardFormCount, setStandardFormCount
  ] = useState<number>(0)

  const [
    shouldRefreshUi,
    setShouldRefreshUi,
  ] = useState<number>()

  const [candidate, setCandidate] = useState(storeCad)

  useEffect(
    () => setCandidate(storeCad),
    [storeCad]
  )

  const [questions, setQuestions] = React.useState<FormField[]>([])
  const { setBottomComponent } = React.useContext(AccordionContext);

  const [
    forms, setForms
  ] = React.useState<
    {
      questionFields?: any, name: string, id: string,
      external?: string, form_id: string,
      interviewee?: string, completed?: boolean,
    }[]
  >([])

  const [loadedForms, setLoadedForms] = useState<boolean>(false)

  const [
    isNotEditing, setIsNotEditing
  ] = React.useState<boolean>(false)

  const [
    currentForm, setCurrentForm
  ] = React.useState<any>()

  const { formatMessage } = useIntl()
  const { userInfo } = useUserInfoData()
  const { new_notification } = useNotificationData()
  const { resetAccordion } = useAccordionData()
  const [toDelete, setToDelete] = useState<string>('')
  const [query, setQuery] = useState<string>('')

  const [
    toBeModifiedCandidate,
    setToBeModifiedCandidate
  ] = useState<any>(undefined)


  const configureBottom = (
    setBottomComponent: any, can_id: string,
    setToBeModifiedCandidate: React.Dispatch<any>,
    candidate: ICandidate | undefined,
    demand: any,
    files = {},
  ) => {
    setBottomComponent(
      <div className={styles.bottomSection}>
        <div className={styles.flex_row}>
          <Button
            component="a" target="blank"
            href={`/report/${can_id}/${language}`}
          >
            <FormattedMessage
              id="shared:download_pdf"
            />
          </Button>
          <UpdateRequestServices
            verifiable_sections={
              demand.hydrated_verifiable_sections
            }
            candidate_id={can_id}
            standard_form_count={standardFormCount}
            onSaveStandardFormCount={setStandardFormCount}

            onDone={refreshUi}
            trigger={(_, deactivate) => (
              <Button
                onClick={_}
                isDisabled={deactivate}
              >
                <FormattedMessage
                  id="candidate_details:update_verifiable_sections"
                />
              </Button>
            )}
          />
          <Button
            onClick={_ => setToBeModifiedCandidate(candidate)}
          >
            <FormattedMessage id="form:update_candidate_info" />
          </Button>
        </div>
        {demand && demand.hydrated_verifiable_sections && (
          <>
            <FormattedMessage id="candidate:demand_details" />
            <ul className={styles.demand_details}>
              {demand.hydrated_verifiable_sections.map(({ name }) => (
                <li>{ name} </li>
              ))}
              {
                demand.credit_scan && <li>{formatMessage({ id: 'new_demand:credit_scan' })}</li>
              }
            </ul>
          </>
        )}
        {demand && !demand.hydrated_verifiable_sections && demand.credit_scan && (
          <>
            <FormattedMessage id="candidate:demand_details" />
            <ul className={styles.demand_details}>
              {
                demand.credit_scan && <li>{formatMessage({ id: 'new_demand:credit_scan' })}</li>
              }
            </ul>
          </>
        )}

        {Object.keys(files).length > 0 && (
          <>
            <FormattedMessage id="shared:attachements" />
            <ul className={styles.demand_files}>
              {Object.keys(files)
                .filter(key => Boolean(files[key]))
                .map(key => (
                  <li>
                    <a className={styles.link_download} target="_blank" href={files[key].replace('http:', 'https:')}>
                      <FormattedMessage id={`demand_detail:${key}`} />
                    </a>
                  </li>
                ))}
            </ul>
          </>
        )}


      </div>
    );
  }

  useEffect(() => {
    if (!Boolean(candidate)) { return }
    resetAccordion({
      title: formatMessage({
        id: 'candidate:candidate'
        //@ts-ignore
      }) + " : " + `${candidate.firstname} ${candidate.lastname}`
    })

    return () => resetAccordion({
      title: undefined
    })
  }, [candidate])

  const switchCurrentForm = (form: any) => {
    setCurrentForm(form)
    const { firstName, lastName } = userInfo as ILoginInfo
    const parsedQuestions = form.questionFields
      .flatMap(({ fields }) => fields)
      .map(
        ({ label: name, label_en:name_en, name: fieldName, value }) => {
          let returnVal = { name, name_en, fieldName, value }

          if (fieldName === 'quest.id' && name === "shared:technician") {
            returnVal.value = `${firstName} ${lastName}`
          }

          return returnVal
        })
    console.log({ parsedQuestions })
    setQuestions(parsedQuestions)
  }

  useEffect(() => {
    fetchCandidateFormsFromApi(can_id)
  }, [can_id, shouldRefreshUi])

  useEffect(() => {

    const unsubscribe = history.listen(location => {
      setCan(location.pathname.split('/'))
      setCan_id(can[can.length - 1])
      console.log({ can_id })
    })

    return unsubscribe

  }, [])


  const refreshUi = () => {
    console.log('refreshUi')
    setShouldRefreshUi(Math.random() * 1000)
  }

  useEffect(() => {
    if (
      !Boolean(candidate)
      || !Boolean(demandDetails)
      || !Boolean(demandFiles)
    ) { return }
    configureBottom(
      setBottomComponent, can_id,
      setToBeModifiedCandidate, candidate,
      demandDetails,
      demandFiles,
    );
    return () => setBottomComponent(<span></span>)
  }, [candidate, demandDetails, demandFiles, standardFormCount])

  /**
   * Listens to changes to the form whose id is to
   * be deleted
   */
  useEffect(() => {
    if (!Boolean(toDelete)) { return }
    deleteCandidateFormFromApi(toDelete)
  }, [toDelete])

  const onFormSubmittion = (data, setSubmitting, completed) => {
    let interviewee
    const updatedForm = {
      ...currentForm,
      questionFields: currentForm.questionFields!.map(
        (questionField) => ({
          ...questionField,
          fields: questionField.fields.map(
            field => {
              const key = Object.keys(data).find(k => k === field.name)
              if (key) {
                if (field.name === "interviewee") {
                  interviewee = data[key]
                }

                return { ...field, value: data[key] || "" }
              }
              return field
            }
          )
        })
      ),
      /**
       * interviewee value is set in the map function call above.
       * Probably line 217( update it if it changes)
       */
      // interviewee,
      completed: Boolean(completed),
    }


    submitCandidateFormToApi(
      {
        target_form_id: currentForm.form_id,
        candidate_id: can_id,
        data: updatedForm,
      },
      (form_instance_id) => {
        setSubmitting(false)
        // setSaveLoading(false)
        setIsNotEditing(!isNotEditing)
        //Update current questions and insert value
        setQuestions(
          questions.map(question => ({
            ...question,
            value: data[question.fieldName]
          }))
        )
        const formidx = forms.findIndex(
          ({ id }) => id === currentForm.id
        )
        const newforms = [...forms]
        updatedForm.id = form_instance_id

        /**
         * As id has changed, update the currentForm var to the new currentForm 
         * with the new id, no need to update questions
         * as questions are identical
         */
        setCurrentForm(updatedForm)
        newforms.splice(formidx, 1, updatedForm)
        setForms(newforms)
      },
      setSubmitting
    )
  }

  const fetchCandidateFormsFromApi = (candidate_id) => {
    FormService.get_candidate_form(candidate_id)
      .catch(err => {
        history.push("/me/candidates")
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const { forms: res, demands_details, files } = await response.json()

          const parsedForms = res.map(
            ({
              data: { form_id: _, interviewee, ...rest },
              id, target_form_id: form_id, is_standard,
            }) => ({
              ...rest, form_id, id, interviewee,
              is_standard,
            })
          )

          setDemandDetails(demands_details)
          setDemandFiles(files)

          setForms([
            // ...forms,
            ...parsedForms,
          ])

          setLoadedForms(true)

        } else if (response.json) {
          history.push("/me/candidates")
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          history.push("/me/candidates")
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const submitCandidateFormToApi = (
    form, onDone, setSubmitting
  ) => {
    let method
    /**
     * id's for local forms are generated using a call to Math.floor(Math.random()*1000
     * which returns a value such that 0>= value >= 999
     */
    if (form.data.id.length > 3) {
      method = FormService.update_candidate_form({
        form_id: form.data.id,
        data: {
          ...form.data,
          external: false,
        },
      })
    } else {
      method = FormService.create_candidate_form(form)
    }

    method
      .catch(err => {
        setSubmitting(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const {
            data: { id: restInstanceId }, message
          } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
          onDone(restInstanceId)
        } else if (
          response.json
        ) {
          setSubmitting(false)
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setSubmitting(false)
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const deleteCandidateFormFromApi = form_id => {
    FormService.delete_candidate_form(form_id)
      .catch(err => {
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
        setToDelete('')
      })
      .then(async response => {
        setToDelete('')
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          setForms(forms.filter(f => f.id !== form_id))
          //empty questions if it's the current form
          if (currentForm && currentForm.id === form_id) {
            setQuestions([])
          }
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
        } else if ([404].includes(response.status)) {
          setForms(forms.filter(({ id }) => id !== form_id))
          if (currentForm && currentForm.id === form_id) {
            console.log({ form_id, currentForm })
            setQuestions([])
            setCurrentForm(undefined)
            setIsNotEditing(true)
          }
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const handleOnformSelected = (...paramforms) => {
    /**For some weird reason,
     * Within the context of this function, 
     * referencing the variable forms in a very short span,
     * forms seems not to reflect the updated value.
     */
    let parsedForms = paramforms.map(
      ({ name, questionFields, id: form_id }) => ({
        form_id,
        name,
        questionFields,
        id: `${Math.floor(Math.random() * 1000)}`
      })
    )

    setForms([...parsedForms, ...forms])
  }

  const onRefreshSelectedForms = (...paramforms) => {
    /**For some weird reason,
     * Within the context of this function, 
     * referencing the variable forms in a very short span,
     * forms seems not to reflect the updated value.
     */
    let parsedForms = paramforms.map(
      ({ name, questionFields, id: form_id }) => ({
        form_id,
        name,
        questionFields,
        id: `${Math.floor(Math.random() * 1000)}`
      })
    )

    setForms([...parsedForms, ...forms])
  }

  const selectOptionsValue = {
    "Excellent": formatMessage({ id: "standard_form:excellent" }),
    "Très bon": formatMessage({ id: "standard_form:very_good" }),
    "Bon": formatMessage({ id: "standard_form:good" }),
    "Passable": formatMessage({ id: "standard_form:fair" }),
    "Faible": formatMessage({ id: "standard_form:low" }),
    "Very good": formatMessage({ id: "standard_form:very_good" }),
    "Good": formatMessage({ id: "standard_form:good" }),
    "Fair": formatMessage({ id: "standard_form:fair" }),
    "Low": formatMessage({ id: "standard_form:low" }),
    "Oui": formatMessage({ id: "shared:yes" }),
    "Non": formatMessage({ id: "shared:false" }),
    "Yes": formatMessage({ id: "shared:yes" }),
    "No": formatMessage({ id: "shared:false" }),
  }

  return (
    <div className={styles.wrapper} >
      {!candidate || +candidate.folder_status !== 3 && (
        <div className={styles.candidate_forms}>
          <span className={styles.title}>
            <FormattedMessage id="shared:forms" />
          </span>
          <div className={styles.flex_column}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <FormSelectorComponent
              loadedForms={loadedForms}
              onSaveStandardFormCount={
                setStandardFormCount
              }
              alreadyLoadedForms={forms}
              standardFormCount={standardFormCount}
              shouldAutoReload={shouldRefreshUi}
              onFormSelected={handleOnformSelected}
              onRefreshSelectedForms={onRefreshSelectedForms}
              candidate_id={can_id}
            >
              {_ => (
                <button className={'btn_icon'}>
                  <IconAdd className={styles.icon_add} />
                </button>
              )}
            </FormSelectorComponent>
          </div>
          <TextInput
            className={styles.textinput}
            onChange={setQuery}
            placeholder={formatMessage({ id: 'shared:search' })}
            isDisabled={forms.length < 1}
          />
          <ul className={styles.forms_list}>
            {forms
              .filter(({ name = '' }) => name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
              .map((form) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '1em',
                    cursor: "pointer",
                  }}
                  className={clsx(
                    currentForm && currentForm.id === form.id && styles.current_form
                  )}
                >
                  <div
                    className={
                      styles.form
                    }
                    onClick={_ => switchCurrentForm(form)}
                  >
                    {toDelete === form.id ? (
                      <Spinner size={spinnerSize.md} />
                    ) : (
                        <IconArchive />
                      )}
                    <span>
                      {form.name}
                    &nbsp;
                    {form.interviewee && (
                        <Tooltip
                          content={
                            <FormattedMessage id="shared:interviewee" />
                          }
                        >
                          <span className={styles.c_primary}>({form.interviewee})</span>
                        </Tooltip>
                      )}
                    &nbsp;
                    {form.external && (
                        <Tooltip
                          content={
                            <FormattedMessage id="shared:external_form" />
                          }
                        >
                          <MailBulkIcon />
                        </Tooltip>
                      )}
                    &nbsp;
                    {form.completed && (
                        <Tooltip
                          content={
                            <FormattedMessage id="shared:completed" />
                          }
                        >
                          <CheckIcon
                            style={{ color: 'green' }}
                          />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                  {false && (
                    <button
                      className={'btn_icon'}
                      onClick={e => {
                        e.preventDefault()
                        // setToDelete(form.id)
                      }}
                    >
                      <IconClose />
                    </button>
                  )}
                </div>
              ))}
          </ul>
        </div>
      )}

      <div className={styles.main_section}>
        {!candidate || ![5, 3].includes(+candidate.folder_status) ? (
          <>
            {Boolean(currentForm) ? (
              <>
                {isNotEditing ? (
                  <div>
                    <div className={styles.title_row}>
                      <h3 className={styles.heading}>
                        {currentForm.name}
                      </h3>
                      <div className={styles.row}>
                        {currentForm && currentForm.id.length < 4 && (
                          <SendFormByEmail
                            currentForm={currentForm}
                            candidate_id={can_id}
                            trigger={onClick => (
                              <Button
                                className={styles.btn_modify}
                                onClick={onClick}
                              >
                                <FormattedMessage id="shared:send_by_email" />
                              </Button>
                            )}
                          />
                        )}
                        <Button
                          className={styles.btn_modify}
                          onClick={e => setIsNotEditing(!isNotEditing)}
                        >
                          <FormattedMessage id="shared:update" />
                        </Button>
                      </div>
                    </div>

                    <div className={styles.grid_columns}>
                      {
                        questions.map(inf => (
                          <div
                            className={styles.flex_column}
                          >
                            {/* -- Here is the preview of the form. eg when you finish filled your form and submit it or when you choose a form and click on cancel -- */}
                            {/* formatMessage is for default standard form  and the check on language is for other*/}
                            <span>{ language == "en" ? (inf.name_en && formatMessage({ id: inf.name_en }) ? formatMessage({ id: inf.name_en }) : (formatMessage({ id: inf.name }))) : formatMessage({ id: inf.name }) }</span>&nbsp; &nbsp;
                            {/* Not only inf.value because for standard form we have option which have to be translated first */}
                            <span className={styles.title}>{inf.value ? (selectOptionsValue[inf.value] ? selectOptionsValue[inf.value] : inf.value) : inf.value}</span>
                          </div>
                        ))
                      }
                    </div>

                  </div>
                ) : (
                    <CandidateForm
                      onSave={onFormSubmittion}
                      loading={saveLoading}
                      setLoading={setSaveLoading}
                      switchEditingToViewMode={
                        () => setIsNotEditing(!isNotEditing)
                      }
                      fields={
                        currentForm.questionFields.filter(
                          question => question.fields.length > 0
                        )
                      }
                      name={currentForm.name}
                      questions={
                        questions.reduce((acc, field, idx, arr) => ({
                          ...acc,
                          [field.fieldName]: field.value,
                          [field.name]: language == "en" ? (field.name_en ? field.name_en : field.name) : field.name
                        }), {})
                      }
                    />
                  )}
              </>
            ) : (
                <ListEmpty
                  title="shared:select_form"
                  message="shared:no_form_selected"
                />
              )}
          </>
        ) : (
            <ListEmpty
              title="shared:request_closed"
              message="shared:request_closed_message"
            />
          )}
      </div>
      <NewCandidate
        onClose={
          e => setToBeModifiedCandidate(undefined)
        }
        candidate={
          toBeModifiedCandidate
        }
        setToBeModifiedCandidate={
          setToBeModifiedCandidate
        }
        onUpdate={setCandidate}
      />
    </div >
  );
}

export {
  CandidateDetailsComponent as CandidateDetailsPage,
}
