export default {
    "shared:payment_status": "Statut de paiement",
    'shared.french': "Français",
    'shared.english': "Anglais",
    'routes.settings': "Paramètres",
    'routes.dashboard': "Tableau de bord",
    'routes.general': "Générale",
    'routes.my_profile': "Mon profil",
    "en": "English",
    "shared:amount_indebted": "Montant à découvert",
    "routes.candidates": "Candidats",
    "fr": "Français",
    'candidate:candidate_list': "Liste des candidats",
    "routes.archives": "Archives",
    "routes.companies": "Entreprises",
    "companies:list_of_companies": "Liste des entreprises",
    'archives:list_of_archived_candidates': "Liste des candidats archivés",
    "shared:users": "Utilisateurs",
    "shared:administration": "Administration",
    "shared:forms": "Formulaires",
    'routes:forms': 'Formulaires',
    "routes:categories": "Catégories",
    "routes:demands": "Demandes",
    "shared:manager": "Correspondant",
    "shared:client": "Client",
    "shared:address": "Adresse",
    "shared:tel": "Téléphone",
    "shared:post_number_required": "Numéro de poste requis",
    "shared:status": "Statut",
    "shared:fax_received": "Fax recue",
    "shared:loading": "En cours",
    "shared:locked": "Fermer",
    "shared:locked_masculine": "Fermé",
    "shared:open": "Ouvertes",
    "shared:opened": "Ouvertes",
    "shared:open_masculine": "Ouvert",
    "shared:more_details": "Détails",
    "shared:archived": "Archivées",
    "shared:archived_masculine": "Archivé",
    "candidate:new_candidate": "Nouveau candidat",
    "shared:search": "Rechercher...",
    "shared:family_name": "Nom de famille",
    "shared:name": "Nom",
    "shared:email": "Courriel",
    "shared:province": "Province",
    "shared:driver_licence_number": "Numero de permis de conduire",
    "shared:NAS": "NAS",
    "shared:document_email": "Email du document",
    "shared:postal_code": "Code postal",
    "shared:contact": "Contact",
    "shared:cancel": "Annuler",
    "new_candidate:create_the_candidate": "Créer le candidat",
    "company:new_company": "Nouvelle entreprise",
    "new_company:new_company": "Nouvelle entreprise",
    "shared:new_company": "Créer l'entreprise",
    "shared:moto": "Devise",
    "shared:fax": "Fax",
    "shared:identification_name": "Nom d'identification",
    "shared:city": "Ville",
    "demands:list_of_demands": "Liste des demandes",
    "demands:new_demands": "Nouvelle demande",
    "demand:requester": "Demandeur",
    "shared:candidate": "Candidat",
    "demand:job_requested": "Travail demandé",
    "shared:date": "Date",
    "shared:filter": "Filtrer",
    "company_filter:company_filter": "Filtrer les entreprises",
    "shared:member_of_entity": "Membre de l'entité",
    "shared:created_on": "Créé le",
    "shared:modified_on": "Modifié le",
    "shared:is_empty": "Est vide",
    "shared:question": "Question (en Français)",
    "shared:question_en": "Question (en Anglais)",
    "shared:type": "Type",
    "shared:form_type": "Type formulaire",
    "shared:category_type": "Type catégorie",
    "shared:mandatory": "Obligatoire",
    "notification:new_candidate_tranfer": "Nouveau transfert de candidat",
    "shared:description": "Description",
    "shared:on": "sur",
    "shared:update": "Mettre à jour",
    "shared:delete": "Supprimer",
    "category:list_of_categories": "Liste des catégories",
    "category:category_name": "Nom de la catégorie",
    "category:add_new_category": "Ajouter une catégorie",
    "category:number_of_questions": "Nombre de questions",
    "category:number_of_forms": "Nombre de formulaires",
    "shared:option": "Option",
    "shared:fullwidth": "A afficher sur toute la ligne",
    "routes.transfer": "Transfert",
    "shared:firstname": "Nom",
    "shared:lastname": "Prénom",
    "shared:driving_license_number": "Numéro de permis de conduire",
    "shared:phonenumber": "Téléphone",
    "shared:your_folder": "Votre dossier",
    "shared:in_progress": "En cours",
    "shared:transfer_of_candidates_to_contact": "Transfert des candidats au contact",
    "routes.profile": "Mon profil",
    "profile:basic_info": "Informations de base",
    "profile:user_profile": "Profil de l'utilisateur",
    "shared:username": "Nom d'utilisateur",
    "shared:load_avatar": "Charger l'avatar",
    "shared:logout": "Déconnexion",
    "user:list_of_users": "Liste des utilisateurs",
    "user:add_new_user": "Nouvel utilisateur",
    "user:user": "Utilisateur",
    "shared:last_connexion": "Dernière connexion",
    "routes.users": "Utilisateurs",
    "user:create_user": "Créer l'utilisateur",
    "shared:company": "Entreprise",
    "shared:password": "Mot de passe",
    "shared:role": "Rôle",
    "shared:save": "Sauvegarder",
    "shared:public": "Public",
    "user:permissions": "Permissions et groupes",
    "shared:search_name": "Rechercher un nom",
    "shared:find": "Rechercher",
    "shared:administrator": "Administrateur",
    "shared:technician": "Technicien",
    "user_filter:user_type": "Type d'utilisateur",
    "shared:cancel_filter": "Annuler le filtre",
    "transfer:element_selected": "Elements sélectionnés",
    "new_form:new_form": "Ajouter un formulaire",
    "shared:is_public": "Est publique",
    "shared:empty_list": "Aucun élément.",
    "shared:confirm_user_suppr": "Voulez-vous vraiment supprimer cet utilisateur?",
    "new_category:new_category": "Ajouter une catégorie",
    "new_category:edit_category": "Editer une catégorie",
    "user:update_user": "Modifier l'utilisateur",
    "shared:field_required": "Ce champ est obligatoire",
    "candidate:candidates": "Candidat",
    "shared:lastname_required": "Prénom requis",
    "shared:firstname_required": "Nom requis",
    "shared:email_required": "Courriel requis",
    "shared:address_required": "Adresse requise",
    "shared:province_required": "Province requise",
    "shared:city_required": "Ville requise",
    "shared:driving_license_number_required": "Permis de conduire requis",
    "shared:nas_required": "NAS requis",
    "shared:request_email_required": "Courriel du demandeur requis",
    "shared:birthday_required": "Date de naissance requise",
    "shared:phonenumber_required": "Numero de téléphone requis",
    "shared:postal_code_required": "Code postal requis",
    "shared:contact_id_required": "Contact requis",
    "shared:connected_as": "Votre rôle : ",
    "shared:admin": "Administrateur",
    "shared:select": "Choisir...",
    "shared:request_email": "Courriel du contact",
    "shared:invalid_email": "Email invalide",
    "shared:birthday": "Date de naissance",
    "shared:number": "Numéro",
    "shared:phone": "Numéro téléphone",
    "shared:initials": "Initiales",
    "shared:invalid_number": "Numéro invalide",
    'shared:number_required': "Numéro requis",
    'shared:name_required': "Nom requis",
    'shared:initials_required': "Initiales requises",
    'shared:fax_required': "Fax requis",
    "shared:invalid_phonenumber": "Numéro de téléphone invalide",
    "shared:identification_name_required": "Numéro d'identification requis",
    "new_demand:application_and_job": "Informations relatives au demandeur",
    "new_demand:requester_name": "Nom du demandeur",
    "new_demand:application_of": "Candidature de",
    "shared:job_requested": "​Emploi demandé",
    "shared:candidate_mail": "Mail du candidat",
    "shared:comments": "Commentaire",
    "demand:info_to_verified": "Vérifiez les informations suivantes",
    "demand:authorization_form": "Formulaire d'autorisation",
    "demand:cv": "Curriculum Vitae",
    "demand:diplomas_copy": "Copie de diplômes",
    "demand:references": "Références",
    "demand:full_plumitif": "Plumitifs civil, statutaire, criminel et pénal",
    "demand:criminal_penal_plumitif": "Plumitifs criminel et pénal seulement",
    "demand:academic_training": "Formation académique(diplômes)",
    "demand:credit_verification": "Vérification du crédit",
    "demand:old_job_references": "Références chez les anciens employeurs",
    "demand:professional_associations": "Appartenance aux associations professionnelles",
    "demand:driver_license": "Permis de conduire",
    "shared:do_you_want_to_continue": "Cette action supprime définitivement les informations séléctionnées.",
    "shared:success": "Succès",
    "shared:error": "Erreur",
    "shared:unknown_error": "Erreur inconnue",
    "shared:modify": "Modifier",
    "new_candidate:edit_candidate": "Modifier le candidat",
    "new_company:edit_company": "Modifier l'entreprise",
    "shared:town": "Ville",
    "edit_form:edit_form": "Editer le formulaire",
    "edit_form:add_form": "Ajouter un formulaire",
    "shared:job_requested_equired": "Travail demandé requis",
    "shared:candidate_lastname_required": "Nom du candidat requis",
    "shared:is_archived": "Archivé",
    "shared:folder_status": "Statut",
    "shared:folder_number": "Numéro du dossier",
    "shared:contact_id": "Contact",
    "shared:nas": "Numéro d'Assurance Sociale(NAS)",
    "shared:candidate_filter": "Filtre des candidats",
    "shared:username_required": "Nom d'utilisateur requis",
    "shared:company_required": "Entreprise requise",
    "shared:role_required": "Rôle requis",
    "demand:attachments": "Pièces jointes",
    "shared:archive": "Archiver",
    "candidate:prompt_archive_candidates": "Voulez-vous archiver ce(s) candidat(s)?",
    "candidate:prompt_unarchive_candidates": "Voulez-vous désarchiver ce(s) candidat(s)?",
    "shared:unarchive": "Désarchiver",
    "shared:delete_candidate": "Supprimer",
    "shared:download_pdf": "Télécharger le pdf",
    "shared:archive_candidate": "Archiver le contact",
    "shared:see_more": "Voir plus",
    "shared:company_filter": "Filtre des entreprises",
    "shared:categories_filter": "Filtre des catégories",
    "shared:enterprise_number": "Numéro de l'entreprise",
    "shared:questions_count": "Nombre de questions",
    "shared:forms_counts": "Nombre de formulaires",
    "candidate:candidate": "Candidat",
    "shared:add_form": "Ajouter un formulaire",
    "shared:demand_filter": "Filtre des demandes",
    "shared:applicant": "Applicant",
    "shared:employment": "Emploi demandé",
    "shared:no_categories": "Pas de catégories",
    "shared:upload": "Téléverser...",
    "ouvert": "Ouvert",
    "pdf:general_info": "Informations générales",
    "shared:new_question": "Ajouter une question",
    "pdf:candidate_report": "Rapport du candidat",
    "shared:n_a": "Non renseigné",
    "new_question:edit": "Editer une question",
    "new_questions:add": "Ajouter une question",
    "shared:edit": "Editer",
    "demand_detail:demand_detail": "Détail d'une demande",
    "demand_detail:application_and_job": "Candidature et emploi",
    "demand_detail:date": "Date de la demande",
    "demand_detail:candidate": "Candidat",
    "demand_detail:requester_name": "Nom du demandeur",
    "demand_detail:job_requested": "Emploi demandé",
    "demand_detail:requester_email": "Courriel du demandeur",
    "demand_detail:comments": "Commentaire",
    "demand_detail:verification_request": "Demande de vérification",
    "demand_detail:attachments": "Pièces jointes",
    "demand_detail:download": "Télécharger",
    "demand_detail:demand_status": "Statut de la demande",
    "shared:cv": "Curriculum vitae",
    "demand_detail:download_pdf": "Télécharger le pdf",
    "shared:references": "Références",
    "shared:categories_count": "Nombre de catégories",
    "new_demand:candidate_firstname": "Prénom du candidat",
    "new_demand:candidate_lastname": "Nom du candidat",
    "form:base_information": "Informations de base",
    "shared:candidate_evaluation": "Evaluation du candidat",
    "shared:professional_competences": "Compétences professionnelles",
    "form:update_candidate_info": "Inspecter/Modifier les informations du candidat",
    "role:error_fetching_role": "Une erreur s'est produite lors de l'obtention des rôles",
    "shared:enterprise_matricule": "Matricule d'entreprise",
    "shared:empty_list_title": "Liste vide",
    "shared:notifications": "Notifications",
    "shared:help": "Aide",
    "shared:select_form": "Veuillez sélectionner un formulaire",
    "shared:no_form_selected": "Aucun formulaire n'a été sélectionné",
    "shared:interviewer": "Interviewer",
    "shared:billed": "Facturé",
    "shared:phone_number": "Numéro de téléphone",
    "shared:tel_and_post_number": "Numéro de poste et téléphone",
    "shared:post_number": "Poste",
    "shared:postNumber": "Numéro de poste",
    "shared:verifiable_section_required": "Les sections vérifiables sont requises",
    "external_company:creation_page_instructions": `
    Cette page est dédiée à la création de votre entreprise
    sur la plateforme Pré-emploi.
    Veuillez remplir les champs suivants pour créer votre entreprise.
        `,
    "external_company:creation_success_message": `
        Votre entreprise est maintenant créée, votre compte sur l'application 
        Pré-emploi ({ui_url}) sera crée sous peu et vous recevrez un couriel 
        de confirmation dans les prochaines heures.
    `,
    "external_company:creation_demand_instructions": `
    Cette page est dédiée à faire des demandes
    sur la plateforme Pré-emploi.
    Veuillez remplir les champs suivants pour créer votre demande.
        `,
    "external_company:creation_demand_success_message": `
        Demande envoyée avec succès. Merci de nous faire confiance! :)
    `,
    "shared:send_company_email_for_creation": "Envoyer un e-mail de création à l'entreprise",
    "company:create_by_email": "Créer par email",
    "shared:send_by_email": "Envoyer par email",
    "shared:error_prompt": "Une erreur s'est produite lors de la récupération des données",
    "shared:send_mail": "Envoyer un mail",
    "shared:send_email_to_company_to_create_company": "Envoyer un e-mail pour créer une entreprise",
    "shared:reorder": "Réorganiser",
    "shared:yes": "Oui",
    "shared:false": "Non",
    "shared:is_standard": "Est un formulaire standard : ",
    "company_creation": "Création d'entreprise",
    'shared:does_not_match': "Ne correspond pas",
    "shared:send": "Envoyer",
    "shared:password_required": "Mot de passe requis",
    "auth:recover_password_instructions": "Un mail vous a été envoyé",
    "shared:confirm_password": "Confirmer le mot de passe",
    "shared:interviewer_info": "Informations",
    "shared:send_email_to_company_to_create_demand": "Envoyer le formulaire pour la création de la demande",
    "shared:requester_email": "Courriel du demandeur",
    "shared:enterprise_email": "Courriel de l'entreprise",
    "new_demand:creation": "Créer une demande",
    "shared:current_password": "Mot de passe actuel",
    "shared:new_password": "Nouveau mot de passe",
    "shared:about_application": "À propos de pré-emploi",
    "shared:ok": "OK",
    "shared:app_env": "Environnement d'application",
    "shared:build_version": "Numéro de build",
    "pdf:reorder_pdf": "Réordonner les formulaires",
    "company:activate_enterprise_prompt": "Choisir un contact comme administrateur de l'entreprise que vous voulez activer",
    "company:write_or_select_candidate": "Ecrire ou sélectionner le contact",
    "shared:activate_enterprise": "Activer l'entreprise",
    "routes.activated_companies": "Entreprises activées",
    "shared:deactivate_enterprise": "Désactiver l'entreprise",
    "shared:deactivate_enterprise_prompt": "Voulez-vous réellement désactiver cette entreprise?",
    "stripe:prompt_stripe_info": "Votre demande a été enregistrée en tant que brouillon, veuillez entrer les informations de votre carte de crédit ci-dessous pour payer.",
    "stripe:enter_stripe_info": "Entrez les détails de votre carte bancaire",
    "stripe:pay_layer": "Je paierai plus tard.",
    "active_company:departments": "Départements",
    "active_company:users": "Utilisateurs",
    "active_company:complete_demands": "Demandes complètes",
    "active_company:candidates": "Candidats",
    "active_company:incomplete_demands": "Demandes incomplètes",
    "shared:of": "de",
    "shared:activated_company_filter": "Filtrer",
    "shared:has_departments": "À des départements?",
    "shared:has_candidates": "À des candidats?",
    "shared:has_incomplete_demands": "A des demandes incomplètes?",
    "shared:has_complete_demands": "À des demandes complètes?",
    "shared:deactivate": "Désactiver",
    "shared:create_department": "Créer le département",
    "shared:delete_department": "Supprimer le(s) département(s)",
    "shared:deactivate_company": "Désactiver l'entreprise",
    "shared:last_update": "Dernière mise à jour",
    "shared:new_department": "Nouveau département",
    "shared:actives": "Activées",
    "shared:in_active": "Non activée",
    "shared:delete_company_department": "Voulez vous supprimer le(s) département(s) de l'entreprise?",
    "shared:create": "Créer",
    "shared:create_department_prompt": "Vous souhaitez ajouter un nouveau département à l'entreprise?",
    "new_dep:enter_dep_name": "Nom du département",
    'routes.enterprise_dashboard': "Tableau de bord",
    "dashboard:total_demands": "Total des demandes",
    "dashboard:current_months_demands": "Demandes du mois en cours",
    "dashboard:stats": "Statistiques des demandes de cette année",
    "shared:complete_demands": "Demandes complètes",
    "shared:incomplete_demands": "Demandes incomplètes",
    "shared:candidates": "Candidats",
    "shared:departments": "Départements",
    "Administrator": "Administrateur",
    "Accountant": "Comptable",
    "Technician": "Technicien",
    "Contact": "Contact",
    "Company_Administrator": "Contact",
    "routes.departments": "Département",
    "routes.enterprise_departments": "Département",
    "shared:lorem_short": `
        In publishing and graphic design, Lorem ipsum is a 
        placeholder text commonly used to demonstrate 
        the visual form of a document or a typeface without 
        relying on meaningfu
    `,
    "shared:update_department_prompt": "Entrez le nouveau nom du département nommé",
    "shared:prompt_delete_department": "Voulez-vous supprimer ce(s) département(s)?",
    "dep_users:users_of_dep": "Utilisateurs du département",
    "shared:new_user": "Nouvel utilisateur",
    "shared:view_users": "Voir les utilisateurs",
    "shared:enterprise": "Entreprises",
    "shared:userListing": "Nom d'utilisateur",
    "shared:mail": "Courriel",
    "shared:department": "Département",
    "shared:matricule": "Matricule",
    "shared:determining": "Détermination...",
    "shared:customers": "Clients",
    "nonEditableStandardForms": "Erreur lors de la récupération des formulaires",
    "shared:active_companies": "Entreprises activées",
    "shared:companies": "Entreprises",
    "shared:inactive": "Non-activée",
    "shared:inactives": "Non-activées",
    "jan": "Jan",
    "feb": "Fev",
    "mar": "Mar",
    "avr": "Avr",
    "mai": "Mai",
    "jun": "Juin",
    "jul": "Juil",
    "aou": "Aou",
    "sep": "Sep",
    "oct": "Oct",
    "nov": "Nov",
    "dec": "Dec",
    "dashboard:division": "Nombre de requêtes divisé par : ",
    "shared:updated_at": "Dernière mise à jour",
    "shared:update_status": "Changer le statut",
    "shared:update_candidate": "Modifier le candidat",
    "candidate:update_status_prompt": "Sélectionnez un statut pour mettre à jour le statut du candidat",
    "shared:close": "Fermer",
    "auth:update_password": "Ré-initialisation du mot de passe",
    "auth:recover_password": "Récupérer mon compte.",
    "auth:enter_new_password_prompt": "Veuillez saisir votre nouveau mot de passe et le confirmer. Il est préférable de ne pas copier et coller",
    "auth:new_password_prompt": "Vous pouvez utiliser le mot de passe généré dans votre courriel ou entrer un nouveau mot de passe de votre choix",
    "company:activate_company": "Activer l'entreprise",
    "shared:manager_username": "Nom d'utilisateur du gestionnaire",
    "shared:manager_email": "Courriel du responsable",
    "shared:manager_username_required": "Le nom d'utilisateur du gestionnaire est requis",
    "shared:email_invalid": "Email invalide",
    "shared:manager_firstName": "Prénom du responsable",
    "shared:manager_firstname_required": "Le prénom du responsable est obligatoire",
    "shared:manager_lastName": "Nom du responsable",
    "shared:manager_lastname_required": "Le nom du responsable est requis",
    "shared:company_correspondent": "Informations correspondantes",
    "candidate:demand_details": "Détails de la demande",
    "shared:closed": "Fermées",
    "shared:order": "Ordre",
    "shared:desc": "Descendant",
    "shared:fax_sent": "Fax envoyé",
    "verification:new_request_verification": "Nouvelle demande de vérification",
    "transfer:contact_list": "Liste des contacts",
    "transfer:selected_candidates": "Candidats sélectionnés",
    "transfer:see_list": "Voir la liste",
    "notification:requested_by": "Demandé par",
    "notification:candidates": "Candidats",
    "notification:new_request_verification": "Nouvelle demande de vérification",
    "notification:show": "Afficher",
    "shared:asc": "Ascendant",
    "question:new_questions": "Ajouter une question",
    "shared:questions": "Question",
    "shared:properties": "Propriété",
    "shared:form": "Formulaire",
    "shared:category": "Catégorie",
    "shared:checkbox": "Checkbox",
    "shared:text": "Texte",
    "shared:textarea": "Textarea",
    "shared:radio": "Radio",
    "question:select_form_and_category": "Avant de pouvoir créer une question, vous devez selectionner le formulaire et une catégorie auxquels elle sera ajoutée.",
    "shared:proceed": "Procéder",
    "questions:all_questions": "Toutes questions",
    "shared:add_question": "Ajouter une question",
    "routes:questions": "Questions",
    "verifiable_section:new_section": "Ajouter un nouvel objet de vérification",
    "verifiable_section:update_section": "Mettre à jour l'objet de vérification",
    "shared:create_section": "Créer l'objet",
    "shared:update_section": "Mettre à jour l'objet",
    "shared:creation_section_prompt": "Ce formulaire vous permet de créer un nouvel objet de vérification pour les demandes de candidats",
    "verfiable_section:new_section": "Ajouter une vérification",
    "verifiable_section:all_sections": "Ces informations sont à vérifier",
    "verifiable_section:title_delete": "Supprimer une section de vérification",
    "verifiable_section:delete_prompt": "Cette action supprime définitivement la section de vérification.",
    "shared:is_active": "Est activée",
    "routes:verifiable_section": "Sections vérifiables",
    "verification:verifiable_sections": "Sections vérifiables",
    "demand_detail:authorization_form": "Formulaire d'autorisation",
    "demand_detail:cv": "Curriculum vitae",
    "demand_detail:diplomas_copy": "Copie diplômes",
    "demand_detail:references": "Références",
    "shared:attachements": "Pièces jointes",
    "shared:see_more_details": "Voir plus de détails",
    "shared:proceed_with_payment": "Procéder au paiement",
    "routes:draft_demands": "Demandes en attente",
    "shared:no_drafted_demands": "Aucune demande soumise",
    "demands:list_of_draft_demands": "Liste des demandes en attente",
    "shared:reopened": "Réouverte",
    "shared:authorization_form_required": "Formulaire d'autorisation requis",
    "shared:request_closed": "Cette demande est close",
    "shared:request_closed_message": "Cette demande a été étiquetée comme fermée",
    "candidate:archived_candidate_list": "Liste des candidats archivés",
    "category:select_categories_prompt": "Sélectionnez les différentes catégories auxquelles ajouter ces questions:",
    "shared:select_company": "Choisir le gestionaire de l'entreprise",
    "users:prompt_remove_user_from_department": "Souhaitez-vous supprimer cet utilisateur du département?",
    "user:add_users": "Ajouter des utilisateurs",
    "shared:add_user_to_department": "Ajouter les utilisateurs au département",
    "shared:send_email": "Cet utilisateur peut recevoir des e-mails",
    "shared:add_user": "Ajouter des utilisateurs",
    "shared:add_option": "Ajouter une option",
    "shared:form_submitted": "Formulaire soumis",
    "shared:send_form_by_email": "Envoyer le formulaire à un tiers.",
    "shared:prompt_email": "Tiers@person.courriel...",
    "pdf:your_documents": "Vos documents",
    "pdf:recruiter": "Recruteur",
    "shared:date_of_birth": "Date de naissance",
    "shared:driver_licence": "Permis de conduire",
    "shared:telephone": "Téléphone",
    "shared:additional_notes": "Notes supplémentaires",
    "shared:phone_and_post": "Numéro de téléphone/poste",
    "shared:price": "Prix",
    "billing:number_of_requests": "Nombre de demandes",
    "shared:total_price": "Coût des demandes ($)",
    "shared:last_demand_date": "Dernière demande",
    "shared:actions": "Action",
    "billing:used_service_billing": "Facturation des services utilisés",
    "routes:billing": "Facturation des services",
    "shared:paid": "Payé",
    "shared:payment_pending": "Paiement en attente",
    "shared:candidate_name": "Nom du candidat",
    "shared:standard_form": "Formulaire standard",
    "shared:discount": "Réduction ",
    "shared:year_month": "Année et mois",
    "shared:year": "Année",
    "shared:month": "Mois",
    "shared:janurary": "Janvier",
    "shared:february": "Février",
    "shared:march": "Mars",
    "shared:april": "Avril",
    "shared:may": "Mai",
    "shared:june": "Juin",
    "shared:jully": "Juillet",
    "shared:august": "Août",
    "shared:september": "Septembre",
    "shared:october": "Octobre",
    "shared:november": "Novembre",
    "shared:december": "Décembre",
    'shared:external_form': "Formulaire externe",
    "questions:add_to_categories": "Ajouter aux catégories",
    'shared:update_balance_prompt': "Entrez + ou - montant pour mettre à jour le solde",
    "shared:categories": "Catégories",
    "shared:list_categories": "Liste des catégories",
    "shared:add_to_categories_prompt": "Souhaitez-vous ajouter la/les question (s) suivante (s) aux catégories suivantes?",
    "shared:add_to_categories": "Ajouter des questions aux catégories",
    "shared:error_fetching_users": "Une erreur inconnue s'est produite lors de la récupération des utilisateurs",
    "shared:pricing_plan": "Plan de souscription",
    "shared:pricing_plan_required": "Plan de souscription requis",
    'shared:pricing_per_unit': "Facturation à l'unité",
    'shared:pricing_per_month': "Facturation par mois",
    "shared:balance": "Solde du compte",
    "shared:total_amount": "Montant total",
    "shared:update_balance": "Mettre à jour le solde",
    'users:no_users': "Pas d'utilisateurs pour ce compte",
    "users:create_users_message": "Veuillez en créer quelques...",
    "shared:update_payment_status": "Mettre à jour l'état du paiement",
    "billing:change_payment_status": "Modifier l'état du paiement",
    "billing:update_billing_status": "Sélectionnez le statut pour mettre à jour le statut de toutes les factures sélectionnées.",
    "shared:no_interviewee_prompt": "Veuillez remplir la personne contacté ci-dessous avec votre nom",
    "shared:no_interviewee": "Aucune information sur la personne contacté",
    "shared:interviewee": "Personne contactée",
    "shared:w_r_t_demand": "Par rapport à l'emploi demandé",
    "shared:w_r_t_candidate": "Par rapport à l'emploi du candidat",
    "shared:completed": "Complété",
    "shared:save_complete": "Sauvegarder et compléter",
    "shared:phonenumber_too_long": "Numéro de téléphone trop long",
    "shared:accountant": "Comptable",
    "shared:accountants": "Comptables",
    "accountant": "Comptable",
    "adminstrateur": "Administrateur",
    "technicien": "Technicien",
    "contact": "Contact",
    "requests:reopened": "Rouverte",
    "shared:true": "Vrai",
    "sahred:false": "Faux",
    "shared:verifiable_sections_required": "Des sections vérifiables sont requises",
    "shared:phone_required": "Numéro de téléphone requis",
    "shared:delete_company_and_users": "Souhaitez-vous supprimer cette/ces entreprise(s) et {total_users} utilisateur(s)?",
    "shared:justify_question_prompt": "Justifiez la réponse à la question ci-dessus.",
    "shared:justify_above_response": "Justifiez la réponse ci-dessus",
    "shared:request_justification_prompt": "Demander une justification?",
    "shared:standard_form_prompt": "Formulaire standard",
    "shared:archived_and_closed": "Fermées et archivées",
    "shared:archived_and_closed_masculine": "Fermé et archivé",
    "auth:enter_email_prompt": "Veuillez remplir votre email auquel nous vous enverrons un mail pour récupérer votre mot de passe",
    "auth:login": "Se connecter",
    "auth:terms_of_use": "Conditions d'utilisation",
    "auth:help": "Aide",
    "auth:confidentiality": "Politique de confidentialité",
    "auth:connect_to_pre_emploie": "Se connecter à Pré-emploi",
    "auth:connect_prompt": "Veuillez saisir un identifiant et mot de passe valide pour vous connecter",
    "auth:welcome": "Bienvenue sur PRÉ EMPLOI DM inc.",
    "shared:is_standard_label": "Standard",
    "auth:explanation": "Pré-emploi DM est la plateforme de suivi, d'analyse et de sélection des profils professionnels au Canada. Les entreprises trouvent ici toutes les informations crédibles et nécessaires pour engager les meilleurs demandeurs d'emploi.",
    "shared:view_modif_logs": "Afficher les journaux de modification",
    "shared:changelog": "Changelog",
    "shared:request_changelog": "Changelog de facture",
    "shared:user": "Utilisateur",
    "pdf:reorder_questions": "Réorganiser les questions",
    "pdf:reorder_categories": "Réorganiser les catégories",
    "shared:error_fetching_forms": "Erreur lors de la récupération du formulaire",
    "shared:admins": "Administrateurs",
    "shared:technicians": "Techniciens",
    "shared:count": "Nombre",
    "shared:price_required": "Prix requis",
    "candidateDetails:update_services": "Mettre à jour les services de demande",
    "deny_form:update_thanks": "Merci de nous avoir notifié",
    "candidate_details:update_verifiable_sections": "Sections vérifiables",
    "update_request:must_select_a_section": "Vous devez sélectionner une section vérifiable",
    "request:id_papers": " Pièces d'identité",
    "demand:id_papers": " Pièces d'identité",
    "demand_detail:id_papers": " Pièces d'identité",
    "shared:external_demands":"Demandes externes",
    "shared:pay":"Payer",
    "shared:password_forgotten": "Mot de passe oublié?",
    "verifiable_section:name_fr": "Nom(Version française)",
    "verifiable_section:name_en": "Nom(Version anglaise)",
    "unknown_err": "Erreur inconnue, veuillez réessayer plus tard",
    "shared:user_error": "Erreur lors de la récupération des utilisateurs",
    "shared:err_get_user": "Une erreur s'est produite lors de la récupération des utilisateurs",
    "shared:province_error": "Erreur lors de la récupération des provinces",
    "shared:err_get_province": "Une erreur s'est produite lors de la récupération des provinces",
    "shared:pricing_plans_error": "Erreur lors de la récupération des plans de souscription",
    "shared:err_get_pricing_plans": "Une erreur s'est produite lors de la récupération des plans de souscription",
    "shared:err_get_candidate": "Une erreur s'est produite lors de la récupération des candidats",
    "shared:err_get_categories": "Une erreur s'est produite lors de la récupération des catégories",
    "shared:err_get_standard_forms": "Une erreur s'est produite lors de la récupération des formulaires standards",
    "shared:err_get_companies": "Une erreur s'est produite lors de la récupération des entreprises",
    "standard_form:ref_name": "Nom de la référence",
    "standard_form:title": "Référence de l'ex employeur",
    "standard_form:excellent": "Excellent",
    "standard_form:excellent_desc": "Le candidat surpasse de façon remarquable et exceptionnelle les exigences de sa tâche.",
    "standard_form:very_good": "Très bon",
    "standard_form:very_good_desc": "Le candidat dépasse les exigences de sa tâche.",
    "standard_form:good": "Bon",
    "standard_form:good_desc": "Le candidat rencontre adéquatement les exigences de sa tâche.",
    "standard_form:fair": "Passable",
    "standard_form:fair_desc": "Le candidat rencontre partiellement les exigences de sa tâche.",
    "standard_form:low": "Faible",
    "standard_form:low_desc": "Le candidat ne rencontre pas du tout les exigences de sa tâche.",
    "standard_form:evaluation_criteria": "Critères d'évaluation",
    "standard_form:previous_function": "Fonction précédente",
    "standard_form:ref_title": "Titre de la référence",
    "standard_form:ref_phone": "Téléphone de la référence",
    "standard_form:ref_phone_post_number": "Numéro de poste du téléphone de la référence",
    "standard_form:quitting_motif": "Quel est le motif de son départ ?",
    "standard_form:supervision_period": "Période de supervision",
    "standard_form:company_name": "Nom de la compagnie",
    "standard_form:job_duration": "Durée de l'emploi",
    "standard_form:output_value": "Rendement",
    "standard_form:autonomy": "Autonomie",
    "standard_form:ponctuality_and_assiduity": "Ponctualité / Assiduité",
    "standard_form:work_ethics": "Attitude au travail",
    "standard_form:is_enterprising": "Diriez-vous qu'il est capable d'initiative ?",
    "standard_form:can_handle_work_under_pressure": "Aptitude à travailler sous pression",
    "standard_form:interpersonal_relationship_with_friends": "Relations interpersonnelles avec ses collègues",
    "standard_form:interpersonal_relation_with_management": "Relations interpersonnelles avec la direction",
    "standard_form:is_receptive_to_critics": "Ouverture à la critique",
    "standard_form:team_spirit": "Esprit d'équipe",
    "standard_form:leadership": "Leadership",
    "standard_form:professional_competences": "Compétences professionnelles",
    "standard_form:ability_of_management": "Sens de l'organisation",
    "standard_form:ability_of_responsibility": "Sens des responsabilités",
    "standard_form:disciplinary_sanctions": "A-t-il/a-t-elle fait l'objet de mesures disciplinaires ?",
    "standard_form:pros": "Quels sont ses points forts ?",
    "standard_form:cons": "Quels sont ses points à améliorer ?",
    "standard_form:will_rehire": "Est-ce que vous ré-embaucheriez ce candidat ?",
    "standard_form:do_you_recommend": "Est-ce que vous nous le recommandez ?",
    "standard_form:comments": "Commentaires",
    "new_demand:credit_scan": "Vérification bancaire",
    /****************update from here *****************/

}
