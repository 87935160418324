import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './forms_listing.module.scss';
import { Gallery, GalleryItem, Button, TextInput, Spinner, spinnerSize } from '@patternfly/react-core';
import { GridCard, AccordionContext, ListEmpty } from 'components';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAccordionData, useNotificationData } from 'hooks';
import { EditFormModal } from 'components/modals/edit_form/EditForm.modal';
import { RouteComponentProps } from 'react-router';
import { CloseIcon } from '@patternfly/react-icons';
import { useFormData } from 'hooks'
import { IFormsType } from 'stores';
import { FormService, VerifiableSectionService } from 'services';
import { ConfirmDialog } from 'dialogs';



export interface FormsListingProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

/**
 * Pour le listing des formulaires
 * @param props 
 */
const FormsListingComponent: React.FC<FormsListingProps> = ({ ...props }) => {

  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  const [open_modal, setopen_modal] = React.useState(false);
  const [form, setForm] = React.useState<IFormsType | undefined>(undefined)
  const centinelRef = useRef<any>(null)
  let {
    resetForms, deleteForm, updateForm, appendForms,
    start: storeStart, limit: storeLimit, total,
    forms: storeForms,
  } = useFormData()
  const [loading, setLoading] = useState<boolean>(false)
  const { new_notification } = useNotificationData()
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  let timeout;
  const [selectedForms, setSelectedForms] = useState<string[]>([])
  const [forms, setForms] = useState<IFormsType[]>(storeForms)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [toDelete, setToDelete] = useState<string[]>([])
  const [observerTriggerState, setObserverTriggerState] = useState<any>({})
  const [verifiableSections, setVerifiableSections] = useState<any[]>([])

  const { setBottomComponent } = React.useContext(AccordionContext);


  useEffect(() => {
    clearTimeout(timeout)
    //text in search field has been emptied
    if (query === '') {
      setForms(storeForms)
      return
    }
    setSearchStart(0) //On each new search, searchStart goes to zero
    setSearchTotal(0)
    timeout = setTimeout(() => {
      searchForms()
    }, 300);
  }, [query])

  useEffect(() => {
    resetAccordion({ title: formatMessage({ id: 'candidate:candidate_list' }) })
    fetchVerifiableSections()
    return configureInfiniteScroll()
  }, [])

  useEffect(() => {
    if (storeForms && storeForms.length > 0) {
      setForms(storeForms)
    }
  }, [storeForms])

  const fetchForms = (paramStart: number, paramSearching: boolean) => {
    setLoading(true)
    const suffix = `?start=${paramStart}&limit=${storeLimit}${paramSearching ? `&keyword=${query}` : ''}`
    FormService.list_forms(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { results, start, total, limit } = await response.json()
          if (!paramSearching) { //Not searching
            if (start < 1) {
              resetForms({ data: results, start, total, limit, })
            }
            else {
              appendForms({ data: results, start, limit, total })
            }
          } else { //Searching 

            setForms([
              ...(start > 0 ? forms : []),
              ...results
            ])
            setSearchTotal(total)
            setSearchStart(start)
          }
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: 'success',
            variant: 'success'
          })

        }

      })
  }

  const deleteFormFromApi = (...forms_ids: string[]) => {
    setLoadingDelete(true)
    FormService.delete_form({ forms_ids })
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          deleteForm(...forms_ids)
          setToDelete([])
          setLoadingDelete(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingDelete(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const onNextPage = () => {
    if (query !== '') {
      if (searchTotal !== 0 && forms.length >= searchTotal) { return }
      fetchForms(searchTotal === 0 ? 0 : searchStart + storeLimit, true)
      return
    }
    if (total !== 0 && storeForms.length >= total) { return }
    fetchForms(total === 0 ? 0 : storeStart + storeLimit, false)
    // setStart(storeStart + storeLimit)
  }

  const searchForms = () => {
    fetchForms(0, true)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeForms, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    console.log({ centinelRef })
    if (centinelRef && centinelRef.current) {
      observer.observe(centinelRef.current)
    }

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  //Pour Ouvrir/Fermer le modal d'edition d'un formulaire
  const OpenCloseModal = () => {
    setopen_modal(!open_modal);
  }

  //Liste des menus actions
  const menus = paramForm => (
    [
      {
        name: formatMessage({ id: "shared:modify" }),
        action: () => {
          setToDelete([])
          setForm({ ...paramForm })
        }
      },
      {
        id: '2',
        action: () => {
          setToDelete([paramForm.id])
          setopen_modal(false)
        },
        name: formatMessage({ id: 'shared:delete' }),
      },
    ]
  )

  React.useEffect(() => {
    //Pour l'en tete d'une page du dashboard
    resetAccordion({
      title: formatMessage({ id: 'shared:forms' }),
      sub_title: "Description",
      show_crumbs: false,
      crumbs: []
    });
    //metre a jour ce qui est dans le BottomComponent du AccordionContext
    configureBottom([])

    //unmount
    return () => setBottomComponent(<span></span>)
  }, [])

  const onNewForm = () => setForm({
    id: '',
    title: '',
    title_en: '',
    description: ''
  })

  const configureBottom = (elements) => {
    setBottomComponent(
      <div className={styles.bottomComponent}>
        <Button className={styles.btn_primary} onClick={() => onNewForm()} variant="primary">
          <FormattedMessage id="new_form:new_form" />
        </Button>
        <TextInput
          placeholder={formatMessage({ id: "shared:search" })}
          type="text"
          onChange={setQuery}
          aria-label="Recherche"
        />
        {elements.length > 0 && (
          <Button
            onClick={i => setToDelete(elements)}
            variant={"secondary"}
          >
            <CloseIcon />
            <FormattedMessage id="shared:delete" />
          </Button>
        )}
      </div>
    );
  }

  const handleCardSelected = formId => {
    const idx = selectedForms.findIndex(f => f === formId)
    if (idx !== -1) {
      const newList = [...selectedForms]
      newList.splice(idx, 1)
      setSelectedForms(newList)
      configureBottom(newList)
    } else {
      setSelectedForms([...selectedForms, formId])
      configureBottom([...selectedForms, formId])
    }
  }


  const fetchVerifiableSections = () => {
    VerifiableSectionService.fetchSections()
      .catch(err => {
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const results = await response.json()
          setVerifiableSections(
            results
              .filter(({ is_active }) => is_active)
              .map(
                ({ name, id, is_active }) => ({ is_active, name, id })
              )
          )
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

        }
      })
  }


  return (
    <div className={styles.container}>
      {/**Modal Edit Form */}

      <EditFormModal
        onClose={e => setForm({
          id: '',
          title: '',
          title_en: '',
          description: ''
        })}
        verifiable_sections={verifiableSections}
        open={open_modal}
        onCloseModal={OpenCloseModal}
        form={form}
      />

      {/**End Modal Edit Form */}
      <ConfirmDialog
        confirmation_message="shared:do_you_want_to_continue"
        onAction={_ => deleteFormFromApi(...toDelete)}
        isModalOpen={toDelete.length > 0}
        loading={loadingDelete}
        onModalClose={_ => setToDelete([])}
      />

      {forms.length === 0 && !loading && (
        <ListEmpty />
      )}

      {
        /**Listing des Formulaires 
         * sans formulaires standard
         */
      }
      <Gallery hasGutter>
        {!loading && forms.length < 1 && (
          <ListEmpty />
        )}
        {
          forms
            .filter(
              ({ editable, is_standard }) => {
                if (is_standard && !editable) { return false }
                return true
              }
            )
            .map(form => (
              <GalleryItem span={4}>
                {/**Grid Formulaire*/}
                <GridCard
                  form={{
                    ...form,
                    description_jsx: (
                      <>
                        <h3>
                          <FormattedMessage id="shared:categories_count" />:&nbsp;{form.categories_count}
                        </h3>
                        <span>{form.description}</span>
                      </>
                    )
                  }}
                  selectedElements={selectedForms}
                  OpenCloseModal={() => { }}
                  {...props}
                  menus={menus(form)}
                  // setform={setForm}
                  onSelect={handleCardSelected}
                />
              </GalleryItem>
            ))}
        <div
          ref={centinelRef}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // background:'orange',
            gridColumn: '1/-1',
            height: 75,
            width: '100%',
          }}
        >
          {loading && (
            <div className={styles.loader}>
              <Spinner size={spinnerSize.md} />
            </div>
          )}
        </div>
      </Gallery>
      {/**Fin Listing des Formulaires  */}

    </div>
  )
}

export {
  FormsListingComponent as FormsListingPage,
}