import React, { useEffect, useState, useContext, createContext, } from 'react';
import styles from './Accordion.module.scss'
import { AppStore } from 'stores'
import { Crumb } from 'stores/app.store'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon, CloseIcon } from '@patternfly/react-icons'
import { FormattedMessage, useIntl } from 'react-intl';
import { BarnerNotifier } from 'components';
import clsx from 'classnames'
import { DropdownItem } from '@patternfly/react-core';
import { TABLE_COLUMN } from 'constants/table_columns';

export interface AccordionProps {
  readonly title?: string;
  readonly sub_title?: string;
  readonly reset_crumbs?: boolean;
  readonly shadow?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  title: props_title = '',
  sub_title: props_sub_title = '',
  reset_crumbs, shadow = true,
}) => {
  const { bottomComponent } = useContext(AccordionContext)
  const [title, setTitle] = useState<string>(props_title)
  const [sub_title, setSubTitle] = useState<string>(props_sub_title)
  const [crumbs, setCrumbs] = useState<Crumb[]>([])
  const [stateShadow, setShadow] = useState<boolean>(true)
  

  /**Setup accordion */
  useEffect(() => {
    const trigger = new Subject<boolean>()

    AppStore.accordion$
      .pipe(
        takeUntil(trigger.asObservable())
      )
      .subscribe(
        ({
          title: storeTitle,
          sub_title: storeSubTitle,
          crumbs: storeCrumbs,
          shadow = true,
        }) => {
          setTitle(storeTitle)
          setSubTitle(storeSubTitle)
          setCrumbs(storeCrumbs)
          setShadow(shadow)
        }
      )

    //onUnmount
    return () => trigger.next(false)
  }, [])

  useEffect(() => {
    console.log({ bottomComponent })
  }, [bottomComponent])

  useEffect(() => {
    if (props_title || props_sub_title) {
      AppStore.resetAccordion({
        title: props_title,
        sub_title: props_sub_title,
        shadow,
      })
    }
    if (reset_crumbs) {
      AppStore.resetAccordion({ crumbs: [] })
    }
  }, [props_title, props_sub_title, reset_crumbs, shadow])


  return (
    <div
      className={clsx(
        styles.accordion,
        stateShadow ? styles.shadow : ''
      )}
    >
      <BarnerNotifier />
      {crumbs.length > 0 && (
        <div className={styles.crumbs_container}>
          {crumbs.map(({ route_to, title }, idx) => (
            <>
              {idx > 0 && (
                <ChevronRightIcon className={styles.icon_chevron} />
              )}
              <NavLink
                to={`${route_to}`}
                exact
                activeClassName={styles.active_route_crumb}
              >
                <FormattedMessage id={title} />
              </NavLink>
            </>
          ))}
        </div>
      )}
      <h3 className={styles.accordion_title} >
        {title}
      </h3>
      {Boolean(sub_title) && (
        <>
          <span>{sub_title}</span>
          <br />
        </>
      )}
      {Boolean(bottomComponent) && bottomComponent}

    </div>
  );
}

interface IAccordionContext {
  bottomComponent: any,
  setBottomComponent: any,
}

export const AccordionContext = React.createContext<IAccordionContext>({
  bottomComponent: undefined,
  setBottomComponent: undefined,
})

export const AccordionProvider = ({ children }) => {
  const [bottomComponent, setBottomComponent] = useState<any>()
  return (
    <AccordionContext.Provider
      value={{ bottomComponent, setBottomComponent }}>
      {children}
    </AccordionContext.Provider>
  )
}