export default {
    "shared:payment_status": "Payment status",
    'shared.french': "French",
    'shared.english': "English",
    'routes.settings': "Settings",
    'routes.dashboard': "Dashboard",
    'routes.general': 'General',
    'routes.my_profile': "My profile",
    "en": "English",
    "routes.candidates": "Candidates",
    "fr": "French",
    'candidate:candidate_list': "List of candidates",
    "routes.archives": "Archives",
    "routes.companies": "Companies",
    "companies:list_of_companies": "List of companies",
    'archives:list_of_archived_candidates': "List of archived candidates",
    "shared:users": "Users",
    "shared:administration": "Administration",
    "shared:forms": "Form",
    'routes:forms': 'Forms',
    "routes:categories": "Categories",
    "nonEditableStandardForms": "Error fetching forms",
    "routes:demands": "Requests",
    "candidate:candidate": "Candidate",
    "shared:manager": "Correspondent",
    "shared:client": "Customer",
    "shared:address": "Address",
    "shared:tel": "Phone number",
    "shared:status": "Status",
    "shared:fax_received": "Fax received",
    "shared:loading": "Loading",
    "shared:locked": "Closed",
    "shared:locked_masculine": "Closed",
    "shared:open": "Open",
    "shared:opened": "Opened",
    "shared:open_masculine": "Opened",
    "shared:more_details": "Detail",
    "shared:archived": "Archived",
    "shared:archived_masculine": "Archived",
    "candidate:new_candidate": "New candidate",
    "shared:search": "Search...",
    "shared:family_name": "Family name",
    "shared:name": "Name",
    "shared:email": "Email",
    "shared:province": "State/Province",
    "shared:driver_licence_number": "Driver's license number",
    "shared:NAS": "NAS",
    "shared:document_email": "Document email",
    "shared:postal_code": "Zip Code",
    "shared:contact": "Contact",
    "shared:cancel": "Cancel",
    "new_candidate:create_the_candidate": "Create the candidate",
    "company:new_company": "New Company",
    "new_company:new_company": "New company",
    "shared:new_company": "Create a company",
    "shared:moto": "Motto",
    "shared:fax": "Fax",
    "shared:identification_name": "Identification name",
    "shared:city": "City",
    "demands:list_of_demands": "List of requests",
    "demands:new_demands": "New request",
    "demand:requester": "Requester",
    "shared:candidate": "Candidate",
    "demand:job_requested": "Apply for the position of",
    "shared:date": "Date",
    "shared:filter": "Filter",
    "company_filter:company_filter": "Filter companies",
    "shared:member_of_entity": "Member of entity",
    "shared:created_on": "Created on",
    "shared:modified_on": "Modified on",
    "shared:is_empty": "Is empty",
    "shared:question": "Question (in French)",
    "shared:question_en": "Question (in English)",
    "shared:type": "Type",
    "shared:form_type": "Form type",
    "shared:category_type": "Category type",
    "shared:mandatory": "Mandatory",
    "notification:new_candidate_tranfer": "New transfer of candidates",
    "shared:description": "Description",
    "shared:on": "On",
    "shared:update": "Modify",
    "shared:delete": "Delete",
    "category:list_of_categories": "List of categories",
    "category:category_name": "Name of categories",
    "category:add_new_category": "Add a category",
    "category:number_of_questions": "Number of questions",
    "category:number_of_forms": "Number of forms",
    "shared:option": "Option",
    "shared:fullwidth": "To be shown on the entire line",
    "routes.transfer": "Transfer",
    "shared:firstname": "Last Name",
    "shared:lastname": "First Name",
    "shared:driving_license_number": "Driver's license number",
    "shared:phonenumber": "Tel",
    "shared:your_folder": "Your document",
    "shared:in_progress": "In progress",
    "shared:see_more": "View more",
    "shared:transfer_of_candidates_to_contact": "Transfer of candidates to contact(s)",
    "routes.profile": "My profile",
    "profile:basic_info": "Basic information",
    "profile:user_profile": "User profile",
    "shared:username": "Username",
    "shared:load_avatar": "Load the avatar",
    "shared:logout": "Sign out",
    "user:list_of_users": "List of users",
    "user:add_new_user": "New user",
    "user:user": "User",
    "shared:last_connexion": "Last connection",
    "routes.users": "Users",
    "user:create_user": "Create the user",
    "shared:company": "Company",
    "shared:password": "Password",
    "shared:role": "Role",
    "shared:save": "Save",
    "shared:public": "Public",
    "user:permissions": "Permissions and groups",
    "shared:search_name": "Search by name",
    "shared:find": "Search",
    "shared:administrator": "Administrator",
    "shared:technician": "Technician",
    "user_filter:user_type": "Type of user",
    "shared:cancel_filter": "Cancel the filter",
    "transfer:element_selected": "Elements selected",
    "new_form:new_form": "Add a form",
    "shared:is_public": "Is public",
    "shared:empty_list": "No element.",
    "shared:confirm_user_suppr": "Do you really want to delete this user?",
    "new_category:new_category": "Add a category",
    "new_category:edit_category": "Edit a category",
    "user:update_user": "Modify user",
    "shared:field_required": "Field required",
    "shared:lastname_required": "First Name required",
    "shared:firstname_required": "Last Name required",
    "shared:email_required": "Email required",
    "shared:address_required": "Address required",
    "shared:province_required": "Province required",
    "shared:city_required": "City required",
    "shared:driving_license_number_required": "Driver's license required",
    "shared:nas_required": "NAS required",
    "shared:request_email_required": "Requester email required",
    "shared:birthday_required": "Date of birth required",
    "shared:phonenumber_required": "Phone number required",
    "shared:postal_code_required": "Zip Code required",
    "shared:contact_id_required": "Contact required",
    "shared:connected_as": "Your role is : ",
    "shared:admin": "Administrator",
    "shared:select": "Select...",
    "shared:request_email": "Email of the contact",
    "shared:invalid_email": "Email invalid",
    "shared:birthday": "Date of birth",
    "shared:number": "Number",
    "shared:phone": "Phone number",
    "shared:initials": "Initials",
    "shared:invalid_number": "Number invalid",
    'shared:number_required': "Number required",
    'shared:name_required': "Name required",
    'shared:initials_required': "Initials required",
    'shared:fax_required': "Fax required",
    "shared:invalid_phonenumber": "Phone number is invalid",
    "shared:job_requested": "Apply for the position of",
    "shared:candidate_mail": "Candidate email",
    "shared:comments": "Comments",
    "archives:new_demand": "New request",
    "shared:identification_name_required": "Identification number required",
    "shared:do_you_want_to_continue": "Do you wish to continue?",
    "new_demand:application_and_job": "Applicant informations",
    "new_demand:requester_name": "Name of requester",
    "new_demand:application_of": "Demand of?",
    "shared:success": "Success",
    "shared:error": "Error",
    "shared:unknown_error": "Unkown error",
    "shared:application_of_required": "Candidate name required",
    "shared:job_requested_equired": "Apply for the position of required",
    "demand:full_plumitif": "Civil, statutory, criminal and penal plumitif",
    "demand:criminal_penal_plumitif": "Criminal and penal plumitif only",
    "demand:academic_training": "Academic training (diplomas)",
    "demand:credit_verification": "Credit Check",
    "demand:old_job_references": "References from former employers",
    "demand:professional_associations": "Membership of professional associations",
    "demand:driver_license": "Driver's license",
    "shared:username_required": "Username required",
    "shared:company_required": "Company required",
    "shared:role_required": "Role required",
    "demand:attachments": "Attachments",
    "demand:info_to_verified": "Select your verification",
    "demand:authorization_form": "Authorization form",
    "demand:cv": "Curriculum vitae",
    "demand:diplomas_copy": "Copy of diplomas",
    "demand:references": "References",
    "shared:modify": "Update",
    "new_candidate:edit_candidate": "Update the candidate",
    "new_company:edit_company": "Update the company",
    "shared:town": "Town",
    "edit_form:edit_form": "Update form",
    "edit_form:add_form": "Add a form",
    "shared:is_archived": "Archived",
    "shared:folder_status": "Status",
    "shared:folder_number": "Document identifier",
    "shared:contact_id": "Contact",
    "candidate:candidates": "Candidate",
    "shared:nas": "Social Insurance Number(SIN)",
    "shared:candidate_filter": "Filter candidates",
    "shared:archive": "Archive",
    "candidate:prompt_archive_candidates": "Do you wish to archive this candidate(s)?",
    "candidate:prompt_unarchive_candidates": "Do you wish to unarchive this candidate(s)",
    "shared:unarchive": "Unarchive",
    "shared:delete_candidate": "Delete candidate",
    "shared:download_pdf": "Download pdf",
    "shared:archive_candidate": "Archive the candidate",
    "shared:company_filter": "Companies filter",
    "shared:categories_filter": "Categories filter",
    "shared:enterprise_number": "Company number",
    "shared:questions_count": "Number of questions",
    "shared:forms_counts": "Number of forms",
    "shared:add_form": "Add a form",
    "shared:demand_filter": "Demands filters",
    "shared:applicant": "Applicant",
    "shared:employment": "Apply for the position of",
    "shared:no_categories": "No category found",
    "shared:upload": "Upload...",
    "ouvert": "Open",
    "pdf:general_info": "General information",
    "shared:new_question": "Add a question",
    "pdf:candidate_report": "Candidate report",
    "shared:n_a": "Not available",
    "new_question:edit": "Update question",
    "new_questions:add": "Add a question",
    "shared:edit": "Update",
    "demand_detail:demand_detail": "Demand detail",
    "demand_detail:application_and_job": "Job and application",
    "demand_detail:date": "Date of the request",
    "demand_detail:candidate": "Candidate",
    "demand_detail:requester_name": "Name of requester",
    "demand_detail:job_requested": "Apply for the position of",
    "demand_detail:requester_email": "Email of requester",
    "demand_detail:comments": "Comments",
    "demand_detail:verification_request": "Demand for verification",
    "demand_detail:attachments": "Attachements",
    "demand_detail:download": "Download",
    "demand_detail:demand_status": "Status of the request",
    "shared:cv": "Curriculum vitae",
    "demand_detail:download_pdf": "Download pdf",
    "shared:categories_count": "Number of categories",
    "shared:references": "References",
    "role:error_fetching_role": "There was an error getting roles",
    "shared:enterprise_matricule": "Company number",
    "shared:empty_list_title": "Empty list",
    "form:base_information": "Basic informations",
    "shared:notifications": "Notifications",
    "shared:help": "Help",
    "form:update_candidate_info": "Inspect/Edit candidate info",
    "shared:select_form": "Please select a form",
    "shared:no_form_selected": "No form has been selected",
    "shared:professional_competences": "Professional competences",
    "shared:candidate_evaluation": "Evaluation of the candidate",
    "shared:interviewer": "Interviewer",
    "shared:billed": "Billed",
    "new_demand:candidate_firstname": "Candidate first name",
    "new_demand:candidate_lastname": "Candidate last name",
    "shared:phone_number": "Phone number",
    "shared:authorization_form_required": "Authorization form required",
    "shared:request_closed": "This request is closed",
    "shared:request_closed_message": "This request has been labelled as closed",
    "shared:tel_and_post_number": "Phone number and extension",
    "shared:post_number": "Extension",
    "shared:postNumber": "Extension number",
    "external_company:creation_page_instructions": `
    This page is dedicated to the creation of your business
    on the Pré-emploi platform.
    Please complete the following fields to start your business.
    `,
    "external_company:creation_success_message": `
    Your company is now created, your account on the application
    Pré-emploi ({ui_url}) will be created shortly and you will receive an email
    confirmation in the next few hours.
    `,
    "external_company:creation_demand_instructions": `
    This page is dedicated to request verification of a candidate
    on the Pré-emploi platform.
    Please complete the following fields to deposit your request.
    `,
    "external_company:creation_demand_success_message": `
    Request submitted successfully. Thanks for trusting us! :)
    `,
    "shared:send_company_email_for_creation": "Send creation email to company",
    "company:create_by_email": "Create by email",
    "shared:send_by_email": "Send by email",
    "shared:error_prompt": "There was an error fetching data",
    "shared:send_mail": "Send mail",
    "shared:send_email_to_company_to_create_company": "Send email to create company",
    "shared:reorder": "Reorder",
    "shared:yes": "Yes",
    "shared:false": "No",
    "shared:is_standard": "Is standard form : ",
    "company_creation": "Company creation",
    'shared:does_not_match': "Does not match",
    "shared:sent": "Sent",
    "shared:confirm_password": "Confirm password",
    "shared:password_required": "Password required",
    "auth:recover_password_instructions": "An email has been sent you",
    "shared:interviewer_info": "Information",
    "shared:send_email": "This user can receive emails",
    "shared:send_email_to_company_to_create_demand": "Send form for request creation",
    "shared:requester_email": "Requester email",
    "shared:enterprise_email": "Company email",
    "new_demand:creation": "Create a request",
    "shared:current_password": "Current password",
    "shared:new_password": "New password",
    "shared:about_application": "About Pré-emploi",
    "shared:ok": "OK",
    "shared:app_env": "App environment",
    "shared:build_version": "Build number",
    "shared:send": "Send",
    "pdf:reorder_pdf": "Reorder forms",
    "company:activate_enterprise_prompt": "Select a contact as administrator of the company to activate it",
    "company:write_or_select_candidate": "Type in or select the contact",
    "shared:activate_enterprise": "Activate the company",
    "routes.activated_companies": "Activated companies",
    "shared:deactivate_enterprise": "Deactivate the company",
    "shared:deactivate_enterprise_prompt": "Do you wish to deactivate this company?",
    "active_company:departments": "Departments",
    "active_company:users": "Users",
    "active_company:complete_demands": "Completed requests",
    "active_company:candidates": "Candidates",
    "active_company:incomplete_demands": "Incomplete requests",
    "shared:of": "Of",
    "shared:activated_company_filter": "Filter",
    "shared:has_departments": "Has departments?",
    "shared:has_candidates": "Has candidates?",
    "shared:has_incomplete_demands": "Has incomplete requests?",
    "shared:has_complete_demands": "Has complete requests?",
    "shared:deactivate": "Deactivate",
    "shared:create_department": "Create department",
    "shared:delete_department": "Delete department(s)",
    "shared:deactivate_company": "Deactivate the company",
    "shared:last_update": "Last updated",
    "shared:new_department": "New department",
    "shared:active": "Active",
    "shared:actives": "Active",
    "shared:in_active": "Inactive",
    "shared:delete_company_department": "Do you wish to delete the department(s) of the company?",
    "shared:create": "Create",
    "shared:create_department_prompt": "Do you wish to add a new department to the company?",
    "new_dep:enter_dep_name": "Name of department",
    "routes.enterprise_dashboard": "Dashboard",
    "shared:departments": "Departments",
    "shared:complete_demands": "Completed requests",
    "shared:incomplete_demands": "Incomplete requests",
    "shared:candidates": "Candidates",
    "dashboard:total_demands": "Request count",
    "dashboard:current_months_demands": "Current month requests",
    "dashboard:stats": "Request statistics for this year",
    "Administrator": "Administrator",
    "Technician": "Technician",
    "Contact": "Contact",
    "Accountant": "Accountant",
    "Company_Administrator": "Contact",
    "routes.departments": "Departments",
    "routes.enterprise_departments": "Departments",
    "shared:lorem_short": `
    In publishing and graphic design, Lorem ipsum is a 
    placeholder text commonly used to demonstrate 
    the visual form of a document or a typeface without 
    relying on meaningfu
    `,
    "shared:update_department_prompt": "Enter the new name of department named",
    "shared:prompt_delete_department": "Do you wish to delete this/these department(s)?",
    "dep_users:users_of_dep": "Users of department",
    "shared:new_user": "New user",
    "shared:view_users": "View users",
    "shared:enterprise": "Company",
    "shared:userListing": "Username",
    "shared:mail": "Email",
    "shared:department": "Department",
    "shared:matricule": "Number",
    "shared:determining": "Determining...",
    "shared:customers": "Customers",
    "shared:active_companies": "Active companies",
    "shared:companies": "Companies",
    "shared:inactive": "Inactive",
    "shared:inactives": "Inactives",
    "jan": "Jan",
    "feb": "Feb",
    "mar": "Mar",
    "avr": "Apr",
    "mai": "May",
    "jun": "Jun",
    "jul": "Jul",
    "aou": "Aug",
    "sep": "Sep",
    "oct": "Oct",
    "nov": "Nov",
    "dec": "Dec",
    "dashboard:division": "Number of requests divided by : ",
    "shared:updated_at": "Updated at",
    "shared:update_status": "Update status",
    "shared:update_candidate": "Update candidate",
    "candidate:update_status_prompt": "Select a status to update the candidate's status",
    "shared:close": "Close",
    "company:activate_company": "Activate company",
    "shared:manager_username": "Manager username",
    "shared:manager_email": "Manager email",
    "shared:manager_username_required": "Manager username is required",
    "shared:email_invalid": "Invalid email",
    "shared:manager_firstName": "Manager first name",
    "shared:manager_firstname_required": "Manager first name is required",
    "shared:manager_lastName": "Manager lastname",
    "shared:manager_lastname_required": "Manager lastname is required",
    "shared:company_correspondent": "Correspondent information",
    "candidate:demand_details": "Demand details",
    "shared:closed": "Closed",
    "auth:update_password": "Reinitialise your password",
    "auth:recover_password": "Recover my account",
    "auth:enter_email_prompt": "Please fill in your email to which we will send you a mail to recover your password",
    "auth:enter_new_password_prompt": "Please fill in your new password and confirm it. It's preferable not to copy and paste.",
    "auth:new_password_prompt": "Please enter a new password of your choice.",
    "shared:fax_sent": "Fax sent",
    "shared:order": "Order",
    "shared:asc": "Ascending",
    "shared:desc": "Descending",
    "verification:new_request_verification": "New request verification",
    "transfer:contact_list": "List of contacts",
    "transfer:selected_candidates": "Selected candidates",
    "transfer:see_list": "View list",
    "notification:requested_by": "Requested by",
    "notification:candidates": "Candidates",
    "notification:new_request_verification": "New request verification",
    "notification:show": "View",
    "question:new_questions": "Add a question",
    "shared:questions": "Question",
    "shared:properties": "Properties",
    "shared:form": "Form",
    "shared:category": "Category",
    "shared:checkbox": "Checkbox",
    "shared:text": "Text",
    "shared:textarea": "Textarea",
    "shared:radio": "Radio",
    "questions:all_questions": "All questions",
    "question:select_form_and_category": "Before creating a question, you will have to select a form and a corresponding category to which it will be added.",
    "shared:proceed": "Proceed",
    "shared:add_question": "Add a question",
    "routes:questions": "Questions",
    "verifiable_section:new_section": "Create a new verifiable section.",
    "verifiable_section:update_section": "Update verifiable section.",
    "shared:create_section": "Create section.",
    "shared:creation_section_prompt": "This form allows you to create a new verification object for candidate requests.",
    "verfiable_section:new_section": "Add a verifiable section.",
    "verifiable_section:all_sections": "These information are to be verified.",
    "verifiable_section:title_delete": "Delete a verifiable section.",
    "verifiable_section:delete_prompt": "Proceeding will delete for good the verifiable section.",
    "shared:update_section": "Update",
    "shared:is_active": "Is active",
    "routes:verifiable_section": "Verifiable sections",
    "verification:verifiable_sections": "Verifiable sections",
    "shared:price": "Price",
    "demand_detail:authorization_form": "Authorization form",
    "demand_detail:cv": "CV",
    "demand_detail:diplomas_copy": "Copy of diplomas",
    "demand_detail:references": "References",
    "shared:attachements": "Attachments",
    "candidate:archived_candidate_list": "Archived candidates",
    "category:select_categories_prompt": "Select the various categories to add these questions to:",
    "shared:select_company": "Select owner of company",
    "shared:see_more_details": "See more details",
    "users:prompt_remove_user_from_department": "Do you wish to remove this user from the department?",
    "shared:proceed_with_payment": "Proceed with payment",
    "user:add_users": "Add users",
    "routes:draft_demands": "Drafted requests",
    "shared:add_user_to_department": "Add user to department",
    "shared:no_drafted_demands": "No drafted requests",
    "shared:add_user": "Add users",
    "demands:list_of_draft_demands": "List of drafted requests",
    "shared:reopened": "Reopened",
    "shared:add_option": "Add an option",
    "shared:form_submitted": "Form submitted",
    "shared:send_form_by_email": "Send form to third party.",
    "shared:prompt_email": "Third@party.email...",
    "pdf:your_documents": "Your documents",
    "pdf:recruiter": "Recruiter",
    "shared:date_of_birth": "Date of birth",
    "shared:driver_licence": "Driver's license",
    "shared:telephone": "Phone number",
    "shared:additional_notes": "Additional notes",
    "shared:phone_and_post": "Phone number/post number",
    "billing:number_of_requests": "Number of requests",
    "shared:total_price": "Total price($)",
    "shared:last_demand_date": "Last request",
    "shared:actions": "Action",
    "billing:used_service_billing": "Billing of used services",
    "routes:billing": "Service invoices",
    "shared:paid": "Paid",
    "shared:payment_pending": "Payment pending",
    "shared:candidate_name": "Candidate name",
    "shared:standard_form": "Standard form",
    "shared:discount": "Discount",
    "shared:year_month": "Year and month",
    "shared:year": "Year",
    "shared:month": "Month",
    "shared:janurary": "January",
    "shared:february": "February",
    "shared:march": "March",
    "shared:april": "April",
    "shared:may": "May",
    "shared:june": "June",
    "shared:jully": "July",
    "shared:august": "August",
    "shared:september": "September",
    "shared:october": "October",
    "shared:november": "November",
    "shared:december": "December",
    'shared:external_form': "External form",
    "questions:add_to_categories": "Add to categories",
    "shared:categories": "Categories",
    "shared:list_categories": "List of categories",
    "shared:add_to_categories_prompt": "Do you wish to add the following question(s) to the following categories?",
    "shared:add_to_categories": "Add questions to categories",
    'shared:update_balance_prompt': "Enter + or - amount to update the balance",
    "shared:error_fetching_users": "There was an unknown error fetching users",
    "shared:pricing_plan": "Pricing plan",
    "shared:pricing_plan_required": "Pricing plan required",
    'shared:pricing_per_unit': "Billing per unit",
    'shared:pricing_per_month': "Billing per month",
    "shared:balance": "Account balance",
    "shared:amount_indebted": "Amount overdraft",
    "shared:total_amount": "Total amount",
    "shared:update_balance": "Update balance",
    "shared:post_number_required": "Extension of number required",
    'users:no_users': "No users",
    "users:create_users_message": "Please do create some users",
    "shared:update_payment_status": "Update payment status",
    "stripe:prompt_stripe_info": "Your request has been saved as a draft, please enter your credit card info below to pay for.",
    "billing:change_payment_status": "Change payment status",
    "billing:update_billing_status": "Select the status to update the status of all selected invoices.",
    "stripe:enter_stripe_info": "Enter card details",
    "stripe:pay_layer": "I'll pay later.",
    "shared:no_interviewee_prompt": "Please fill in the contacted person field below with your name",
    "shared:no_interviewee": "No contacted person info",
    "shared:interviewee": "Contacted person",
    "shared:w_r_t_demand": "With respect to the job requested",
    "shared:w_r_t_candidate": "With respect to the candidate",
    "shared:completed": "Completed",
    "shared:save_complete": "Save and complete",
    "shared:phonenumber_too_long": "Phone number too long",
    "shared:accountant": "Accountant",
    "shared:accountants": "Accountants",
    "accountant": "Accountant",
    "adminstrateur": "Administrator",
    "technicien": "Technician",
    "contact": "Contact",
    "requests:reopened": "Reopened",
    "shared:true": "True",
    "sahred:false": "False",
    "shared:verifiable_sections_required": "Verifiable sections are required",
    "shared:phone_required": "Phone number required",
    "shared:verifiable_section_required": "Verifiable section is required",
    "shared:delete_company_and_users": "Do you wish to delete this(these) company(companies) and {total_users} user(s)?",
    "shared:justify_question_prompt": "Justify the response to the above question.",
    "shared:justify_above_response": "Justify the above response",
    "shared:request_justification_prompt": "Request justification?",
    "shared:standard_form_prompt": "Standard form",
    "shared:archived_and_closed": "Closed and archived",
    "shared:archived_and_closed_masculine": "Closed and archived",
    "auth:login": "Login",
    "auth:terms_of_use": "Terms of use.",
    "auth:help": "Help",
    "auth:confidentiality": "Privacy policy",
    "auth:connect_to_pre_emploie": "Connect to Pré-emploi",
    "auth:connect_prompt": "Please enter a valid username and password to access",
    "auth:welcome": "Welcome to PRE EMPLOI DM inc.",
    "shared:is_standard_label": "Is standard",
    "auth:explanation": "Pré-emploi DM is the platform for monitoring, analyzing and selecting professional profiles in Canada. Companies find here all the credible and necessary information to hire the best job seekers.",
    "shared:view_modif_logs": "View modification logs",
    "shared:changelog": "Changelog",
    "shared:request_changelog": "Changelog of invoice",
    "shared:user": "User",
    "pdf:reorder_questions": "Reorder questions",
    "pdf:reorder_categories": "Reorder categories",
    "shared:error_fetching_forms": "Error fetching form",
    "shared:admins": "Administrators",
    "shared:technicians": "Technicians",
    "shared:count": "Count",
    "shared:price_required": "Price required",
    "candidateDetails:update_services": "Update request services",
    "candidate_details:update_verifiable_sections": "Verifiable sections",
    "deny_form:update_thanks": "Thanks for notifying us",
    "update_request:must_select_a_section": "Vous devez sélectionner une section vérifiable",
    "request:id_papers": "Identification documents",
    "demand:id_papers": "Identification documents",
    "demand_detail:id_papers": "Identification documents",
    "shared:external_demands": "External requests",
    "shared:pay": "Pay",
    "shared:password_forgotten": "Forgot your password?",
    "verifiable_section:name_fr": "Name(French version)",
    "verifiable_section:name_en": "Name(English version)",
    "unknown_err": "Unknown error, please try later",
    "shared:user_error": "Error getting users",
    "shared:err_get_user": "There was an error getting users",
    "shared:province_error": "Error getting provinces",
    "shared:err_get_province": "There was an error getting provinces",
    "shared:pricing_plans_error": "Error getting pricing plans",
    "shared:err_get_pricing_plans": "There was an error getting pricing plans",
    "shared:err_get_candidate": "There was an error getting candidates",
    "shared:err_get_categories": "There was an error getting all categories",
    "shared:err_get_standard_forms": "There was an error getting standard forms",
    "shared:err_get_companies": "There was an error getting companies",
    "standard_form:ref_name": "Name of the reference",
    "standard_form:title": "Reference of the former employer",
    "standard_form:excellent": "Excellent",
    "standard_form:excellent_desc": "The candidate exceeds the requirements of the task in an outstanding and exceptional manner.",
    "standard_form:very_good": "Very good",
    "standard_form:very_good_desc": "The candidate exceeds the requirements of the task.",
    "standard_form:good": "Good",
    "standard_form:good_desc": "The candidate adequately meets the requirements of the task.",
    "standard_form:fair": "Fair",
    "standard_form:fair_desc": "The candidate partially meets the requirements of the task.",
    "standard_form:low": "Low",
    "standard_form:low_desc": "The candidate does not meet the requirements of the task at all.",
    "standard_form:evaluation_criteria": "Evaluation criteria",
    "standard_form:previous_function": "Previous function",
    "standard_form:ref_title": "Title of the reference",
    "standard_form:ref_phone": "Phone number of the reference",
    "standard_form:ref_phone_post_number": "Extension number of the reference phone",
    "standard_form:quitting_motif": "Why did he leave?",
    "standard_form:supervision_period": "Supervision period",
    "standard_form:company_name": "Company name",
    "standard_form:job_duration": "Duration of employment",
    "standard_form:output_value": "Performance",
    "standard_form:autonomy": "Autonomy",
    "standard_form:ponctuality_and_assiduity": "Punctuality / Attendance",
    "standard_form:work_ethics": "Attitude at work",
    "standard_form:is_enterprising": "Would you say that he is capable of initiative?",
    "standard_form:can_handle_work_under_pressure": "Ability to work under pressure",
    "standard_form:interpersonal_relationship_with_friends": "Interpersonal relations with colleagues",
    "standard_form:interpersonal_relation_with_management": "Interpersonal relations with management",
    "standard_form:is_receptive_to_critics": "Openness to criticism",
    "standard_form:team_spirit": "Team spirit",
    "standard_form:leadership": "Leadership",
    "standard_form:professional_competences": "Professional skills",
    "standard_form:ability_of_management": "Organizational skills",
    "standard_form:ability_of_responsibility": "Sense of responsibility",
    "standard_form:disciplinary_sanctions": "Has he/she been disciplined?",
    "standard_form:pros": "What are its strengths?",
    "standard_form:cons": "What are its areas for improvement?",
    "standard_form:will_rehire": "Would you hire this candidate again?",
    "standard_form:do_you_recommend": "Do you recommend it to us?",
    "standard_form:comments": "Comments",
    "new_demand:credit_scan": "Banking verification",
    /********************* */
    
}        
