import React, { useState, useEffect, } from 'react'
import {
    ICategoryType,
} from 'stores'
import { UtilitiesService } from 'services'
import { useNotificationData } from 'hooks'
import { useIntl } from 'react-intl'

export interface IPricingPlan {
    name: string
    id: string
}

export const usePricingPlanData = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [fetchError, setFetchError] = useState<boolean>(false)
    const [pricing_plan, setPricingPlans] = useState<IPricingPlan[]>([])
    const { formatMessage } = useIntl()

    const { new_notification } = useNotificationData()

    useEffect(() => {
        fetchAllCategories()
    }, [])

    const fetchAllCategories = () => {
        setLoading(true)
        UtilitiesService.list_pricing_plans()
            .catch(err => {
                new_notification({
                    title: formatMessage({ id: 'shared:pricing_plans_error' }),
                    variant: 'error',
                    message: formatMessage({ id: 'shared:err_get_pricing_plans' })
                })
                setLoading(false)
                setFetchError(true)
            })
            .then(async response => {
                if ([200, 201].includes(response.status)) {

                    const payload = await response.json()
                    setPricingPlans(payload)
                    setLoading(false)

                } else if ([404, 401, 500].includes(response.status)) {
                    const { message } = await response.json()
                    new_notification({
                        title: formatMessage({ id: "shared:error" }),
                        message,
                        variant: 'error',
                    })
                    setLoading(false)
                    setFetchError(true)
                } else {
                    new_notification({
                        title: formatMessage({ id: "shared:error" }),
                        message: formatMessage({ id: "shared:err_get_pricing_plans" }),
                        variant: 'error',
                    })
                    setLoading(false)
                    setFetchError(true)
                }
            })

    }

    return { loading, pricing_plan }

}