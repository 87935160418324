export interface Role {
    name: string
    default_route: string
    priority: number
}

const ROLE_ADMINISTRATOR: Role = {
    name: 'administrator',
    default_route: 'dashboard',
    priority: 1
}


const ROLE_TECHNICIAN: Role = {
    name: 'technician',
    default_route: 'candidates',
    priority: 2
}

const ROLE_CONTACT: Role = {
    name: 'contact',
    default_route: 'demands',
    priority: 3
}

const COMPANY_ADMINISTRATOR: Role = {
    name: 'company_administrator',
    default_route: 'dashboard',
    priority: 4
}

const ACCOUNTANT: Role = {
    name: 'accountant',
    default_route: 'billing',
    priority: 5
}


export {
    ROLE_ADMINISTRATOR,
    ROLE_TECHNICIAN,
    ROLE_CONTACT,
    COMPANY_ADMINISTRATOR,
    ACCOUNTANT,
}