import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { Demandstore, IDemand } from 'stores'
import { takeUntil } from 'rxjs/operators'



export function useDemandData() {
    const { demands$, appendDemand, appendDemands, resetDemands, deleteDemand, updateDemand } = Demandstore

    const [start, setStart] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [limit, setLimit] = useState<number>(30)
    const [demands, setDemands] = useState<IDemand[]>([])

    /**Setup accordion */
    useEffect(() => {
        const trigger = new Subject<boolean>()

        demands$
            .pipe(
                takeUntil(trigger.asObservable())
            )
            .subscribe(
                ({
                    start,
                    limit,
                    total,
                    data,
                }) => {
                    setStart(start!)
                    setTotal(total!)
                    setLimit(limit!)
                    setDemands(data)
                }
            )

        //onUnmount
        return () => trigger.next(false)
    }, [])

    return {
        appendDemands, resetDemands,
        deleteDemand, updateDemand,
        appendDemand,
        demands, start, limit, total,
    }
}