import { ObservableStore } from '@codewithdan/observable-store';
import {
    map,
    distinctUntilChanged,
} from 'rxjs/operators'
import {
    ICompany, COMPANY_STORE_ACTIONS,
} from '.'


class CompanyStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        INIT_STATE: 'INIT_STATE',
        ...COMPANY_STORE_ACTIONS,
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }


    //#region companies
    resetCompanies = (param: IStoreCompany) => {
        this.setState(
            state => ({
                ...state,
                companies: { ...param, data: param.data }
            }),
            CompanyStore.ACTIONS.RESET_COMPANIES,
        )
    }

    resetAllCompanies = (all_companies: ICompany[]) => {
        this.setState(
            state => ({
                ...state,
                all_companies
            }),
            CompanyStore.ACTIONS.RESET_COMPANIES,
        )
    }

    appendCompanies = (param: IStoreCompany) => {
        this.setState(
            state => ({
                ...state,
                companies: { ...param, data: [...param.data, ...(state.companies.data || [])] },
            }),
            CompanyStore.ACTIONS.APPEND_COMPANIES,
        )
    }

    updateCompany = (company: ICompany) => {
        const oldCompanyId = this.getState().companies.data!.findIndex(cad => cad.id === company.id)
        if (oldCompanyId === -1) { return }

        /**At this point, companies CANNOT be an empty array no undefined reference */
        const companies = this.getState().companies.data
        companies![oldCompanyId] = company

        this.setState(
            state => ({
                ...state,
                companies: { ...state.companies, data: companies },
            }),
            CompanyStore.ACTIONS.UPDATE_COMPANY,
        )
    }

    deleteCompany = (...companyIds: string[]) => {
        const companiesData = this.getState().companies.data.filter(
            cp => !companyIds.includes(cp.id!)
        )

        this.setState(
            state => ({
                ...state,
                companies: { ...state.companies, data: companiesData },
            }),
            CompanyStore.ACTIONS.DELETE_COMPANY,
        )
    }

    /**
     * **************getter for everything companies data.****************
     * Notice the distinctUntilChanged operator
     * used to ensure that it doesn't fire at all
     * https://rxjs-dev.firebaseapp.com/api/operators/distinctUntilChanged
     */
    get companies$() {
        return this.stateChanged.pipe(
            map(({ companies }) => companies),
            distinctUntilChanged(),
        )
    }

    get all_companies$() {
        return this.stateChanged.pipe(
            map(({ all_companies }) => all_companies),
            distinctUntilChanged(),
        )
    }
    //#endregion companies

    logout = () => {
        const defaultData = {
            data: [],
            start: 0,
            limit: 30,
            total: 0,
        }
        this.setState({
            companies: defaultData,
        }, CompanyStore.ACTIONS.INIT_STATE)

    }

}

export default new CompanyStore()



export interface IStoreCompany {
    limit?: number,
    start?: number,
    total?: number,
    data: ICompany[],
}

export interface StoreState {
    companies: IStoreCompany,
    all_companies: ICompany[]
}