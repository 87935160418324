import React, { useState, useEffect, useCallback } from 'react';
import styles from './NewCompany.module.scss';
import {
  Modal, Button, TextInput,
  Select, SelectOption, SelectVariant, EmptyStateIcon, EmptyState, EmptyStateVariant, Title, EmptyStateBody, Switch,
} from '@patternfly/react-core';
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { AngellistIcon } from '@patternfly/react-icons';
import { useFormik } from 'formik';
import { useProvinceData, useCompanyData, useNotificationData } from 'hooks';
import { CompanyService } from 'services';
import { ICompany } from 'stores';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { ReactComponent as PreEmploiLogo } from 'assets/imgs/logo.svg'
import { Link } from 'react-router-dom';
import { maskNumber } from 'utilities';
import config from 'config'

export interface NewCompanyProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const validationSchema = yup.object().shape({
  name: yup.string().required('shared:name_required'),
  phone: yup.string()
    .max(20, "shared:phonenumber_too_long")
    .matches(
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
      "shared:invalid_phonenumber"
    ),
  fax: yup.string(),
  address: yup.string().required('shared:address_required'),
  city: yup.string().required('shared:city_required'),
  postal_code: yup.string().required('shared:postal_code_required'),
  province: yup.string().required('shared:province_required'),
  activate_company: yup.boolean(),
  /**
 * https://krzysztofzuraw.com/blog/2020/yup-validation-two-fields
 * make field optional or required depending on another field.
 */
  manager_username: yup.string()
    .when(['activate_company'], {
      is: activate_company => activate_company,
      then: yup.string().required('shared:manager_username_required'),
    }),
  manager_firstName: yup.string()
    .when(['activate_company'], {
      is: activate_company => activate_company,
      then: yup.string().required('shared:manager_firstname_required'),
    }),
  manager_email: yup.string()
    .when(['activate_company'], {
      is: activate_company => activate_company,
      then: yup.string().email('shared:email_invalid')
        .required('shared:manager_email_required'),
    }),
  manager_lastName: yup.string()
    .when(['activate_company'], {
      is: activate_company => activate_company,
      then: yup.string().required('shared:manager_lastname_required'),
    }),

})

const NewCompanyComponent: React.FC<NewCompanyProps> = ({
  match: { params, },
  location: { search },
  ...props
}) => {

  const { token } = params as any;
  const [success, setSuccess] = useState(false)
  const { formatMessage } = useIntl()
  const [isOpen, setIsExapanded] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { provinces } = useProvinceData()
  const { new_notification, } = useNotificationData()
  const urlSearch = new URLSearchParams(search)
  const has_manager = urlSearch.get('has_manager')
  const onNewCompany = (value, { resetForm }) => {
    const data = { ...value, province: provinces.find(province => province.name === value.province)!.id }
    saveMemberToApi(data, resetForm)
  }
  const defaultInitialValues = {
    manager_email: '',
    token,
    name: '',
    phone: '',
    post_number: '',
    fax: '',
    address: '',
    city: '',
    postal_code: '',
    activate_company: false,
    province: '',
    discount: 0,
    discount_value: "$",
  }

  const [initialValues, setInitialValues] = useState(
    defaultInitialValues
  )

  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    errors,
    handleReset,
    handleSubmit,
  } = useFormik<ICompany>({
    validationSchema,
    onSubmit: onNewCompany,
    enableReinitialize: true,
    initialValues,
  })

  useEffect(() => {
    let data = {
      ...defaultInitialValues,
      has_manager : `${has_manager}` === '1' ? '1' : '0',
    }
    if (`${has_manager}` === '1')
      data.manager_email = urlSearch.get('manager_email')!

    setInitialValues(data)
  }, [has_manager])

  const handleSelectToggle = is_expanded => setIsExapanded(is_expanded)

  const onSelect = (event, selection) => {
    setIsExapanded(false)
    setFieldValue('province', selection)
  }

  const clearSelection = () => {
    setFieldValue('province', [])
    setIsExapanded(false)
  }

  const saveMemberToApi = (new_company, resetForm) => {
    setLoading(true)
    CompanyService.new_external_company(new_company)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { data, message } = await response.json()
          setSuccess(true)
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          resetForm(initialValues)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  const options = provinces.map(({ id, name }) => (
    <SelectOption key={id} name={name} value={name} />
  ))

  options.unshift(
    <SelectOption
      isDisabled={true}
      value={
        formatMessage({ id: 'shared:select' })
      }
    />
  )

  const SuccessCompanyCreation = () => (
    <EmptyState
      variant={EmptyStateVariant.small}
      className={styles.empty_state}
    >
      <EmptyStateIcon icon={AngellistIcon} />
      <Title headingLevel="h4" size="lg">
        <FormattedMessage id="shared:success" />
      </Title>
      <EmptyStateBody>
        <FormattedMessage
          id="external_company:creation_success_message"
          values={{ ui_url: config.ui_url }}
        />
      </EmptyStateBody>
    </EmptyState>
  )

  return (
    <div
      className={styles.wrapper}
    >
      <Link className={styles.header} to={"/auth/login"}>
        <PreEmploiLogo className={styles.logo} />
      </Link>
      {success ? (
        <SuccessCompanyCreation />
      ) : (
          <div className={styles.container} >
            <form
              className={styles.content}
              onSubmit={handleSubmit}
            >
              <h4 className={styles.title}>
                <FormattedMessage id="company_creation" />
              </h4>
              <span>
                <FormattedMessage
                  id="external_company:creation_page_instructions"
                />
              </span>
              <label
                htmlFor="province"
                className={styles.field}
              >
                <span
                  className={errors.province && touched.province ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.province && touched.province ? errors.province : "shared:province"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <Select
                  variant={SelectVariant.typeahead}
                  onToggle={handleSelectToggle}
                  name="province"
                  onSelect={onSelect}
                  onBlur={handleBlur}
                  selections={values.province}
                  isOpen={isOpen}
                  // placeholderText={formatMessage({ id: "shared:member_of_entity" })}
                  // ariaLabelledBy={'province'}
                  onClear={clearSelection}
                >
                  {options}
                </Select>
              </label>
              <label className={styles.field}>
                <span
                  className={errors.name && touched.name ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.name && touched.name ? errors.name : "shared:name"}
                  />
                  <span
                    className={styles.c_error}
                  >*</span>
                </span>
                <TextInput
                  name={'name'}
                  value={values.name}
                  onChange={text => setFieldValue('name', text)}
                  onBlur={handleBlur}
                  type="text"
                  //css={{}}
                  aria-label="text input example"
                />
              </label>
              <label htmlFor="post_number" className={styles.field}>
                <span
                  className={errors.phone && touched.phone ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.phone && touched.phone ? errors.phone : "shared:tel_and_post_number"}
                  />
                </span>
                <div className="telephone_field">
                  <TextInput
                    name={'phone'}
                    value={values.phone}
                    onChange={text => setFieldValue('phone', text)}
                    onBlur={e => {
                      handleBlur(e)
                      setFieldValue(
                        'phone',
                        maskNumber(values.phone)
                      )
                    }} type="text"
                    //css={{}}
                    aria-label="text input example"
                  />
                  {Boolean(values.phone) && (
                    <TextInput
                      name={'post_number'}
                      id={'post_number'}
                      value={values.post_number}
                      onChange={text => setFieldValue('post_number', text)}
                      onBlur={handleBlur}
                      type="number"
                      //css={{}}
                      placeholder={formatMessage({ id: 'shared:post_number' })}
                    />
                  )}
                </div>
              </label>
              <label className={styles.field}>
                <span
                  className={errors.fax && touched.fax ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.fax && touched.fax ? errors.fax : "shared:fax"}
                  />
                  {/* <span className={styles.c_error}>*</span> */}
                </span>
                <TextInput
                  name={'fax'}
                  value={values.fax}
                  onChange={text => setFieldValue('fax', text)}
                  onBlur={handleBlur}
                  type="text"
                  //css={{}}
                  aria-label="text input example"
                />
              </label>
              <label className={styles.field}>
                <span
                  className={errors.address && touched.address ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.address && touched.address ? errors.address : "shared:address"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  name={'address'}
                  value={values.address}
                  onChange={text => setFieldValue('address', text)}
                  onBlur={handleBlur}
                  type="text"
                  //css={{}}
                  aria-label="text input example"
                />
              </label>
              <label className={styles.field}>
                <span
                  className={errors.city && touched.city ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.city && touched.city ? errors.city : "shared:town"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  name={'city'}
                  value={values.city}
                  onChange={text => setFieldValue('city', text)}
                  onBlur={handleBlur}
                  type="text"
                  //css={{}}
                  aria-label="text input example"
                />
              </label>

              <label className={styles.field}>
                <span
                  className={errors.postal_code && touched.postal_code ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.postal_code && touched.postal_code ? errors.postal_code : "shared:postal_code"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  name={'postal_code'}
                  value={values.postal_code}
                  onChange={text => setFieldValue('postal_code', text)}
                  onBlur={handleBlur}
                  type="text"
                  //css={{}}
                  aria-label="text input example"
                />
              </label>

              {false &&
                <div style={{ gridColumn: '1/-1' }}>
                  <Switch
                    id="simple-switch"
                    label={formatMessage({ id: 'company:activate_company' })}
                    isChecked={Boolean(values.activate_company)}
                    onChange={isChecked => setFieldValue('activate_company', isChecked)}
                  />
                </div>
              }
              {`${has_manager}` === '1' && (
                <>
                  <label className={styles.field}>
                    <span
                      className={errors.manager_username && touched.manager_username ? styles.c_error : ''}
                    >
                      <FormattedMessage
                        id={errors.manager_username && touched.manager_username ? errors.manager_username : "shared:manager_username"}
                      />
                      <span className={styles.c_error}>*</span>
                    </span>
                    <TextInput
                      name={'manager_username'}
                      value={values.manager_username}
                      onChange={text => setFieldValue('manager_username', text)}
                      onBlur={handleBlur}
                      type="text"
                      aria-label="text input example"
                    />
                  </label>
                  <label className={styles.field}>
                    <span
                      className={errors.manager_email && touched.manager_email ? styles.c_error : ''}
                    >
                      <FormattedMessage
                        id={errors.manager_email && touched.manager_email ? errors.manager_email : "shared:manager_email"}
                      />
                      <span className={styles.c_error}>*</span>
                    </span>
                    <TextInput
                      name={'manager_email'}
                      value={values.manager_email}
                      onChange={text => setFieldValue('manager_email', text)}
                      onBlur={handleBlur}
                      type="email"
                      aria-label="text input example"
                    />
                  </label>
                  <label className={styles.field}>
                    <span
                      className={errors.manager_firstName && touched.manager_firstName ? styles.c_error : ''}
                    >
                      <FormattedMessage
                        id={errors.manager_firstName && touched.manager_firstName ? errors.manager_firstName : "shared:manager_firstName"}
                      />
                      <span className={styles.c_error}>*</span>
                    </span>
                    <TextInput
                      name={'manager_firstName'}
                      value={values.manager_firstName}
                      onChange={text => setFieldValue('manager_firstName', text)}
                      onBlur={handleBlur}
                      type="text"
                      aria-label="text input example"
                    />
                  </label>
                  <label className={styles.field}>
                    <span
                      className={errors.manager_lastName && touched.manager_lastName ? styles.c_error : ''}
                    >
                      <FormattedMessage
                        id={errors.manager_lastName && touched.manager_lastName ? errors.manager_lastName : "shared:manager_lastName"}
                      />
                      <span className={styles.c_error}>*</span>
                    </span>
                    <TextInput
                      name={'manager_lastName'}
                      value={values.manager_lastName}
                      onChange={text => setFieldValue('manager_lastName', text)}
                      onBlur={handleBlur}
                      type="text"
                      //css={{}}
                      aria-label="text input example"
                    />
                  </label>
                </>
              )}

              <Button
                className={styles.actions}
                isDisabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length < 1}
                type="submit"
              >
                <FormattedMessage id={"shared:save"} />
                {loading && (
                  <Spinner size="sm" />
                )}
              </Button>
            </form>
          </div>
        )}
    </div>
  );
}

export {
  NewCompanyComponent as NewCompanyPage,
}
