import React, { useState } from 'react';
import styles from './StripePaymentDialog.module.scss'
import {
  Modal, Button, Spinner, spinnerSize, ButtonVariant
} from '@patternfly/react-core';
import {
  FormattedMessage, useIntl
} from 'react-intl'
import {
  CardElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';


export interface StripePaymentDialogProps {
  onModalClose: any
  isOpen: boolean
  loading: boolean
  onSaveAsDraft?: any
  onAction: any
  price?: number
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#8481dd',
      iconColor: '#8481dd'
    }

  }
};

export const StripePaymentDialog: React.FC<StripePaymentDialogProps> = ({
  isOpen, price,
  onModalClose, loading = false,
  onAction, onSaveAsDraft = undefined,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>()
  const { formatMessage } = useIntl()

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements!.getElement(CardElement);
    const result = await stripe!.createToken(card!)
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      onAction(result.token!.id);
    }
  };


  return (
    <>
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: 'stripe:enter_stripe_info' })}
          </span>
        }
        isOpen={isOpen}
        onClose={onModalClose}
        className={styles.modal_root}
        width={'70%'}
      >
        <FormattedMessage
          id="stripe:prompt_stripe_info"
        />
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className="form-row">
            <CardElement
              id="card-element"
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleChange}
            />
            {error && (
              <div
                className={styles.error}
                role="alert"
              >{error}</div>
            )}
            {onSaveAsDraft && (
              <Button
                className={styles.link}
                role="alert"
                variant={ButtonVariant.link}
                onClick={onSaveAsDraft}
              >
                <FormattedMessage
                  id="stripe:pay_layer"
                />
              </Button>
            )}
          </div>
          <Button
            key="StripePayment"
            variant="primary"
            className={styles.btn_proceed}
            isDisabled={loading}
            type="submit"
          >
            {price ? (
              <>
                <FormattedMessage id={"shared:pay"} /> : ${price}
              </>
            ) : (
                <FormattedMessage id={"shared:proceed"} />
              )}
            {loading && (
              <Spinner
                size={spinnerSize.md}
              />
            )}
          </Button>
        </form>
      </Modal>
    </>
  );
}
