import BaseService from './BaseService'
import { utilitiesUrls } from './urls'

class DashboardService {
    static fetch_admin_stats = () => {
        return BaseService.getRequest(utilitiesUrls.ADMIN_STATS, true)
    }
    static fetch_manager_stats = () => {
        return BaseService.getRequest(utilitiesUrls.ACTIVATED_COMPANY_STATS, true)
    }
}

export default DashboardService;