import BaseService from './BaseService'
import { candidateUrls } from './urls'

class CompanyService {
    static list_candidates = (suffix?) => BaseService.getRequest(candidateUrls.CANDIDATES(suffix), true);
    static candidate = (candidate_id) => BaseService.getRequest(candidateUrls.CANDIDATE(candidate_id), true);
    static external_candidate = (candidate_id, token: string) => BaseService.getRequest(candidateUrls.EXTERNAL_CANDIDATE(candidate_id, token), false);
    static transfer_candidates = (data) => BaseService.postRequest(candidateUrls.TRANSFER_CANDIDATE, data, true);
    static new_candidate = (data) => BaseService.postRequest(candidateUrls.NEW_CANDIDATE, data, true);
    static update_candidate = (id,data) => BaseService.putRequest(candidateUrls.UPDATE_CANDIDATE(id), data, true);
    static delete_candidates = (data) => BaseService.deleteRequest(candidateUrls.DELETE_CANDIDATE, data, true);
    static archive_candidates = (data) => BaseService.postRequest(candidateUrls.ARCHIVE_CANDIDATE, data, true);
}

export default CompanyService;