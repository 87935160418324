import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { AuthStore } from 'stores'
import { takeUntil } from 'rxjs/operators'
import { ILoginInfo } from 'stores/auth.store'

export function useUserInfoData(){
    const [userInfo, setUserInfo] = useState<ILoginInfo| null | {}>({})
    const trigger = new Subject<boolean>()
    const { userInfo$, saveUserInfo, updateProfileInfo } = AuthStore
    useEffect(() => {
        userInfo$.pipe(
            takeUntil(trigger.asObservable())
        ).subscribe({
            next: setUserInfo
        })

        return () => trigger.next(false)
    }, [])

    return { userInfo, saveUserInfo, updateProfileInfo }
}