import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import styles from './CompanyBillingDetail.module.scss';
import {
  Button, TextInput, Spinner, spinnerSize,
  Checkbox, SelectOption, Select, SelectVariant,
  Dropdown, DropdownItem, DropdownToggle,
  InputGroup
} from '@patternfly/react-core';
import {
  useIntl, FormattedMessage
} from 'react-intl';
import clsx from 'classnames'
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
  SortByDirection
} from '@patternfly/react-table';
import { Link } from 'react-router-dom'
import {
  useAccordionData, useNotificationData,
  useUserRoleData, useUserInfoData,
  useUserData, useRoleData
} from 'hooks'
import { RouteComponentProps } from 'react-router-dom'
import { BillingService } from 'services'
import { useCandidatesData } from 'hooks'
import {
  ArrowsAltVIcon, FilterAltIcon, SearchIcon
} from '@patternfly/react-icons';
import {
  ListEmpty, SearchField, AccordionContext, InvoiceChangelogPopover
} from 'components';
import { TABLE_COLUMN } from 'constants/table_columns';
import moment from 'moment';
import { ILoginInfo } from 'stores/auth.store';
import { ChangeBalance, ConfirmDialog } from 'dialogs';

export interface CompanyBillingDetailProps extends RouteComponentProps {
}

export interface IInvoice {
  amount: number;
  id: string;
  date: string;
  status: number;
  folder_number: number;
  candidate_name: string;
  verifiable_sections: { name: string, id: string }[],
}


const CompanyBillingDetailComponent: React.FC<CompanyBillingDetailProps> = ({
  match: { url, params, ...match },
  history, location,
  ...props
}) => {

  const BILLED = 3;
  const PAID = 1;
  const PAYMENT_PENDING = 0;

  let {
    start: storeStart, limit: storeLimit,
    candidates: storeCandidates,
  } = useCandidatesData()
  const { userInfo } = useUserInfoData() as any
  let company_id = ''
  //@ts-ignore
  company_id = params.company_id || (userInfo as ILoginInfo).company_id
  //@ts-ignore
  const { billing = { name: '' } } = location.state || {}
  const { currentRole } = useUserRoleData()
  // console.log({ currentRole })
  const { setBottomComponent } = React.useContext(AccordionContext);
  const [isOpen, setIsOpen] = useState<string>()
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [
    observerTriggerState, setObserverTriggerState
  ] = useState<any>({})
  const [dateFilterOpen, setDateFilterOpen] = useState<string>('')
  const [
    dateFilter, setDateFilter
  ] = useState<string>(
    `${new Date().getFullYear()}&month=${new Date().getMonth()}`
  )
  const { formatMessage } = useIntl()

  const table_header_columns: TABLE_COLUMN[] = [
    {
      title: formatMessage({ id: 'shared:candidate' }),
      sortable: false,
      field: 'candidate_name',
    },
    {
      title: formatMessage({ id: "verification:verifiable_sections" }),
      field: 'section',
      searcheable: false,
      type: 'number',
    },
    {
      title: formatMessage({ id: "shared:price" }),
      field: 'amount',
      type: 'number',
    },
    {
      title: formatMessage({ id: "shared:date" }),
      field: 'date',
      type: 'date',
    },
    {
      title: formatMessage({ id: "shared:folder_number" }),
      field: 'folder_number',
      type: 'text',
    },
    {
      title: formatMessage({ id: "shared:status" }),
      searcheable: false,
    },
    {
      title: formatMessage({ id: "shared:changelog" }),
      searcheable: false,
    },
  ]

  const [order, setOrder] = useState<string>('')


  const onSelect = (title) => {
    setSelected(title)
    setFilterOpen(false)
  }

  const orders = [
    {
      title: formatMessage({ id: "shared:asc" }),
      value: 'asc',
    },
    {
      title: formatMessage({ id: "shared:desc" }),
      value: 'desc',
    },
  ]

  const dateFields = table_header_columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'date'
  )
    .map(({ title }) => title)

  const numberFields = table_header_columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'number'
  )
    .map(({ title }) => title)

  const searcheableFields = table_header_columns
    .filter(({ searcheable = true }) => searcheable)
    .map(({ title }) => title)
    .map(title => (
      <DropdownItem
        onClick={_ => {
          onSelect(title)
          setQuery('')
        }}
        key={title} value={title}
        component="button"
      >
        {title}
      </DropdownItem>
    ))

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [orderOpen, setOrderOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>('')

  if (currentRole !== "Contact") {
    //Empty column for details
    table_header_columns.push(
      { title: '', searcheable: false }
    )
  }

  const [loading, setLoading] = useState<boolean>(false)
  const centinelRef = useRef<any>(null)

  const { new_notification } = useNotificationData()

  const [rows, setRows] = useState<any[]>([])
  const [sortBy, setSortBy] = useState<any>({})
  const { resetAccordion } = useAccordionData()
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  const [filterState, setFilterState] = useState<any>(undefined)
  const [balance, setBalance] = useState<number>(0)
  const [
    selectedInvoices, setSelectedInvoices
  ] = useState<string[]>([])
  const [
    toUpdateStatus, setToUpdateStatus,
  ] = useState<string[]>([])
  const [
    confirmOpen, setConfirmOpen
  ] = useState<boolean>(false)
  const [currentStatus, setCurrentStatus] = useState<string>('')
  useEffect(() => {
    let title = `
    ${formatMessage({ id: 'billing:used_service_billing' })} : 
    ${billing.name ? " : " + billing.name : ''}
    `
    if(`${company_id}` === "-1"){
      title = `
      ${formatMessage({ id: 'billing:used_service_billing' })} : 
      ${formatMessage({id:"shared:external_demands"})}
      `
    }
    resetAccordion({
      title,
      sub_title: ''
    })
    configureInfiniteScroll()
  }, [])

  const configureBottom = () => {
    setBottomComponent(
      <div className={styles.bottom}>
        {`${company_id}` !== `-1` &&  (
          <>
            {balance && (
              <h1
                style={{
                  color: balance > 0 ? 'red' : 'inherit'
                }}
              >
                <FormattedMessage id="shared:amount_indebted" />
        : ${balance}
              </h1>
            )}
            {
              ["Administrator", "Technician"].includes(currentRole) && (
                <ChangeBalance
                  company_id={company_id}
                  onDone={_ => setBalance(_)}
                  balance={balance}
                  renderTrigger={trigger => (
                    <Button
                      onClick={trigger}
                      variant="secondary"
                    >
                      <FormattedMessage id="shared:update" />
                    </Button>
                  )}
                />
              )
            }
          </>
        )}

        <span className={styles.spacer}></span>
        <h1 className={styles.total_amount}>
          <FormattedMessage id="shared:total_amount" /> :&nbsp;
          ${totalAmount}
        </h1>
      </div>
    )
  }

  useEffect(() => {
    fetchBillingInfo(0)
  }, [userInfo, currentRole])

  useEffect(
    () => {
      if (storeCandidates && storeCandidates.length > 0 && !query) {
        setRows(storeCandidates)
      }
    },
    [storeCandidates]
  )

  useEffect(
    () => {
      const column = table_header_columns
        .find(({ title }) => title === selected)
      if (!Boolean(column)) return
      let field = column!.field

      setFilterState(
        {
          order, order_field: field,
          [field!]: query, year: dateFilter
        }
      )
      fetchBillingInfo(
        0,
        {
          order, order_field: field,
          [field!]: query, year: dateFilter
        }
      )

    },
    [selected, query, order, dateFilter]
  )

  useEffect(() => {
    configureBottom()
    return () => setBottomComponent(<></>)
  }, [
    balance, currentRole, totalAmount,
  ])

  const fetchBillingInfo = (
    paramStart: number,
    filter?: any,
  ) => {
    if (!Boolean(userInfo) || !Boolean(currentRole)) { return }
    setLoading(true)
    let suffix = `${company_id}?start=${paramStart}&limit=${30}`


    if (Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (
          Boolean((filter || filterState)[field])
          || (filter || filterState)[field] === 0
        ) {
          suffix = `${suffix}&${field == 'undefined' ? 'keyword' : field}=${(filter || filterState)[field]}`
        }
      })
    }


    BillingService.list_company_billing(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const {
            results, start, total, total_amount,
            company_debt,
          } = await response.json()
          setRows([...(start > 0 ? rows : []), ...results])
          setSearchTotal(total)
          setSearchStart(start)
          setTotalAmount(total_amount)
          setBalance(company_debt)
        } else if (response.json){
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const parseCompanyBillingDetail = (data: IInvoice[]) => {
    return data.map(
      datum => ({
        cells: [
          `${datum.candidate_name} `,
          <>
            <ul className={styles.verifiable_sections}>
              {(datum.verifiable_sections || []).map(({ name }) => (
                <li>{name}</li>
              ))}
            </ul>
          </>,
          `$${datum.amount} `,
          `${moment.utc(
            (datum.date|| "").replace("-05:00", "")
          )
            .utcOffset("-05:00")
            .format('DD-MM-YYYY')} `,
          `${datum.folder_number || formatMessage({ id: "shared:n_a" })} `,
          <>
            {
              ['Administrator', 'Technician', 'Accountant'].includes(currentRole) ? (
                <Select
                  onToggle={onToggleChangeStatus(datum.id)}
                  variant={SelectVariant.single}
                  isOpen={isOpen === datum.id}
                  selections={parsePaymentStatus(datum.status)}
                  onSelect={onChangeStatus([datum.id])}
                >
                  <SelectOption value={formatMessage({ id: 'shared:payment_pending' })} />
                  {/* <SelectOption value={formatMessage({ id: 'shared:paid' })} /> */}
                  <SelectOption value={formatMessage({ id: 'shared:billed' })} />
                </Select>
              ) : (
                  `${parsePaymentStatus(datum.status)}`
                )}
          </>,
          <>
            <InvoiceChangelogPopover
              trigger={fn => (
                <Button
                  onMouseEnter={_ => fn(true)}
                  onMouseLeave={_ => fn(false)}
                  variant="link"
                // onClick={_ => { 
                // fn(false); 
                //   viewModifLogs(datum) 
                // }}
                >
                  <FormattedMessage id="shared:changelog" />
                </Button>
              )}
              request={datum}
            />
          </>
        ],
        selected: selectedInvoices.includes(datum.id),
      })
    )
  }

  const updateBalance = (status, invoice_ids, currentInvoices) => {
    const concerned_invoices = currentInvoices
      .filter(_ => invoice_ids.includes(_.id))
    let amountToAdd = 0
    if (status === BILLED) {
      amountToAdd = concerned_invoices.filter(_ => BILLED !== _.id)
        .reduce((acc, curr,) => {
          acc += curr.amount
          return acc
        }, amountToAdd)
      if (amountToAdd < 0) amountToAdd = amountToAdd * -1
    } else {
      amountToAdd = concerned_invoices.filter(_ => BILLED === _.id)
        .reduce((acc, curr,) => {
          acc += curr.amount
          return acc
        }, amountToAdd)
      if (amountToAdd > 0) amountToAdd = amountToAdd * -1
    }
    console.log({
      balance, amountToAdd, status,
      concerned_invoices, currentInvoices
    })
    setBalance(balance + amountToAdd)
  }

  const onChangeStatus = ids => (_, status) => {
    if (
      formatMessage({ id: "shared:payment_pending" }) === status
    ) {
      updateStatus(ids, PAYMENT_PENDING)
    } else if (
      formatMessage({ id: "shared:paid" }) === status
    ) {
      updateStatus(ids, PAID)
    } else if (
      formatMessage({ id: "shared:billed" }) === status
    ) {
      updateStatus(ids, BILLED)
    }
  }

  const updateStatus = (invoice_ids, status) => {
    setIsOpen('')
    setLoading(true)
    BillingService.updateInvoiceStatus({ status, invoice_ids })
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:success" }),
            variant: 'success'
          })
          updateBalance(status, invoice_ids, rows)
          setRows([
            ...rows
              .map(
                row => invoice_ids.includes(row.id) ? ({ ...row, status }) : row
              )
          ])
          setConfirmOpen(false)
          setSelectedInvoices([])
          setToUpdateStatus([])
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const parsePaymentStatus = status => {
    if (status === 1) {
      return formatMessage({ id: "shared:paid" })
    }
    if (status === 0) {
      return formatMessage({ id: "shared:payment_pending" })
    }
    if (status === 3) {
      return formatMessage({ id: "shared:billed" })
    }
  }

  const onToggleChangeStatus = id => open => {
    if (open) {
      setIsOpen(id)
    } else {
      setIsOpen('')
    }
  }

  const onNextPage = () => {
    if (searchTotal !== 0 && rows.length >= searchTotal) { return }
    fetchBillingInfo(searchTotal === 0 ? 0 : searchStart + storeLimit)
    return
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeCandidates, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  const parsePagination = () => {
    const count = rows.length
    let total = searchTotal
    return `${count} of ${total}`
  }

  const onSelectTableItem = (_, is_selected, idx) => {
    if (idx === -1) {
      if (is_selected) {
        setSelectedInvoices(
          rows.map(_ => _.id)
        )
      } else {
        setSelectedInvoices([])
      }
      return
    }
    const row_id = rows[idx].id
    if (is_selected) {
      setSelectedInvoices([...selectedInvoices, row_id])
    } else {
      setSelectedInvoices([
        ...selectedInvoices.filter(_ => _ !== row_id)
      ])
    }
  }

  const viewModifLogs = invoice_id => () => {

  }

  // const actions = [
  //   {
  //     title: <div className={styles.menu_link}>
  //       <span>
  //         <FormattedMessage id="shared:view_modif_logs" />
  //       </span>
  //     </div>,
  //     onClick: (_, rowId,) => viewModifLogs(rows[rowId])
  //   },

  // ]

  const years = [
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
  ]

  const months = [
    {
      value: 0,
      name: formatMessage({ id: "shared:janurary" }),
    },
    {
      value: 1,
      name: formatMessage({ id: "shared:february" }),
    },
    {
      value: 2,
      name: formatMessage({ id: "shared:march" }),
    },
    {
      value: 3,
      name: formatMessage({ id: "shared:april" }),
    },
    {
      value: 4,
      name: formatMessage({ id: "shared:may" }),
    },
    {
      value: 5,
      name: formatMessage({ id: "shared:june" }),
    },
    {
      value: 6,
      name: formatMessage({ id: "shared:jully" }),
    },
    {
      value: 7,
      name: formatMessage({ id: "shared:august" }),
    },
    {
      value: 8,
      name: formatMessage({ id: "shared:september" }),
    },
    {
      value: 9,
      name: formatMessage({ id: "shared:october" }),
    },
    {
      value: 10,
      name: formatMessage({ id: "shared:november" }),
    },
    {
      value: 11,
      name: formatMessage({ id: "shared:december" }),
    },
  ]

  return (
    <div className={styles.wrapper} >
      <ConfirmDialog
        title_message={"billing:change_payment_status"}
        confirmation_message={'billing:update_billing_status'}
        isModalOpen={toUpdateStatus.length > 0}
        onAction={
          () => onChangeStatus(toUpdateStatus)(
            undefined,
            currentStatus
          )
        }
        disabled={currentStatus === ''}
        loading={loading}
        onModalClose={_ => {
          setToUpdateStatus([])
          setSelectedInvoices([])
        }}
        actionText="shared:proceed"
      >
        <Select
          onToggle={setConfirmOpen}
          variant={SelectVariant.single}
          isOpen={confirmOpen}
          selections={currentStatus}
          className={styles.modal_select}
          onSelect={
            (_, status) => {
              setCurrentStatus(`${status}`)
              setConfirmOpen(false)
            }
          }
        >
          <SelectOption value={''} isDisabled={true} />
          <SelectOption value={formatMessage({ id: 'shared:payment_pending' })} />
          {/* <SelectOption value={formatMessage({ id: 'shared:paid' })} /> */}
          <SelectOption value={formatMessage({ id: 'shared:billed' })} />
        </Select>
      </ConfirmDialog>

      <div className={styles.content}>
        <div className={styles.toolbar}>
          <InputGroup
            className={styles.search}
          >
            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<FilterAltIcon />}
                  onToggle={setFilterOpen}
                >
                  {selected ? selected : formatMessage({ id: 'shared:filter' })}
                </DropdownToggle>
              }
              isOpen={filterOpen}
              dropdownItems={searcheableFields}
            />

            {selected !== 'section' && (
              <Dropdown
                toggle={
                  <DropdownToggle
                    icon={<ArrowsAltVIcon />}
                    onToggle={setOrderOpen}
                  >
                    {order ?
                      orders.find(jk => jk.value === order)!.title
                      : formatMessage({ id: 'shared:order' })}
                  </DropdownToggle>
                }
                isOpen={orderOpen}
                dropdownItems={
                  orders
                    .map(orderIn => (
                      <DropdownItem
                        onClick={_ => {
                          setOrder(orderIn.value)
                          setOrderOpen(false)
                        }}
                        key={orderIn.value}
                        value={orderIn.value}
                        component="button"
                      >
                        {orderIn.title}
                      </DropdownItem>
                    ))
                }
              />
            )}

            <SearchField
              value={query}
              dateFields={dateFields}
              numberFields={numberFields}
              removeArchivedStatus={true}
              field={selected}
              onAction={setQuery}
            />

            <Button
              variant="control"
              className={styles.btn_nothing}
            >
              <SearchIcon />
            </Button>

          </InputGroup>
          <InputGroup
            className={styles.search}
          >
            <Dropdown
              // className={className}
              isOpen={dateFilterOpen.indexOf('year') !== -1}
              toggle={
                <DropdownToggle
                  onToggle={
                    (isOpen) => setDateFilterOpen(isOpen ? 'year' : '')
                  }
                >
                  {
                    dateFilter ?
                      years.find(jk => jk === `${dateFilter}`.split('&month=')[0])
                      : formatMessage({ id: 'shared:year' })
                  }
                </DropdownToggle>
              }

              dropdownItems={
                years.map(it => (
                  <DropdownItem
                    onClick={_ => {
                      const month = `${dateFilter}`.split('&month=').pop()
                      setDateFilter(`${it}&month=${month || (new Date().getMonth())}`)
                      setDateFilterOpen('')
                    }}
                    key={it}
                    value={it}
                    component="button"
                  >
                    {it}
                  </DropdownItem>
                ))
              }
            />
            <Dropdown
              isOpen={dateFilterOpen.indexOf('month') !== -1}
              toggle={
                <DropdownToggle
                  onToggle={
                    isOpen => setDateFilterOpen(isOpen ? 'month' : '')
                  }
                >
                  {
                    dateFilter ?
                      months.find(
                        _ => _.value === +`${dateFilter}`.split('&month=').pop()!
                      )!.name
                      : formatMessage({ id: 'shared:month' })
                  }
                </DropdownToggle>
              }

              dropdownItems={
                months
                  .map(it => (
                    <DropdownItem
                      onClick={_ => {
                        const year = `${dateFilter}`.split('&month=')[0] || (new Date()).getFullYear()
                        setDateFilter(`${year}&month=${it.value}`)
                        setDateFilterOpen('')
                      }}
                      key={it.value}
                      value={it.value}
                      component="button"
                    >
                      {it.name}
                    </DropdownItem>
                  ))
              }
            />
          </InputGroup>

          {
            selectedInvoices.length > 0 && (
              <Button
                variant="secondary"
                onClick={
                  _ => setToUpdateStatus(selectedInvoices)
                }
              >
                <FormattedMessage
                  id="shared:update_payment_status"
                />
              </Button>
            )
          }
          {loading && <Spinner size={spinnerSize.md} />}

        </div>

        {rows.length === 0 && !loading && (
          <ListEmpty />
        )}

        {rows.length > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            cells={table_header_columns}
            borders={true}
            canSelectAll={
              ['Administrator', 'Technician']
                .includes(currentRole) ? true : false
            }
            onSelect={
              ['Administrator', 'Technician']
                .includes(currentRole) ?
                onSelectTableItem : undefined
            }
            rows={parseCompanyBillingDetail(rows)}
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}

        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{parsePagination()}</span>
        </div>

      </div>

    </div>
  )
}

export {
  CompanyBillingDetailComponent as CompanyBillingDetailPage,
}
