import React, { useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Checkbox } from '@patternfly/react-core';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ArrowRightIcon, } from '@patternfly/react-icons';
import scss from "./grid_card.module.scss";
import { MenuAction } from 'components';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

interface GridCardProps extends RouteComponentProps {
  readonly form: any;
  readonly OpenCloseModal: (form) => void;
  readonly root_url?: string;
  readonly menus?: any[];
  readonly setform?: (form) => void;
  readonly selected?: boolean;
  readonly btn_delete?: boolean;
  readonly header_border?: boolean;
  readonly footer?: boolean;
  readonly enableCheckbox?: boolean;
  readonly onSelect: any;
  readonly selectedElements: string[];

}
/**
 * Grid pour afficher un formulaire
 * @param props 
 */
export const GridCard: React.FC<GridCardProps> = props => {
  const {
    form, setform, root_url, menus = [],
    enableCheckbox = true,
    // selected = true, btn_delete = false,
    header_border = false, footer = true,
    onSelect, selectedElements,
  } = props

  //Pour l'even click sur un option menu
  const handleClick = () => {
    if (setform) {
      setform(form);
    }
  }


  return (
    <Card>
      <CardHeader className={classNames(scss.header, header_border && scss.border_bottom)}>
        <h1>{form.title}</h1>
        {/**Afficage du menus action */}
        {menus.length > 0 && <MenuAction options={menus} handleClick={handleClick} />}
        {/**Fin Afficage du menus action*/}
        {enableCheckbox && (
          <Checkbox
            onChange={i => {
              // setState(!state)
              onSelect(form.id)
            }}
            isChecked={selectedElements.includes(form.id)}
            className={scss.checkbox}
            id="checkbox"
          />
        )}
      </CardHeader>
      <CardBody>
        <div className={scss.description}>
          {form.description_jsx || form.description}
        </div>
      </CardBody>
      {footer && <CardFooter>
        <Link
          to={{
            pathname: root_url ? root_url : `${props.location.pathname}/${form.id}`,
            state: { form: { ...form.category, description: '' } }
          }}
        >
        <FormattedMessage id="shared:see_more" />
         <ArrowRightIcon /> </Link>
      </CardFooter>}
    </Card>);

}