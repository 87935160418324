import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import styles from './Profile.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { AccordionContext } from 'components'
import {
  Form,
  TextInput,
  ActionGroup,
  Button,
  Spinner,
  spinnerSize
} from '@patternfly/react-core';
import { ReactComponent as Icon_profil } from 'assets/imgs/Icon_profil.svg';
import { useUserInfoData } from 'hooks'
import clsx from 'classnames'
import { ILoginInfo } from 'stores/auth.store';
import { useFormik, FormikBag } from 'formik';
import * as yup from 'yup'
import { UserService } from 'services';
// import { AuthStore } from 'stores';
import { maskNumber } from 'utilities';

export interface ProfileProps {
}

export interface Profile {
  username: string,
  email: string,
  lastname: string,
  firstname: string,
  fax: string,
  profile_photo?: any,
  phone: string,
  post_number: string,
  current_password?:string,
  new_password?:string,
}

const validationSchema = yup.object().shape({
  username: yup.string().required(),
  email: yup.string().required(),
  lastname: yup.string().required(),
  firstname: yup.string().required(),
  fax: yup.string().required(),
  phone: yup.string().required(),
  post_number: yup.string(),//.required(),
})

const ProfileComponent: React.FC<ProfileProps> = props => {

  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  const { setBottomComponent } = useContext(AccordionContext)
  const { userInfo, updateProfileInfo } = useUserInfoData()
  const { new_notification } = useNotificationData()
  // const { updateProfileInfo } = AuthStore
  const [loading, setLoading] = useState<boolean>(false)

  const [initialValues, setInitialValues] = useState<Profile>({
    username: '',
    email: '',
    lastname: '',
    firstname: '',
    fax: '',
    post_number: '',
    phone: '',
    profile_photo: "",
    current_password:'',
    new_password:'',
  })

  // Update header page
  const accordionBottom = () => (
    <span></span>
  )

  React.useEffect(() => {
    setBottomComponent(accordionBottom)
    resetAccordion({
      title: formatMessage({ id: 'routes.profile' }),
      sub_title: ""
    })
  }, [])

  // Pre-fill fields with connected user's information
  useEffect(() => {
    if (userInfo !== undefined)
      setInitialValues({
        username: (userInfo as ILoginInfo).username,
        email: (userInfo as ILoginInfo).email,
        lastname: (userInfo as ILoginInfo).lastName,
        firstname: (userInfo as ILoginInfo).firstName,
        fax: (userInfo as any).fax,
        phone: (userInfo as any).phone,
        post_number: (userInfo as any).post_number,
        current_password:'',
        new_password:'',
      })
  }, [userInfo]);

  const submitToApi = async (
    {
      firstname, lastname,
      profile_photo = "",
      ...values
    },
  ) => {
    const data = {
      ...values,
      firstName: firstname,
      lastName: lastname,
    }

    let formData = new FormData()
    Object.keys(data).forEach(
      key => formData.append(key, data[key])
    )

    setLoading(true)
    UserService.update_profile(formData)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { data, message } = await response.json()
          updateProfileInfo(data)
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
        } else if (response.json) {
          const { detail } = await response.json()
          new_notification({
            message:detail,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map(file => setFieldValue(
      'profile_photo',
      URL.createObjectURL(file)
    ))
    acceptedFiles.forEach((file) => {
      setFieldValue("photo", file)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*'
  })



  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleReset,
    handleSubmit,
  } = useFormik<Profile>({
    onSubmit: submitToApi,
    validationSchema,
    enableReinitialize: true,
    initialValues,
  })


  return (
    <div className={styles.container}>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <span className={styles.group}>
          <FormattedMessage id="profile:basic_info" />
        </span>
        {/* Manage avatar */}
        <label className={styles.label}>
          <div className={styles.avatar_container}>
            {values.profile_photo && (
              <img
                src={values.profile_photo}
                alt=""
                className={styles.image_container}
              />
            )}

            <div {...getRootProps()} >
              <input {...getInputProps()} />
            </div>

            <div className={styles.avatar_text}>
              <FormattedMessage id="shared:load_avatar" />
            </div>

          </div>
        </label>
        {/* End Manage avatar */}
        <label htmlFor="username" className={styles.text_input}>
          <FormattedMessage id="shared:username" />
          <TextInput
            isRequired
            type="text"
            id="username"
            name="username"
            isDisabled={true}
            value={values.username}
            onChange={text => setFieldValue('username', text)}
          />
        </label>
        <span className={styles.group}>
          <FormattedMessage id="profile:user_profile" />
        </span>
        <label htmlFor="email" className={styles.text_input}>
          <FormattedMessage id="shared:email" />
          <TextInput
            isRequired
            type="email"
            isDisabled={true}
            id="email"
            name="email"
            value={values.email}
            onChange={t => setFieldValue('email', t)}
          />
        </label>
        <label className={styles.text_input}>
          <FormattedMessage id="shared:family_name" />
          <TextInput
            isRequired
            type="text"
            id="lastname"
            name="lastname"
            value={values.lastname}
            onChange={t => setFieldValue('lastname', t)}
          />
        </label>
        <label htmlFor="firstname" className={styles.text_input}>
          <FormattedMessage id="shared:lastname" />
          <TextInput
            isRequired
            type="text"
            id="firstname"
            name="firstname"
            value={values.firstname}
            onChange={t => setFieldValue('firstname', t)}
          />
        </label>
        <label htmlFor="fax" className={styles.text_input}>
          <FormattedMessage id="shared:fax" />
          <TextInput
            isRequired
            type="text"
            id="fax"
            name="fax"
            value={values.fax}
            onChange={t => setFieldValue('fax', t)}
          />
        </label>
        <label htmlFor="post_number" className={styles.text_input}>
          <FormattedMessage id="shared:tel_and_post_number" />
          <div className="telephone_field">
            <TextInput
              isRequired
              type="tel"
              id="phone"
              name="phone"
              value={values.phone}
              onChange={text => setFieldValue('phone', text)}
              onBlur={e => {
                handleBlur(e)
                setFieldValue(
                  'phone',
                  maskNumber(values.phone)
                )
              }}
              />
            <TextInput
              isRequired
              type="number"
              id="post_number"
              name="post_number"
              value={values.post_number}
              onChange={text => setFieldValue('post_number', text)}
              placeholder={formatMessage({ id: "shared:post_number" })}
            />
          </div>
        </label>
        <label htmlFor="current_password" className={styles.text_input}>
          <FormattedMessage id="shared:current_password" />
          <TextInput
            isRequired
            type="password"
            id="current_password"
            name="current_password"
            value={values.current_password}
            onChange={t => setFieldValue('current_password', t)}
          />
        </label>
        <label htmlFor="new_password" className={styles.text_input}>
          <FormattedMessage id="shared:new_password" />
          <TextInput
            isRequired
            type="password"
            id="new_password"
            name="new_password"
            value={values.new_password}
            onChange={t => setFieldValue('new_password', t)}
          />
        </label>

        <ActionGroup className={styles.actions}>
          <Button className={clsx(
            styles.btn,
            styles.btn_secondary,
          )}
          >
            <FormattedMessage id="shared:cancel" /></Button>
          <Button
            type="submit"
            isDisabled={loading || Object.keys(errors).length > 0}
            className={clsx(
              styles.btn,
              styles.btn_primary,
            )}
          >
            <FormattedMessage id="shared:save" />
            {loading && <Spinner size={spinnerSize.sm} />}
          </Button>
        </ActionGroup>
      </Form>

    </div>
  );
}

export {
  ProfileComponent as ProfilePage,
}
