import config from 'config'
/*Urls for the application */
export const prefixer = config.api_url;

export const authUrls = {
	//Auth URI
	SIGNINUSER: `${prefixer}`,
	LOGINUSER: `${prefixer}auth/login`,
	RECOVER_PASSWORD: `${prefixer}auth/reset-password`,
	CHANGE_PASSWORD: `${prefixer}auth/reset-password`,
	CHANGE_PASSWORD_EXTERNAL: `${prefixer}auth/reset-password-external`,
	CURRENT_USER: refresh_token => `${prefixer}auth/logged?refresh_token=${refresh_token}`,
}

export const companyUrls = {
	//company URI
	COMPANIES: (suffix?: string) => `${prefixer}companies${suffix ? `${suffix}` : ''}`,
	ACTIVE_COMPANIES: (suffix?: string) => `${prefixer}companies/active${suffix ? `${suffix}` : ''}`,
	NEW_COMPANY: `${prefixer}companies`,
	ACTIVATE_COMPANY: company_id => `${prefixer}companies/status/${company_id}`,
	NEW_EXTERNAL_COMPANY: `${prefixer}companies/external`,
	COMPANY_CREATION_EMAIL: `${prefixer}companies/send-company-creation-email`,
	DELETE_COMPANIES: `${prefixer}companies`,
	UPDATE_COMPANY: (id: string) => `${prefixer}companies/${id}`,
}

export const deparmentUrls = {
	DEPARTMENT: (suffix?: string) => (
		`${prefixer}department${suffix ? suffix : ''}`
	),
	DEPARTMENT_NEW_USER: (dep_id: string) => (
		`${prefixer}users/${dep_id}`
	),
	ADD_USERS: department_id => `${prefixer}users/${department_id}`,
}

export const questionsUrl = {
	NEW_QUESTION: `${prefixer}questions`,
	REORDER_QUESTIONS: category_id => `${prefixer}questions/reorder/${category_id}`,
	REMOVE_QUESTION_FROM_CATEGORY: cat_id => `${prefixer}questions/remove/${cat_id}`,
	ADD_QUESTION_TO_CATEGORY: `${prefixer}questions/add/categories`,
	DELETE_QUESTION: `${prefixer}questions`,
	UPDATE_QUESTION: id => `${prefixer}questions/${id}`,
	ALL_QUESTIONS: suffix => `${prefixer}questions${suffix ? `${suffix}` : ''}`,
}

export const verifiableSectionUrls = {
	ALL_SECTIONS: `${prefixer}verifiable-section`,
	UPDATE_SECTION: id => `${prefixer}verifiable-section/${id}`,
	DELETE_SECTIONS: `${prefixer}verifiable-section`,
	UPDATE_REQUEST_VERIFIABLE_SECTIONS: candidate_id => (
		`${prefixer}verifiable-section/change/${candidate_id}`
	),
}

export const candidateUrls = {
	//company URI
	CANDIDATES: (suffix?: string) => `${prefixer}candidates${suffix ? `${suffix}` : ''}`,
	CANDIDATE: (candidate_id: string) => `${prefixer}candidates/${candidate_id}`,
	EXTERNAL_CANDIDATE: (candidate_id: string, token: string) => `${prefixer}candidates/${candidate_id}/${token}`,
	UPDATE_CANDIDATE: (id: string) => `${prefixer}candidates/${id}`,
	NEW_CANDIDATE: `${prefixer}candidates`,
	DELETE_CANDIDATE: `${prefixer}candidates`,
	ARCHIVE_CANDIDATE: `${prefixer}candidates/archive`,
	TRANSFER_CANDIDATE: `${prefixer}candidates/transfer`,
}

export const demandsUrls = {
	//company URI
	DEMANDS: (suffix: string) => `${prefixer}demands${suffix ? `${suffix}` : ''}`,
	DRAFTED_DEMANDS: (suffix: string) => `${prefixer}demands/contact/draft${suffix ? `${suffix}` : ''}`,
	NEW_DEMAND: `${prefixer}demands`,
	DELETE_DRAFTED_REQUESTS: `${prefixer}demands/contact/draft`,
	SWITCH_DRAFTED_DEMAND: demand_id => `${prefixer}demands/paid/${demand_id}`,
	NEW_DEMAND_EXTERNAL: `${prefixer}demands/external`,
	SEND_DEMAND_CREATION_EMAIL: `${prefixer}demands/send-demand-creation-form`,
	GET_DEMAND_BY_ID: candidate_id => `${prefixer}demands/${candidate_id}`,
	EXTERNAL_GET_DEMAND_BY_ID: (candidate_id: string, token:string) => `${prefixer}demands/${candidate_id}/${token}`,
}

export const userUrls = {
	//user URI
	USERS: (suffix?: string) => `${prefixer}users${suffix ? `${suffix}` : ''}`,
	DEPARTMENT_USERS: (suffix?: string) => (
		`${prefixer}helper/department/users/${suffix ? `${suffix}` : ''}`
	),
	NEW_USER: `${prefixer}users`,
	UPDATE_USER: `${prefixer}users`,
	UPDATE_PROFILE: `${prefixer}users/logged`,
	DELETE_USER: `${prefixer}users`,
	DELETE_USER_DEPARTMENT: dep_id => `${prefixer}users/${dep_id}`,
	CURRENT_COMPANY: `${prefixer}helper/get-auth-company`,
};

export const formsUrls = {
	//company URI
	FORMS: (suffix: string) => `${prefixer}admin/forms${suffix ? `${suffix}` : ''}`,
	FORM_DATA: (form_id: string) => `${prefixer}admin/forms/${form_id}`,
	TREAT_FORM_DATA: (
		form_id: string, candidate_id: string,
	) => `${prefixer}admin/forms/${candidate_id}/${form_id}`,
	DELETE_FORM: `${prefixer}admin/forms`,
	DENY_FILLING_FORM: `${prefixer}forms/denied/form`,
	SEND_FORM_BY_EMAIL: `${prefixer}forms/send-by-mail`,
	NEW_FORM: `${prefixer}admin/forms`,
	FORMS_LITE: `${prefixer}helper/forms`,
	FORMS_AUTO_FILL: candidate_id => `${prefixer}helper/forms/${candidate_id}`,
	FORM_CATEGORIES: (form_id: string, suffix: string) => `${prefixer}admin/forms/${form_id}/categories${suffix ? `${suffix}` : ''}`,
	UPDATE_FORM: (form_id: string) => `${prefixer}admin/forms/${form_id}`,
	CANDIDATE_FORM: `${prefixer}forms`,
	CANDIDATE_FORM_EXTERNAL: `${prefixer}forms/external`,
	GET_CANDIDATE_FORM: candidate_id => `${prefixer}forms/${candidate_id}`,
	EXTERNAL_GET_CANDIDATE_FORM: (candidate_id:string, token:string) => `${prefixer}forms/${candidate_id}/${token}`,
	DELETE_CANDIDATE_FORM: form_id => `${prefixer}forms/${form_id}`,
}

export const categoriesUrls = {
	NEW_CATEGORY: `${prefixer}categories`,
	CATEGORIES: (suffix: string) => `${prefixer}categories${suffix ? `${suffix}` : ''}`,
	UPDATE_CATEGORY: id => `${prefixer}categories/${id}`,
	REORDER_CATEGORIES: id => `${prefixer}categories/reorder/${id}`,
	DELETE_CATEGORY: `${prefixer}categories`,
	DELETE_QUESTION: `${prefixer}questions`,
	ALL_CATEGORIES: `${prefixer}helper/categories`,
	CATEGORY_QUESTIONS: cat_id => `${prefixer}categories/${cat_id}/questions`
}


export const utilitiesUrls = {
	//company URI
	PROVINCES: `${prefixer}helper/provinces`,
	CONTACTS: `${prefixer}helper/contacts`,
	CATEGORIES: `${prefixer}helper/categories`,
	ROLES: `${prefixer}roles/role`,
	ADMIN_STATS: `${prefixer}helper/admin-stats`,
	ACTIVATED_COMPANY_STATS: `${prefixer}helper/manager-stats`,
	COMPANIES: `${prefixer}helper/companies`,
	STANDARD_FORMS: `${prefixer}helper/get-standards-forms`,
	PRICING_PLAN: `${prefixer}helper/pricing-plan`,
	COMPANY_USERS: company_id => `${prefixer}helper/users/${company_id}`,

};

export const billingUrls = {
	//billing URI
	BILLING: suffix => `${prefixer}billing${suffix}`,
	UPDATE_BALANCE: company_id => `${prefixer}companies/balance/${company_id}`,
	UPDATE_BILLING_STATUS: `${prefixer}billing`,
	COMPANY_BILLING: company_id => `${prefixer}billing/${company_id}`,
};

