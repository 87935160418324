import BaseService from './BaseService'
import {  billingUrls } from './urls'

class BillingService {
    static list_billing = (suffix) => BaseService.getRequest(billingUrls.BILLING(suffix), true);
    static updateBalance = (company_id, data) => BaseService.putRequest(
        billingUrls.UPDATE_BALANCE(company_id), data, true
        );
    static updateInvoiceStatus = ( data) => BaseService.putRequest(billingUrls.UPDATE_BILLING_STATUS,data, true);
    static list_company_billing = (suffix) => BaseService.getRequest(billingUrls.COMPANY_BILLING(suffix), true);
}

export default BillingService;