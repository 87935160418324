export interface IUser {
    access: {
        manageGroupMembership: boolean,
        view: boolean,
        mapRoles: boolean,
        impersonate: boolean,
        manage: boolean,
    }
    // attributes: {
    //     update_at: string,
    // }
    last_connection: string,
    createdTimestamp: number
    update_at: string,
    disableableCredentialTypes: string[],
    email: string,
    emailVerified: boolean,
    enabled: boolean,
    firstName: string,
    id: string,
    is_admin: boolean,
    is_contact: boolean,
    is_technicien: boolean,
    lastName: string,
    notBefore: number,
    requiredActions: any[],
    totp: boolean,
    username: string,
}

export const USER_STORE_ACTIONS = {
    RESET_USERS: 'USERS:RESET',
    APPEND_USERS: 'USERS:APPEND',
    UPDATE_USER: 'USERS:UPDATE',
    DELETE_USER: 'USERS:DELETE',
}