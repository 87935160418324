import React, { useState, useEffect } from 'react';
import styles from './AdminDashboard.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import clsx from 'classnames'
import { ReactComponent as IconArchived } from 'assets/imgs/icon_archived.svg'
import { ReactComponent as IconLocked } from 'assets/imgs/icon_locked.svg'
import { ReactComponent as IconPhone } from 'assets/imgs/icon_phone.svg'
import { ReactComponent as IconOpen } from 'assets/imgs/icon_open.svg'
import { ReactComponent as IconInProgress } from 'assets/imgs/icon_in_progress.svg'
import { ChartPie, Chart, ChartAxis, ChartGroup, ChartArea, ChartThemeColor, ChartLegendTooltip, ChartVoronoiContainer, createContainer, ChartThemeVariant } from '@patternfly/react-charts';
import { ReactComponent as IconActive } from 'assets/imgs/icon_done.svg'
import { ReactComponent as IconInactive } from 'assets/imgs/icon_cross_red.svg'
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
} from '@patternfly/react-table';
import { Button, TextInput, Spinner, spinnerSize, Card, CardBody, Grid, GridItem } from '@patternfly/react-core';
import { ExpandableTable } from 'components';
import { ActivatedCompany } from 'pages/activated_company/ActivatedCompany.page';
import { DashboardService } from 'services';
import { CubeIcon, CubesIcon, UserIcon } from '@patternfly/react-icons';


export interface AdminDashboardProps {
  readonly dumm?: boolean;
}

const AdminDashboardComponent: React.FC<AdminDashboardProps> = props => {

  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  const [statistics, setStatistics] = useState<any>({
    total_demands: undefined, month_demands: undefined,
    year_demands: undefined, users_stats: undefined,
    candidates: undefined, company_count: undefined,
    active_company_count: undefined,
    inactive_company_count: undefined,
  })
  const [division, setDivision] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [illustrations, setIllustrations] = useState<any[]>([])
  const [users, setUsers] = useState<any>()
  const [pies, setPies] = useState<any[]>([])
  const [requestVsTime, setRequestVsTime] = useState<any[]>([])
  const [tickValues, setTickValues] = useState<any[]>([])

  React.useEffect(() => {
    fetchStatistics()
    resetAccordion({ title: formatMessage({ id: 'routes.dashboard' }) })
  }, [])

  useEffect(() => {
    if (
      Object.keys(statistics)
        .map(key => statistics[key])
        .some(i => !Boolean(i) && i !== 0)
    ) { return }
    const data: any[] = []
    let demandsInfo = statistics.total_demands

    data.push({
      title: formatMessage({ id: 'dashboard:total_demands' }),
      value: demandsInfo.demands_count,
      graphs: [],
      labels: [
        {
          label: formatMessage({ id: 'shared:archived_and_closed' }),
          text: demandsInfo.archived_and_closed_demands_count,
          Icon: IconLocked,
        },
        {
          label: formatMessage({ id: 'shared:archived' }),
          text: demandsInfo.archived_demands_count, Icon: IconArchived,
        },
        {
          label: formatMessage({ id: 'shared:closed' }),
          text: demandsInfo.closed_demands_count, Icon: IconLocked,
        },
        {
          label: formatMessage({ id: 'shared:fax_sent' }),
          text: demandsInfo.fax_send_demands_count, Icon: IconPhone,
        },
        {
          label: formatMessage({ id: 'shared:open' }),
          text: demandsInfo.open_demands_count, Icon: IconOpen,
        },
        {
          label: formatMessage({ id: 'shared:in_progress' }),
          text: demandsInfo.in_progress_demands_count, Icon: IconInProgress,
        },
      ]
    })

    let monthsDemandinfo = statistics.month_demands
    data.push({
      title: formatMessage({ id: 'dashboard:current_months_demands' }),
      value: monthsDemandinfo.demands_count,
      graphs: [],
      labels: [
        {
          label: formatMessage({ id: 'shared:archived_and_closed' }),
          text: monthsDemandinfo.archived_and_closed_demands_count,
          Icon: IconLocked,
        },
        {
          label: formatMessage({ id: 'shared:archived' }),
          text: monthsDemandinfo.archived_demands_count, Icon: IconArchived,
        },
        {
          label: formatMessage({ id: 'shared:closed' }),
          text: monthsDemandinfo.closed_demands_count, Icon: IconLocked,
        },
        {
          label: formatMessage({ id: 'shared:fax_sent' }),
          text: monthsDemandinfo.fax_send_demands_count, Icon: IconPhone,
        },
        {
          label: formatMessage({ id: 'shared:open' }),
          text: monthsDemandinfo.open_demands_count, Icon: IconOpen,
        },
        {
          label: formatMessage({ id: 'shared:in_progress' }),
          text: monthsDemandinfo.in_progress_demands_count, Icon: IconInProgress,
        },
      ]
    })

    setPies(data)

    setIllustrations([
      {
        value: statistics.candidates,
        name: formatMessage({ id: "shared:candidates" }),
      },
      {
        value: statistics.company_count,
        active: statistics.active_company_count,
        inactive: statistics.inactive_company_count,
        name: formatMessage({ id: "shared:companies" }),
      },
    ])

    const userInfo = statistics.users_stats
    setUsers({
      count: userInfo.users_count,
      pie: [
        {
          x: formatMessage({ id: "shared:admins" }),
          y: userInfo.admin_count
        },
        {
          x: formatMessage({ id: "shared:technicians" }),
          y: userInfo.tech_count
        },
        {
          x: formatMessage({ id: "shared:accountants" }),
          y: userInfo.accountant_count
        },
        {
          x: formatMessage({ id: "shared:customers" }),
          y: userInfo.customers_count
        },
      ]
    })

    let lineChartInfo = statistics.year_demands
    let highestCount = Object.keys(lineChartInfo)
      .reduce((acc, key) => +acc < +lineChartInfo[key] ? lineChartInfo[key] : acc, 0)
    let paramDivision = highestCount > 1000 ? 100 : 5
    setDivision(paramDivision)
    lineChartInfo = Object.keys(lineChartInfo)
      .map(key => ({
        x: formatMessage({ id: key }),
        y: lineChartInfo[key] / paramDivision
      }))

    setRequestVsTime(lineChartInfo)

    let length = lineChartInfo.reduce((acc, { y }) => acc < y ? y : acc, 0)

    let tickvals = new Array(
      Math.ceil(length)
    ).fill(0).map((_, idx) => idx + 1)
    tickvals.push(...[1, 2, 3, 4].map((_, idx) => idx + tickvals.length))

    setTickValues(tickvals)

  }, [statistics])

  const CursorVoronoiContainer = createContainer("cursor", "voronoi");

  const legendData = [
    { childName: 'cats', name: 'Cats' },
    {
      childName: 'dogs',
      name: 'Dogs',
      symbol: { type: 'dash' }
    },
    { childName: 'birds', name: 'Birds' },
    { childName: 'mice', name: 'Mice' }
  ]

  const { new_notification } = useNotificationData()

  const fetchStatistics = () => {
    setLoading(true)
    DashboardService.fetch_admin_stats()
      .catch(_ => {
        setLoading(false)
      })
      .then(async response => {
        if ((response && response.status) && [200, 201].includes(response.status || 0)) {
          const stats = await response.json()
          setStatistics(stats)
          setLoading(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }


  return (
    <div className={styles.container}>
      <Grid className={styles.toolbar} span={6} hasGutter>
        <GridItem rowSpan={12}>
          <Card>
            <CardBody>
              {
                illustrations.length > 1 ? (
                  <>
                    <CubesIcon/><br/>
                    <div className={styles.grid_column}>
                      <span className={styles.card__title}>{illustrations[1].name}</span>
                      <span className={clsx(styles.card__main_text, styles.flexEnd)}>{illustrations[1].value}</span>
                    </div>
                    <div className={clsx(styles.grid_column, styles.textCenter)}>
                      <div className={styles.section}>
                        <span className={styles.textIcon}>
                          <IconActive /> 
                          <FormattedMessage id="shared:actives" />
                        </span>
                        <span className={styles.center_justify}>{illustrations[1].active}</span>
                      </div>

                      <div className={styles.section}>
                        <span className={styles.textIcon}>
                          <IconInactive /> 
                          <FormattedMessage id="shared:inactives" />
                        </span>
                        <span className={styles.center_justify}>{illustrations[1].inactive}</span>
                      </div>

                    </div>
                  </>
                ) : (
                    <Spinner
                      size={spinnerSize.md}
                      style={{
                        margin: '0 auto',
                        justifySelf: 'center',
                      }}
                    />
                  )}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem rowSpan={12}>
          <Card>
            <CardBody>
              {
                illustrations.length > 0 ? (
                  <>
                    <UserIcon/><br/><br/>
                    <div className={styles.grid_column}>
                      <span className={styles.card__title}>{illustrations[0].name}</span>
                    </div>
                    <br/>
                    <div className={styles.grid_column}>
                      <span className={clsx(styles.card__main_text, styles.textCenter)}>{illustrations[0].value}</span>
                    </div>
                  </>
                ) : (
                    <Spinner
                      size={spinnerSize.md}
                      style={{
                        margin: '0 auto',
                        justifySelf: 'center',
                      }}
                    />
                  )}
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
      <br/>
      <Grid hasGutter span={4}>
        {(pies).map((pie, idxxx) => (
          <GridItem>
            <Card>
              <CardBody>
                <span className={styles.card__title}>
                  {Boolean(users) ? (
                    <>
                      {pie.title}
                    :{pie.value}
                    </>
                  ) : (
                      <Spinner size={spinnerSize.md} />
                    )}

                </span>
                {Boolean(statistics.total_demands) ? (
                  <ChartPie
                    ariaDesc="total demands"
                    constrainToVisibleArea={true}
                    data={pie.labels.map(item => (
                      { x: item.label, y: item.text }
                    ))}
                    height={290}
                    legendData={pie.labels.map(item => (
                      { name: `${item.label} : ${item.text}` }
                    ))}
                    legendOrientation="vertical"
                    legendPosition="right"
                    labels={({ datum }) => `${datum.x} : ${datum.y}`}
                    padding={{
                      bottom: 20,
                      left: 20,
                      right: 220, // Adjusted to accommodate legend
                      top: 20
                    }}
                    themeColor={
                      idxxx === 1 ?
                        ChartThemeColor.orange
                        : ChartThemeColor.cyan
                    }
                    width={350}
                  />
                ) : (
                    <Spinner
                      size={spinnerSize.lg}
                      style={{
                        margin: '0 auto',
                        justifySelf: 'center',
                      }}
                    />
                  )}
              </CardBody>
            </Card>
          </GridItem>
        ))}
        
     

        {pies.length < 1 && [1, 2].map(_ =>
          <div>
            <div
              className={clsx(
                // styles.pie,
                styles.section,
              )}
            >
              <div className={styles.card__title}>
                <Spinner size={spinnerSize.md} />

              </div>
              <Spinner
                size={spinnerSize.md}
                style={{
                  position: 'relative',
                  justifySelf: 'center',
                  top: '1rem',
                }}
              />

            </div>
          </div>

        )}

      <GridItem>          
      <Card>
        <CardBody>
          <span className={styles.card__title}>
            <FormattedMessage id="shared:users" /> :
            {Boolean(users) ? (
              <>
                {users.count}
              </>
            ) : (
                <Spinner size={spinnerSize.md} />
              )}

          </span>
          {Boolean(users) ? (
            <ChartPie
              ariaDesc="users"
              ariaTitle="users"
              constrainToVisibleArea={true}
              data={users.pie}
              height={190}
              legendData={users.pie.map(
                ({ x, y }) => ({ name: `${x} : ${y || 0}` })
              )}
              legendOrientation="vertical"
              legendPosition="right"
              labels={({ datum }) => `${datum.x} : ${datum.y}`}
              padding={{
                bottom: 20,
                left: 20,
                right: 220, // Adjusted to accommodate legend
                top: 20
              }}
              themeColor={ChartThemeColor.purple}
              width={350}
              themeVariant={ChartThemeVariant.default}
            />
          ) : (
              <Spinner
                size={spinnerSize.lg}
                style={{
                  margin: '0 auto',
                  justifySelf: 'center',
                }}
              />
            )}
        </CardBody>
      </Card>
      </GridItem>
      </Grid>
      <br/>

      <Grid>
        <GridItem>
          <Card>
            <CardBody
            >
              <span className={styles.card__title}>
                {formatMessage({ id: "dashboard:stats" })}
              </span>
              {division > 1 && (
                <span className={styles.card__title}>
                  {formatMessage({ id: "dashboard:division" })} {division}
                </span>
              )}
              <div className={styles.line_graph}>
                {Boolean(statistics.month_demands) ? (
                  <Chart
                    ariaDesc="Average number of pets"
                    ariaTitle="Line chart example"
                    containerComponent={
                      <CursorVoronoiContainer
                        cursorDimension="x"
                        labels={({ datum: { y, x } }) => `${y} : ${x}`}
                        labelComponent={
                          <ChartLegendTooltip
                            legendData={legendData}
                            title={({ x, y }) => `${x} : ${y}`}
                          />}
                        mouseFollowTooltips
                        voronoiDimension="x"
                        voronoiPadding={50}
                      />
                    }
                    height={tickValues.length * 16}
                    maxDomain={{ y: tickValues.length }}
                    minDomain={{ y: 0 }}
                    padding={{
                      // Adjusted to accommodate legend
                      bottom: 30,
                      left: tickValues.length > 99 ? 60 : 30,
                      right: 16,
                      top: 16
                    }}
                    themeColor={ChartThemeColor.green}
                  >
                    <ChartAxis
                      tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                    />
                    <ChartAxis
                      dependentAxis
                      showGrid
                      tickValues={tickValues}
                    />
                    <ChartGroup>
                      <ChartArea
                        data={requestVsTime}
                        name="month"
                        interpolation="monotoneX"
                      />
                    </ChartGroup>
                  </Chart>
                ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner
                        size={spinnerSize.lg}
                        style={{
                          margin: '0 auto',
                          textAlign: 'center',
                        }}
                      />
                    </div>
                  )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </div>
  );

}

export {
  AdminDashboardComponent as AdminDashboardPage,
}
