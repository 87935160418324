import BaseService from './BaseService'
import { demandsUrls } from './urls'

class DemandService {
    static list_demands = (suffix) => BaseService.getRequest(demandsUrls.DEMANDS(suffix), true);
    static list_drafted_demands = (suffix) => BaseService.getRequest(demandsUrls.DRAFTED_DEMANDS(suffix), true);
    static new_demand = (data) => BaseService.postFileRequest(demandsUrls.NEW_DEMAND, data, true);
    static switchDraftedDemand = (demand_id, data) => BaseService.putRequest(demandsUrls.SWITCH_DRAFTED_DEMAND(demand_id), data, true);
    static send_demand_creation_email = (data) => BaseService.postRequest(demandsUrls.SEND_DEMAND_CREATION_EMAIL, data, true);
    static delete_drafts = (data) => BaseService.deleteRequest(demandsUrls.DELETE_DRAFTED_REQUESTS, data, true);
    static new_external_demand = (data) => BaseService.postFileRequest(demandsUrls.NEW_DEMAND_EXTERNAL, data, true);
    static update_demand = (id, data) => BaseService.putRequest(demandsUrls.GET_DEMAND_BY_ID(id), data, true);
    static get_demand = (id) => BaseService.getRequest(demandsUrls.GET_DEMAND_BY_ID(id), true);
    static external_get_demand = (id: string, token: string) => BaseService.getRequest(demandsUrls.EXTERNAL_GET_DEMAND_BY_ID(id, token), true);
}

export default DemandService;