import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './CompanyListing.module.scss';
import {
  Button, TextInput, Spinner, spinnerSize,
  DropdownItem, Dropdown, DropdownToggle,
  InputGroup,
} from '@patternfly/react-core';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
  SortByDirection
} from '@patternfly/react-table';
import { RouteComponentProps } from 'react-router-dom'
import {
  useAccordionData, useNotificationData,
  usePricingPlanData, useUserRoleData,
} from 'hooks'
import {
  NewCompany, ConfirmDialog, PromptActivateCompany,
} from 'dialogs';
import { CompanyService } from 'services';
import { useCompanyData } from 'hooks'
import { ICompany } from 'stores';
import {
  ArrowsAltVIcon, CloseIcon,
  FilterAltIcon, SearchIcon
} from '@patternfly/react-icons';
import { ListEmpty, SearchField } from 'components';
import { TABLE_COLUMN } from 'constants/table_columns';

export interface CompanyListingProps extends RouteComponentProps {
}

const CompanyListingComponent: React.FC<CompanyListingProps> = ({
  history,
  match,
  ...props
}) => {
  let {
    start: storeStart, limit: storeLimit, total: storeTotal,
    companies: storeCompanies,
  } = useCompanyData()
  const { currentRole } = useUserRoleData()
  const { pricing_plan } = usePricingPlanData()
  const [observerTriggerState, setObserverTriggerState] = useState<any>({})
  const [email, setEmail] = useState<string>('')
  const [showEmailDialog, setShowEmailDialog] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const centinelRef = useRef<any>(null)
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  let timeout;
  const [
    selectedCandidatesIds, setSelectedCandidtesIds
  ] = useState<string[]>([])
  const [
    loadingDelete, setLoadingDelete
  ] = useState<boolean>(false)
  const [
    loadingSendEmail, setLoadingSendEmail
  ] = useState<boolean>(false)
  const [toDelete, setToDelete] = useState<string[]>([])
  const [toActivate, setToActivate] = useState<string>()
  const [
    toBeModifiedCompany, setToBeModifiedCompany
  ] = useState<ICompany | undefined>(undefined)
  const [filterState, setFilterState] = useState<any>(undefined)

  const columns: TABLE_COLUMN[] = [
    {
      title: formatMessage({ id: "shared:enterprise_matricule" }),
      field: 'number',
      searcheable: true,
    },
    {
      title: formatMessage({ id: 'shared:name' }),
      field: 'name',
      searcheable: true,
    },
    {
      title: formatMessage({ id: 'shared:pricing_plan' }),
      field: 'pricing_plan',
      searcheable: false,
    },
    {
      title: formatMessage({ id: 'shared:amount_indebted' }),
      field: 'balance',
      searcheable: false,
    },
    {
      title: formatMessage({ id: 'shared:phonenumber' }),
      field: 'phonenumber',
      searcheable: true,
      type: 'number',
    },
    {
      title: formatMessage({ id: 'shared:postNumber' }),
      field: 'postNumber',
      searcheable: false,
    },
    {
      title: formatMessage({ id: 'shared:address' }),
      field: 'address',
      searcheable: true,
    },
    {
      title: formatMessage({ id: 'shared:town' }),
      field: 'city',
      searcheable: true,
    },
  ]

  const dateFields = columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'date'
  )
    .map(({ title }) => title)

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [orderOpen, setOrderOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>('')
  const [order, setOrder] = useState<string>('')

  const { new_notification } = useNotificationData()

  useEffect(() => {
    const column = columns.find(({ title }) => title === selected)
    // if (!Boolean(column)) return
    let field = (column || { field: '' })!.field

    setFilterState(
      { order, order_field: field, [field!]: query }
    )
    fetchCompanies(
      0,
      { order, order_field: field, [field!]: query }
    )
  }, [selected, query, order,])


  const onSelect = (title) => {
    setSelected(title)
    setFilterOpen(false)
  };

  const searcheableFields = columns
    .filter(({ searcheable = true }) => searcheable)
    .map(({ title }) => title)
    .map(title => (
      <DropdownItem
        onClick={_ => {
          onSelect(title)
        }}
        key={title} value={title}
        component="button"
      >
        {title}
      </DropdownItem>
    ))


  const deleteCompany = (...company_ids) => {
    setRows(rows.filter(
      ({ id }) => !company_ids.includes(id)
    ))
  }

  const [sortBy, setSortBy] = useState<any>({})
  const { resetAccordion } = useAccordionData()

  const onSort = (_event, index, direction) => {
    const sortedRows = rows.sort((a, b) => (a[index] < b[index] ? -1 : a[index] > b[index] ? 1 : 0));
    setSortBy({
      index,
      direction
    })
    setRows(direction === SortByDirection.asc ? sortedRows : sortedRows.reverse())
  }

  useEffect(() => {
    resetAccordion({ title: formatMessage({ id: "companies:list_of_companies" }) })
    configureInfiniteScroll()
  }, [])

  const [rows, setRows] = useState<any[]>([])
  const [modifyTrigger, setModifyTrigger] = useState<string>('')

  useEffect(() => {
    console.log('modifyTrigger')
    if (modifyTrigger !== "") {
      console.log({ modifyTrigger })
      setToBeModifiedCompany({ ...rows[modifyTrigger] })
    }
  }, [modifyTrigger])

  const orders = [
    {
      title: formatMessage({ id: "shared:asc" }),
      value: 'asc',
    },
    {
      title: formatMessage({ id: "shared:desc" }),
      value: 'desc',
    },
  ]

  const admin_actions = [
    {
      title: <div className={styles.menu_link}>
        <span>
          <FormattedMessage id="shared:modify" />
        </span>
      </div>,
      onClick: (_, rowId,) => setModifyTrigger(rowId)
    },
    {
      title: <div className={styles.menu_link}>
        <span>
          <FormattedMessage id="shared:view_users" />
        </span>
      </div>,
      onClick: (_, rowId,) => viewUsersOfCompany(rows[rowId].id)
    },
    {
      title: <div className={styles.menu_link}>
        <span>
          <FormattedMessage id="shared:activate_enterprise" />
        </span>
      </div>,
      onClick: (_, rowId,) => setToActivate(rows[rowId].id)
    },
    {
      isSeparator: true
    },
    {
      title: <div className={styles.menu_link}>
        <span>
          <FormattedMessage id="shared:delete" />
        </span>
      </div>,
      onClick: (_, rowId,) => setToDelete([rows[rowId].id!])
    },
  ]

  const technician_actions = [
    {
      title: <div className={styles.menu_link}>
        <span>
          <FormattedMessage id="shared:view_users" />
        </span>
      </div>,
      onClick: (_, rowId,) => viewUsersOfCompany(rows[rowId].id)
    },
    {
      isSeparator: true
    },
  ]

  const viewUsersOfCompany = (company_id: string) => {
    history.push(`/me/users/${company_id}`)
  }

  const fetchCompanies = (
    paramStart: number,
    filter?: any,
  ) => {
    setLoading(true)
    let suffix = `?start=${paramStart}&limit=${storeLimit}`
    if (Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (
          Boolean((filter || filterState)[field])
          || (filter || filterState)[field] === 0
        ) {
          suffix = `${suffix}&${field || 'keyword'}=${(filter || filterState)[field]}`
        }
      })
    }
    CompanyService.list_companies(suffix)
      .catch(_ => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { results, start, total, limit } = await response.json()
          setRows([...(start > 0 ? rows : []), ...results])
          setSearchTotal(total)
          setSearchStart(start)
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const sendCompanyCreationEmail = (email: string) => {
    setLoadingSendEmail(true)
    CompanyService.send_company_creation_email({ email })
      .catch(_ => {
        setLoadingSendEmail(false)
        new_notification({
          message: formatMessage({ id: "shared:unkown_error" }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoadingSendEmail(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          setShowEmailDialog(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const deleteFormFromApi = (...company_ids: string[]) => {
    setLoadingDelete(true)
    CompanyService.delete_companies({ company_ids })
      .catch(_ => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          deleteCompany(...company_ids)
          setToDelete([])
          setSelectedCandidtesIds([])
          setLoadingDelete(false)
          if (company_ids.length > 15) {
            setTimeout(() => {
              setObserverTriggerState({ hello: Math.random() })
            })
          }
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingDelete(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const activateEnterprise = (company_id, contact_id) => {
    setLoadingDelete(true)
    const data = {
      status: true,
      contact_id,
    }
    CompanyService.update_company_activation_status(company_id, data)
      .catch(_ => {
        setLoadingDelete(false)
        new_notification({
          message: formatMessage({ id: "shared:unkown_error" }),
          title: 'success',
          variant: 'success'
        })
      })
      .then(async response => {
        setLoadingDelete(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          deleteCompany(company_id)
          setToActivate(undefined)
          setSelectedCandidtesIds([])
          setLoadingDelete(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingDelete(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const parsePricingPlan = id => {
    if (`${id}` === '1') {
      return formatMessage({ id: 'shared:pricing_per_unit' })
    } else if (`${id}` === '2') {
      return formatMessage({ id: "shared:pricing_per_month" })
    } else {
      return (
        pricing_plan.find(_ => _.id === id) || { name: '' }
      )!.name
    }
  }

  const onTableRowSelect = (
    event, isSelected: boolean, rowIndex: number
  ) => {
    if (rowIndex === -1) {
      if (isSelected) {
        setSelectedCandidtesIds(rows.map(_ => _.id))
        return
      }
      setSelectedCandidtesIds([])
      return
    }
    const company_id = rows[rowIndex].id
    if (isSelected) {
      setSelectedCandidtesIds([...selectedCandidatesIds, company_id])
      return
    }
    const newList = [...selectedCandidatesIds]
    setSelectedCandidtesIds(
      newList.filter(_ => _ !== company_id)
    )

  }

  const parseCompanies = (data: ICompany[]) => {
    return data.map(datum => ({
      cells: [
        datum.number,
        datum.name,
        parsePricingPlan(datum.pricing_plan_id),
        <>
          {+datum.company_debt! < 0 ? (
            `$${datum.company_debt}`
          ) : (
              <span
                style={{ color: 'red' }}
              >${datum.company_debt}</span>
            )}
        </>,
        datum.phone,
        datum.post_number,
        datum.address,
        // datum.postal_code,
        datum.city,
      ],
      selected: selectedCandidatesIds.includes(datum.id!)
    }
    ))
  }

  const onNextPage = () => {

    if (searchTotal !== 0 && rows.length >= searchTotal) { return }
    fetchCompanies(searchTotal === 0 ? 0 : searchStart + storeLimit)
    // setStart(storeStart + storeLimit)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeCompanies, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    // cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  const parsePagination = () => {
    const count = rows.length
    let total = searchTotal
    if (Boolean(query) || Boolean(filterState)) {
      total = searchTotal
    }
    return `${count} of ${total}`
  }

  return (
    <div className={styles.wrapper} >

      <ConfirmDialog
        confirmation_message={
          formatMessage({
            id: 'shared:delete_company_and_users',
          },
            {
              total_users: rows.filter(
                _ => toDelete.includes(_.id)
              ).map(_ => _.total_users)
                .reduce((acc, curr) => acc + curr, 0)
            })
        }
        // title_message="shared:warning"
        onAction={_ => deleteFormFromApi(...toDelete)}
        isModalOpen={toDelete.length > 0}
        loading={loadingDelete}
        onModalClose={_ => setToDelete([])}
      />

      <PromptActivateCompany
        rowId={toActivate}
        onAction={activateEnterprise}
        loading={loadingDelete}
        onModalClose={() => setToActivate(undefined)}
      />

      <ConfirmDialog
        onAction={_ => sendCompanyCreationEmail(email)}
        actionText={formatMessage({ id: "shared:send_mail" })}
        title_message={
          formatMessage({
            id: "shared:send_email_to_company_to_create_company"
          })}
        isModalOpen={showEmailDialog}
        loading={loadingSendEmail}
        disabled={
          email.length < 1
          || ['@', '.'].some(_ => email.indexOf(_) === -1)
        }
        onModalClose={_ => setShowEmailDialog(false)}
      >
        <form
          action=""
          onSubmit={
            e => {
              e.preventDefault()
              sendCompanyCreationEmail(email)
            }
          }
        >
          <label htmlFor="email">
            <span>
              <FormattedMessage id="shared:enterprise_email" />
            </span>
            <TextInput id="email" value={email} onChange={setEmail} />
          </label>
        </form>
      </ConfirmDialog>

      <div className={styles.content}>
        <div className={styles.toolbar}>
          <InputGroup
            className={styles.search}
          >
            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<FilterAltIcon />}
                  onToggle={setFilterOpen}
                >
                  {selected ? selected : formatMessage({ id: 'shared:filter' })}
                </DropdownToggle>
              }
              isOpen={filterOpen}
              dropdownItems={searcheableFields}
            />

            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<ArrowsAltVIcon />}
                  onToggle={setOrderOpen}
                >
                  {order ?
                    orders.find(jk => jk.value === order)!.title
                    : formatMessage({ id: 'shared:order' })}
                </DropdownToggle>
              }
              isOpen={orderOpen}
              dropdownItems={
                orders
                  .map(orderIn => (
                    <DropdownItem
                      onClick={_ => {
                        setOrder(orderIn.value)
                        setOrderOpen(false)
                      }}
                      key={orderIn.value}
                      value={orderIn.value}
                      component="button"
                    >
                      {orderIn.title}
                    </DropdownItem>
                  ))
              }
            />

            <SearchField
              value={query}
              dateFields={dateFields}
              statusField={formatMessage({ id: "shared:status" })}
              field={selected} onAction={setQuery}
            />

            <Button
              variant="control"
              className={styles.btn_nothing}
            >
              <SearchIcon />
            </Button>

          </InputGroup>

          {currentRole === "Administrator" && (
            <NewCompany
              company={toBeModifiedCompany}
              onClose={e => {
                setToBeModifiedCompany(undefined)
                setModifyTrigger("")
              }}
              onDone={newCom => {
                setRows([
                  newCom,
                  ...rows.filter(
                    comp => comp.id !== newCom.id
                  )
                ])
              }}
              renderTrigger={trigger => (
                <Button onClick={trigger} className={styles.btn_filled}>
                  <FormattedMessage id="company:new_company" />
                </Button>
              )}
            />
          )}

          {currentRole === "Administrator" && (
            <>
              <Button onClick={() => setShowEmailDialog(true)} className={styles.btn_filled}>
                <FormattedMessage id="company:create_by_email" />
              </Button>

              {selectedCandidatesIds.length > 0 && (
                <Button
                  onClick={i => setToDelete(selectedCandidatesIds)}
                  variant={"secondary"}
                >
                  <CloseIcon />
                  <FormattedMessage id="shared:delete" />
                </Button>
              )}
            </>
          )}

          {loading && <Spinner size={spinnerSize.md} />}
        </div>
        {rows.length === 0 && !loading && (
          <ListEmpty />
        )}
        {rows.length > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            onSort={onSort}
            onSelect={
              currentRole === "Administrator" ? onTableRowSelect : undefined
            }
            canSelectAll={
              currentRole === "Administrator" ? true : false
            }
            cells={columns}
            borders={false}
            rows={parseCompanies(rows)}
            actions={
              currentRole === "Administrator" ? admin_actions : technician_actions
            }
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}
        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{parsePagination()}</span>

        </div>
      </div>
    </div>
  );
}

export {
  CompanyListingComponent as CompanyListingPage,
}
