export interface ICompany{
address:string;
city:string;
created_at?:string;
company_debt?:string;
fax:string;
id?:string;
name:string;
number?:string;
phone:string;
post_number:string;
pricing_plan_id?:string;
discount:number;
discount_value?:string;
postal_code:string;
province:string;
standard_form_id?:string;
updated_at?:string;
activate_company?:boolean;
manager_username?:string;
manager_email?:string;
manager_firstName?:string;
manager_lastName?:string;
}


export const COMPANY_STORE_ACTIONS = {
    APPEND_COMPANIES:'COMPANY:APPEND',
    RESET_COMPANIES:'COMPANY:RESET',
    DELETE_COMPANY:'COMPANY:DELETE',
    UPDATE_COMPANY:'COMPANY:UPDATE',
    UPDATE_DEPARTMENT:'COMPANY:UDPATE_DEPARTMENT',
    DELETE_DEPARTMENT:'COMPANY:DELETE_DEPARTMENT',
    APPEND_DEPARTMENT:'COMPANY:APPEND_DEPARTMENT',
    RESET_DEPARTMENTS:'COMPANY:RESET_DEPARTMENTS',
}