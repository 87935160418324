import React, { useState, useEffect, } from 'react'
import { AppStore, IProvince } from 'stores'
import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'
import { UtilitiesService } from 'services'
import { useNotificationData } from 'hooks'


import { useIntl } from 'react-intl';


export const useProvinceData = () => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [fetchError, setFetchError] = useState<boolean>(false)
    const [provinces, setProvinces] = useState<IProvince[]>([])
    
    const trigger = new Subject<boolean>()
    const { resetProvinces, provinces$,  } = AppStore
    const {new_notification} = useNotificationData()

    useEffect(() => {
        provinces$.pipe(
            take(1)
        ).subscribe(
            storeProvinces => {
                if (storeProvinces.length === 0 && !loading && !fetchError) {
                    fetchProvinces()
                    return
                }
                setProvinces(storeProvinces)
            }
        )

        return () => trigger.next(false)
    },[])

    const fetchProvinces = () => {
        setLoading(true)
        UtilitiesService.list_provinces()
            .catch(err => {
                new_notification({
                    variant: 'error',
                    title: formatMessage({ id: 'shared:province_error' }),
                    message: formatMessage({ id: 'shared:err_get_province' }),
                })
                setLoading(false)
                setFetchError(true)
            })
            .then(async response => {
                if ([200, 201].includes(response.status)) {

                    const payload = await response.json()
                    resetProvinces(payload)
                    setProvinces(payload)
                    
                    setLoading(false)

                } else if ([404, 401, 500].includes(response.status)) {
                    const { message } = await response.json()
                    new_notification({
                        title: formatMessage({ id: 'shared:province_error' }),
                        message,
                        variant: 'error',
                    })
                    setLoading(false)
                setFetchError(true)
                } else {
                    new_notification({
                        title: formatMessage({ id: 'shared:province_error' }),
                        message: formatMessage({ id: 'shared:err_get_province' }),
                        variant: 'error',
                    })
                    setLoading(false)
                setFetchError(true)
                }
            })

    }

    return {loading, provinces}

}