import React, { useState, useEffect } from 'react';
import styles from './ReorderCategories.module.scss'
import { Modal, Button, Spinner, spinnerSize, } from '@patternfly/react-core';
import {
  DragDropContext, Droppable, Draggable
} from "react-beautiful-dnd";
import {
  FormattedMessage, useIntl
} from 'react-intl'
import { CategoriesService, QuestionService } from 'services';
import { useNotificationData } from 'hooks';

export interface ReorderCategoriesProps {
  readonly data: any[];
  readonly onClose: any;
  readonly form_id: string;
  readonly renderTrigger: any;
}

// a little function to help us with reordering the result
const reorder = (
  list: any[], startIndex, endIndex
) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "var(--pf-c-button--m-primary--BackgroundColor)" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: 'white',
  padding: grid,
  width: '100%'
});

export const ReorderCategories: React.FC<ReorderCategoriesProps> = ({
  data, renderTrigger, onClose, form_id,
  ...props
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const { new_notification } = useNotificationData()

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSave = () => {
    const categories_order = items.map(_ => _.id)
    setLoading(true)
    CategoriesService.reorder_categories(form_id, { categories_order })
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:success" }),
            variant: 'success'
          })
          setIsOpen(false)
          onClose(items)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

        }
      })
  }

  useEffect(() => {
    if (!Boolean(data)) { return }
    setItems(data)
  }, [data, isOpen])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const var_items = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(var_items)
  }

  return (
    <>
      {renderTrigger(_ => setIsOpen(true))}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: "pdf:reorder_categories" })}
          </span>
        }
        isOpen={isOpen}
        onClose={handleClose}
        //isFooterLeftAligned={false}
        className={styles.modal_root}
        width={'50%'}
        actions={[
          <Button
            key="confirm"
            variant="primary"
            onClick={handleSave}
            isDisabled={loading}
          >
            <FormattedMessage id="shared:save" />
            {loading && <Spinner size={spinnerSize.sm} />}
          </Button>,
          <Button key="cancel" variant="link" onClick={handleClose}>
            <FormattedMessage id="shared:cancel" />
          </Button>
        ]}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.container}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className={styles.dragable_item}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <span>
                          {formatMessage({ id: "shared:name" })}
                          :
                          {item.title}
                        </span><br />
                        <span>
                          {formatMessage({ id: "shared:questions_count" })}
                          :
                          {item.questions_count}
                        </span><br />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

      </Modal>
    </>
  );
}
