import BaseService from './BaseService'
import {authUrls} from './urls'

class AuthService {
    static login = (info) => BaseService.postFileRequest(authUrls.LOGINUSER, info, false);
    static recover_password = (info) => BaseService.postRequest(authUrls.RECOVER_PASSWORD, info, false);
    static change_password = (info) => BaseService.putRequest(authUrls.CHANGE_PASSWORD, info, false);
    static change_password_external = (info) => BaseService.putRequest(authUrls.CHANGE_PASSWORD_EXTERNAL, info, false);
    static currentUser = (token) => BaseService.getRequest(authUrls.CURRENT_USER(token), true);
}

export default AuthService;