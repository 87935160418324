export interface IFormsType {
    id: string;
    title: string;
    title_en: string;
    description: any;
    editable?:boolean;
    categories_count?: number;
    mark_for_deletion?: boolean;
    is_standard?: boolean;
}


export const FORM_STORE_ACTIONS = {
    APPEND_FORMS: 'FORM:APPEND',
    RESET_FORMS: 'FORM:RESET',
    DELETE_FORM: 'FORM:DELETE',
    MARK_FORM_FOR_DELETION: 'FORM:MARK_FORM_FOR_DELETION',
    UN_MARK_FORM_FOR_DELETION: 'FORM:UN_MARK_FORM_FOR_DELETION',
    UPDATE_FORM: 'FORM:UPDATE',
}