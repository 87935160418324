import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps, } from "react-router-dom";
import {
  LoginPage,
  ValidatePage,
  SignUpPage,
  RecoverPage,
  ManagerChangePasswordPage,
  RecoverPasswordPage,
} from 'pages';

interface AuthPageRouteProps extends RouteComponentProps {
  readonly url: string;
}
const AuthPageRoute: React.FC<AuthPageRouteProps> = (props) => {
  const {
    match: { url },
  } = props
  const routes = () => (
    <Switch>
      <Route
        path={`${url}/validate/:id`}
        exact
        render={renderProps => (<ValidatePage {...renderProps} />)}
      />
      <Route
        path={`${url}/recover`}
        exact
        render={renderProps => (<RecoverPage {...renderProps} />)}
      />
      <Route
        path={`${url}/recover/:token`}
        exact
        render={renderProps => (<RecoverPasswordPage {...renderProps} />)}
      />
      <Route
        path={`${url}/reset/:token`}
        exact
        render={renderProps => (<ManagerChangePasswordPage {...renderProps} />)}
      />
      <Route
        path={`${url}/sign_up`}
        exact
        render={renderProps => (<SignUpPage {...renderProps} />)}
      />
      <Route
        path={`${url}/login`}
        render={renderProps => (<LoginPage {...renderProps} />)}
      />
      <Route
        path={`${url}`}
        render={renderProps => (<LoginPage {...renderProps} />)}
      />
    </Switch>
  )

  return (
    <>
      {routes()}
    </>
  );
}
export default AuthPageRoute