import BaseService from './BaseService'
import { userUrls } from './urls'

class UserService {
    static list_users = (suffix?) => BaseService.getRequest(
        userUrls.USERS(suffix), true
    )

    static get_current_company = () => BaseService.getRequest(
        userUrls.CURRENT_COMPANY, true
    )

    static list_department_users = (suffix?) => BaseService.getRequest(
        userUrls.DEPARTMENT_USERS(suffix), true
    )

    static new_user = (data) => BaseService.postRequest(
        userUrls.NEW_USER, data, true
    )

    static update_user = (data) => BaseService.putRequest(
        userUrls.UPDATE_USER, data, true
    )

    static update_profile = (data) => BaseService.putFileRequest(
        userUrls.UPDATE_PROFILE, data, true
    )

    static delete_user = (data) => BaseService.deleteRequest(
        userUrls.DELETE_USER, data, true
    )

    static remove_user_from_department = (dep_id, data) => BaseService.deleteRequest(
        userUrls.DELETE_USER_DEPARTMENT(dep_id), data, true
    )
}

export default UserService