import React, { useState, } from 'react'
import styles from './CandidateFilter.module.scss'
import {
  Modal, Button, TextInput,
  Select, SelectOption, SelectVariant, 
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
import { useUserData, useProvinceData, useFolderStatuses } from 'hooks';

export interface CandidateFilterProps {
  readonly renderTrigger: any;
  readonly onDone: any;
}

export interface IMember {
  firstname: string,
  lastname: string,
  is_archived: boolean,
  owner_id: string,
  folder_number: string,
  folder_status: string,
  driving_license_number: string,
  nas: string,
  contact_id: string,
  province: string,

}

const validationSchema = yup.object().shape({
  firstname: yup.string(),
  lastname: yup.string(),
  is_archived: yup.string(),
  owner_id: yup.string(),
  province: yup.string(),
  folder_number: yup.string(),
  folder_status: yup.string(),
  driving_license_number: yup.string(),
  nas: yup.string(),
  contact_id: yup.string(),
})

export const CandidateFilter: React.FC<CandidateFilterProps> = ({
  renderTrigger,
  onDone,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [expandedField, setIsExapanded] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  // const { loading: loadingGroups, message: messageGroups, groups } = useQueryGroups()
  const { users } = useUserData()
  const { provinces } = useProvinceData()
  const { folderStatus } = useFolderStatuses()

  const onFilter = (data, { resetForm }) => {
    let province
    if (data.province) province = provinces.find(prov => prov.name === data.province)!.id
    let contact_id
    if (data.contact_id) contact_id = users.find(user => user.username === data.contact_id)!.id
    let folder_status
    if (data.folder_status) folder_status = folderStatus.find(status => status.name === data.folder_status)!.id
    onDone({ ...data, province, folder_status, contact_id })
    handleModalToggle()
  }

  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    handleReset,
    errors,
    handleSubmit,
  } = useFormik<IMember>({
    validationSchema,
    onSubmit: onFilter,
    initialValues: {
      firstname: '',
      lastname: '',
      is_archived: false,
      driving_license_number: '',
      province: '',
      nas: '',
      folder_number: '',
      owner_id: '',
      folder_status: '',
      contact_id: '',
    }
  })

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  };

  const handleSelectToggle = field => () => setIsExapanded(field)

  const onSelect = field => (event, selection) => {
    setIsExapanded('')
    setFieldValue(field, selection)
  }

  const onFieldChanged = field => checked => setFieldValue(field, checked)



  const clearSelection = field => () => {
    setFieldValue(field, [])
  }

  const options_contact = users.map(({ id, firstName, lastName }) => (
    <SelectOption key={id} value={firstName + ' ' + lastName} name={lastName} />
  ))

  const options_provinces = provinces.map(({ id, name }) => (
    <SelectOption key={id} value={name} name={name} />
  ))

  const options_folder_status = folderStatus.map(({ id, name }) => (
    <SelectOption key={id} value={name} name={name} />
  ))

  options_folder_status.unshift(
    <SelectOption isDisabled={true} isPlaceholder={true} key={'lksdflk'} value={formatMessage({ id: 'shared:select' })} name={'username'} />
  )


  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: "shared:candidate_filter" })}
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        //isFooterLeftAligned
        className={styles.modal_root}
      >
        <form
          className={styles.content}
          onSubmit={handleSubmit}
        >
          <label className={styles.field}>
            <span
              className={errors.firstname && touched.firstname ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.firstname && touched.firstname ? errors.firstname : "shared:firstname"}
              />
            </span>
            <TextInput
              name='firstname'
              value={values.firstname}
              type="text"
              //css={{}}
              onChange={text => setFieldValue('firstname', text)}
              onBlur={handleBlur}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.lastname && touched.lastname ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.lastname && touched.lastname ? errors.lastname : "shared:lastname"}
              />
            </span>
            <TextInput
              name={'lastname'}
              value={values.lastname}
              onChange={text => setFieldValue('lastname', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.driving_license_number && touched.driving_license_number ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.driving_license_number && touched.driving_license_number ? errors.driving_license_number : "shared:driving_license_number"}
              />
            </span>
            <TextInput
              name={'driving_license_number'}
              value={values.driving_license_number}
              onChange={text => setFieldValue('driving_license_number', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.nas && touched.nas ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.nas && touched.nas ? errors.nas : "shared:nas"}
              />
            </span>
            <TextInput
              name={'nas'}
              value={values.nas}
              onChange={text => setFieldValue('nas', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.folder_number && touched.folder_number ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.folder_number && touched.folder_number ? errors.folder_number : "shared:folder_number"}
              />
            </span>
            <TextInput
              name={'folder_number'}
              value={values.folder_number}
              onChange={text => setFieldValue('folder_number', text)}
              onBlur={handleBlur}
              type="number"
              //css={{}}
              aria-label="text input example"
            />
          </label>


          <label
            htmlFor="folder_status"
            className={styles.field}
          // style={{ gridColumn: 'span 2' }}
          >
            <span
              className={errors.folder_status && touched.folder_status ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.folder_status && touched.folder_status ? errors.folder_status : "shared:folder_status"}
              />
            </span>
            <Select
              variant={SelectVariant.single}
              onToggle={handleSelectToggle("folder_status")}
              name="folder_status"
              onSelect={onSelect("folder_status")}
              onBlur={handleBlur}
              selections={values.folder_status}
              isOpen={expandedField === "folder_status"}
              placeholderText={formatMessage({ id: "shared:folder_status" })}
              // ariaLabelledBy={'folder_status'}
              onClear={clearSelection}
            >
              {options_folder_status}
            </Select>
          </label>

          <label
            htmlFor="province"
            className={styles.field}
          >
            <span
              className={errors.province && touched.province ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.province && touched.province ? errors.province : "shared:province"}
              />
            </span>
            <Select
              variant={SelectVariant.typeahead}
              onToggle={handleSelectToggle('province')}
              name="province"
              onSelect={onSelect("province")}
              onBlur={handleBlur}
              selections={values.province}
              isOpen={expandedField === 'province'}
              placeholderText={formatMessage({ id: "shared:province" })}
              // ariaLabelledBy={'province'}
              onClear={clearSelection}
            >
              {options_provinces}
            </Select>
          </label>

          <label
            htmlFor="contact_id"
            className={styles.field}
          >
            <span
              className={errors.contact_id && touched.contact_id ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.contact_id && touched.contact_id ? errors.contact_id : "shared:contact_id"}
              />
            </span>
            <Select
              variant={SelectVariant.typeahead}
              onToggle={handleSelectToggle("contact_id")}
              name="contact_id"
              onSelect={onSelect("contact_id")}
              onBlur={handleBlur}
              selections={values.contact_id}
              isOpen={expandedField === "contact_id"}
              placeholderText={formatMessage({ id: "shared:contact" })}
              // ariaLabelledBy={'contact_id'}
              onClear={clearSelection}
            >
              {options_contact}
            </Select>
          </label>

          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={e => {
                handleReset(e)
                onDone(undefined)
                handleModalToggle()
              }}
              type="button"
            >
              <FormattedMessage id="shared:cancel_filter" />
            </button>
            <Button
              // isDisabled={Object.keys(touched).length < 1}
              type="submit"
            >
              <FormattedMessage id="shared:filter" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
