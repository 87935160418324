import React, { useEffect, useState, useCallback } from 'react';
import styles from './UsersListing.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import moment from 'moment'
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
} from '@patternfly/react-table';
import { ExpandableTable, ListEmpty } from 'components';
import {
  Button, InputGroup, TextInput, ButtonVariant,
  Modal, Spinner, spinnerSize,
} from '@patternfly/react-core';
import clsx from 'classnames'
import { SearchIcon } from '@patternfly/react-icons';
import { UserService } from 'services';
import { NewUser } from 'dialogs/new_user/NewUser';
import { DepartmentNewUser } from 'dialogs/department_new_user/DepartmentNewUser';
import { DepartmentAddUser } from 'dialogs';
import { ConfirmDialog } from 'dialogs/confirm_dialog/ConfirmDialog';


export interface UsersListingProps {
  readonly isModalOpen: boolean;
  readonly onModalClose: any;
  readonly department: any;
}

const UsersListingComponent: React.FC<UsersListingProps> = ({
  isModalOpen,
  onModalClose,
  department,
  ...props
}) => {

  const { formatMessage } = useIntl()
  const { resetAccordion } = useAccordionData()
  const [loading, setLoading] = useState<boolean>(false)
  const [columns, setColumns] = useState<any[]>([
    formatMessage({ id: "shared:userListing" }),
    formatMessage({ id: "shared:mail" }),
    formatMessage({ id: "shared:created_on" }),
    formatMessage({ id: "shared:modified_on" }),
    "",
    "",
  ])
  const { new_notification } = useNotificationData()
  const [toUpdate, setToUpdate] = useState<any>(undefined)
  const [toRemove, setToRemove] = useState<any>(undefined)
  const [rows, setRows] = useState<any[]>([])
  const [query, setQuery] = useState<string>('')
  // const [selectedUsers, setSelectedUsers] = useState<string[]>([])

  const onSelect = (event, isSelected, rowId) => {
    let newRows;
    if (rowId === -1) {
      newRows = rows.map(oneRow => {
        oneRow.selected = isSelected;
        return oneRow;
      });
    } else {
      newRows = [...rows];
      newRows[rowId].selected = isSelected;
    }
    setRows(newRows)
  }

  const onCollapse = (event, rowKey, isOpen) => {
    console.log({ rowKey })
    /**
     * Please do not use rowKey as row index for more complex tables.
     * Rather use some kind of identifier like ID passed with each row.
     */
    let newRows = [...rows]
    newRows[rowKey].isOpen = isOpen;
    setRows(newRows)
  }


  const actions = [
    // { title: formatMessage({ id: 'shared:delete' }) }
  ]

  useEffect(() => {
    resetAccordion({
      title: formatMessage({ id: 'UsersListing:user_listing' })
    })
  }, [])

  const appendUser = (reset: boolean) => (...users: any[]) => {
    let newUsers = [...rows]
    let length = rows.length
    if (reset) {
      newUsers = []
      length = 0
    }
    users.forEach((user, idx) => {
      newUsers.push(
        {
          isOpen: false,
          cells: [
            user.username,
            user.email,
            moment.utc(
              user.createTimestamp
                .replace("-05:00", "")
            )
              .utcOffset("-05:00")
              .format('YYYY-MM-DD'),
            moment.utc(
              user.updated_at
                .replace("-05:00", "")
            )
              .utcOffset("-05:00")
              .format('YYYY-MM-DD'),
            <Button
              onClick={_ => setToUpdate(user)}
              variant={ButtonVariant.link}
              className={styles.link}
            >
              <FormattedMessage id="shared:update" />
            </Button>,
            <Button
              onClick={_ => setToRemove({ user, idx })}
              variant={ButtonVariant.link}
              className={styles.link}
            >
              <FormattedMessage id="shared:remove" />
            </Button>
          ]
        },
        {
          parent: length + (2 * idx),
          fullWidth: true,
          noPadding: true,
          cells: [
            {
              noPadding: false,
              title: (
                <React.Fragment>
                  <div className={styles.grid_column}>
                    {[
                      [formatMessage({ id: 'shared:fax' }), user.fax],
                      [formatMessage({ id: "shared:post_number" }), user.post_number],
                      [formatMessage({ id: "shared:phonenumber" }), user.phone],
                    ].map(([title, message]) => (
                      <div className={styles.flex_col}>
                        <span className={styles.title}>{title}</span>
                        <span className={styles.message}>{message}</span>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )
            }
          ]
        }
      )
    })
    setRows(newUsers)

  }

  const onUpdateUser = (user: any) => {
    let newUsers = [...rows]
    const foundIdx = newUsers.findIndex(
      oldUser => {
        if (Boolean(oldUser.parent) || oldUser.parent === 0) { return false }
        if (oldUser.cells[0] === user.username) { return true }
        return false
      }
    )
    if (foundIdx === -1) { return }
    const parent = newUsers[foundIdx + 1].parent
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
    newUsers.splice(
      foundIdx,
      2,
      {
        isOpen: false,
        cells: [
          user.username,
          user.email,
          moment.utc(`${user.createTimestamp.replace("-05:00", "")}`)
            .utcOffset("-05:00")
            .format('YYYY-MM-DD'),
          moment.utc(
            user.updated_at.replace("-05:00", "")
          )
            .utcOffset("-05:00")
            .format('YYYY-MM-DD'),
          <Button
            onClick={_ => setToUpdate(user)}
            variant={ButtonVariant.link}
            className={styles.link}
          >
            <FormattedMessage id="shared:update" />
          </Button>,
          <Button
            onClick={_ => setToRemove({ foundIdx, user })}
            variant={ButtonVariant.link}
            className={styles.link}
          >
            <FormattedMessage id="shared:remove" />
          </Button>,
        ]
      },
      {
        parent,
        fullWidth: true,
        noPadding: true,
        cells: [
          {
            noPadding: false,
            title: (
              <React.Fragment>
                <div className={styles.grid_column}>
                  {[
                    [formatMessage({ id: 'shared:fax' }), user.fax],
                    [formatMessage({ id: "shared:post_number" }), user.post_number],
                    [formatMessage({ id: "shared:phonenumber" }), user.phone],
                  ].map(([title, message]) => (
                    <div className={styles.flex_col}>
                      <span className={styles.title}>{title}</span>
                      <span className={styles.message}>{message}</span>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
          }
        ]
      }
    )
    setRows(newUsers)
    console.log({ foundIdx, newUsers, user, anything: false, rows })
  }


  const addUsers = (users: any[]) => {
    let newUsers = [...rows]
    users.forEach((user, idx) => ([
      newUsers.push(
        {
          isOpen: false,
          cells: [
            user.username,
            user.email,
            moment.utc(
              user.createTimestamp
                .replace("-05:00", "")
            )
              .utcOffset("-05:00")
              .format('YYYY-MM-DD'),
            moment.utc(
              user.updated_at
                .replace("-05:00", "")
            )
              .utcOffset("-05:00")
              .format('YYYY-MM-DD'),
            <Button
              onClick={_ => setToUpdate(user)}
              variant={ButtonVariant.link}
              className={styles.link}
            >
              <FormattedMessage id="shared:update" />
            </Button>,
            <Button
              onClick={_ => setToRemove({ idx, user })}
              variant={ButtonVariant.link}
              className={styles.link}
            >
              <FormattedMessage id="shared:remove" />
            </Button>,
          ]
        },
        {
          parent: rows.length + (2 * idx),
          fullWidth: true,
          noPadding: true,
          cells: [
            {
              noPadding: false,
              title: (
                <React.Fragment>
                  <div className={styles.grid_column}>
                    {[
                      [formatMessage({ id: 'shared:fax' }), user.fax],
                      [formatMessage({ id: "shared:post_number" }), user.post_number],
                      [formatMessage({ id: "shared:phonenumber" }), user.phone],
                    ].map(([title, message]) => (
                      <div className={styles.flex_col}>
                        <span className={styles.title}>{title}</span>
                        <span className={styles.message}>{message}</span>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )
            }
          ]
        }
      )
    ]))
    setRows(newUsers)
  }


  useEffect(() => {
    if (Boolean(department)) {
      fetchDepartmentUsers(department.id)
    }
  }, [department])


  const fetchDepartmentUsers = (id) => {
    setLoading(true)
    UserService.list_department_users(id)
      .catch(_ => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: "shared:unkown_error" }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const data = await response.json()
          // setRows()
          appendUser(true)(...data)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {

          new_notification({
            message: formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const handleRemove = () => {
    setLoading(true)
    UserService.remove_user_from_department(department.id, { id_list: [toRemove.user.id] })
      .catch(_ => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: "shared:unkown_error" }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          const newRows = [...rows]
          newRows.splice(toRemove.idx, 2)
          setRows(newRows)
          setToRemove(undefined)
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const parseRows = rowArray => rowArray.filter((row, idx, arr) => {
    console.log({ row, idx, parent: row.parent, query, cells: row.cells })
    if (row.parent >= 0) {
      if (arr[idx - 1].cells[0].indexOf(query) === -1) {
        return false
      }
      return true
    } else if (row.cells[0].indexOf(query) === -1) {
      return false
    }
    return true
  })


  return (
    <Modal
      //@ts-ignore
      title={
        <span className={styles.header_title}>
          {formatMessage({ id: "dep_users:users_of_dep" })}
          {" : "}
          {department && department.name}
        </span>
      }
      isOpen={isModalOpen}
      onClose={onModalClose}
      isFooterLeftAligned={false}
      actions={[
        <Button
          variant={ButtonVariant.secondary}
          onClick={onModalClose}
        >
          <FormattedMessage id="shared:close" />
        </Button>,
      ]}
      className={styles.modal_root}
      width={'50%'}
    >
      <div
        className={clsx(
          styles.card
        )}
      >
        <span className={styles.sub_title}>
          <FormattedMessage id="shared:enterprise" />
          {department && department.company_id}
        </span>
        <header className={styles.header}>
          <InputGroup className={styles.search_field}>
            <TextInput
              placeholder={formatMessage({ id: 'shared:search' })}
              id="textInput11" type="search"
              aria-label="search input example"
              value={query}
              onChange={setQuery}
            />
            <Button
              variant="control"
              aria-label="search button for search input"
            >
              <SearchIcon />
            </Button>
          </InputGroup>
          <DepartmentNewUser
            renderTrigger={trigger => (
              <Button onClick={trigger}>
                <FormattedMessage id="shared:new_user" />
              </Button>
            )}
            department={department}
            onCreate={appendUser(false)}
            setUserToUpdate={setToUpdate}
            userToUpdate={toUpdate}
            onUpdate={onUpdateUser}
          />
          <DepartmentAddUser
            renderTrigger={trigger => (
              <Button onClick={trigger}>
                <FormattedMessage id="shared:add_user" />
              </Button>
            )}
            department={department}
            onDone={addUsers}
          />

          <ConfirmDialog
            isModalOpen={Boolean(toRemove)}
            onAction={handleRemove}
            confirmation_message={
              formatMessage({
                id: 'users:prompt_remove_user_from_department'
              })
            }
            onModalClose={() => setToRemove(undefined)}
          />

        </header>
        <div className={styles.table_container}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Spinner
                size={spinnerSize.md}
                className={styles.empty_list}
              />
            </div>
          ) : (
              <>

                {parseRows(rows).length < 1 ? (
                  <ListEmpty />
                ) : (
                    <Table
                      aria-label="Compact expandable table"
                      variant={TableVariant.compact}
                      onCollapse={onCollapse}
                      rows={parseRows(rows)}
                      actions={actions}
                      // onSelect={onSelect}
                      // canSelectAll={true}
                      cells={columns}
                      borders={false}
                    >
                      <TableHeader />
                      <TableBody />
                    </Table>
                  )}
              </>
            )}
        </div>
      </div>
    </Modal>
  )

}

export {
  UsersListingComponent as UsersListingDialog,
}
