import React, {
  useState, useEffect, useContext,
  useRef, useCallback
} from 'react';
import styles from './SelectCategoryListing.module.scss';
import {
  Button, TextInput,
  spinnerSize, Spinner, Checkbox
} from '@patternfly/react-core';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
  sortable,
  SortByDirection
} from '@patternfly/react-table';
import { RouteComponentProps } from 'react-router-dom'
import { CloseIcon } from '@patternfly/react-icons'
import {
  useAccordionData, useNotificationData, useCategoryData
} from 'hooks'
import { ConfirmDialog } from 'dialogs';
import {
  AccordionContext, ListEmpty
} from 'components'
import { ICategoryType } from 'stores';
import { CategoriesService, QuestionService } from 'services';

export interface CategoryListingProps extends RouteComponentProps {
}

const SelectCategoryListingComponent: React.FC<CategoryListingProps> = ({
  match: { url, params },
  history,
  location,

  ...props
}) => {

  //@ts-ignore
  const { questions = [] } = location.state

  if (questions.length < 1) {
    const splits = url.split('/')
    splits.pop()
    history.push(splits.join('/'))
  }

  const [
    observerTriggerState, setObserverTriggerState
  ] = useState<any>({})
  const centinelRef = useRef<any>(null)
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  let timeout;
  const [
    selectedCategoriesIds, setSelectedCandidtesIds
  ] = useState<string[]>([])
  const [loadingAddToCategories, setLoadingDelete] = useState<boolean>(false)
  const [selected, setSelected] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { setBottomComponent } = useContext(AccordionContext)
  const { formatMessage } = useIntl()
  const [columns, setColumns] = useState<any[]>([
    formatMessage({ id: 'category:category_name' }),
    {
      title: formatMessage({ id: 'category:number_of_questions' }),
      sortable: false
    },
  ])
  const [filterState, setFilterState] = useState<any>(undefined)

  const {
    categories: storeCategories, start: storeStart,
    limit: storeLimit, total: storeTotal, resetCategories,
    appendCategories,
  } = useCategoryData()


  useEffect(() => {
    clearTimeout(timeout)
    setFilterState(undefined)
    //text in search field has been emptied
    if (query === '') {
      // const companysParsed = parseCompanies(storeCategories)
      setRows(storeCategories)
      return
    }
    setSearchStart(0) //On each new search, searchStart goes to zero
    timeout = setTimeout(() => {
      searchCandidates()
    }, 300);
  }, [query])

  const { notification, new_notification } = useNotificationData()

  const [rows, setRows] = useState<ICategoryType[]>([])


  const [sortBy, setSortBy] = useState<any>({})
  const { resetAccordion } = useAccordionData()

  const onSort = (_event, index, direction) => {
    const sortedRows = rows.sort((a, b) => (a[index] < b[index] ? -1 : a[index] > b[index] ? 1 : 0));
    setSortBy({
      index,
      direction
    })
    setRows(direction === SortByDirection.asc ? sortedRows : sortedRows.reverse())
  }

  const accordionBottom = () => (
    <ul className={styles.questionList}>
      {questions.map(it => (
        <li>{it.title}</li>
      ))}
    </ul>
  )


  const handleSelectCategory = (isSelected, rowId) => {

    if (rowId === -1) {
      if (isSelected)
        setSelectedCandidtesIds(
          rows.map(_ => _.id!)
        )
      else setSelectedCandidtesIds([])
      return
    }

    const company_id = rows[rowId].id!
    const idx = selectedCategoriesIds.findIndex(id => id === company_id)
    if (idx === -1) {
      setSelectedCandidtesIds([...selectedCategoriesIds, company_id])
      return
    }
    const newList = [...selectedCategoriesIds]
    newList.splice(idx, 1)
    setSelectedCandidtesIds(newList)

  }

  useEffect(() => {
    setBottomComponent(accordionBottom)
    resetAccordion({
      title: formatMessage({
        id: "category:select_categories_prompt"
      }),
      sub_title: ""
    })
    configureInfiniteScroll()
    return () => setBottomComponent(
      <span></span>
    )
  },
    []
  )

  useEffect(() => {
    if (storeCategories && storeCategories.length > 0 && !query) {
      setRows(storeCategories)
    }
  }, [storeCategories])


  const fetchCategories = (
    paramStart: number,
    paramSearching: boolean,
    filter?: any,
    absolute?: any,
  ) => {
    setLoading(true)
    let suffix = `?start=${paramStart}&limit=${30}`
    if (absolute !== null && Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (Boolean((filter || filterState)[field])) {
          suffix = `${suffix}&${field}=${(filter || filterState)[field]}`
        }
      })
    } else if (Boolean(paramSearching)) {
      suffix = `${suffix}&title=${query}`
    }
    CategoriesService.list_categories(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { results, start, total, limit } = await response.json()
          //if we are not searching nore filtering
          if (!paramSearching && (absolute !== null && !Boolean(filter || filterState))) { //Not searching
            if (start < 1) {
              resetCategories({ data: results, start, total, limit, })
            }
            else {
              appendCategories({ data: results, start, limit, total })
            }
          } else { //Searching 
            setRows([...(start > 0 ? rows : []), ...results])
            setSearchTotal(total)
            setSearchStart(start)
          }
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const addQuestionsToCategories = (
    ...category_ids: string[]
  ) => {
    setLoadingDelete(true)
    QuestionService.addQuestionsToCategories(
      {
        category_ids,
        question_ids: questions.map(_ => _.id)
      }
    )
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          setSelected([])
          setLoadingDelete(false)
          history.goBack()
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)
          setSelectedCandidtesIds([])
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingDelete(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const parseCategories = (data: ICategoryType[]) => {
    return data.map(datum => ({
      cells: [
        datum.title,
        datum.questions_counts,
      ],
      selected: selectedCategoriesIds.includes(datum.id!)
    }))
  }

  const handleSelect = (_, isSelected, rowId) => {
    handleSelectCategory(isSelected, rowId)
    // isChecked={selectedCategoriesIds.includes(datum.id!)}

  }

  const onNextPage = () => {
    if (query !== '') {
      if (searchTotal !== 0 && rows.length >= searchTotal) { return }
      fetchCategories(searchTotal === 0 ? 0 : searchStart + storeLimit, true)
      return
    }
    if (storeTotal !== 0 && storeCategories.length >= storeTotal) { return }
    fetchCategories(storeTotal === 0 ? 0 : storeStart + storeLimit, false)
    // setStart(storeStart + storeLimit)
  }

  const searchCandidates = (filter?) => {
    fetchCategories(0, filter ? false : true, filter)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeCategories, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    // cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }


  const parsePagination = () => {
    const count = rows.length
    let total = storeTotal
    if (Boolean(query) || Boolean(filterState)) {
      total = searchTotal
    }
    return `${count} of ${total}`
  }


  return (
    <div className={styles.wrapper} >
      <ConfirmDialog
        title_message='shared:add_to_categories'
        onAction={_ => addQuestionsToCategories(...selected)}
        isModalOpen={selected.length > 0}
        actionText="shared:proceed"
        confirmation_message={
          `${formatMessage({ id: "shared:add_to_categories_prompt" })}`
        }
        loading={loadingAddToCategories}
        onModalClose={_ => setSelected([])}
      >
        <div className={styles.modal}>
          <ul className={styles.questions}>
            <li className={styles.section_title}>
              <FormattedMessage id="shared:questions" />
            </li>
            {
              (questions || []).map(({ title }) => (
                <li>{title}</li>
              ))
            }
          </ul>
          <ul className={styles.categories}>
            <li className={styles.section_title}>
              <FormattedMessage id="shared:categories" />
            </li>
            {
              rows
                .filter(({ id }) => selectedCategoriesIds.includes(id!))
                .map(({ title }) => (
                  <li>{title}</li>
                ))
            }
          </ul>
        </div>
      </ConfirmDialog>
      <div className={styles.content}>
        <div className={styles.toolbar}>
          <TextInput
            placeholder={formatMessage({ id: 'shared:search' })}
            className={styles.search}
            type="search"
            onChange={setQuery}
            aria-label="search text input"
          />
          {selectedCategoriesIds.length > 0 && (
            <Button
              onClick={i => setSelected(selectedCategoriesIds)}
              variant={"secondary"}
            >
              <FormattedMessage id="shared:proceed" />
            </Button>
          )}
          <span>{parsePagination()}</span>
        </div>
        {rows.length === 0 && !loading && (
          <ListEmpty />
        )}

        {rows.length > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            onSort={onSort}
            canSelectAll={false}
            onSelect={handleSelect}
            cells={columns}
            borders={false}
            rows={parseCategories(rows)}
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}
        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{parsePagination()}</span>
        </div>
      </div>
    </div>
  );
}

export {
  SelectCategoryListingComponent as SelectCategoryListingPage,
}
