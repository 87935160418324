import { useEffect, useState } from 'react'
import { INotification } from 'stores/app.store'
import { Subject } from 'rxjs'
import { takeUntil,  } from 'rxjs/operators'
import { NotificationStore } from 'stores'

export const useNotificationData = () => {
    const [notification, setNotifications] = useState<INotification>()
    const { new_notification } = NotificationStore
    const trigger = new Subject<boolean>()

    useEffect(() => {


        NotificationStore.notification$.pipe(
            takeUntil(trigger.asObservable()),
            // distinctUntilChanged((x, y) => {
            //     console.log({ x, y })
            //     return x!.message !== y!.message
            // })
        )
            .subscribe(
                store_notifications => {
                    setNotifications(store_notifications)
                    // new_notification(undefined)
                }
            )
        return () => trigger.next(false)
    }, [])

    return { notification, new_notification }
}