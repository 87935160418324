import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { ActivatedCompanyStore, IActivatedCompany } from 'stores'
import { takeUntil } from 'rxjs/operators'



export function useActivatedCompanyData() {
    const {
        appendCompanies, resetCompanies, deleteDepartments,
        deleteCompany, updateCompany, companies$,
        appendDepartments, resetDepartments, updateDepartment
    } = ActivatedCompanyStore

    const [start, setStart] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [limit, setLimit] = useState<number>(30)
    const [companies, setCompany] = useState<IActivatedCompany[]>([
    ])

    /**Setup accordion */
    useEffect(() => {
        const trigger = new Subject<boolean>()
        companies$
            .pipe(
                takeUntil(trigger.asObservable()),
            )
            .subscribe(
                ({
                    start,
                    limit,
                    total,
                    data,
                }) => {
                    setStart(start!)
                    setTotal(total!)
                    setLimit(limit!)
                    setCompany(data)
                }
            )

        //onUnmount
        return () => trigger.next(false)
    }, [])

    return {
        appendCompanies, resetCompanies,
        deleteCompany, updateCompany,
        companies, start, limit, total,
        deleteDepartments, appendDepartments,
        resetDepartments, updateDepartment,
    }
}