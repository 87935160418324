import React from 'react';
import ReactDOM from 'react-dom';
/**
 * Very fundamental you import patternfly styles in
 * index.tsx file here as some styles risk being processed
 * by react if imported else where
*/
// import '';
import './index.scss';
// import 'styles/vars.scss'
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
