import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './DemandDraftListing.module.scss';
import {
  Button, TextInput,
  DropdownItem, Spinner, spinnerSize,
  InputGroup, Dropdown, DropdownToggle,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Table, TableHeader,
  TableBody, TableVariant,
} from '@patternfly/react-table';
import { Link, RouteComponentProps } from 'react-router-dom'
import {
  useAccordionData, useNotificationData, useDemandData,
  useUserInfoData
} from 'hooks'
import { ConfirmDialog, StripePaymentDialog } from 'dialogs';
import { DemandDetails } from 'dialogs'
import { DemandService } from 'services';
import { IDemand } from 'stores';
import moment from 'moment'

import clsx from 'classnames'
import { ReactComponent as StatusArchivedIcon } from 'assets/imgs/status_archived.svg'
import { ReactComponent as StatusLockedIcon } from 'assets/imgs/status_locked.svg'
import { ReactComponent as StatusOpenIcon } from 'assets/imgs/status_open.svg'
import { ReactComponent as StatusLoadingIcon } from 'assets/imgs/status_loading.svg'
import { ILoginInfo } from 'stores/auth.store';
import { ListEmpty, SearchField } from 'components';
import { useUserRoleData } from 'hooks';
import { FilterAltIcon, SearchIcon, ArrowsAltVIcon, CloseIcon } from '@patternfly/react-icons';
import { TABLE_COLUMN } from 'constants/table_columns';

export interface DemandListingProps extends RouteComponentProps {
}


const DemandDraftListingComponent: React.FC<DemandListingProps> = ({
  match: { url },
  history,
  ...props
}) => {
  let {
    resetDemands, appendDemands,
    start: storeStart, limit: storeLimit, total: storeTotal,
    demands: storeDemands,
  } = useDemandData()
  const [observerTriggerState, setObserverTriggerState] = useState<any>({})
  const { formatMessage } = useIntl()
  const { userInfo } = useUserInfoData()
  const [
    selectedRequests, setSelectedRequests
  ] = useState<string[]>([])
  const columns: TABLE_COLUMN[] = [
    {
      title: formatMessage({ id: 'demand:requester' }),
      sortable: false,
      field: 'applicant',
      type: 'search',
    },
    {
      title: formatMessage({ id: 'candidate:candidates' }),
      field: 'candidate',
      type: 'search',
    },
    {
      title: formatMessage({ id: 'demand:job_requested' }),
      field: 'employment',
      type: 'search',
    },
    {
      title: formatMessage({ id: 'shared:date' }),
      field: 'date',
      type: 'date',
    },
    {
      title: formatMessage({ id: 'shared:updated_at' }),
      field: 'update_at',
      type: 'date',
    },
    {
      title: formatMessage({ id: 'shared:status' }),
      field: 'status',
      type: 'select',
    },
  ]

  const dateFields = columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'date'
  )
    .map(({ title }) => title)
  const [toDelete, setToDelete] = useState<string[]>([])
  const [
    loadingDelete, setLoadingDelete
  ] = useState<boolean>(false)
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [orderOpen, setOrderOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>('')
  const [order, setOrder] = useState<string>('')
  const { currentRole } = useUserRoleData()

  const [
    loadingSendEmail, setLoadingSendEmail
  ] = useState<boolean>(false)

  const [
    showEmailDialog, setShowEmailDialog
  ] = useState<boolean>(false)

  const [email, setEmail] = useState<string>('')

  const [
    toBePaidRequest, setToBePaidRequest
  ] = useState<string>('')

  const orders = [
    {
      title: formatMessage({ id: "shared:asc" }),
      value: 'asc',
    },
    {
      title: formatMessage({ id: "shared:desc" }),
      value: 'desc',
    },
  ]

  const actions = [
    {
      title: <span className={styles.link}>
        <span>
          <FormattedMessage id="shared:proceed_with_payment" />
        </span>
      </span>,
      onClick: (_, rowIdx) => {
        setToBePaidRequest(rows[rowIdx].id)
      }
    },
    {
      isSeparator: true
    },
  ]

  const { new_notification } = useNotificationData()

  const [rows, setRows] = useState<any[]>([])
  const [sortBy, setSortBy] = useState<any>({})
  const { resetAccordion } = useAccordionData()
  const [loading, setLoading] = useState<boolean>(false)
  const centinelRef = useRef<any>(null)
  const [query, setQuery] = useState<string | number>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  const [filterState, setFilterState] = useState<any>(undefined)

  const translateStatus = (demand_status: number) => {
    let returnVal = 'locked'

    if (demand_status === 5) returnVal = 'archived_and_closed'
    else if (demand_status === 4) returnVal = 'archived'
    else if (demand_status === 0) returnVal = 'open'
    else if (demand_status === 3) returnVal = 'locked'
    else if (demand_status === 1) returnVal = 'loading'
    else if (demand_status === 2) returnVal = 'fax_received'


    return returnVal
  }

  const parseStatus = (status) => {
    let StatusIcon = StatusLockedIcon
    switch (status) {
      case 'archived':
        StatusIcon = StatusArchivedIcon
        break;
      case 'open':
        StatusIcon = StatusOpenIcon
        break;
      case 'locked':
        StatusIcon = StatusLockedIcon
        break;
      case 'archived_and_closed':
        StatusIcon = StatusLockedIcon
        break;
      case 'loading':
        StatusIcon = StatusLoadingIcon
        break;
    }
    return (
      <div>
        <div
          className={clsx(
            styles.status_box,
            styles[status],
          )}
        >
          <StatusIcon /> &nbsp;  &nbsp;
        <span>{formatMessage({ id: `shared:${status}` })}</span>
        </div>
      </div>
    )
  }

  /**track change in limit collected. */
  const [_, setPerPage] = useState<number>(storeLimit)
  useEffect(() => setPerPage(storeLimit), [storeLimit])

  let timeout;

  useEffect(() => {
    resetAccordion({ title: formatMessage({ id: "demands:list_of_draft_demands" }) })
    configureInfiniteScroll()
  }, [])

  useEffect(() => {
    if (storeDemands && storeDemands.length > 0 && !query) {
      setRows(storeDemands)
    }
  }, [storeDemands])

  const fetchDemands = (
    paramStart: number,
    filter?: any,
  ) => {
    setLoading(true)
    let suffix = `?start=${paramStart}&limit=${30}`
    if (!Boolean(userInfo) || Object.keys(userInfo!).length < 1) { return }

    if ((userInfo as ILoginInfo).is_contact) {
      if (["contact"].includes(currentRole.toLowerCase())) {
        suffix = `${suffix}&applicant_id=${(userInfo as ILoginInfo).id}`
      } else if (['company_administrator'].includes(currentRole.toLowerCase())) {
        suffix = `${suffix}&company_id=${(userInfo as ILoginInfo).company_id}`
      }

    }

    if (Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (
          Boolean((filter || filterState)[field])
          || (filter || filterState)[field] === 0
        ) {
          suffix = `${suffix}&${field == 'undefined' ? "keyword" : field}=${(filter || filterState)[field]}`
        }
      })
    }

    DemandService.list_drafted_demands(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          let { results, start, total, limit } = await response.json()
          results = results.map(res => ({
            ...res,
            update_at: moment.utc(
              res.date.replace("-05:00", "")
            )
              .utcOffset("-05:00")
              .format("MM-DD-YYYY"),
            date: moment.utc(
              res.update_at.replace("-05:00", "")
            )
              .utcOffset("-05:00")
              .format("MM-DD-YYYY"),
          }))
          setRows([...(start > 0 ? rows : []), ...results])
          setSearchTotal(total)
          setSearchStart(start)
          // }
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  const parseDemands = (data: IDemand[]) => {
    return data.map(datum => ({
      cells: [
        `${datum.applicant.firstName + ' ' + datum.applicant.lastName}`,
        `${datum.candidate.name ? datum.candidate.name : datum.candidate.firstname + ' ' + datum.candidate.lastname}`,
        datum.employment,
        datum.update_at,
        datum.date,
        <span>
          {parseStatus(translateStatus(datum.status))}
        </span>,
      ],
      selected: selectedRequests.includes(datum.id),
    }))
  }

  const onNextPage = () => {
    if (rows.length && searchTotal && rows.length >= searchTotal) { return }
    fetchDemands(searchTotal === 0 ? 0 : searchStart + storeLimit, false)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      /*
       * The following call will trigger the useEffect that 
       * depends on observerTriggerState variable.
       */
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeDemands, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  const parsePagination = () => {
    const count = rows.length
    let total = searchTotal
    return `${count} of ${total}`
  }

  const sendDemandCreationEmail = (email: string) => {
    setLoadingSendEmail(true)
    DemandService.send_demand_creation_email({ email })
      .catch(_ => {
        setLoadingSendEmail(false)
        new_notification({
          message: formatMessage({ id: "shared:unkown_error" }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoadingSendEmail(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          setShowEmailDialog(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const deleteDemands = (...demand_ids) => {
    setLoadingDelete(true)
    DemandService.delete_drafts({ demand_ids })
      .catch(_ => {
        setLoadingDelete(false)
        new_notification({
          message: formatMessage({ id: "shared:unkown_error" }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoadingDelete(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          setShowEmailDialog(false)
          setRows(rows.filter(_ => !toDelete.includes(_.id)))
          setToDelete([])
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const onSelect = (title) => {
    setSelected(title)
    setFilterOpen(false)
  };

  useEffect(() => {
    const column = columns.find(({ title }) => title === selected)
    // if (!Boolean(column)) return
    let field = (column || { field: undefined })!.field

    setFilterState(
      { order, order_field: field, [field!]: query }
    )
    fetchDemands(
      0,
      { order, order_field: field, [field!]: query }
    )
  }, [selected, query, order,])

  const searcheableFields = columns
    .filter(({ searcheable = true }) => searcheable)
    .map(({ title }) => title)
    .map(title => (
      <DropdownItem
        onClick={_ => {
          onSelect(title)
          setQuery('')
        }}
        key={title} value={title}
        component="button"
      >
        {title}
      </DropdownItem>
    ))

  const updateDemand = demand => {
    setRows(rows => rows.map((row) => demand.id === row.id ? demand : row))
  }

  const onSelectTableRow = (_, isSelected: boolean, idx: number) => {
    if (idx === -1) {
      setSelectedRequests(isSelected ? rows.map(_ => _.id) : [])
      return
    }
    const requestId = rows[idx].id
    if (isSelected) {
      setSelectedRequests([...selectedRequests, requestId])
    } else {
      setSelectedRequests(selectedRequests.filter(_ => _ !== requestId))
    }
  }

  const onStripeAction = (token) => {
    setLoading(true)
    const datum = {
      payment_token: token,
    }
    DemandService.switchDraftedDemand(toBePaidRequest, datum)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const {
            payload, message,
          } = await response.json()
          setRows(rows.filter(_ => _.id !== toBePaidRequest))
          setToBePaidRequest("")
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })

  }


  return (
    <div className={styles.wrapper} >

      <StripePaymentDialog
        onSaveAsDraft={() => setToBePaidRequest("")}
        onAction={onStripeAction}
        isOpen={toBePaidRequest !== ""}
        loading={loading}
        onModalClose={() => setToBePaidRequest("")}
      />

      <ConfirmDialog
        onAction={_ => sendDemandCreationEmail(email)}
        actionText={formatMessage({ id: "shared:send_mail" })}
        title_message={
          formatMessage({
            id: "shared:send_email_to_company_to_create_demand"
          })}
        isModalOpen={showEmailDialog}
        loading={loadingSendEmail}
        onModalClose={_ => setShowEmailDialog(false)}
      >
        <form
          action=""
          onSubmit={
            e => {
              e.preventDefault()
              sendDemandCreationEmail(email)
            }
          }
        >
          <label htmlFor="email">
            <span>
              <FormattedMessage id="shared:requester_email" />
            </span>
            <TextInput id="email" value={email} onChange={setEmail} />
          </label>
        </form>
      </ConfirmDialog>

      <ConfirmDialog
        confirmation_message="shared:do_you_want_to_continue"
        onAction={_ => deleteDemands(...toDelete)}
        isModalOpen={toDelete.length > 0}
        loading={loadingDelete}
        onModalClose={_ => setToDelete([])}
      />

      <div className={styles.content}>
        <div className={styles.toolbar}>

          <InputGroup
            className={styles.search}
          >

            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<FilterAltIcon />}
                  onToggle={setFilterOpen}
                >
                  {selected ? selected : formatMessage({ id: 'shared:filter' })}
                </DropdownToggle>
              }
              isOpen={filterOpen}
              dropdownItems={searcheableFields}
            />

            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<ArrowsAltVIcon />}
                  onToggle={setOrderOpen}
                >
                  {order ?
                    orders.find(jk => jk.value === order)!.title
                    : formatMessage({ id: 'shared:order' })}
                </DropdownToggle>
              }
              isOpen={orderOpen}
              dropdownItems={
                orders
                  .map(orderIn => (
                    <DropdownItem
                      onClick={_ => {
                        setOrder(orderIn.value)
                        setOrderOpen(false)
                      }}
                      key={orderIn.value}
                      value={orderIn.value}
                      component="button"
                    >
                      {orderIn.title}
                    </DropdownItem>
                  ))
              }
            />

            <SearchField
              value={query}
              dateFields={dateFields}
              statusField={formatMessage({ id: "shared:status" })}
              field={selected} onAction={setQuery}
            />

            <Button
              variant="control"
              className={styles.btn_nothing}
            >
              <SearchIcon />
            </Button>

          </InputGroup>

          {selectedRequests.length > 0 && (
            <Button
              onClick={i => {
                setToDelete(selectedRequests)
                setSelectedRequests([])
              }}
              variant={"secondary"}
            >
              <CloseIcon />
              <FormattedMessage id="shared:delete" />
            </Button>
          )}
        </div>

        {rows.length === 0 && !loading && (
          <ListEmpty
            title={"shared:no_drafted_demands"}
            message={" "}
          />
        )}

        {rows.length > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            cells={columns}
            onSelect={onSelectTableRow}
            canSelectAll={true}
            borders={false}
            rows={parseDemands(rows)}
            actions={actions}
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}

        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{parsePagination()}</span>
        </div>

      </div>

    </div>
  );
}


export {
  DemandDraftListingComponent as DemandDraftListingPage,
}
