import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { ICandidate, CandidateStore } from 'stores'
import { takeUntil } from 'rxjs/operators'



export function useCandidatesData() {
    const {
        appendCandidate, appendCandidates,
        resetCandidates, deleteCandidate,
        updateCandidate, candidates$
    } = CandidateStore

    const [start, setStart] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [limit, setLimit] = useState<number>(30)
    const [candidates, setCandidates] = useState<ICandidate[]>([])

    /**Setup accordion */
    useEffect(() => {
        const trigger = new Subject<boolean>()

        candidates$
            .pipe(
                takeUntil(trigger.asObservable())
            )
            .subscribe(
                ({
                    start,
                    limit,
                    total,
                    data,
                }) => {
                    setStart(start!)
                    setTotal(total!)
                    setLimit(limit!)
                    setCandidates(data)
                }
            )

        //onUnmount
        return () => trigger.next(false)
    }, [])

    return {
        appendCandidates, resetCandidates,
        deleteCandidate, updateCandidate,
        appendCandidate,
        candidates, start, limit, total,
    }
}