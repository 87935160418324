import React, { useState, useEffect } from 'react';
import styles from './EnterpriseDashboard.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import clsx from 'classnames'
import { ReactComponent as IconArchived } from 'assets/imgs/icon_archived.svg'
import { ReactComponent as IconLocked } from 'assets/imgs/icon_locked.svg'
import { ReactComponent as IconPhone } from 'assets/imgs/icon_phone.svg'
import { ReactComponent as IconOpen } from 'assets/imgs/icon_open.svg'
import { ReactComponent as IconInProgress } from 'assets/imgs/icon_in_progress.svg'
import {
  ChartPie, Chart, ChartAxis, ChartGroup,
  ChartArea, ChartThemeColor, ChartLegendTooltip,
  createContainer
} from '@patternfly/react-charts';
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
} from '@patternfly/react-table';
import { Button, TextInput, Spinner, spinnerSize } from '@patternfly/react-core';
import { EnterpriseDepartmentsPage } from 'pages/enterprise_departments/EnterpriseDepartments.page';
import { DashboardService } from 'services';

export interface EnterpriseDashboardProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const EnterpriseDashboardComponent: React.FC<EnterpriseDashboardProps> = props => {

  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  const [statistics, setStatistics] = useState<any>({
    total_demands: undefined, month_demands: undefined,
    year_demands: undefined,
    candidates: undefined, company_count: undefined,
    users_counts: undefined,
    departments_count: undefined,
  })
  const [division, setDivision] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [illustrations, setIllustrations] = useState<any[]>([])
  const [pies, setPies] = useState<any[]>([])
  const [requestVsTime, setRequestVsTime] = useState<any[]>([])
  const [tickValues, setTickValues] = useState<any[]>([])

  React.useEffect(() => {
    fetchStatistics()
    resetAccordion({ title: formatMessage({ id: 'routes.dashboard' }) })
  }, [])

  useEffect(() => {
    if (
      Object.keys(statistics)
        .map(key => statistics[key])
        .some(i => !Boolean(i) && i !== 0)
    ) { return }
    const data: any[] = []
    let demandsInfo = statistics.total_demands

    data.push({
      title: formatMessage({ id: 'dashboard:total_demands' }),
      value: demandsInfo.demands_count,
      graphs: [],
      labels: [
        {
          label: formatMessage({ id: 'shared:archived_and_closed' }),
          text: demandsInfo.archived_and_closed_demands_count,
          Icon: IconLocked,
        },
        {
          label: formatMessage({ id: 'shared:archived' }),
          text: demandsInfo.archived_demands_count, Icon: IconArchived,
        },
        {
          label: formatMessage({ id: 'shared:closed' }),
          text: demandsInfo.closed_demands_count, Icon: IconLocked,
        },
        {
          label: formatMessage({ id: 'shared:fax_sent' }),
          text: demandsInfo.fax_send_demands_count, Icon: IconPhone,
        },
        {
          label: formatMessage({ id: 'shared:open' }),
          text: demandsInfo.open_demands_count, Icon: IconOpen,
        },
        {
          label: formatMessage({ id: 'shared:in_progress' }),
          text: demandsInfo.in_progress_demands_count, Icon: IconInProgress,
        },
      ]
    })

    let monthsDemandinfo = statistics.month_demands
    data.push({
      title: formatMessage({ id: 'dashboard:current_months_demands' }),
      value: monthsDemandinfo.demands_count,
      graphs: [],
      labels: [
        {
          label: formatMessage({ id: 'shared:archived_and_closed' }),
          text: monthsDemandinfo.archived_and_closed_demands_count,
          Icon: IconLocked,
        },
        {
          label: formatMessage({ id: 'shared:archived' }),
          text: monthsDemandinfo.archived_demands_count, Icon: IconArchived,
        },
        {
          label: formatMessage({ id: 'shared:closed' }),
          text: monthsDemandinfo.closed_demands_count, Icon: IconLocked,
        },
        {
          label: formatMessage({ id: 'shared:fax_sent' }),
          text: monthsDemandinfo.fax_send_demands_count, Icon: IconPhone,
        },
        {
          label: formatMessage({ id: 'shared:open' }),
          text: monthsDemandinfo.open_demands_count, Icon: IconOpen,
        },
        {
          label: formatMessage({ id: 'shared:in_progress' }),
          text: monthsDemandinfo.in_progress_demands_count, Icon: IconInProgress,
        },
      ]
    })

    setPies(data)

    setIllustrations([
      {
        value: statistics.departments_count,
        name: formatMessage({ id: "shared:departments" }),
      },
      {
        value: statistics.users_counts,
        name: formatMessage({ id: "shared:users" }),
      },
      {
        x: formatMessage({ id: "shared:accountants" }),
        y: statistics.accountant_count
      },
      {
        value: statistics.candidates,
        name: formatMessage({ id: "shared:candidates" }),
      },
    ])

    let lineChartInfo = statistics.year_demands
    let highestCount = Object.keys(lineChartInfo)
      .reduce((acc, key) => +acc < +lineChartInfo[key] ? lineChartInfo[key] : acc, 0)
    let paramDivision = highestCount > 1000 ? 100 : 5
    setDivision(paramDivision)
    lineChartInfo = Object.keys(lineChartInfo)
      .map(key => ({
        x: formatMessage({ id: key }),
        y: lineChartInfo[key] / paramDivision
      }))


    setRequestVsTime(lineChartInfo)
    let length = lineChartInfo.reduce(
      (acc, { y }) => acc < +y ? +y : acc, 0
    )

    console.log({ lineChartInfo, length })
    let tickvals = new Array(
      Math.ceil(length)
    ).fill(0).map((_, idx) => idx + 1)
    tickvals.push(...[1, 2, 3, 4].map((_, idx) => idx + tickvals.length))

    setTickValues(tickvals)

  }, [statistics])

  const CursorVoronoiContainer = createContainer("cursor", "voronoi");
  const legendData = [
    { childName: 'cats', name: 'Cats' },
    {
      childName: 'dogs',
      name: 'Dogs',
      symbol: { type: 'dash' }
    },
    { childName: 'birds', name: 'Birds' },
    { childName: 'mice', name: 'Mice' }
  ]
  const { new_notification } = useNotificationData()

  const fetchStatistics = () => {
    setLoading(true)
    DashboardService.fetch_manager_stats()
      .catch(_ => {
        setLoading(false)
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const stats = await response.json()
          setStatistics(stats)
          setLoading(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  return (
    <div className={styles.container} >
      {illustrations.map(({ name, value }) => (
        <div
          className={clsx(
            styles.card,
            styles.card__illustration,
          )}
        >
          <span className={styles.card__title}>{name}</span>
          <span className={styles.card__main_text}>{value}</span>
        </div>
      ))}
      <div
        className={clsx(
          styles.card,
          styles.card__fullwidth,
          styles.card__graph,
        )}
        style={{
          paddingBottom: 0,
        }}
      >
        {pies.length < 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Spinner
              size={spinnerSize.lg}
              style={{
                margin: '0 auto',
                textAlign: 'center',
                gridColumn: '1/-1',
              }}
            />
          </div>
        )}

        {pies.map((pie, idxxx) => (
          <>
            <div
              className={clsx(
                styles.pie,
                styles.section,
                styles.vertical_seperator
              )}
            >
              <span className={styles.card__title}>{pie.title}</span>
              <span className={styles.card__main_text}>{pie.value}</span>
              <div className={styles.pie_graph}>
                {Boolean(statistics.month_demands) ? (
                  <ChartPie
                    constrainToVisibleArea={true}
                    data={pie.labels.map(item => (
                      { x: item.label, y: item.text }
                    ))}
                    legendOrientation="vertical"
                    legendPosition="right"
                    legendData={pie.labels.map(item => (
                      { name: `${item.label} : ${item.text}` }
                    ))}
                    height={190}
                    labels={({ datum }) => `${datum.x}: ${datum.y}`}
                    padding={{
                      bottom: 20,
                      left: 20,
                      right: 240, // Adjusted to accommodate legend
                      top: 20
                    }}
                    themeColor={
                      idxxx === 1 ?
                        ChartThemeColor.orange
                        : ChartThemeColor.cyan
                    }
                    width={350}
                  />
                ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner
                        size={spinnerSize.lg}
                        style={{
                          margin: '0 auto',
                          textAlign: 'center',
                        }}
                      />
                    </div>
                  )}

              </div>
              {false && <div className={styles.pie_labels}>
                {pie.labels.map(({ Icon, text }) => (
                  <span
                    style={{
                      display: 'flex',
                      alignContent: 'flex-end',
                      alignItems: 'center'
                    }}
                  >
                    <Icon />{text}
                  </span>
                ))}
              </div>}
            </div>
          </>
        ))}
      </div>
      <div
        className={clsx(
          styles.card,
          styles.card__fullwidth,
          styles.card__graph,
        )}
        style={{
          paddingBottom: 0,
        }}
      >

        <div
          className={clsx(
            styles.line,
            styles.section,
          )}
        >
          <span className={styles.card__title}>
            {formatMessage({ id: "dashboard:stats" })}
          </span>
          {division > 1 && (
            <span className={styles.card__title}>
              {formatMessage({ id: "dashboard:division" })} {division}
            </span>
          )}
          <div className={styles.line_graph}>
            {Boolean(statistics.month_demands) ? (
              <Chart
                ariaDesc="Average number of pets"
                ariaTitle="Line chart example"
                containerComponent={
                  <CursorVoronoiContainer
                    cursorDimension="x"
                    labels={({ datum: { y, x } }) => `${y} : ${x}`}
                    labelComponent={
                      <ChartLegendTooltip
                        legendData={legendData}
                        title={({ x, y }) => `${x} : ${y}`}
                      />
                    }
                    mouseFollowTooltips
                    voronoiDimension="x"
                    voronoiPadding={50}
                  />
                }
                height={tickValues.length * 16}
                maxDomain={{ y: tickValues.length }}
                minDomain={{ y: 0 }}
                padding={{
                  bottom: 35, // Adjusted to accommodate legend
                  left: 30,
                  right: 50,
                  top: 15
                }}
                themeColor={ChartThemeColor.purple}
              >
                <ChartAxis
                  tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                />
                <ChartAxis
                  dependentAxis
                  showGrid
                  tickValues={tickValues}
                />
                <ChartGroup>
                  <ChartArea
                    data={requestVsTime}
                    interpolation="monotoneX"
                    name="month"
                  />
                </ChartGroup>
              </Chart>
            ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Spinner
                    size={spinnerSize.lg}
                    style={{
                      margin: '0 auto',
                      textAlign: 'center',
                    }}
                  />
                </div>
              )}

          </div>
        </div>
      </div>
    </div>
  );
}

export {
  EnterpriseDashboardComponent as EnterpriseDashboardPage,
}
