import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { FormStore, IFormsType } from 'stores'
import { takeUntil } from 'rxjs/operators'



export function useFormData() {
    const {
        appendForms, resetForms, deleteForm, updateForm,
        forms$,
    } = FormStore

    const [start, setStart] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [limit, setLimit] = useState<number>(30)
    const [forms, setForms] = useState<IFormsType[]>([])

    /**Setup accordion */
    useEffect(() => {
        const trigger = new Subject<boolean>()

        forms$
            .pipe(
                takeUntil(trigger.asObservable())
            )
            .subscribe(
                ({
                    start = 0,
                    limit = 30,
                    total = 0,
                    data,
                }) => {
                    setStart(start)
                    setTotal(total)
                    setLimit(limit)
                    setForms(data)
                }
            )

        //onUnmount
        return () => trigger.next(false)
    }, [])

    return {
        appendForms, resetForms,
        deleteForm, updateForm,
        forms, start, limit, total,

    }
}