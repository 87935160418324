import React,
{ useState, useEffect }
  from 'react';
import styles from './DemandDetails.module.scss'
import {
  Modal, Button, Spinner, spinnerSize,
  SelectOption, Select, SelectVariant,
} from '@patternfly/react-core';
import {
  FormattedMessage, useIntl
} from 'react-intl'
import { IDemand } from 'stores';
import {
  useFolderStatuses, useNotificationData,
  useUserRoleData,
  useLanguageData,
} from 'hooks';
import { DemandService } from 'services';

export interface DemandDetailsProps {
  isOpen: boolean,
  onClose: any,
  detail: IDemand,
  updateDemand: any,
  deleteDemand: any,
}

export const DemandDetails: React.FC<DemandDetailsProps> = ({
  isOpen,
  detail = { candidate: {} },
  updateDemand,
  deleteDemand,
  onClose,
  ...props
}) => {

  const { language } = useLanguageData()
  const { formatMessage } = useIntl()
  const { folderStatus } = useFolderStatuses()
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const { new_notification } = useNotificationData()
  // const { updateDemand } = useDemandData()
  const { currentRole } = useUserRoleData()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)


  useEffect(() => {
    console.log({ detail, folderStatus })
    if (!isOpen) { return }
    //@ts-ignore
    const status = folderStatus.find(stat => stat.id === detail.status)!.name
    setStatus(status)
  }, [detail])

  const onChangeStatus = (_, status_name) => {
    console.log({ status_name })
    setLoading(true)
    setIsExpanded(false)
    const status = folderStatus.find(stat => stat.name === status_name)!.id
    DemandService.update_demand(detail.id, { status })
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          let { data, message } = await response.json()
          new_notification({
            message: message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          setStatus(status_name)
          if ([3, 5].includes(status)) {
            deleteDemand({
              ...detail as IDemand,
              status,
            })
          } else {
            updateDemand({
              ...detail as IDemand,
              status,
            })
          }
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }


  const status_options = folderStatus.map(({ id, name }) => (
    <SelectOption key={id} name={name} value={name} />
  ))

  status_options.unshift(
    <SelectOption isDisabled={true} value={formatMessage({ id: "shared:select" })} />
  )


  return (
    <Modal
      //@ts-ignore
      name={<span className={styles.header_title}>{formatMessage({ id: "demand_detail:demand_detail" })}</span>}
      isOpen={isOpen}
      onClose={onClose}
      //isFooterLeftAligned
      className={styles.modal_root}
    >
      <div
        className={styles.content}
      >
        <h5 className={styles.title}>
          <FormattedMessage id="demand_detail:application_and_job" />
        </h5>
        <div className={styles.grid_column}>
          <div className={styles.grid_row}>
            <span>
              <FormattedMessage id="demand_detail:date" />
             :</span>
            <span>{detail.date}</span>
            <span>
              <FormattedMessage id="demand_detail:candidate" />
            :  </span>
            <span>{
              detail.candidate!.firstname + " " + detail.candidate!.lastname
            }</span>
            <span>
              <FormattedMessage id="demand_detail:requester_name" />
            :  </span>
            <span>{detail.candidate!.contact_name}</span>
            <span>
              <FormattedMessage id="demand_detail:job_requested" />
            :  </span>
            <span>{detail.employment}</span>
            <span>
              <FormattedMessage id="demand_detail:requester_email" />
            :  </span>
            <span>{detail.email}</span>
            <span>
              <FormattedMessage id="shared:address" />
            :  </span>
            <span>{detail.candidate!.adress}</span>
            <span>
              <FormattedMessage id="shared:phone_and_post" />
            :  </span>
            <span>{detail.candidate!.phone_number}/{detail.candidate!.post_number}</span>
            <span>
              <FormattedMessage id="shared:province" />
            :  </span>
            <span>{detail.candidate!.province}</span>
            <span>
              <FormattedMessage id="shared:driver_licence_number" />
            :  </span>
            <span>{
              detail.candidate!.driving_license_number || formatMessage({ id: 'shared:n_a' })
            }</span>
          </div>
        </div>
        <h5 className={styles.title}>
          <FormattedMessage id="demand_detail:comments" />
        </h5>
        <span>
          {detail.comments}
        </span>
        {[
          detail.credit_verification,
          detail.old_job_references,
          detail.professional_associations,
          detail.academic_training,
          detail.driver_license,
          detail.cipc,
          detail.criminal_penal_plumitif,
          detail.full_plumitif,
        ].some(_ => _) && (
            <h5 className={styles.title}>
              <FormattedMessage id="demand_detail:verification_request" />
            </h5>
          )}
        <div className={styles.grid_column}>

          {detail.credit_verification && (
            <span>
              <FormattedMessage id="demand:credit_verification" />
            </span>
          )}

          {detail.old_job_references && (
            <span>
              <FormattedMessage id="demand:old_job_references" />
            </span>
          )}


          {detail.professional_associations && (
            <span>
              <FormattedMessage id="demand:professional_associations" />
            </span>
          )}


          {detail.academic_training && (
            <span>
              <FormattedMessage id="demand:academic_training" />
            </span>
          )}

          {detail.driver_license && (
            <span>
              <FormattedMessage id="demand:driver_license" />
            </span>
          )}

          {detail.cipc && (
            <span>
              CIPC
            </span>
          )}

          {detail.criminal_penal_plumitif && (
            <span>
              <FormattedMessage id="demand:criminal_penal_plumitif" />
            </span>
          )}
          {detail.full_plumitif && (
            <span>
              <FormattedMessage id="demand:full_plumitif" />
            </span>
          )}
        </div>

        {[
          detail.authorization_form,
          detail.cv,
          detail.diplomas_copy,
          detail.references,
        ].some(_ => _) && (
            <h5 className={styles.title}>
              <FormattedMessage id="demand_detail:attachments" />
            </h5>
          )}
        {detail.authorization_form && (
          <div className={styles.file}>
            <span>
              <FormattedMessage id="demand:authorization_form" />
            </span>
            <a className={styles.btn_download} target="_blank" href = {(detail.authorization_form).replace('http:', 'https:')}>
              <FormattedMessage id="demand_detail:download" />
            </a>
          </div>
        )}
        {detail.id_papers && (
          <div className={styles.file}>
            <span>
              <FormattedMessage id="demand:id_papers" />
            </span>
            <a className={styles.btn_download} target="_blank"  href = {(detail.id_papers).replace('http:', 'https:')}>
              <FormattedMessage id="demand_detail:download" />
            </a>
          </div>
        )}
        {detail.cv && (
          <div className={styles.file}>
            <span>
              <FormattedMessage id="shared:cv" />
            </span>
            <a className={styles.btn_download} target="_blank" href = {(detail.cv).replace('http:', 'https:')}>
              <FormattedMessage id="demand_detail:download" />
            </a>
          </div>
        )}
        {detail.diplomas_copy && (
          <div className={styles.file}>
            <span>
              <FormattedMessage id="demand:diplomas_copy" />
            </span>
            <a className={styles.btn_download} target="_blank" href = {(detail.diplomas_copy).replace('http:', 'https:')}>
              <FormattedMessage id="demand_detail:download" />
            </a>
          </div>
        )}
        {detail.references && (
          <div className={styles.file}>
            <span>
              <FormattedMessage id="shared:references" />
            </span>
            <a className={styles.btn_download} target="_blank" href = {(detail.references).replace('http:', 'https:')}>
              <FormattedMessage id="demand_detail:download" />
            </a>
          </div>
        )}

        <h5 className={styles.title}>
          <FormattedMessage id="demand_detail:demand_status" />
          {loading && (<Spinner size={spinnerSize.md} />)}
        </h5>

        {["Administrator", 'Technician'].includes(currentRole) ? (
          <Select
            variant={SelectVariant.typeahead}
            onToggle={
              open => setIsExpanded(open)
            }
            name="status"
            onSelect={onChangeStatus}
            selections={status}
            isOpen={isExpanded}
          // ariaLabelledBy={'status'}
          >
            {status_options}
          </Select>
        ) : (
            <span>{status}</span>
          )}

        <Button
          className={styles.btn_download_pdf}
          component="a"
          target="new"
          href={`/report/${detail.candidate!.id}/${language}`}
        >
          <FormattedMessage id="demand_detail:download_pdf" />
        </Button>

      </div>
    </Modal>
  );
}
