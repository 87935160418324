import { ObservableStore } from '@codewithdan/observable-store';
import { timer } from 'rxjs';


/**
 * This file handles roles with respect to the currently
 * connected user and not the entire roles in the application.
 * For that, please refer to the appstore where the roles of the 
 * pre-emploi platform is managed.
 */
class CurrentRoleStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        INIT_STATE: 'INIT_STATE',
        RESET_ROLES: 'RESET_ROLES',
        SWITCH_ROLE: 'SWITCH_ROLE',
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }


    //#region roles
    resetUserRoles = (roles: string[]) => {
        const localStorageRole = localStorage.currentRole || roles[0]
        let current_role = roles.includes(
            localStorageRole
        ) ? localStorageRole : roles[0]
        this.setState(
            {
                userRoles: roles,
                current_role
            },
            CurrentRoleStore.ACTIONS.RESET_ROLES,
        )
    }

    switchRole = (role: string) => {
        localStorage.currentRole = role
        this.setState(
            {
                current_role: role
            },
            CurrentRoleStore.ACTIONS.SWITCH_ROLE,
        )
        window.location.reload()
    }

    /**
     * *********getter for everything roles data.***********
     */
    get roles$() {
        return this.stateChanged
    }
    //#endregion crumbs and accordion

    logout = () => {
        this.setState({
            userRoles: [],
            current_role: localStorage.currentRole || '',
        }, CurrentRoleStore.ACTIONS.INIT_STATE)

    }

}

export default new CurrentRoleStore()


export interface StoreState {
    userRoles: string[],
    current_role: string;
}