import React, {
  useState, useEffect, useContext,
  useCallback, useRef
} from 'react';
import styles from './UserListing.module.scss';
import {
  Button, Dropdown, Spinner,
  spinnerSize, DropdownItem, DropdownToggle,
  InputGroup,
} from '@patternfly/react-core';
import {
  useIntl, FormattedMessage
} from 'react-intl';
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
  sortable,
  SortByDirection
} from '@patternfly/react-table';
import { RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  CloseIcon, ArrowsAltVIcon, FilterAltIcon,
  SearchIcon,
} from '@patternfly/react-icons'
import {
  useAccordionData, useNotificationData, useUserInfoData
} from 'hooks'
import { NewUser, ConfirmDialog } from 'dialogs';
import {
  AccordionContext, ListEmpty, SearchField
} from 'components';
import { UserService } from 'services';
import { useUserDataH } from 'hooks'
import { IUser } from 'stores';
import moment from 'moment'
import { useLanguageData } from 'hooks';
import 'moment/locale/fr';
import { TABLE_COLUMN } from 'constants/table_columns';
import { ILoginInfo } from 'stores/auth.store';
import { useParams } from 'react-router';

export interface UserListintProps extends RouteComponentProps {
  data?: any;
}

const UserListingComponent: React.FC<UserListintProps> = ({
  history,
  data: { company_users = false } = { company_users: false },
  ...props
}) => {
  /**
   * Company id is generated by the technician in company
   * listing page.
   */
  const { company_id } = useParams() as { company_id: string }
  const { language } = useLanguageData()
  let {
    start: storeStart, limit: storeLimit,
    total: storeTotal, users: storeUsers,
  } = useUserDataH()

  /**track change in limit collected. */
  let timeout;

  const [query, setQuery] = useState<string>('')
  const [
    selectedUserIds,
    setSelectedUserIds
  ] = useState<string[]>([])
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  const [searching, setSearching] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [observerTriggerState, setObserverTriggerState] = useState<any>({})
  const centinelRef = useRef<any>(null)
  const { new_notification, } = useNotificationData()
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const { userInfo } = useUserInfoData()
  const { setBottomComponent } = useContext(AccordionContext)
  const { formatMessage } = useIntl()

  // Table header
  const [columns] = useState<TABLE_COLUMN[]>([
    {
      title: formatMessage({ id: 'user:user' }),
      field: 'name',
    },
    {
      title: formatMessage({ id: 'shared:email' }),
      field: 'email',
    },
    {
      title: formatMessage({ id: 'shared:created_on' }),
      transforms: [sortable],
      field: 'created_at',
      type: 'date',
    },
    {
      title: formatMessage({ id: 'shared:modified_on' }),
      field: 'modified_at',
      type: 'date',
    },
    {
      title: formatMessage({ id: 'shared:last_connexion' }),
      field: 'last_connexion',
      type: 'date',
    },
  ])

  const [order, setOrder] = useState<string>('')

  const onSelect = (title) => {
    setSelected(title)
    setFilterOpen(false)
  }

  const orders = [
    {
      title: formatMessage({ id: "shared:asc" }),
      value: 'asc',
    },
    {
      title: formatMessage({ id: "shared:desc" }),
      value: 'desc',
    },
  ]

  const dateFields = columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'date'
  )
    .map(({ title }) => title)

  const searcheableFields = columns
    .filter(({ searcheable = true }) => searcheable)
    .map(({ title }) => title)
    .map(title => (
      <DropdownItem
        onClick={_ => {
          onSelect(title)
        }}
        key={title} value={title}
        component="button"
      >
        {title}
      </DropdownItem>
    ))

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [orderOpen, setOrderOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>('')
  const [filterState, setFilterState] = useState<any>(undefined)

  useEffect(() => {
    const column = columns.find(({ title }) => title === selected)
    if (!Boolean(column)) return
    let field = column!.field

    setFilterState(
      { order, order_field: field, [field!]: query }
    )

    fetchUsers(
      0,
      { order, order_field: field, [field!]: query }
    )

  }, [selected, query, order,])

  const [toDelete, setToDelete] = useState<string[]>([])

  // Text in delete modal
  const confirmation_message = formatMessage({ id: 'shared:confirm_user_suppr' })

  const deleteUserFromApi = (...id_list: string[]) => {
    setLoadingDelete(true)
    UserService.delete_user({ id_list })
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
          deleteUsers(...id_list)
          setToDelete([])
          setSelectedUserIds([])
          setLoadingDelete(false)
          if (id_list.length > 15) {
            setTimeout(() => {
              setObserverTriggerState({ hello: Math.random() })
            })
          }
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingDelete(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const deleteUsers = (...user_ids: string[]) => {
    setRows(rows.filter(
      ({ id }) => !user_ids.includes(id)
    ))
  }

  const [userToUpdate, setUserToUpdate] = useState(null)
  // All actually loaded user
  const [users, setUsers] = useState<IUser[]>([])

  const handleUpdate = (e, user) => {
    setUserToUpdate(user);
  }

  // Table body
  const [rows, setRows] = useState<any[]>([])

  const actions = [
    {
      title: <Link to='#' className={styles.link}>
        <span>
          <FormattedMessage id="shared:modify" />
        </span>
      </Link>,
      onClick: (e, rowId) => handleUpdate(e, rows[rowId])
    },
    {
      isSeparator: true
    },
    {
      title: <Link to='#' className={styles.link}>
        <span>
          <FormattedMessage id="shared:delete" />
        </span>
      </Link>,
      onClick: (_, rowId,) => setToDelete([rows[rowId].id])
    }
  ]

  // Use to sort column of table
  const [sortBy, setSortBy] = useState<any>({})

  // Change the hearder of the page
  const { resetAccordion } = useAccordionData()

  // Sorting
  const onSort = (_event, index, direction) => {
    const sortedRows = rows.sort((a, b) => (a[index] < b[index] ? -1 : a[index] > b[index] ? 1 : 0));
    setSortBy({
      index,
      direction
    })
    setRows(direction === SortByDirection.asc ? sortedRows : sortedRows.reverse())
  }

  // Description of page
  const accordionBottom = () => (
    <span></span>
  )

  // Page header
  useEffect(() => {
    setBottomComponent(accordionBottom)
    resetAccordion({
      title: formatMessage({ id: "user:list_of_users" }),
      sub_title: ""
    })
    configureInfiniteScroll()
  }, [])

  useEffect(() => {
    clearTimeout(timeout)
    //text in search field has been emptied
    if (query === '') {
      setRows(storeUsers)
      return
    }
    setSearchStart(0) //On each new search, searchStart goes to zero
    setSearchTotal(0)
    timeout = setTimeout(() => {
      searchUsers()
    }, 300);
  }, [query])

  // This is the function used to fetch users with all filter data
  const fetchUsers = (
    paramStart: number, filter?: any,
  ) => {
    if (!(userInfo as ILoginInfo).id) { return }
    setLoading(true)

    let suffix = `?start=${paramStart}&limit=${storeLimit}`
    if ((userInfo as ILoginInfo).is_manager) {
      suffix += `&company_id=${(userInfo as any).company_id}`
    }
    console.log({ company_users })
    if (company_users === true) {
      suffix += `&company_id=${company_id}`
    }

    if (Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (
          Boolean((filter || filterState)[field])
          || (filter || filterState)[field] === 0
        ) {
          suffix = `${suffix}&${field || 'keyword'}=${(filter || filterState)[field]}`
        }
      })
    }
    if (!Boolean(filter) && !Boolean(filterState)) {
      suffix = `${suffix}&keyword=${query}`
    }

    UserService.list_users(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if (response)
          if ([200, 201].includes(response.status)) {
            const { results, start, total, limit } = await response.json()

            setRows([...(start > 0 ? rows : []), ...results])
            setSearchTotal(total)
            setSearchStart(start)

          } else if (response.json) {
            const { message } = await response.json()
            new_notification({
              message,
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          } else {
            new_notification({
              message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          }
      })
  }

  const onTableRowSelected = (
    event, isSelected: boolean, rowIndex: number,
  ) => {

    if (rowIndex === -1) {
      if (isSelected) {
        setSelectedUserIds(rows.map(_ => _.id))
        return
      }
      setSelectedUserIds([])
      return
    }

    if (isSelected) {
      setSelectedUserIds([...selectedUserIds, rows[rowIndex].id])
      return
    }

    const newSelectedUserIds = selectedUserIds
      .filter(_ => _ !== rows[rowIndex].id)
    setSelectedUserIds(newSelectedUserIds)
  }


  // Here is where we constructs our table body
  const parseUsers = (data: IUser[]) => {
    return data.map(datum => ({
      cells: [
        `${datum.firstName} ${datum.lastName}`,
        datum.email,
        moment.utc(datum.createdTimestamp)
          .utcOffset("-05:00")
          .locale(language).format("DD  MMMM YYYY à H:mm"),
        moment.utc(datum.update_at)
          .utcOffset("-05:00")
          .locale(language).format("DD  MMMM YYYY à H:mm"),
        datum.last_connection ? moment.utc(
          datum.last_connection
        )
          .utcOffset("-05:00")
          .locale(language).format("DD  MMMM YYYY à H:mm") : ''
      ],
      selected: selectedUserIds.includes(datum.id),
    })
    )
  }

  useEffect(() => {
    fetchUsers(0)
  }, [company_id])

  // Manage next page by conserving all filter data that were set
  const onNextPage = () => {
    // if (query !== '') {
    if (searchTotal !== 0 && rows.length >= searchTotal) { return }
    fetchUsers(
      searchTotal === 0 ? 0 : searchStart + storeLimit
    )
    return
  }

  const searchUsers = () => {
    fetchUsers(0)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeUsers, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      //use window as intersection box
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  // Page content
  return (
    <div className={styles.wrapper} >
      <ConfirmDialog
        confirmation_message={confirmation_message}
        onAction={_ => deleteUserFromApi(...toDelete)}
        isModalOpen={toDelete.length > 0}
        loading={loadingDelete}
        onModalClose={_ => setToDelete([])}
      />
      {
        userToUpdate && <NewUser
          userToUpdate={userToUpdate}
          onDone={newuser => {
            const idx = rows.findIndex(({ id }) => id === newuser.id)
            if (idx === -1) { return }
            const newRows = [...rows]
            newRows.splice(idx, 1, newuser)
            setRows(newRows)
          }}
          setUserToUpdate={setUserToUpdate}
        />
      }

      <div className={styles.content}>
        <div className={styles.toolbar}>

          <InputGroup
            className={styles.search}
          >
            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<FilterAltIcon />}
                  onToggle={setFilterOpen}
                >
                  {selected ? selected : formatMessage({ id: 'shared:filter' })}
                </DropdownToggle>
              }
              isOpen={filterOpen}
              dropdownItems={searcheableFields}
            />

            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<ArrowsAltVIcon />}
                  onToggle={setOrderOpen}
                >
                  {order ?
                    orders.find(jk => jk.value === order)!.title
                    : formatMessage({ id: 'shared:order' })}
                </DropdownToggle>
              }
              isOpen={orderOpen}
              dropdownItems={
                orders
                  .map(orderIn => (
                    <DropdownItem
                      onClick={_ => {
                        setOrder(orderIn.value)
                        setOrderOpen(false)
                      }}
                      key={orderIn.value}
                      value={orderIn.value}
                      component="button"
                    >
                      {orderIn.title}
                    </DropdownItem>
                  ))
              }
            />

            <SearchField
              value={query}
              dateFields={dateFields}
              statusField={formatMessage({ id: "shared:status" })}
              field={selected}
              onAction={setQuery}
            />

            <Button
              variant="control"
              className={styles.btn_nothing}
            >
              <SearchIcon />
            </Button>

          </InputGroup>
          {company_users !== true && (
            <NewUser
              renderTrigger={trigger => (
                <Button onClick={trigger} className={styles.btn_filled}>
                  <FormattedMessage id="user:add_new_user" />
                </Button>
              )}
              onCreate={newuser => {
                const newRows = [newuser, ...rows]
                setRows(newRows)
              }}
            />
          )}
          {selectedUserIds.length > 0 && (
            <Button
              onClick={i => {
                setToDelete(selectedUserIds)
                setSelectedUserIds([])
              }}
              variant={"secondary"}
            >
              <CloseIcon />
              <FormattedMessage id="shared:delete" />
            </Button>
          )}
        </div>
        {rows.length === 0 && !loading && (
          <ListEmpty />
        )}
        {rows.length > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            onSort={onSort}
            cells={columns}
            onSelect={company_users === true ? undefined : onTableRowSelected}
            canSelectAll={company_users === true ? false : true}
            borders={false}
            rows={parseUsers(rows)}
            actions={company_users === true ? undefined : actions}
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}
        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{
            `${rows.length} ${formatMessage({ id: 'shared:of' })} ${searchTotal}`
          }</span>
        </div>
      </div>
    </div>
  );
}

export {
  UserListingComponent as UserListingPage,
}
