import { ObservableStore } from '@codewithdan/observable-store';
import {
    map,
    distinctUntilChanged,
} from 'rxjs/operators'
import {
    IDemand, DEMAND_STORE_ACTIONS,
} from '.'


class DemandStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        ...DEMAND_STORE_ACTIONS,
        INIT_STATE: 'INIT_STATE',
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }

    //#region demands
    resetDemands = (param: IStoreDemand) => {
        this.setState(
            state => ({
                ...state,
                demands: { ...param, data: param.data }
            }),
            DemandStore.ACTIONS.RESET_DEMANDS,
        )
    }

    appendDemands = (param: IStoreDemand) => {
        this.setState(
            state => ({
                ...state,
                demands: { ...param, data: [ ...(state.demands.data || []), ...param.data] },
            }),
            DemandStore.ACTIONS.APPEND_DEMANDS,
        )
    }

    appendDemand = (param: IDemand) => {
        this.setState(
            state => ({
                ...state,
                demands: { ...state.demands, data: [param, ...(state.demands.data || [])] },
            }),
            DemandStore.ACTIONS.APPEND_DEMANDS,
        )
    }

    updateDemand = (demand: IDemand) => {
        const idx = this.getState().demands.data!.findIndex(dem => dem.id === demand.id)
        if (idx === -1) { return }

        /**At this point, demands CANNOT be an empty array no undefined reference */
        const demands = [...this.getState().demands.data]
        demands.splice(idx, 1, demand)
        

        this.setState(
            state => ({
                ...state,
                demands: { ...state.demands, data: demands },
            }),
            DemandStore.ACTIONS.UPDATE_DEMAND,
        )
    }


    deleteDemand = (demandid: string) => {
        const oldDemandId = this.getState().demands.data!.findIndex(cad => cad.id === demandid)
        if (oldDemandId === -1) { return }

        /**At this point, demands CANNOT be an empty array nor undefined reference */
        const demandsData = this.getState().demands.data
        demandsData.splice(oldDemandId, 1)

        this.setState(
            state => ({
                ...state,
                demands: { ...state.demands, data: demandsData },
            }),
            DemandStore.ACTIONS.DELETE_DEMAND,
        )
    }

    /**
     * **************getter for everything demands data.****************
     * Notice the distinctUntilChanged operator
     * used to ensure that it doesn't fire at all
     * https://rxjs-dev.firebaseapp.com/api/operators/distinctUntilChanged
     */
    get demands$() {
        return this.stateChanged.pipe(
            map(({ demands }) => demands),
            distinctUntilChanged(),
        )
    }
    //#endregion demands

    logout = () => {
        const defaultData = {
            data: [],
            start: 0,
            limit: 30,
            total: 0,
        }
        this.setState({
            demands: defaultData,
        }, DemandStore.ACTIONS.INIT_STATE)

    }

}

export default new DemandStore()



export interface IStoreDemand {
    limit?: number,
    start?: number,
    total?: number,
    data: IDemand[],
}



export interface StoreState {
    demands: IStoreDemand,
}