import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router"
import styles from './Login.module.scss'
import { AuthStore } from 'stores';
import { Subscription } from 'rxjs'
import { ILoginInfo } from 'stores/auth.store';
import { AuthService } from 'services'
import { Link } from "react-router-dom";

import {
  LoginFooterItem,
  LoginMainFooterBandItem,
  LoginPage,
  ListItem,
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button
} from '@patternfly/react-core';

import auth_img from 'assets/imgs/auth_img.png'
import logo from 'assets/imgs/logo.png'
import { useNotificationData, useUserRoleData } from 'hooks'
import { Spinner } from '@patternfly/react-core'
import { FormattedMessage, useIntl } from 'react-intl';

import { LanguageSwitcher } from 'components'


export interface LoginProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

interface LoginState {
  readonly usernameValue: string;
  readonly passwordValue: string;
  readonly isValidUsername: boolean;
  readonly isValidPassword: boolean;
  readonly showHelperText: boolean
  readonly isSubmitting: boolean
}



const LoginComponent: React.FC<LoginProps> = ({
  location: { search },
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { notification, new_notification } = useNotificationData()

  //https://codedaily.io/tutorials/78/Create-a-Query-Param-Login-Modal-Route-with-React-Router
  const params = new URLSearchParams(search);
  const redirect = params.get("redirect")

  const [userInfo, setUserInfo] = React.useState<ILoginInfo | {} | null>(null)
  const [formState, setFormState] = React.useState<LoginState>({
    usernameValue: "",
    passwordValue: "",
    isValidUsername: true,
    isValidPassword: true,
    showHelperText: false,
    isSubmitting: false
  })
  const { resetUserRoles } = useUserRoleData()



  // const signUpForAccountMessage = (
  //   <LoginMainFooterBandItem>
  //     <span>Vous n'avez pas encore de compte? </span>
  //     <Link to='/auth/sign_up'>
  //       S'enregistrer
  //     </Link>
  //   </LoginMainFooterBandItem>
  // );
  const forgotCredentials = (
    <>
      <LoginMainFooterBandItem>
        <Link to='/auth/recover'>
          {formatMessage({ id: 'shared:password_forgotten' })}
      </Link>
      </LoginMainFooterBandItem>
    </>
  );

  // Set the state when email field is fill
  const handleUsernameChange = value => {
    setFormState({ ...formState, usernameValue: value })
  }

  // Set the state when password field is fill
  const handlePasswordChange = passwordValue => {
    setFormState({ ...formState, passwordValue: passwordValue })
  }

  // When the form is submitted
  const onLoginButtonClick = event => {
    event.preventDefault();
    // Change isSubmitting to True(usefull for the loader)
    setFormState({ ...formState, isSubmitting: true })

    // Send data to API
    const formData = new FormData()
    formData.append('username', formState.usernameValue)
    formData.append('password', formState.passwordValue)
    AuthService.login(formData)
      .then(async response => {
        // Waiting for response
        setTimeout(() => {
        }, 100);
        // When result is available, set isSubmitting to false to stop loader
        setFormState({ ...formState, isSubmitting: false })
        if (response.status == 200) {
          let json_response = await response.json()
          let roles: string[] = [];
          // Get user's role and add it to roles array
          if (json_response.is_admin) {
            roles.push("Administrator");
          }
          if (json_response.is_technicien) {
            roles.push("Technician");
          }
          if (json_response.is_accountant) {
            roles.push("Accountant");
          }
          if (json_response.is_manager) {
            roles.push("Company_Administrator");
          } else if (json_response.is_contact) {
            roles.push("Contact");
          }

          resetUserRoles(roles)

          // Add roles array to response
          json_response = {
            ...json_response,
            roles
          }

          // Save user info to store
          AuthStore.saveUserInfo(
            json_response
          )

          // Redirect
          setTimeout(() => {
            if (redirect && redirect.indexOf('auth') !== -1) {
              props.history.push("/me")
              return
            }
            props.history.push(redirect || '/me')
            /**
             * The minimum duration is 300ms
             * for reasons of the store setting the token before
             * reaching the candidates page for the subsequent
             * requests to fetch with the appropriate
             * toke 
             */
          }, 300)

          // Notify user that he has successfully login
          new_notification({
            variant: "success",
            title: 'login-success',
            message: `Welcome ${json_response.firstName}`,
            duration: 200,
          });
          // if error while logging in also notify user
        } else if (response.json) {
          let { message } = await response.json();
          setFormState({ ...formState, isSubmitting: false })

          new_notification({
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
            message,
            duration: 200,
          })
        } else {
          setFormState({ ...formState, isSubmitting: false })
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
            duration: 200,
          })
        }
      })
      .catch(error => {
        setFormState({ ...formState, isSubmitting: false })
        new_notification({
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
          message: formatMessage({ id: 'shared:unknown_err' }),
          duration: 200,
        })
      });

  }

  const loginForm = (
    <Form className={styles.form_container} onSubmit={onLoginButtonClick}>
      <FormGroup label={formatMessage({ id: 'shared:mail' })} isRequired fieldId="login-email">
        <TextInput
          isRequired
          type="email"
          id="login-email"
          placeholder={formatMessage({ id: 'shared:mail' })}
          name="username_email"
          value={formState.usernameValue}
          onChange={handleUsernameChange}
        />
      </FormGroup>
      <FormGroup label={formatMessage({ id: 'shared:password' })} isRequired fieldId="login-password">
        <TextInput
          isRequired
          type="password"
          id="login-password"
          placeholder={formatMessage({ id: 'shared:password' })}
          name="password"
          value={formState.passwordValue}
          onChange={handlePasswordChange}
        />
      </FormGroup>

      <ActionGroup>
        <Button type="submit" onClick={onLoginButtonClick}>
          <FormattedMessage id="auth:login" />
          {Boolean(formState.isSubmitting) && (
            <Spinner size="md" />
          )}
        </Button>
      </ActionGroup>
    </Form>
  );

  const listItem = (
    <React.Fragment>
      <ListItem>
        <LoginFooterItem href="#">
          <FormattedMessage id="auth:terms_of_use" />
        </LoginFooterItem>
      </ListItem>
      <ListItem>
        <LoginFooterItem href="#">
          <FormattedMessage id="auth:help" />
        </LoginFooterItem>
      </ListItem>
      <ListItem>
        <LoginFooterItem href="#">
          <FormattedMessage id="auth:confidentiality" />
        </LoginFooterItem>
      </ListItem>
    </React.Fragment>
  );

  return (
    <div className={styles.container}>
      <div className={styles.language}>
        <LanguageSwitcher/>
      </div>
      <LoginPage className={styles.login_page_container}
        brandImgSrc={logo}
        brandImgAlt="Pre Emploi logo"
        backgroundImgSrc={auth_img}
        backgroundImgAlt="Images"
        loginTitle={formatMessage({ id: "auth:connect_to_pre_emploie" })}
        loginSubtitle={
          `
          ${formatMessage({ id: "auth:welcome" })}
          ${formatMessage({ id: "auth:connect_prompt" })}`
        }
        forgotCredentials={forgotCredentials}
        textContent={formatMessage({ id: "auth:explanation" })}
        footerListItems={listItem}
      >
        {loginForm}
      </LoginPage>
    </div>

  );
}

export {
  LoginComponent as LoginPage,
}
