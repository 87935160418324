import React, { useState, useEffect } from 'react';
import { useFolderStatuses, useNotificationData, useProvinceData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
// import  from 'assets/imgs/logo_print.svg'
import {
  ReactComponent as LogoPrint
} from 'assets/imgs/logo_color.svg'

import { FormService, CandidateService, DemandService } from 'services';
import clsx from 'classnames'
import styles from './PdfPage.module.scss'
import { ICandidate } from 'stores';
import { Spinner, spinnerSize, Button } from '@patternfly/react-core';
import { DownloadIcon, ArrowsAltIcon, } from '@patternfly/react-icons';
import { ListEmpty } from 'components';
import { ReactComponent as FrontLogo } from 'assets/imgs/pre_emploi_logo_pdf.svg'
import { ReactComponent as Documentstack } from 'assets/imgs/document_stack.svg'
import { ReactComponent as CheckedIcon } from 'assets/imgs/checked.svg'
import moment from 'moment'
import { ReorderPdf } from 'dialogs';
import { form_fields } from 'constants/example';

export interface PdfPageProps extends RouteComponentProps {
  readonly dumm?: boolean
}

const PdfPageComponent: React.FC<PdfPageProps> = props => {

  const { formatMessage } = useIntl();
  const { new_notification } = useNotificationData()
  const [forms, setForms] = useState<any>()
  const [candidate, setCandidate] = useState<Partial<ICandidate>>({})
  const { candidate_id, demand_id, language } = props.match.params as any
  const [error, setError] = useState<boolean>(false)
  const [generalInfo, setGeneralInfo] = useState<any>({})
  const { provinces } = useProvinceData()
  const [data, setData] = useState<any>({})
  const [reorderedForms, setReorderedForms] = useState<any[]>([])
  const [demand, setDemand] = useState<any>(undefined)
  const [hide, setHide] = useState<boolean>(false)
  const { folderStatus } = useFolderStatuses()

  React.useEffect(() => {
    fetchCandidateDemandForms(candidate_id)
  }, [])

  const fetchCandidateDemandForms = candidate_id => {

    Promise.all([
      FormService.get_candidate_form(candidate_id),
      CandidateService.candidate(candidate_id),
      DemandService.get_demand(candidate_id),
    ])
      .catch(err => {
        setError(true)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async responses => {
        setError(true)
        let [response, response2, response3] = responses as [any, any, any]
        if (
          [200, 201].includes(response.status)
          && [200, 201].includes(response2.status)
        ) {
          setError(false)
          const res = await response.json()
          const data = await response2.json()
          const resDemand = await response3.json()
          // console.log({res})
          if (res && res.forms) {
            setForms(res.forms)
          }
          setDemand(resDemand)
          setCandidate(data)
        } else if (
          [400, 500, 422, 401, 409, 404].includes(response.status)
          || [400, 500, 422, 401, 409, 404].includes(response2.status)
        ) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })

  }

  useEffect(() => {
    if (Object.keys(candidate).length === 0) { return }

    const info = {
      title: formatMessage({ id: "pdf:general_info" }),
      data: [
        [
          formatMessage({ id: "shared:date" }) + ' :',
          moment.utc(
            demand.date.replace("-05:00", "")
          )
            .utcOffset("-05:00")
            .format("DD-MM-YYYY")
        ],
        [
          formatMessage({ id: "pdf:your_documents" }) + ' :',
          candidate.folder_number
        ],
        [
          formatMessage({ id: 'shared:candidate' }) + ' :',
          `${candidate.firstname} ${candidate.lastname}`
        ],
        [
          formatMessage({ id: 'pdf:recruiter' }) + ' :',
          candidate.request_email
        ],
        [
          formatMessage({ id: "shared:status" }) + ' :',
          typeof candidate.folder_status === 'number' ? (
            folderStatus.find(
              ({ id }) => id === +candidate.folder_status!
            )!.name
          ) : (
              candidate.folder_status
            )
        ],
        [
          formatMessage({ id: 'shared:address' }) + ' :',
          candidate.adress
        ],
        [
          formatMessage({ id: "shared:postal_code" }) + ' :',
          candidate.postal_code
        ],
        [
          formatMessage({ id: "shared:province" }) + ' :',
          (
            provinces.find(
              ({ id }) => id === candidate.province)
            || { name: '' }).name
        ],
        [
          formatMessage({ id: "shared:town" }) + ' :',
          candidate.city
        ],
        [
          formatMessage({ id: "shared:date_of_birth" }) + ' :',
          moment.utc(
            candidate.birthday
          )
            .format("DD-MM-YYYY")
        ],
        [
          formatMessage({ id: 'shared:driver_licence' }) + ' :',
          candidate.driving_license_number
        ],
        [
          formatMessage({ id: "shared:telephone" }) + ' :',
          candidate.phone_number || candidate.phonenumber
        ],
        [
          formatMessage({ id: "shared:post_number" }) + ' :',
          candidate.post_number
        ],
        [
          formatMessage({ id: 'shared:job_requested' }) + ' :',
          demand.employment
        ],
        [formatMessage({ id: 'shared:nas' })+' :', candidate.nas],
        [
          formatMessage({ id: "shared:comments" }) + ' :',
          demand.comments
        ],
      ]
    }

    setGeneralInfo(info)

    setData(parseForms(
      forms
      // .filter(f => !f.is_standard)
    ))

    // setSTandardForms(parseForStandardForms(
    //   forms.filter(f => f.is_standard)
    // ))
  }, [candidate])

  useEffect(() => {
    if (!Boolean(reorderedForms)) { return }
    setData(parseForms(reorderedForms))
  }, [reorderedForms])

  const parseForms = (data) => {
    return data.map(
      ({ is_standard, editable, ...datum }) => {
        if (!editable && is_standard) {
          return parseFormStandardForms({
            is_standard, editable, ...datum
          })
        }


        //Normal form here
        return {
          title: datum.title,
          is_standard,
          editable: editable === undefined ? true : editable,
          categories: datum.data.questionFields.map(
            questionField => {
              const fields = questionField.fields.map(
                ({
                  label, label_en, value, justification_field, type, ...rest
                }) => ([label, label_en, value, justification_field, type])
              )
                /**Parse out justification fields into their respective selects */
                .reduce(
                  (
                    acc,
                    [label, label_en, value, justification_field, type],
                    idx,
                  ) => {
                    if (Boolean(justification_field)) {
                      if (idx === 0 || !Boolean(value)) return acc
                      const actualQuestionField = acc[idx - 1]
                      actualQuestionField[1] = `${actualQuestionField[1]}, ${value}`
                      acc.splice(idx - 1, 1, actualQuestionField)
                      return acc
                    }
                    if (type == 'date') {
                      return [
                        ...acc,
                        [
                          language=="fr" ? label : label_en ? label_en : label,
                          moment.utc(
                            value
                          )
                            .format("DD-MM-YYYY")
                        ]
                      ]
                    }
                    return [...acc, [language=="fr" ? label : label_en ? label_en : label, value]]
                  },
                  []
                )

              return {
                description: questionField.description,
                title: language=="fr" ? (questionField.catName_fr ? questionField.catName_fr : questionField.catName) : questionField.catName_en ? questionField.catName_en : questionField.catName,
                fields,
              }
            }
          )
            .filter(cat => cat.fields.length > 0)
        }
      })
      .filter(
        ({ is_standard, ...form }) => is_standard || form.categories.length > 0
      )
  }

  const parseFormStandardForms = (form) => {

    const selectOptionsValue = {
      "Excellent": 1,
      "Très bon": 2,
      "Bon": 3,
      "Passable": 4,
      "Faible": 5,
      "Very good": 2,
      "Good": 3,
      "Fair": 4,
      "Low": 5
    }

    const selectThirdOptionsValue = value => {
      if ((value === "Oui") || value === "Yes"){
        value = formatMessage({ id: "shared:yes" })
      }
      if ((value === "Non") || value === "No"){
        value = formatMessage({ id: "shared:false" })
      }
      return value
    }

    return {
      is_standard: form.is_standard,
      editable: form.editable === undefined ? true : form.editable,
      firstSection: form.data.questionFields[0].fields.map(
        ({ label, value, type }) => {
          if (type == 'date') {
            return [
              label,
              moment.utc(
                value
              )
                .format("DD-MM-YYYY")
            ]
          }
          return [label, value]
        }
      )
        .reduce(
          (acc, curr) => {
            if (acc.length < 1) { return [[curr]] }

            if (acc[acc.length - 1].length < 2) {
              acc[acc.length - 1].push(curr)
              return acc
            }

            if (acc[acc.length - 1].length = 2) {
              acc.push([curr])
              return acc
            }

          },
          []
        )
      ,
      secondSection: form.data.questionFields[1].fields.map(
        ({ label, value }) => ([label, selectOptionsValue[value]])),
      thirdSection: form.data.questionFields[2].fields.map(
        ({ label, value }) => ([label, selectThirdOptionsValue(value)]))
        /**
        * Removes the last field which 
        * is that of the interviewer
        */
        .filter((_, idx, arr) => idx + 1 !== arr.length),
      interviewer: form.interviewer || form.data.questionFields[3]?.fields[1]?.value,
      update_at: Boolean(form.update_at) ?
        moment.utc(
          `${form.update_at}`.replace("-05:00", "")
        )
          .utcOffset("-05:00")
          .format("DD-MM-YYYY") :
        formatMessage({ id: 'shared:n_a' })
    }
  }

  const download = () => {
    setHide(true)
    setTimeout(() => {
      window.print()
      setHide(false)
    }, 300);
  }

  const FirstPage = () => {
    return (
      <div className={styles.first_page}>
        <FrontLogo className={styles.front_logo} />
        <h2 className={styles.first_page_text}>
          <FormattedMessage id="pdf:candidate_report" />
        </h2>
        <Documentstack className={styles.document_stack} />
      </div>
    )
  }

  const StandardForms = ({ form, }) => {
    return (
      <div className={styles.standard_page}>
        <h2 className={styles.form_name}>
          {formatMessage({ id: "standard_form:title" })}
        </h2>
        <table className={styles.pdf_container}>
          {form.firstSection.map(
            /*
            * The first field will always be set 
            * for the current row
            */
            ([[label, value], secondQuestion]) => (
              <tr>
                {/* <td>{label}</td> */}
                <td>{formatMessage({ id: label })}</td>
                <td>{value || formatMessage({ id: "shared:n_a" })}</td>
                {/* <td>{secondQuestion && secondQuestion[0]}</td> */}
                <td>{secondQuestion && formatMessage({ id: secondQuestion[0] })}</td>
                <td>{secondQuestion && (secondQuestion[1] || formatMessage({ id: "shared:n_a" }))}</td>
              </tr>
            )
          )}
          <tr >
            <td colSpan={4}>
              <div className={styles.standard_content}>
                <div className={styles.legends}>
                  <div>
                    <span className={styles.bold}>
                      {formatMessage({ id: "standard_form:excellent" })}:
                    </span>
                    <span> {formatMessage({ id: "standard_form:excellent_desc" })} </span>
                  </div>
                  <div>
                    <span className={styles.bold}>
                      {formatMessage({ id: "standard_form:very_good" })}:
                    </span>
                    <span> {formatMessage({ id: "standard_form:very_good_desc" })} </span>
                  </div>
                  <div>
                    <span className={styles.bold}>
                      {formatMessage({ id: "standard_form:good" })}:
                    </span>
                    <span> {formatMessage({ id: "standard_form:good_desc" })} </span>
                  </div>
                  <div>
                    <span className={styles.bold}>
                      {formatMessage({ id: "standard_form:fair" })}:
                    </span>
                    <span> {formatMessage({ id: "standard_form:fair_desc" })} </span>
                  </div>
                  <div>
                    <span className={styles.bold}>
                      {formatMessage({ id: "standard_form:low" })}:
                    </span>
                    <span>  {formatMessage({ id: "standard_form:low_desc" })} </span>
                  </div>
                </div>
                <table
                  className={clsx(
                    styles.pdf_container,
                    styles.inner_table
                  )}
                >
                  <tr>
                    <td colSpan={4}> {formatMessage({ id: "standard_form:evaluation_criteria" })}</td>
                    <td> {formatMessage({ id: "standard_form:excellent" })} </td>
                    <td> {formatMessage({ id: "standard_form:very_good" })} </td>
                    <td> {formatMessage({ id: "standard_form:good" })} </td>
                    <td> {formatMessage({ id: "standard_form:fair" })} </td>
                    <td> {formatMessage({ id: "standard_form:low" })} </td>
                  </tr>
                  {
                    form.secondSection.map(
                      ([question, fieldValue]) => (
                        <tr>
                          <td colSpan={4}>{formatMessage({ id: question })}</td>
                          {/* <td colSpan={4}>{question}</td> */}
                          {[1, 2, 3, 4, 5].map(idx => (
                            <td className={styles.center_cell}>
                              {idx === fieldValue && (
                                <CheckedIcon className={styles.checked} />
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                </table>
              </div>
            </td>
          </tr>
          {form.thirdSection.map(
            ([question, answer]) => (
              <tr>
                <td colSpan={2}>{formatMessage({ id: question })}</td>
                {/* <td colSpan={2}>{question}</td> */}
                <td colSpan={2}>
                  {answer || formatMessage({ id: 'shared:n_a' })}
                </td>
              </tr>
            ))}
          <tr>
            <td>{formatMessage({ id: "shared:technician" })}</td>
            <td>{form.interviewer || formatMessage({ id: "shared:n_a" })}</td>
            <td>{formatMessage({ id: "shared:date" })}</td>
            <td>{form.update_at}</td>
          </tr>
        </table>
      </div>
    )
  }

  const NormalForm = ({ form }) => {
    return (
      <div
        className={clsx(styles.grid_column, styles.section)}
      >
        <span
          className={styles.title}
        >
          <h4
            className={styles.form_name}
          >{form.title}</h4>
        </span>
        <div
          className={clsx(styles.grid_column, styles.cat_section)}
        >
          {form.categories.map(
            ({ title, description, fields }) => (
              <>
                <h2
                  className={clsx(styles.bold, styles.cat_name)}
                >{title}</h2>
                <span>{description}</span>
                <table
                  className={styles.pdf_container}
                >
                  {
                    fields.map(([name, value]) => (
                      <tr
                        className={styles.tr}
                      >
                        {/* <td>{name}</td> */}
                        <td>{formatMessage({ id: name })}</td>
                        <td>{`${value || formatMessage({ id: "shared:n_a" })}`}</td>
                      </tr>
                    ))
                  }
                </table>
              </>
            ))}
        </div>
      </div>
    )
  }

  return (
    <div
      className={
        clsx(styles.container, styles.grid_column)
      }
    >
      <>
        {Object.keys(generalInfo).length === 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '200px',
            }}
          >
            <Spinner size={spinnerSize.lg} />
          </div>
        ) : (
            <>
              <FirstPage />
              <div
                className={styles.header}
              >
                <LogoPrint
                  className={styles.logo}
                />
                <div
                  className={styles.header_content}
                >
                  <span>
                    PRÉ EMPLOI DM inc.
                  </span>
                  <span>
                    200-450 avenue saint-jean baptiste, Québec, Qc , G2E6H5  418-704-2222
                  </span>
                  <span>
                    Courriel: demandes@pre-emploidm.com
                  </span>
                </div>
              </div>
              <hr
                className={styles.underline}
              />
              <div
                className={clsx(styles.grid_column, styles.section)}
              >
                <span
                  className={styles.title}
                >
                  <h4
                    className={styles.form_name}
                  >{generalInfo.title}</h4>
                </span>
                <div
                  className={styles.grid_column}
                >
                  {generalInfo.data.map(([name, value]) => (
                    <div
                      className={styles.grid_row}
                    >
                      <span
                        className={styles.bold}
                      >{name}&nbsp;&nbsp;</span>
                      <span>{value || formatMessage({ id: "shared:n_a" })}</span>
                    </div>
                  ))}
                </div>
              </div>
              {data.map(
                form => (form.is_standard && !(form.editable === true)) ? (
                  <StandardForms form={form} />
                ) : (
                    <NormalForm form={form} />
                  ))
              }
              {!hide && (
                <>
                  <ReorderPdf
                    data={forms}
                    onClose={reorderedData => setReorderedForms(reorderedData)}
                    renderTrigger={trigger => (
                      <Button
                        variant="secondary"
                        onClick={trigger}
                        className={styles.btn_reorder}
                        isDisabled={forms.length < 2}
                      >
                        <ArrowsAltIcon />
                        <FormattedMessage id="shared:reorder" />
                      </Button>
                    )}
                  />
                  <Button
                    variant="secondary"
                    onClick={download}
                    className={styles.btn_download}
                  >
                    <DownloadIcon />
                    <FormattedMessage id="shared:download_pdf" />
                  </Button>
                </>
              )}
            </>
          )}
        {Boolean(error) && (<ListEmpty />)}
      </>
    </div>
  )
}

export {
  PdfPageComponent as PdfPagePage,
}
