import React, { useState, useEffect } from 'react';
import styles from './NotificationRequest.module.scss'
import { Modal } from '@patternfly/react-core';
import { Accordion, AccordionItem, AccordionContent, AccordionToggle } from '@patternfly/react-core';
import {
    Table,
    TableHeader,
    TableBody,
    TableVariant,
} from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

export interface NotificationRequestProps {
    readonly clickedId: string;
    readonly setisKebabDropdownOpen?: any;
    readonly notifications: any;
    readonly isOpen: boolean;
    readonly onClose: any;
    readonly onReadNotification: any;
}

export const NotificationRequest: React.FC<NotificationRequestProps> = ({
    clickedId, setisKebabDropdownOpen,
    notifications, isOpen, onClose,
    onReadNotification,
    ...props
}) => {

    const [expanded, setExpanded] = useState("ex-toggle1")
    const [toggleModal, setToggleModal] = useState(isOpen)
    const [requestRows, setRequestRows] = useState<any[]>([])
    const { formatMessage } = useIntl()
    const history = useHistory()

    useEffect(() => {
        if (clickedId == "new_request")
            setToggleModal(true)
        else console.log(clickedId)
    }, [clickedId])

    //listens for changes to isOpen parameter 
    //and update internal open state
    useEffect(() => {
        setToggleModal(isOpen)
    }, [isOpen])

    const onToggle = id => {
        if (id === expanded) {
            setExpanded('');
        } else {
            setExpanded(id);
        }
    }

    const columns = [
        'Candidats',
        'Adresse mail',
        ''
    ]

    useEffect(() => {
        if (!Boolean(notifications)) { return }
        let request_rows = notifications.map(notif => (
            notif.candidates.map(candidate => ({
                cells: [
                    `${candidate.firstname} ${candidate.lastname}`,
                    candidate.email || formatMessage({ id: "shared:n_a" }),
                    {
                        title:
                            <>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        history.push('/me/candidates')
                                        setTimeout(() => {
                                            onCloseModal()
                                            onReadNotification(notif)
                                            history.push(`/me/candidates/${candidate.id}`)
                                        }, 100)
                                    }}
                                >
                                    <FormattedMessage id="notification:show" />
                                </span>
                            </>
                    }
                ]
            }))
        ))

        setRequestRows(request_rows)
    }, [notifications])

    const onCloseModal = () => {
        setToggleModal(false)
        onClose()
        if (setisKebabDropdownOpen)
            setisKebabDropdownOpen(false)
    }

    return (
        <Modal
            name=""
            className={styles.modal}
            title="Notifications"
            isOpen={toggleModal}
            onClose={onCloseModal}
        >
            <span className={styles.notif_title}>
                <FormattedMessage id="notification:new_request_verification" />
            </span>

            {notifications.map((notification, idx) => (
                <Accordion asDefinitionList >
                    <AccordionItem>
                        <AccordionToggle
                            onClick={() => { onToggle(notification.id) }}
                            isExpanded={expanded === notification.id}
                            id={notification.id}
                            className={styles.accordion_toggle}
                        >
                            <FormattedMessage id="notification:requested_by" />&nbsp;
                            {`${notification.initiated_by.firstname} ${notification.initiated_by.lastname}`}
                        </AccordionToggle>

                        <p>
                            {notification.candidates.length}&nbsp;
                            <FormattedMessage id="notification:candidates" />
                        </p>

                        <AccordionContent
                            id={notification.id}
                            isHidden={expanded !== notification.id}
                        >

                            <Table
                                borders={false}
                                variant={TableVariant.compact}
                                aria-label="Simple Table"
                                cells={columns}
                                rows={requestRows[idx]}
                            >
                                <TableHeader />
                                <TableBody />
                            </Table>

                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            ))}

        </Modal>
    );
}
