import React, { useState, useEffect } from 'react';
import styles from './NewUser.module.scss'
import {
  Modal, Button, TextInput,
  Select, SelectOption, SelectVariant,
  Form, Checkbox
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import { CloseIcon } from '@patternfly/react-icons';
import clsx from 'classnames'
// import DayPicker from 'react-day-picker';
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
// import 'react-day-picker/lib/style.css';
import { useUserRoleData, useUserDataH, useFetchCompanies, useNotificationData, useRoleData, useUserInfoData } from 'hooks';
import { UserService } from 'services';
import { NotificationManager } from 'components'
import { maskNumber } from 'utilities';
import { ILoginInfo } from 'stores/auth.store';

export interface NewUserProps {
  readonly renderTrigger?: any;
  userToUpdate?: any;
  setUserToUpdate?: any
  onCreate?: any
  onDone?: any
}

export interface IUser {
  username: string,
  company_id: string,
  email: string,
  family_name: string,
  lastname: string,
  phone: string,
  send_email: boolean,
  post_number: string,
  password?: string,
  confirm_password?: string,
  fax: string,
  role: string[],
}

const validationSchema = yup.object().shape({
  username: yup.string().required('shared:username_required'),
  company_id: yup.string().required('shared:company_required'),
  phone: yup.string()
    .max(20, "shared:phonenumber_too_long")
    .matches(
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
      "shared:invalid_phonenumber"
    ).required('shared:phone_required'),
  post_number: yup.string()
    .when(['phone'], {
      is: phone => phone,
      then: yup.string()
      // .required('shared:post_number_required')
    }),

  email: yup.string().email("shared:invalid_email").required("shared:email_required"),
  family_name: yup.string().required('shared:firstname_required'),
  role: yup.array().required('shared:role_required'),
})

const validationSchemaNewUser = yup.object().shape({
  username: yup.string().required('shared:username_required'),
  company_id: yup.string().required('shared:company_required'),
  phone: yup.string()
    .max(20, "shared:phonenumber_too_long")
    .matches(
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
      "shared:invalid_phonenumber"
    ).required('shared:phone_required'),
  post_number: yup.string()
    .when(['phone'], {
      is: phone => phone,
      then: yup.string()
      // .required('shared:post_number_required')
    }),
  email: yup.string().email("shared:invalid_email").required("shared:email_required"),
  family_name: yup.string().required('shared:firstname_required'),
  role: yup.array().required('shared:role_required'),
  password: yup.string(),
  confirm_password: yup.string()
    .when(['password'], {
      is: password => password,
      then: yup.string()
        .oneOf([yup.ref('password'), null], 'shared:does_not_match')
        .required('shared:password_required')
    }),
})

export const NewUser: React.FC<NewUserProps> = ({
  renderTrigger, userToUpdate, setUserToUpdate,
  onCreate, onDone,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(userToUpdate ?? false)
  const { formatMessage } = useIntl()
  const [isOpenCompany, setisOpenCompany] = useState<boolean>(false)
  const [isOpenRole, setisOpenRole] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { new_notification, } = useNotificationData()
  const { roles } = useRoleData()
  const { companies } = useFetchCompanies()
  const { appendUsers, updateUser } = useUserDataH()
  const { userInfo } = useUserInfoData()

  // Get old role of the user in case of update
  let role: string[] = []
  let company = '';
  if (userToUpdate) {
    if (userToUpdate.is_admin) {
      role.push(formatMessage({ id: "administrateur" }))
    }
    if (userToUpdate.is_technicien) {
      role.push(formatMessage({ id: "technicien" }))
    }
    if (userToUpdate.is_contact) {
      role.push(formatMessage({ id: "contact" }))
    }
    if (userToUpdate.is_accountant) {
      role.push(formatMessage({ id: "accountant" }))
    }
  }

  const saveMemberToApi = e => {
    let post_data: any = {
      username: values.username,
      email: values.email,
      role: roles.filter(
        _ => (
          values.role
            // .map(
            //   it => formatMessage({ id: it })
            // )
            .includes(
              formatMessage({ id: _.name })
            )
        ))
        .map(_ => _.name),
      firstName: values.family_name,
      lastName: values.lastname,
      phone: values.phone,
      send_email: values.send_email,
      post_number: values.post_number,
      //@ts-ignore
      company_id: userInfo.is_manager ?
        //@ts-ignore
        userInfo.company_id
        : companies.find(d => d.name === values.company_id)!.id,
      user_id: userToUpdate ? userToUpdate.id : "",
      fax: values.fax,
    }

    if (Boolean(values.password))
      post_data = { ...post_data, password: values.password }

    setLoading(true)

    userToUpdate ?
      UserService.update_user(post_data)
        .catch(err => {
          setLoading(false)
          new_notification({
            message: formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        })
        .then(async response => {
          setLoading(false)
          if ([200, 201].includes(response.status)) {
            const { data, message } = await response.json()
            setIsModalOpen(false)
            // updateUser(data)
            if (onDone) {
              onDone(data)
            }

            new_notification({
              message,
              variant: 'success',
              title: formatMessage({ id: 'shared:success' }),
            })

            if (Boolean(onCreate)) {
              onCreate(data)
            }

            resetForm()
            setIsModalOpen(false)
            setUserToUpdate(null)
          } else if (response.json) {
            const { message } = await response.json()
            new_notification({
              message,
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          } else {
            new_notification({
              message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          }
        })
      : UserService.new_user(post_data)
        .catch(err => {
          setLoading(false)
          new_notification({
            message: formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        })
        .then(async response => {
          setLoading(false)
          if ([200, 201].includes(response.status)) {
            const { data, message } = await response.json()
            setIsModalOpen(false)
            if (Boolean(onCreate)) {
              onCreate(data)
            }
            if (Boolean(onDone)) {
              onDone(data)
            }
            new_notification({
              message,
              variant: 'success',
              title: formatMessage({ id: 'shared:success' }),
            })
            resetForm()
            setIsModalOpen(false)
          } else if (response.json) {
            const { message } = await response.json()
            new_notification({
              message,
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          } else {
            new_notification({
              message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          }
        })
  }

  const initialValues = userToUpdate ? {
    username: userToUpdate.username,
    company_id: company,
    email: userToUpdate.email,
    family_name: userToUpdate.firstName,
    send_email: userToUpdate.send_email,
    lastname: userToUpdate.lastName,
    phone: userToUpdate.phone ? userToUpdate.phone : '',
    post_number: userToUpdate.post_number ? userToUpdate.post_number : '',
    fax: userToUpdate.fax ? userToUpdate.fax : '',
    role: role,
  } : {
      username: '',
      company_id: (userInfo as ILoginInfo).is_manager ? (userInfo as ILoginInfo).company_id : '',
      email: '',
      family_name: '',
      send_email: false,
      lastname: '',
      phone: '',
      password: '',
      confirm_password: '',
      post_number: '',
      fax: '',
      role: (userInfo as ILoginInfo).is_manager ? [formatMessage({id:'contact'})] : [],
    }

  // for form processing
  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    errors,
    handleSubmit,
    resetForm,
  } = useFormik<IUser>({
    validationSchema: userToUpdate ? validationSchema : validationSchemaNewUser,
    onSubmit: saveMemberToApi,
    enableReinitialize: true,
    initialValues: initialValues
  })

  // Selected old user's company in case of update
  useEffect(() => {
    if (userToUpdate && userToUpdate.company_id) {
      let old_company = companies.find(d => d.id === userToUpdate.company_id)
      if (old_company)
        company = old_company.name
      onCompanySelect(null, company);
    }
  }, [userToUpdate, companies])


  // Open/Close modal
  const handleModalToggle = () => {
    if (isModalOpen && userToUpdate) setUserToUpdate(null)
    setIsModalOpen(!isModalOpen)
  };

  const handleCancel = e => {
    handleModalToggle();
    values.username = ''
    values.company_id = ''
    values.email = ''
    values.family_name = ''
    values.lastname = ''
    values.phone = ''
    values.post_number = ''
    values.fax = ''
    values.send_email = false
    values.role = []
    resetForm();
    if (userToUpdate)
      setUserToUpdate(null)
  }

  // Which company is selected
  const onCompanySelect = (_, selection) => {
    setisOpenCompany(false)
    setFieldValue('company_id', selection)
  }

  // Expand/Close company field
  const handleSelectCompanyToggle = isOpen => setisOpenCompany(isOpen);

  // For company field
  const options_companies = companies.map(({ id, name }) => (
    <SelectOption key={id} value={name} name={name} />
  ))

  options_companies.unshift(
    <SelectOption
      isDisabled={true}
      value={formatMessage({ id: 'shared:select' })}
    />
  )

  // Which role is selected
  const handleSelectRoleToggle = is_expanded => setisOpenRole(is_expanded)

  const onRoleSelect = (_, selection) => {
    let roles: any = values.role || []

    if (roles.includes(selection)) {
      roles = roles.filter(r => r !== selection)
    } else {
      roles = [...roles, selection]
    }

    setFieldValue('role', roles)
  }

  const clearSelection = field => () => {
    setFieldValue(field, [])
    // setIsExapandedProvince(false)
  }

  // Options of role select field
  // const roles_options=[]
  const roles_options = roles
    .filter(_ => _.name !== 'administrateur')
    .map(({ id, name }) => (
      <SelectOption
        key={id}
        value={formatMessage({ id: name })}
        name={formatMessage({ id: name })}
      />
    ))

  roles_options.unshift(
    <SelectOption
      isPlaceholder={true}
      key={'roles'}
      value={formatMessage({ id: 'shared:select' })}
      name={'roles'}
      isDisabled={true}
    />
  )

  return (
    <>
      {renderTrigger ? renderTrigger(handleModalToggle) : ''}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            <FormattedMessage
              id={userToUpdate ? "user:update_user" : "user:add_new_user"}
            />
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        //isFooterLeftAligned
        className={styles.modal_root}
      >
        <Form
          className={styles.content}
          onSubmit={handleSubmit}
        >

          {!(userInfo as ILoginInfo).is_manager && (
            <label
              htmlFor="role"
              className={styles.field}
            >
              <span
                className={errors.role && touched.role ? styles.c_error : ''}
              >
                <FormattedMessage
                  //@ts-ignore
                  id={errors.role && touched.role ? errors.role : "shared:role"}
                />
                <span className={styles.c_error}>*</span>
              </span>
              <Select
                variant={SelectVariant.typeaheadMulti}
                onToggle={handleSelectRoleToggle}
                name="role"
                onClear={clearSelection("role")}
                onSelect={onRoleSelect}
                onBlur={handleBlur}
                selections={values.role}
                isOpen={isOpenRole}
              >
                {roles_options}
              </Select>
            </label>
          )}
          <label className={styles.field}>
            <span
              className={errors.username && touched.username ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.username && touched.username ? errors.username : "shared:username"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name='username'
              value={values.username}
              type="text"
              onChange={text => setFieldValue('username', text)}
              onBlur={handleBlur}
              aria-label="text input example"
              isDisabled={userToUpdate ? true : false}
            />
          </label>
          {!(userInfo as ILoginInfo).is_manager && (
            <label
              htmlFor="company_id"
              className={styles.field}
            >
              <span
                className={errors.company_id && touched.company_id ? styles.c_error : ''}
              >
                <FormattedMessage
                  id={errors.company_id && touched.company_id ? errors.company_id : "shared:company"}
                />
                <span className={styles.c_error}>*</span>
              </span>
              <Select
                variant={SelectVariant.typeahead}
                onToggle={handleSelectCompanyToggle}
                onSelect={onCompanySelect}
                selections={values.company_id}
                isOpen={isOpenCompany}
                name="company_id"
                onClear={clearSelection("company_id")}
              >
                {options_companies}
              </Select>
            </label>
          )}
          <label className={styles.field}>
            <span
              className={errors.email && touched.email ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.email && touched.email ? errors.email : "shared:email"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'email'}
              value={values.email}
              onChange={text => setFieldValue('email', text)}
              onBlur={handleBlur}
              type="text"
              isDisabled={userToUpdate ? true : false}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.family_name && touched.family_name ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.family_name && touched.family_name ? errors.family_name : "shared:family_name"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'family_name'}
              value={values.family_name}
              onChange={text => setFieldValue('family_name', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:lastname" />
            </span>
            <TextInput
              name={'lastname'}
              value={values.lastname}
              onChange={text => setFieldValue('lastname', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:fax" />
            </span>
            <TextInput
              name={'fax'}
              value={values.fax}
              onChange={text => setFieldValue('fax', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label htmlFor="phone" className={styles.field}>
            <span
              className={(errors.phone || errors.post_number) && touched.phone ? styles.c_error : ''}
            >
              <FormattedMessage
                id={(errors.phone || errors.post_number) && touched.phone ? (errors.phone || errors.post_number) : "shared:tel_and_post_number"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <div className="telephone_field">
              <TextInput
                name={'phone'}
                value={values.phone}
                onChange={text => setFieldValue('phone', text)}
                onBlur={e => {
                  handleBlur(e)
                  setFieldValue(
                    'phone',
                    maskNumber(values.phone)
                  )
                }}
                type="text"
                id="phone"
                //css={{}}
                aria-label="text input example"
              />
              {Boolean(values.phone) && (
                <TextInput
                  name={'post_number'}
                  id={'post_number'}
                  value={values.post_number}
                  onChange={text => setFieldValue('post_number', text)}
                  onBlur={handleBlur}
                  type="number"
                  placeholder={formatMessage({ id: "shared:post_number" })}
                  //css={{}}
                  aria-label="text input example"
                />
              )}
            </div>
          </label>
          {!Boolean(userToUpdate) && (
            <>
              <label className={styles.field}>
                <span
                  className={errors.password && touched.password ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.password && touched.password ? errors.password : "shared:password"}
                  />
                </span>
                <TextInput
                  name={'password'}
                  value={values.password}
                  onChange={text => setFieldValue('password', text)}
                  onBlur={handleBlur}
                  type="password"
                  //css={{}}
                  aria-label="text input example"
                />
              </label>
              <label className={styles.field}>
                <span
                  className={errors.confirm_password && touched.confirm_password ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.confirm_password && touched.confirm_password ? errors.confirm_password : "shared:confirm_password"}
                  />
                </span>
                <TextInput
                  name={'confirm_password'}
                  value={values.confirm_password}
                  onChange={text => setFieldValue('confirm_password', text)}
                  onBlur={handleBlur}
                  type="password"
                  //css={{}}
                  aria-label="text input example"
                />
              </label>
            </>
          )}
          <label className={styles.field}>
            <Checkbox
              id="c"
              label={formatMessage({ id: errors.send_email && touched.send_email ? errors.send_email : "shared:send_email" })}
              isChecked={values.send_email}
              onChange={_ => setFieldValue('send_email', _)}
              aria-label="controlled checkbox example"
              name={'send_email'}
              onBlur={handleBlur}
              className={errors.send_email && touched.send_email ? styles.c_error : ''}
            />
          </label>

          {/* <NotificationManager /> */}
          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={handleCancel}
              type="button"
            >
              <FormattedMessage id="shared:cancel" />
            </button>
            <Button
              disabled={loading}
              type="submit"
              isDisabled={loading || Object.keys(errors).length > 0}
            >
              <FormattedMessage id={userToUpdate ? "shared:save" : "user:create_user"} />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
