// import { FieldType } from "./example";

export interface FieldType {
  name: string;
  id?: string;
  data: {
    type: "text" | 'number' | 'date' | 'select';
    halfWidth?: boolean; //Pour afficher deux champs dans la meme ligne
    required: boolean;
    multiline: boolean;
    rows: number;
    switch?: boolean,
    select?: boolean;
    is_select?: boolean;
  },
  title: string;
  // label: string;
  answer?: { name: string, value: string }[]
}

interface StandardFormCategory {
  title: string,
  description: string,
  questions: FieldType[],
}

export const standard_form_categories: StandardFormCategory[] = [
  {
    title: "form:base_information",
    description: "les informations de base du candidats concernant le formulaire standard",
    questions: [
      {
        name: "previous_role",
        id: "previous_role",
        answer: [],
        data: {
          type: "text",
          rows: 1,
          required: false,
          multiline: false,
          is_select: true,
        },
        title: "standard_form:previous_function",
        // label: "Fonction précédente",
      },
      {
        name: "reference_name",
        id: "reference_name",
        answer: [],
        data: {
          type: "text",
          rows: 1,
          required: false,
          multiline: false,
          is_select: true,
        },
        title: "standard_form:ref_name",
      },
      {
        name: "title_reference",
        id: "title_reference",
        answer: [],
        data: {
          type: "text",
          rows: 1,
          required: false,
          multiline: false,
          is_select: true,
        },
        title: "standard_form:ref_title",
        // label: "Titre de la référence",
      },
      {
        name: "telephone_1",
        id: "telephone_1",
        answer: [],
        data: {
          type: "text",
          multiline: false,
          rows: 1,
          required: false,
          is_select: true
        },
        title: "standard_form:ref_phone",
        // label: "Téléphone de la référence 1",
      },
      {
        name: "post_number_telephone_1",
        id: "post_number_telephone_1",
        answer: [],
        data: {
          type: "number",
          multiline: false,
          rows: 1,
          required: false,
          is_select: true
        },
        title: "standard_form:ref_phone_post_number",
        // label: "Téléphone de la référence 1",
      },
      {
        name: "quitting_motif",
        id: "quitting_motif",
        answer: [],
        data: {
          type: "text",
          multiline: false,
          rows: 1,
          required: false,
          select: false
        },
        title: "standard_form:quitting_motif",
        // label: "Quel est le motif de son départ ?",
      },
      // {
      //   name: "salary",
      //   id: "salary",
      //   answer: [],
      //   data: {
      //     type: "text",
      //     rows: 1,
      //     required: false,
      //     is_select: true,
      //     multiline: false,
      //   },
      //   title: "Quel salaire gagnait-il?",
      // },
      {
        name: "supervision_period",
        id: "supervision_period",
        answer: [],
        data: {
          type: "text",
          rows: 1,
          required: false,
          multiline: false,
          is_select: true,
        },
        title: "standard_form:supervision_period",
        // label: "Période de supervision",
      },
      {
        name: "company_name",
        id: "company_name",
        answer: [],
        data: {
          type: "text",
          rows: 1,
          required: false,
          multiline: false,
          select: false,
        },
        title: "standard_form:company_name",
        // label: "Nom de la compagnie",
      },
      {
        name: "job_duration",
        id: "job_duration",
        answer: [],
        data: {
          type: "text",
          rows: 1,
          required: false,
          multiline: false,
          select: false,
        },
        title: "standard_form:job_duration",
        // label: "Durée de l'emploi",
      },
    ]
  },
  {
    title: 'shared:candidate_evaluation',
    description: '',
    questions: [
      {
        name: "output_value",
        id: "output_value",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:output_value",
        // label: "Rendement",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "autonomy",
        id: "autonomy",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:autonomy",
        // label: "Autonomie",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "ponctuality_and_assiduity",
        id: "ponctuality_and_assiduity",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:ponctuality_and_assiduity",
        // label: "Ponctualité / Assiduité",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "work_ethics",
        id: "work_ethics",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:work_ethics",
        // label: "Attitude au travail",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "is_enterprising",
        id: "is_enterprising",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:is_enterprising",
        // label: "Diriez-vous qu'il est capable d'initiative ?",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "can_handle_work_under_pressure",
        id: "can_handle_work_under_pressure",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:can_handle_work_under_pressure",
        // label: "Aptitude à travailler sous pression",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "interpersonal_relationship_with_friends",
        id: "interpersonal_relationship_with_friends",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:interpersonal_relationship_with_friends",
        // label: "Relations interpersonnelles avec ses collègues",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "interpersonal_relation_with_management",
        id: "interpersonal_relation_with_management",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:interpersonal_relation_with_management",
        // label: "Relations interpersonnelles avec la direction",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "is_receptive_to_critics",
        id: "is_receptive_to_critics",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:is_receptive_to_critics",
        // label: "Ouverture à la critique",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "team_spirit",
        id: "team_spirit",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:team_spirit",
        // label: "Esprit d'équipe",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "communication",
        id: "communication",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "Communication",
        // label: "Communication",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "leadership",
        id: "leadership",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:leadership",
        // label: "Leadership",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "professional_competences",
        id: "professional_competences",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:professional_competences",
        // label: "Compétences professionnelles",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "ability_of_management",
        id: "ability_of_management",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:ability_of_management",
        // label: "Sens de l'organisation",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
      {
        name: "ability_of_responsibility",
        id: "ability_of_responsibility",
        data: {
          type: "select",
          multiline: false,
          rows: 1,
          required: false,
          switch: false,
          is_select: true,
        },
        title: "standard_form:ability_of_responsibility",
        // label: "Sens des responsabilités",
        answer: [
          {
            name: 'standard_form:excellent',
            value: 'standard_form:excellent',
          },
          {
            name: 'standard_form:very_good',
            value: 'standard_form:very_good',
          },
          {
            name: 'standard_form:good',
            value: 'standard_form:good',
          },
          {
            name: 'standard_form:fair',
            value: 'standard_form:fair',
          },
          {
            name: 'standard_form:low',
            value: 'standard_form:low',
          }
        ],
      },
    ]
  },
  {
    title: 'shared:professional_competences',
    description: '',
    questions: [
      {
        name: "disciplinary_sanctions",
        id: "disciplinary_sanctions",
        answer: [
          {
            name: 'shared:yes',
            value: 'shared:yes',
          },
          {
            name: 'shared:false',
            value: 'shared:false',
          },
        ],
        data: {
          type: "select",
          is_select: true,
          select: true,
          multiline: true,
          rows: 2,
          required: false,
          switch: false,
          halfWidth: false,
        },
        title: "standard_form:disciplinary_sanctions",
      },
      {
        name: "pros",
        id: "pros",
        answer: [],
        data: {
          type: "text",
          multiline: true,
          rows: 2,
          required: false,
          select: false,
          switch: false,
          halfWidth: false,
        },
        title: "standard_form:pros",
        // label: "Quels sont ses points forts ?",
      },
      {
        name: "cons",
        id: "cons",
        answer: [],
        data: {
          type: "text",
          multiline: true,
          rows: 2,
          required: false,
          select: false,
          switch: false,
          halfWidth: false,
        },
        title: "standard_form:cons",
        // label: "Quels sont ses points à améliorer ?",
      },
      {
        name: "will_rehire",
        id: "will_rehire",
        answer: [
          {
            name: 'shared:yes',
            value: 'shared:yes',
          },
          {
            name: 'shared:false',
            value: 'shared:false',
          },
        ],
        data: {
          type: "select",
          is_select: true,
          select: true,
          multiline: true,
          rows: 2,
          required: false,
          switch: false,
          halfWidth: false,
        },
        title: "standard_form:will_rehire",
        // label: "Est-ce que vous ré-embaucheriez ce candidat ?",
      },
      {
        name: "do_you_recommend",
        id: "do_you_recommend",
        answer: [
          {
            name: 'shared:yes',
            value: 'shared:yes',
          },
          {
            name: 'shared:false',
            value: 'shared:false',
          },
        ],
        data: {
          type: "select",
          is_select: true,
          select: true,
          multiline: true,
          rows: 2,
          required: false,
          switch: false,
          halfWidth: false,
        },
        title: "standard_form:do_you_recommend",
        // label: "Est-ce que vous nous le recommandez ?",
      },
      {
        name: "comments",
        id: "comments",
        answer: [],
        data: {
          type: "text",
          multiline: true,
          rows: 2,
          required: false,
          select: false,
          switch: false,
          halfWidth: false,
        },
        title: "standard_form:comments",
        // label: "Commentaires",
      },
    ]
  }
]