import React, { useState } from 'react';
import styles from './AppInfo.module.scss'
import { Modal, Button, Spinner, spinnerSize } from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'

export interface AppInfoProps {
  renderTrigger: any

}

export const AppInfo: React.FC<AppInfoProps> = ({
  renderTrigger,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const app_version = process.env.NODE_ENV || 'process.env.REACT_APP_APP_ENV'
  const build_version = process.env.REACT_APP_TEST || 'process.env.REACT_APP_BUILD_NUMBER'
  console.log({ env: process.env })

  // const app_version = process.env.REACT_APP_APP_ENV || 'process.env.REACT_APP_APP_ENV'
  // const build_version = process.env.REACT_APP_BUILD_NUMBER || 'process.env.REACT_APP_BUILD_NUMBER'

  return (
    <>
      {renderTrigger(() => setOpen(true))}
      <Modal
        //@ts-ignore
        title={formatMessage({ id: "shared:about_application" })}
        isOpen={open}
        onClose={() => setOpen(false)}
        //isFooterLeftAligned={false}
        className={styles.modal_root}
        width={'50%'}
        actions={[
          <Button
            key="confirm"
            className={styles.btn_action}
            variant="primary"
            onClick={() => setOpen(false)}
          >
            <FormattedMessage id="shared:ok" />
          </Button>,
        ]}
      >
        <div className={styles.container}>
          <span>
            <FormattedMessage id="shared:app_env" /> &nbsp;
            {app_version}
          </span>
          <span>
            <FormattedMessage id="shared:build_version" /> &nbsp;
            {build_version}
          </span>
        </div>
      </Modal>
    </>
  );
}
