import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { ICandidate, CandidateStore } from 'stores'
import { takeUntil } from 'rxjs/operators'
import { CandidateService } from 'services'
import { shuffle } from 'gsap'
import { useNotificationData } from 'hooks'
import { useIntl } from 'react-intl'



export function useCandidateData(candidate_id) {
    const { formatMessage } = useIntl()
    const {
        updateCandidate
    } = CandidateStore
    const [loading, setLoading] = useState<boolean>(false)
    const [fetchError, setFetchError] = useState<boolean>(false)
    const [candidate, setCandidate] = useState<ICandidate>()

    const { new_notification } = useNotificationData()

    useEffect(() => {
        if (!Boolean(candidate_id)) { return }
        fetchCandidate(candidate_id)
    }, [candidate_id])

    const fetchCandidate = (candidate_id) => {
        setLoading(true)
        CandidateService.candidate(candidate_id)
            .catch(err => {
                new_notification({
                    title: formatMessage({ id: 'shared:error' }),
                    variant: 'error',
                    message: formatMessage({ id: 'shared:err_get_candidate' }),
                })
                setLoading(false)
                setFetchError(true)
            })
            .then(async response => {
                if ([200, 201].includes(response.status)) {

                    const payload = await response.json()

                    setCandidate(payload)
                    updateCandidate(payload)
                    setLoading(false)
                    console.log({ candidatemm: candidate })

                } else if ([404, 401, 500].includes(response.status)) {
                    const { message } = await response.json()
                    new_notification({
                        title: formatMessage({ id: 'shared:error' }),
                        message,
                        variant: 'error',
                    })
                    setLoading(false)
                    setFetchError(true)
                } else {
                    new_notification({
                        title: formatMessage({ id: 'shared:error' }),
                        message: formatMessage({ id: 'shared:err_get_candidate' }),
                        variant: 'error',
                    })
                    setLoading(false)
                    setFetchError(true)
                }
            })

    }

    return { loading, candidate }

}