import { ObservableStore } from '@codewithdan/observable-store';
import {
    map,
    skip,
    filter,
    debounceTime
} from 'rxjs/operators'
import { timer } from 'rxjs';


class NotificationStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        INIT_STATE: 'INIT_STATE',
        NEW_NOTIFICATION: 'NEW_NOTIFICATION',
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }


    //#region notifications
    new_notification = (notif: INotification | undefined) => {
        this.setState(
            {
                notification: notif
            },
            NotificationStore.ACTIONS.NEW_NOTIFICATION,
        )
    }

    /**
     * *********getter for everything notification data.***********
     */
    get notification$() {
        return this.stateChanged.pipe(
            skip(1),
            map(({ notification: notificaiton }) => notificaiton),
            filter(x => Boolean(x)),
            debounceTime(700)
        )
    }
    //#endregion crumbs and accordion

    logout = () => {
        this.setState({
            notification: undefined,
        }, NotificationStore.ACTIONS.INIT_STATE)

    }

}

export default new NotificationStore()



export interface INotification {
    message: string;
    title: string;
    variant: 'warning' | 'error' | 'default' | 'success';
    duration?: number;
}



export interface StoreState {
    notification?: INotification,
}