
import BaseService from './BaseService'
import { questionsUrl } from './urls'

class QuestionService {
    static new_category_question = (data) => BaseService.postRequest(
        questionsUrl.NEW_QUESTION, data, true
    );
    static reorder_questions = (category_id, data) => BaseService.putRequest(
        questionsUrl.REORDER_QUESTIONS(category_id), data, true
    );
    static update_question = (id, data) => BaseService.putRequest(
        questionsUrl.UPDATE_QUESTION(id), data, true
    );
    static fetchQuestions = (suffix) => BaseService.getRequest(
        questionsUrl.ALL_QUESTIONS(suffix), true
    );
    static deleteQuestions = (data) => BaseService.deleteRequest(
        questionsUrl.DELETE_QUESTION, data, true
    );
    static addQuestionsToCategories = (data) => BaseService.putRequest(
        questionsUrl.ADD_QUESTION_TO_CATEGORY, data, true
    );
    static removeCategoryFromQuestion = (cat_id, data) => BaseService.putRequest(
        questionsUrl.REMOVE_QUESTION_FROM_CATEGORY(cat_id), data, true
    );

}

export default QuestionService;