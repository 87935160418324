import React, { useState, useEffect } from 'react'
import { UtilitiesService } from 'services'
import { useNotificationData } from 'hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { CompanyStore, ICompany } from 'stores'
import { Subject } from 'rxjs'
import { take, skip } from 'rxjs/operators'

export function useFetchCompanies() {
    const { formatMessage } = useIntl()

    const { resetAllCompanies, all_companies$ } = CompanyStore

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [data, setData] = useState<any>()
    const { new_notification } = useNotificationData()
    const [fetchError, setFetchError] = useState<boolean>(false)
    const trigger = new Subject<boolean>()
    const [companies, setCompanies] = useState<ICompany[]>([])

    useEffect(() => {
        // all_companies$.pipe(
        //     take(1)
        // ).subscribe(
        //     storeCompanies => {
        //         if (!Boolean(storeCompanies)) {
        //             fetchCompanies()
        //             return
        //         }
        //         if (storeCompanies.length === 0 && !loading && !fetchError) {
        //             fetchCompanies()
        //             return
        //         }
        //         setCompanies(storeCompanies)
        //     }
        // )
        fetchCompanies()

        return () => trigger.next(false)
    }, [])

    const fetchCompanies = () => {
        setLoading(true)
        UtilitiesService.list_companies()
            .catch(err => {
                new_notification({
                    title: formatMessage({ id: 'shared:error' }),
                    variant: 'error',
                    message: formatMessage({ id: 'shared:err_get_companies' }),
                })
                setLoading(false)
                setFetchError(true)
            })
            .then(async response => {
                if (response)
                    if ([200, 201].includes(response.status)) {

                        const payload = await response.json()
                        resetAllCompanies(payload.data)
                        setCompanies(payload.data)
                        setLoading(false)

                    } else if ([404, 401, 500].includes(response.status)) {
                        const { message } = await response.json()
                        new_notification({
                            title: formatMessage({ id: 'shared:error' }),
                            message,
                            variant: 'error',
                        })
                        setLoading(false)
                        setFetchError(true)
                    } else {
                        new_notification({
                            title: formatMessage({ id: 'shared:error' }),
                            message: formatMessage({ id: 'shared:err_get_companies' }),
                            variant: 'error',
                        })
                        setLoading(false)
                        setFetchError(true)
                    }
            })

    }


    return {
        loading,
        companies,
        error,
    }
}