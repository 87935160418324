import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './EnterpriseDepartments.module.scss';
import { useAccordionData, useActivatedCompanyData, useUserRoleData, useNotificationData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import clsx from 'classnames'
import {
  Table,
  TableHeader,
  TableBody,
  sortable,
  wrappable,
  TableVariant,
  SortByDirection,
} from '@patternfly/react-table';
import { Button, TextInput, Spinner, spinnerSize, ButtonVariant } from '@patternfly/react-core';
import { ICompanyDepartment } from 'stores';
import { CompanyService, DepartmentService, UserService } from 'services';
import { CloseIcon } from '@patternfly/react-icons';
import { ListEmpty } from 'components';
import { ConfirmDialog, UsersListingDialog, DepartmentNewUser } from 'dialogs';
import { Link } from 'react-router-dom';

export interface EnterpriseDepartmentsProps extends RouteComponentProps {
  readonly is_embeded?: boolean;
}

const EnterpriseDepartmentsComponent: React.FC<EnterpriseDepartmentsProps> = ({
  history, is_embeded,
  ...props
}) => {

  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  let {
    resetDepartments, appendDepartments,
    start: storeStart, limit: storeLimit,
    total: storeTotal, updateDepartment,
    companies: storeCompanies, deleteDepartments,
    appendCompanies,
  } = useActivatedCompanyData()
  const [currentcompany, setCurrentCompany] = useState<any>(storeCompanies.length > 0 ? storeCompanies[0] : undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const centinelRef = useRef<any>(null)
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  let timeout;
  const [rowsBackup, setRowsBackup] = useState<any[]>([])
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [toDelete, setToDelete] = useState<string[]>([])
  const [toBeModifiedDepartment, setToBeModifiedDepartment] = useState<ICompanyDepartment | undefined>(undefined)
  const [filterState, setFilterState] = useState<any>(undefined)
  const [observerTriggerState, setObserverTriggerState] = useState<any>({})
  const [newDepartmentOpen, setNewDepartmentOpen] = useState<boolean>(false)
  const [nameNewDep, setNameNewDep] = useState<string>('')
  const [departmentToBeViewed, setDepartmentToBeViewed] = useState<any>()

  React.useEffect(() => {
    let returnValue = configureInfiniteScroll()
    if (is_embeded) { return }
    resetAccordion({
      title: formatMessage({ id: 'routes.departments' }),
    })
    return () => {
      returnValue()
      resetAccordion({
        title: '',
        sub_title: '',
      })
    }
  }, [])

  React.useEffect(() => {
    setRows(parseCompanies(rowsBackup))
  }, [rowsBackup])

  const [columns, setColumns] = useState<any[]>([
    {
      title: formatMessage({ id: "shared:departments" }),
      transforms: [sortable, wrappable]
    },
    {
      title: formatMessage({ id: "shared:users" }),
      transforms: [sortable, wrappable]
    },
    {
      title: formatMessage({ id: "shared:complete_demands" }),
      transforms: [sortable, wrappable]
    },
    {
      title: formatMessage({ id: "shared:incomplete_demands" }),
      transforms: [sortable, wrappable]
    },
    {
      title: formatMessage({ id: "shared:candidates" }),
      transforms: [sortable, wrappable]
    },
    "",
  ])

  const [rows, setRows] = useState<any[]>([])

  const [sortBy, setSortBy] = useState<any>()

  const onSelect = (event, isSelected, rowId) => {
    let newRows;
    if (rowId === -1) {
      newRows = rows.map(oneRow => {
        oneRow.selected = isSelected;
        return oneRow;
      });
    } else {
      newRows = [...rows];
      newRows[rowId].selected = isSelected;
    }
    setRows(newRows)
  }

  const actions = [
    {
      title: formatMessage({ id: 'shared:view_users' }),
      onClick: (event, rowId, rowData, extra) => {
        let department = rowsBackup[rowId]
        setDepartmentToBeViewed(department)
      }
    },
    {
      title: formatMessage({ id: 'shared:update' }),
      onClick: (event, rowId, rowData, extra) => {
        let department = rowsBackup[rowId]
        setToBeModifiedDepartment(department)
        setNameNewDep(department.name)
        setNewDepartmentOpen(true)
      }
    },
    {
      isSeparator: true
    },
    {
      title: formatMessage({ id: "shared:delete" }),
      onClick: (event, rowId, rowData, extra) => {
        let department = rowsBackup[rowId]
        setToDelete(department.id)
      }
    }
  ]

  const onSort = (_event, index, direction) => {
    let idx = index - 1
    const sortedRows = [...rows].sort((a, b) => (
      a.cells[idx].localeCompare(b.cells[idx])
    ))
    setSortBy({
      index,
      direction
    })
    setRows(
      direction === SortByDirection.asc ? sortedRows : sortedRows.reverse()
    )
  }

  const { new_notification } = useNotificationData()

  useEffect(() => {
    clearTimeout(timeout)
    setFilterState(undefined)
    //text in search field has been emptied
    if (query === '') {
      if (!Boolean(storeCompanies[0])) { return }
      setRowsBackup(storeCompanies[0].departments)
      return
    }
    //On each new search, searchStart goes to zero
    setSearchStart(0)
    timeout = setTimeout(() => {
      searchCompanies()
    }, 300);
  }, [query])


  useEffect(() => {
    if (storeCompanies && storeCompanies.length > 0 && !query) {
      setRowsBackup(storeCompanies[0].departments)
    }
  }, [storeCompanies])

  const fetchCompany = () => {
    setLoading(true)
    UserService.get_current_company()
      .catch(_ => {
        setLoading(false)
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const data = await response.json()
          appendCompanies({ data: [data], start: 0, total: 0, })
          setCurrentCompany(data)
          fetchDepartments(data)(0, false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  useEffect(
    () => {
      if (!Boolean(currentcompany))
        fetchCompany()
    },
    [currentcompany]
  )

  const fetchDepartments = company => (
    paramStart: number,
    paramSearching: boolean,
    filter?: any,
    absolute?: any,
  ) => {
    console.log({ company })
    if (!Boolean(company)) {
      return
    }
    setLoading(true)
    let suffix = (
      `?start=${paramStart}&limit=${30}&company_id=${company.id}`
    )
    if (absolute !== null && Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (Boolean((filter || filterState)[field])) {
          suffix = `${suffix}&${field}=${(filter || filterState)[field]}`
        }
      })
    } else if (Boolean(paramSearching)) {
      suffix = `${suffix}&keyword=${query}`
    }
    DepartmentService.list_departments(suffix)
      .catch(_ => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { results, start, total, limit } = await response.json()
          //if we are not searching nore filtering
          if (!paramSearching && (absolute !== null && !Boolean(filter || filterState))) { //Not searching
            if (start < 1) {
              resetDepartments(company.id, ...results)
              appendCompanies({ data: [], start, total, limit, })
            }
            else {
              appendDepartments(company.id, ...results)
              appendCompanies({ data: [], start, limit, total })
            }
          } else { //Searching 
            setRowsBackup([...(start > 0 ? rows : []), ...results])
            setSearchTotal(total)
            setSearchStart(start)
          }
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }


  const deleteFormFromApi = (...department_ids: string[]) => {
    setLoadingDelete(true)
    CompanyService.delete_companies({ department_ids })
      .catch(_ => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          deleteDepartments(
            storeCompanies[0].id,
            ...department_ids
          )
          setToDelete([])
          setLoadingDelete(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingDelete(false)
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }


  const parseCompanies = (data: ICompanyDepartment[]) => {
    return data.map(datum => (
      {
        cells: [
          datum.name,
          datum.total_users,
          datum.total_complete_demands,
          datum.total_incomplete_demands,
          datum.total_candidates,
          <div>
            <DepartmentNewUser
              renderTrigger={trigger => (
                <Button onClick={trigger} variant={ButtonVariant.secondary}>
                  <FormattedMessage id="shared:new_user" />
                </Button>
              )}
              department={datum}
              onCreate={_ => { }}
              // setUserToUpdate={setToUpdate}
              // userToUpdate={toUpdate}
              onUpdate={_ => { }}
            />
          </div>
        ]
      }
    ))
  }

  const onNextPage = () => {
    if (query !== '') {
      if (searchTotal !== 0 && rows.length >= searchTotal) { return }
      fetchDepartments(currentcompany)(searchTotal === 0 ? 0 : searchStart + storeLimit, true)
      return
    }
    if (
      storeTotal !== 0 && storeCompanies[0].departments.length >= storeTotal
    ) { return }
    fetchDepartments(currentcompany)(storeTotal === 0 ? 0 : storeStart + storeLimit, false)
    // setStart(storeStart + storeLimit)
  }

  const searchCompanies = (filter?) => {
    fetchDepartments(currentcompany)(0, filter ? false : true, filter)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {
      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeCompanies, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])


  const handleDepartmentCreated = () => {
    let company_id = storeCompanies[0].id
    setLoading(true)
    let method = DepartmentService.new_department
    if (Boolean(toBeModifiedDepartment)) {
      method = DepartmentService.update_department(`/${toBeModifiedDepartment!.id}`)
    }
    method(
      {
        company_id,
        name: nameNewDep, description: '',
      }
    )
      .catch(_ => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message, data } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          if (Boolean(toBeModifiedDepartment)) {
            updateDepartment(company_id, data)
          } else {
            appendDepartments(company_id, data)
          }
          setToBeModifiedDepartment(undefined)
          setNewDepartmentOpen(false)
          setLoading(false)
          setNameNewDep('')
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    // cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  const parsePagination = () => {
    const count = rows.length
    let total = storeTotal
    if (Boolean(query) || Boolean(filterState)) {
      total = searchTotal
    }
    return `${count} of ${total}`
  }


  return (
    <div className={is_embeded ? '' : styles.container} >
      <ConfirmDialog
        isModalOpen={Boolean(newDepartmentOpen)}
        onAction={handleDepartmentCreated}
        actionAlignLeft={false}
        loading={loading}
        actionText={
          Boolean(toBeModifiedDepartment) ? (
            formatMessage({ id: "shared:update" })
          ) : formatMessage({ id: "shared:create" })
        }
        title_message={
          Boolean(toBeModifiedDepartment) ? (
            formatMessage({ id: "shared:update" })
          ) : formatMessage({ id: "shared:create_department" })
        }
        onModalClose={() => setNewDepartmentOpen(false)}
      >
        <div className={styles.new_dep_container}>
          <div className={styles.inline}>
            <span>
              <FormattedMessage
                id={
                  Boolean(toBeModifiedDepartment) ?
                    "shared:update_department_prompt"
                    : "shared:create_department_prompt"
                }
              />
            </span> : {' '}
            <br />
            {
              Boolean(toBeModifiedDepartment) ? (
                <span>{toBeModifiedDepartment!.name}</span>
              ) : (
                  <span>{storeCompanies[0] && storeCompanies[0].name}</span>
                )
            }
          </div>
          <TextInput
            style={{ width: '100%' }}
            onChange={setNameNewDep}
            value={nameNewDep}
            placeholder={formatMessage({ id: "new_dep:enter_dep_name" })}
          />
        </div>
      </ConfirmDialog>

      <ConfirmDialog
        isModalOpen={toDelete.length > 0}
        onAction={_ => deleteFormFromApi(...toDelete)}
        actionAlignLeft={false}
        confirmation_message={formatMessage({ id: "shared:prompt_delete_department" })}
        loading={loadingDelete}
        actionText={
          formatMessage({ id: "shared:delete" })
        }
        title_message={
          formatMessage({ id: "shared:delete" })
        }
        onModalClose={() => setToDelete([])}
      />

      <UsersListingDialog
        department={departmentToBeViewed}
        onModalClose={_ => setDepartmentToBeViewed(undefined)}
        isModalOpen={Boolean(departmentToBeViewed)}
      />

      <div
        className={clsx(
          is_embeded ? "" : styles.card,
          styles.card__fullwidth,
          styles.card__table,
        )}
      >
        <div className={styles.table_container}>
          <header className={styles.table_header}>
            <TextInput
              className={styles.input_search}
              onChange={setQuery}
              placeholder={formatMessage({ id: 'shared:search' })}
            />
            <Button
              className={styles.btn_new_dep}
              onClick={_ => setNewDepartmentOpen(true)}
            >
              <FormattedMessage id="shared:new_department" />
            </Button>
            {
              rows.filter(({ selected }) => Boolean(selected)).length > 0 && (
                <Button
                  onClick={i => setToDelete(
                    rowsBackup.filter(
                      (_, idx) => Boolean(rows[idx].selected)
                    ).map(({ id }) => id)
                  )}
                  variant={"secondary"}
                >
                  <CloseIcon />
                  <FormattedMessage id="shared:delete" />
                </Button>
              )}
          </header>
          {rows.length === 0 && !loading && (
            <ListEmpty />
          )}
          {rows.length > 0 && (
            <Table
              onSelect={onSelect}
              canSelectAll={true}
              borders={false}
              actions={actions}
              onSort={onSort}
              sortBy={sortBy}
              variant={TableVariant.compact}
              aria-label="Selectable Table"
              cells={columns}
              rows={rows}>
              <TableHeader />
              <TableBody />
            </Table>
          )}
          <div className={styles.counter} ref={centinelRef}>
            <span>
              {loading && <Spinner size={spinnerSize.md} />}
            </span>
            <span>{parsePagination()}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  EnterpriseDepartmentsComponent as EnterpriseDepartmentsPage
}