import * as React from 'react';
import styles from './ListEmpty.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Title,
  Button,
  EmptyState,
  EmptyStateVariant,
  EmptyStateIcon,
  EmptyStateBody,
  EmptyStateSecondaryActions
} from '@patternfly/react-core';
import { CubesIcon } from '@patternfly/react-icons';
import clsx from 'classnames'

export interface ListEmptyProps {
  readonly message?: string;
  readonly action_title?: string;
  readonly onAction?: any;
  readonly title?: string;
  readonly className?: string
}

export const ListEmpty: React.FC<ListEmptyProps> = ({
  message: paramMessage,
  title: paramTitle,
  onAction, action_title,
  className = '',
}) => {

  const { formatMessage } = useIntl()
  let message = paramMessage || formatMessage({ id: "shared:empty_list" })
  let title = paramTitle || formatMessage({ id: "shared:empty_list_title" })

  return (
    <div
      className={
        clsx(
          styles.wrapper,
          className,
        )
      }
    >
      <EmptyState variant={EmptyStateVariant.small}>
        <EmptyStateIcon icon={CubesIcon} />
        <Title headingLevel="h5" size="lg">
          {formatMessage({ id: title })}
        </Title>
        <EmptyStateBody>
          {formatMessage({ id: message })}
        </EmptyStateBody>
        {Boolean(onAction) && Boolean(action_title) && (
          <Button onClick={onAction} variant="primary">{formatMessage({ id: action_title })}</Button>
        )}
      </EmptyState>
    </div>
  )
}
