import React, { useState, useEffect, useCallback } from 'react';
import styles from './ManagerChangePassword.module.scss';
import {
  Modal, Button, TextInput,
  EmptyStateIcon, EmptyState, EmptyStateVariant,
  Title, EmptyStateBody, Switch,
} from '@patternfly/react-core';
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { AngellistIcon } from '@patternfly/react-icons';
import { useFormik } from 'formik';
import { useProvinceData, useNotificationData } from 'hooks';
import { AuthService } from 'services';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { ReactComponent as PreEmploiLogo } from 'assets/imgs/logo.svg'
import { Link } from 'react-router-dom';

export interface ManagerChangePasswordProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const validationSchema = yup.object().shape({
  password: yup.string().required('shared:password_required'),
  confirm_password: yup.string()
    .oneOf([yup.ref('password'), null], 'shared:does_not_match')
    .required('shared:password_required')
})

const ManagerChangePasswordComponent: React.FC<ManagerChangePasswordProps> = ({
  match: { params },
  history,
  ...props
}) => {

  const { token } = params as any;
  const [success, setSuccess] = useState(false)
  const { formatMessage } = useIntl()
  const [isOpen, setIsExapanded] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { provinces } = useProvinceData()
  const { new_notification, } = useNotificationData()

  const onNewCompany = ({ token, password }, { resetForm }) => {
    saveMemberToApi({ token, password }, resetForm)
  }

  const defaultInitialValues = {
    token,
    password: '',
    confirm_password: '',
  }

  const [initialValues, setInitialValues] = useState(defaultInitialValues)

  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    errors,
    handleSubmit,
  } = useFormik<
    { token: string, password: string, confirm_password: string }
  >({
    validationSchema,
    onSubmit: onNewCompany,
    enableReinitialize: true,
    initialValues,
  })

  useEffect(() => {
    setInitialValues(defaultInitialValues)
  }, [])


  const saveMemberToApi = (new_company, resetForm) => {
    setLoading(true)
    AuthService.change_password_external(new_company)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { data, message } = await response.json()
          setSuccess(true)
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          resetForm(initialValues)
          history.push('/auth/login')
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }


  const SuccessCompanyCreation = () => (
    <EmptyState variant={EmptyStateVariant.small} className={styles.empty_state}>
      <EmptyStateIcon icon={AngellistIcon} />
      <Title headingLevel="h4" size="lg">
        <FormattedMessage id="shared:success" />
      </Title>
      <EmptyStateBody>
        <FormattedMessage id="external_company:creation_success_message" />
      </EmptyStateBody>
    </EmptyState>
  )

  return (
    <div
      className={styles.wrapper}
    >
      <Link className={styles.header} to={"/auth/login"}>
        <PreEmploiLogo className={styles.logo} />
      </Link>
      {success ? (
        <SuccessCompanyCreation />
      ) : (
          <div className={styles.container} >
            <form
              className={styles.content}
              onSubmit={handleSubmit}
            >

              <h4 className={styles.title}>
                <FormattedMessage id='auth:update_password' />
              </h4>

              <span>
                <FormattedMessage id="auth:new_password_prompt" />
              </span>

              <label className={styles.field}>
                <span
                  className={errors.password && touched.password ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.password && touched.password ? errors.password : "shared:password"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  type={'password'}
                  name={'password'}
                  id={'password'}
                  value={values.password}
                  onChange={text => setFieldValue('password', text)}
                  onBlur={handleBlur}
                  aria-label="text input example"
                />
              </label>
              <label className={styles.field}>
                <span
                  className={errors.confirm_password && touched.confirm_password ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.confirm_password && touched.confirm_password ? errors.confirm_password : "shared:confirm_password"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  name={'confirm_password'}
                  id={'confirm_password'}
                  value={values.confirm_password}
                  onChange={text => setFieldValue('confirm_password', text)}
                  onBlur={handleBlur}
                  type="password"
                  aria-label="text input example"
                />
              </label>

              <Button
                className={styles.actions}
                isDisabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length < 1}
                type="submit"
              >
                <FormattedMessage id={"shared:update"} />
                {loading && (
                  <Spinner size="sm" />
                )}
              </Button>

            </form>
          </div>
        )}
    </div>
  )
}

export {
  ManagerChangePasswordComponent as ManagerChangePasswordPage,
}
