import React, { useState, useEffect } from 'react';
import styles from './InvoiceChangelogPopover.module.scss'
import { PopoverPosition, Popover } from '@patternfly/react-core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment'

export interface InvoiceChangelogPopoverProps {
  readonly trigger: any;
  readonly request: any;
}

export const InvoiceChangelogPopover: React.FC<InvoiceChangelogPopoverProps> = ({
  trigger,
  request,
  ...props
}) => {

  const [show, setShow] = useState<boolean>(false)
  const [keepInViewChecked, setInViewChecked] = useState<boolean>(false)
  const [editors, setEditors] = useState<string[][]>([])
  const handleClick = (status: boolean) => {
    setShow(status)
  };

  const shouldClose = tip => {
    setShow(false)
  };

  const containerStyle = {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'row',
    justifyItems: 'flex-start',
  }

  useEffect(() => {
    let newEditors: string[][] = []
    if (Boolean(request.editors)) {

      newEditors = request.editors.map(_ => ([
        _.updated_by ? _.updated_by : null,
        _.update_at ? moment.utc(
          _.update_at.replace("-05:00", ""))
          .utcOffset("-05:00")
          .format("yyyy-M-D") : null,
        _.update_at ?  moment.utc(
          _.update_at.replace("-05:00", ""))
          .utcOffset("-05:00")
          .format("LTS") : null,
      ]))
    } else {
      newEditors = [[
        request.updated_by  ? request.updated_by : null,
        request.update_at ? moment.utc(
          request.update_at.replace("-05:00", ""))
          .utcOffset("-05:00")
          .format("yyyy-M-D") : null,
        request.update_at ? moment.utc(
          request.update_at
            .replace("-05:00", ""))
          .utcOffset("-05:00")
          .format("LTS") : null,
      ]]
    }
    setEditors(newEditors)
  }, [request])

  return (
    <Popover
      isVisible={show}
      shouldClose={shouldClose}
      enableFlip={keepInViewChecked}
      headerContent={
        <div>
          <FormattedMessage id="shared:request_changelog" />
        </div>
      }
      bodyContent={
        <div style={containerStyle}>
          {request && (
            <>
              {
                editors.map((
                  [updated_by, updated_at_date, updated_at_time]
                ) => (
                  <>
                    <span>
                      <FormattedMessage id="shared:user" />
                      &nbsp;:&nbsp;
                      {updated_by}
                    </span>
                    <span>
                      <FormattedMessage id="shared:last_update" />
                      &nbsp;:&nbsp;
                      {updated_at_date}/
                      {updated_at_time}
                    </span>
                  </>
                ))
              }
            </>
          )}
        </div >
      }
    >
      { trigger(handleClick)}
    </Popover >
  );
}
