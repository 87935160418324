import React, { useState, useEffect } from 'react';
import styles from './PromptActivateCompany.module.scss'
import {
    Modal, Button, SelectOptionObject,
    DropdownItem, Dropdown, DropdownToggle,
    SelectOption, Spinner,
    spinnerSize, TextInput, InputGroup
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCompanyUserData } from 'hooks';
import { CaretDownIcon, SearchIcon } from '@patternfly/react-icons';
import { ListEmpty } from 'components';

export const PromptActivateCompany = ({
    onAction, loading,
    rowId, onModalClose,
}) => {
    const [companyOwner, setCompanyOwner] = useState<string[] | SelectOptionObject[]>([])
    const [selectOwnerOpen, setSelectOwnerOpen] = useState<boolean>(false)
    const { users } = useCompanyUserData(rowId)
    const { formatMessage } = useIntl()
    const [ownerName, setOwnerName] = useState<string>(formatMessage({ id: "shared:select_company" }))
    const [query, setQuery] = useState<string>('')
    const handleModalToggle = () => {
        onModalClose()
    }


    const UserOptions = users.map(({ id, firstName, lastName }) => (
        <SelectOption value={`${firstName} ${lastName}`} />
    ))

    UserOptions.unshift(
        <SelectOption isDisabled={true} value={formatMessage({ id: 'shared:select' })} />
    )

    const dropdownItems = users
        .filter(
            ({ firstName, lastName }) => {
                if (query === "") { return true }
                return (
                    `${firstName} ${lastName}`
                        .toLowerCase()
                        .indexOf(
                            query.trim().toLowerCase()
                        ) !== -1
                )
            }
        )
        .map(({ id, firstName, lastName }) => (
            <DropdownItem
                key="disabled link"
                onClick={_ => {
                    handleOnSelect(id)
                    setOwnerName(`${firstName} ${lastName}`)
                    setSelectOwnerOpen(false)
                }}
            >
                {`${firstName} ${lastName}`}
            </DropdownItem>
        ))

    const handleOnSelect = (id,) => {
        setSelectOwnerOpen(false)
        setCompanyOwner(id)
    }


    return (
        <Modal
            title={formatMessage({ id: "shared:activate_enterprise" })}
            isOpen={Boolean(rowId) || rowId === 0}
            onClose={handleModalToggle}
            className={styles.modal_root}
            //isFooterLeftAligned={false}
            actions={[
                <Button key="cancel" variant="link" onClick={handleModalToggle}>
                    <FormattedMessage id="shared:cancel" />
                </Button>,
                <Button
                    key="confirm" variant="primary"
                    isDisabled={loading || ownerName === formatMessage({ id: 'shared:select_company' })}
                    onClick={_ => onAction(rowId, companyOwner)}
                >
                    <FormattedMessage id="shared:activate_enterprise" />
                    {loading && <Spinner size={spinnerSize.md} />}
                </Button>
            ]}
        >
            {users.length > 0 ? (
                <form
                    action=""
                    className={styles.form}
                >
                    <InputGroup
                        className={styles.search}
                    >
                        <TextInput
                            placeholder={formatMessage({ id: "shared:search" })}
                            value={query}
                            onChange={setQuery}
                        />
                        <Button
                            variant="control"
                            className={styles.btn_nothing}
                        >
                            <SearchIcon />
                        </Button>
                    </InputGroup>
                    <label
                        htmlFor="email"
                        className={styles.label}
                    >
                        <span>
                            <FormattedMessage id="company:activate_enterprise_prompt" />
                        </span>
                        <Dropdown
                            onSelect={() => setSelectOwnerOpen(false)}
                            toggle={
                                <DropdownToggle
                                    id="toggle-id-menu-document-body"
                                    onToggle={isOpen => setSelectOwnerOpen(isOpen)}
                                    //@ts-ignore
                                    toggleIndicator={CaretDownIcon}
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    {ownerName}
                                </DropdownToggle>
                            }
                            isOpen={selectOwnerOpen}
                            dropdownItems={dropdownItems}
                            //@ts-ignore
                            menuAppendTo="parent"
                        />

                    </label>
                </form>
            ) : (
                    <ListEmpty
                        title={'users:no_users'}
                        message={"users:create_users_message"}
                    />

                )}
        </Modal>
    );
}
