import React, { useState, useEffect } from 'react';
import styles from './NewCompany.module.scss'
import {
  Modal, Button, TextInput,
  Select, SelectOption, SelectVariant, Switch,
  InputGroup,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
import {
  useProvinceData, useNotificationData,
  usePricingPlanData,
  useFetchStandardForms
} from 'hooks';
import { CompanyService } from 'services';
import { ICompany } from 'stores';
import { maskNumber } from 'utilities';
import {
  DollarSignIcon, PercentageIcon,
} from '@patternfly/react-icons';


export interface NewCompanyProps {
  readonly renderTrigger: any;
  readonly company?: ICompany;
  readonly onClose: any
  readonly onDone: any
}

const validationSchema = yup.object().shape({
  name: yup.string().required('shared:name_required'),
  phone: yup.string()
    .max(20, "shared:phonenumber_too_long")
    .matches(
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
      "shared:invalid_phonenumber"
    ),
  fax: yup.string(),
  standard_form_id: yup.string(),
  address: yup.string().required('shared:address_required'),
  city: yup.string().required('shared:city_required'),
  postal_code: yup.string().required('shared:postal_code_required'),
  province: yup.string().required('shared:province_required'),
  activate_company: yup.boolean().required(),
  pricing_plan_id: yup.string().required('shared:pricing_plan_required'),
  /**
   * https://krzysztofzuraw.com/blog/2020/yup-validation-two-fields
   * make field optional or required depending on another field.
   */
  manager_username: yup.string()
    .when(['activate_company'], {
      is: activate_company => activate_company,
      then: yup.string().required('shared:manager_username_required'),
    }),
  manager_firstName: yup.string()
    .when(['activate_company'], {
      is: activate_company => activate_company,
      then: yup.string().required('shared:manager_firstname_required'),
    }),
  manager_email: yup.string()
    .when(['activate_company'], {
      is: activate_company => activate_company,
      then: yup.string().email('shared:email_invalid')
        .required('shared:manager_email_required'),
    }),
  manager_lastName: yup.string()
    .when(['activate_company'], {
      is: activate_company => activate_company,
      then: yup.string().required('shared:manager_lastname_required'),
    }),
})

export const NewCompany: React.FC<NewCompanyProps> = ({
  renderTrigger,
  company,
  onDone,
  onClose,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [isOpen, setIsExapanded] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { provinces } = useProvinceData()
  const { pricing_plan } = usePricingPlanData()
  const { new_notification, } = useNotificationData()
  const [companyId, setCompanyId] = useState<string | undefined>(undefined)
  const [updating, setUpdating] = useState<boolean>(false)
  const { standardForms } = useFetchStandardForms()

  const onNewCompany = (value, { resetForm }) => {
    const data = {
      ...value,
      standard_form_id: standardForms.find(
        _ => _.title === value.standard_form_id
      )!.id,
      province: provinces.find(
        province => province.name === value.province
      )!.id,
      pricing_plan_id: pricing_plan.find(
        plan => parsePricingPlan(plan.id) === value.pricing_plan_id
      )!.id,
    }
    saveMemberToApi(data, resetForm)
  }

  const parsePricingPlan = id => {
    if (`${id}` === '1') {
      return formatMessage({ id: 'shared:pricing_per_unit' })
    } else if (`${id}` === '2') {
      return formatMessage({ id: "shared:pricing_per_month" })
    } else {
      return (pricing_plan.find(_ => _.id === id) || { name: '' })!.name
    }
  }


  const defaultInitialValues = {
    name: '',
    phone: '',
    post_number: '',
    fax: '',
    address: '',
    city: '',
    postal_code: '',
    standard_form_id: '',
    province: '',
    activate_company: false,
    discount: 0,
    discount_value: '$',
    pricing_plan_id: parsePricingPlan(2),
    // manager_email: '',
    // manager_firstName: '',
    // manager_lastName: '',
  }

  const [initialValues, setInitialValues] = useState(defaultInitialValues)

  const {
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    handleBlur,
    errors,
    handleSubmit,
  } = useFormik<ICompany>({
    validationSchema,
    onSubmit: onNewCompany,
    enableReinitialize: true,
    initialValues,
  })

  const handleModalToggle = () => {
    if (isModalOpen) {
      onClose()
      setCompanyId(undefined)
    }
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    if (!isModalOpen) {
      setInitialValues(defaultInitialValues)
    }
  }, [isModalOpen])

  useEffect(() => {
    if (!Boolean(company)) {
      setUpdating(false)
      return
    }
    setCompanyId(company!.id)
    setIsModalOpen(true)
    setInitialValues({
      activate_company: false,
      name: company!.name || '',
      phone: company!.phone || '',
      standard_form_id: (
        standardForms
          .find(_ => _.id === company!.standard_form_id)
        || { title: '' }
      ).title,
      post_number: company!.post_number || '',
      discount: company!.discount || 0,
      discount_value: company!.discount_value || '$',
      fax: company!.fax || '',
      address: company!.address || '',
      city: company!.city || '',
      postal_code: company!.postal_code || '',
      province: (
        provinces.find(
          province => province.id === company!.province
        ) || { name: '' }).name || '',
      pricing_plan_id: parsePricingPlan(company!.pricing_plan_id) || '',
    })
    setUpdating(true)
  }, [company])

  const handleSelectToggle = field => is_expanded => {
    setIsExapanded(is_expanded ? field : '')
  }

  const onSelect = field => (event, selection) => {
    setIsExapanded('')
    setFieldValue(field, selection)
    setFieldTouched(field, true)
  }

  const clearSelection = field => () => {
    setFieldValue(field, [])
    setIsExapanded('')
  }

  const saveMemberToApi = (new_company, resetForm) => {
    setLoading(true)
    let data = Object.keys(new_company || {}).reduce(
      (acc, key) => {
        if (new_company[key])
          acc[key] = new_company[key]
        return acc
      }, {}
    )
    let method
    if (companyId) {
      method = CompanyService.update_company(companyId, data)
    } else {
      method = CompanyService.new_company(data)
    }
    method
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { data, message } = await response.json()
          onDone(data)
          setIsModalOpen(false)
          // if (companyId) {
          // } else {
          //   appendCompanies({ data: [data] })
          // }
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          resetForm(initialValues)
          setIsModalOpen(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  const options = provinces.map(({ id, name }) => (
    <SelectOption key={id} name={name} value={name} />
  ))

  const optionsPricingPlan = pricing_plan.map(({ id, name }) => (
    <SelectOption key={id} name={parsePricingPlan(id)} value={parsePricingPlan(id)} />
  ))

  const optionsStandardForms = standardForms.map(({ id, title }) => (
    <SelectOption key={id} name={title} value={title} />
  ))


  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {
              formatMessage({
                id: companyId ? "new_company:edit_company" : "new_company:new_company"
              })
            }
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        //isFooterLeftAligned
        className={styles.modal_root}
      >
        <form
          className={styles.content}
          onSubmit={handleSubmit}
        >
          <label
            htmlFor="pricing_plan_id"
            className={styles.field}
          >
            <span
              className={errors.pricing_plan_id && touched.pricing_plan_id ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.pricing_plan_id && touched.pricing_plan_id ? errors.pricing_plan_id : "shared:pricing_plan"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <Select
              variant={SelectVariant.typeahead}
              onToggle={handleSelectToggle('pricing_plan_id')}
              name="pricing_plan_id"
              id="pricing_plan_id"
              onSelect={onSelect('pricing_plan_id')}
              selections={values.pricing_plan_id}
              isOpen={isOpen === 'pricing_plan_id'}
              // placeholderText={formatMessage({ id: "shared:member_of_entity" })}
              // ariaLabelledBy={'pricing_plan_id'}
              onClear={clearSelection('pricing_Plan_id')}
            >
              {optionsPricingPlan}
            </Select>
          </label>
          <label
            htmlFor="standard_form_id"
            className={styles.field}
          >
            <span>
              <FormattedMessage
                id={errors.standard_form_id && touched.standard_form_id ? errors.standard_form_id : "shared:standard_form_prompt"}
              />
            </span>
            <Select
              variant={SelectVariant.typeahead}
              onToggle={handleSelectToggle('standard_form_id')}
              name="standard_form_id"
              id="standard_form_id"
              onSelect={onSelect('standard_form_id')}
              selections={values.standard_form_id}
              isOpen={isOpen === 'standard_form_id'}
              onClear={clearSelection('standard_form_id')}
            >
              {optionsStandardForms}
            </Select>
          </label>
          <label
            htmlFor="province"
            className={styles.field}
          >
            <span
              className={errors.province && touched.province ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.province && touched.province ? errors.province : "shared:province"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <Select
              variant={SelectVariant.typeahead}
              onToggle={handleSelectToggle('province')}
              name="province"
              onSelect={onSelect('province')}
              selections={values.province}
              isOpen={isOpen === 'province'}
              // placeholderText={formatMessage({ id: "shared:member_of_entity" })}
              // ariaLabelledBy={'province'}
              onClear={clearSelection('province')}
            >
              {options}
            </Select>
          </label>


          <label className={styles.field}>
            <span
              className={errors.name && touched.name ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.name && touched.name ? errors.name : "shared:name"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'name'}
              value={values.name}
              onChange={text => setFieldValue('name', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label htmlFor="post_number" className={styles.field}>
            <span
              className={errors.phone && touched.phone ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.phone && touched.phone ? errors.phone : "shared:tel_and_post_number"}
              />
            </span>
            <div className="telephone_field">
              <TextInput
                name={'phone'}
                value={values.phone}
                onChange={text => setFieldValue('phone', text)}
                onBlur={e => {
                  handleBlur(e)
                  setFieldValue(
                    'phone',
                    maskNumber(values.phone)
                  )
                }} type="text"
                //css={{}}
                aria-label="text input example"
              />
              {Boolean(values.phone) && (
                <TextInput
                  name={'post_number'}
                  id={'post_number'}
                  value={values.post_number}
                  onChange={text => setFieldValue('post_number', text)}
                  onBlur={handleBlur}
                  type="number"
                  //css={{}}
                  placeholder={formatMessage({ id: 'shared:post_number' })}
                />
              )}
            </div>
          </label>
          <label className={styles.field}>
            <span
              className={errors.discount && touched.discount ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.discount && touched.discount ? errors.discount : "shared:discount"}
              />
            </span>
            <InputGroup>
              <TextInput
                name={'discount'}
                value={values.discount}
                onChange={text => setFieldValue('discount', text)}
                onBlur={handleBlur}
                type="number"
                //css={{}}
                aria-label="text input example"
              />
              <Button
                variant="control"
                onClick={_ => setFieldValue('discount_value', '$')}
                onBlur={handleBlur}
                name="discount_value"
                aria-label="search button for search input"
              >
                <DollarSignIcon
                  className={
                    values.discount_value === '$' ? styles.valid_discount_value : ''
                  }
                />
              </Button>
              <Button
                name="discount_value"
                variant="control"
                onClick={_ => setFieldValue('discount_value', '%')}
                onBlur={handleBlur}
                aria-label="search button for search input"
              >
                <PercentageIcon
                  className={
                    values.discount_value === '%' ? styles.valid_discount_value : ''
                  }
                />
              </Button>
            </InputGroup>
          </label>
          <label className={styles.field}>
            <span
              className={errors.fax && touched.fax ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.fax && touched.fax ? errors.fax : "shared:fax"}
              />
              {/* <span className={styles.c_error}>*</span> */}
            </span>
            <TextInput
              name={'fax'}
              value={values.fax}
              onChange={text => setFieldValue('fax', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.address && touched.address ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.address && touched.address ? errors.address : "shared:address"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'address'}
              value={values.address}
              onChange={text => setFieldValue('address', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.city && touched.city ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.city && touched.city ? errors.city : "shared:town"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'city'}
              value={values.city}
              onChange={text => setFieldValue('city', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>

          <label className={styles.field}>
            <span
              className={errors.postal_code && touched.postal_code ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.postal_code && touched.postal_code ? errors.postal_code : "shared:postal_code"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'postal_code'}
              value={values.postal_code}
              onChange={text => setFieldValue('postal_code', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          {false && !Boolean(updating) && (
            <div style={{ gridColumn: '1/-1' }}>
              <Switch
                id="simple-switch"
                label={formatMessage({ id: 'company:activate_company' })}
                // labelOff={formatMessage({id:"company:"})}
                isChecked={values.activate_company}
                onChange={isChecked => setFieldValue('activate_company', isChecked)}
              />
            </div>
          )}
          {values.activate_company && (
            <>
              <label className={styles.field}>
                <span
                  className={errors.manager_username && touched.manager_username ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.manager_username && touched.manager_username ? errors.manager_username : "shared:manager_username"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  name={'manager_username'}
                  value={values.manager_username}
                  onChange={text => setFieldValue('manager_username', text)}
                  onBlur={handleBlur}
                  type="text"
                  aria-label="text input example"
                />
              </label>
              <label className={styles.field}>
                <span
                  className={errors.manager_email && touched.manager_email ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.manager_email && touched.manager_email ? errors.manager_email : "shared:manager_email"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  name={'manager_email'}
                  value={values.manager_email}
                  onChange={text => setFieldValue('manager_email', text)}
                  onBlur={handleBlur}
                  type="email"
                  aria-label="text input example"
                />
              </label>
              <label className={styles.field}>
                <span
                  className={errors.manager_firstName && touched.manager_firstName ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.manager_firstName && touched.manager_firstName ? errors.manager_firstName : "shared:manager_firstName"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  name={'manager_firstName'}
                  value={values.manager_firstName}
                  onChange={text => setFieldValue('manager_firstName', text)}
                  onBlur={handleBlur}
                  type="text"
                  aria-label="text input example"
                />
              </label>
              <label className={styles.field}>
                <span
                  className={errors.manager_lastName && touched.manager_lastName ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.manager_lastName && touched.manager_lastName ? errors.manager_lastName : "shared:manager_lastName"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  name={'manager_lastName'}
                  value={values.manager_lastName}
                  onChange={text => setFieldValue('manager_lastName', text)}
                  onBlur={handleBlur}
                  type="text"
                  //css={{}}
                  aria-label="text input example"
                />
              </label>
            </>
          )}
          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={handleModalToggle}
              type="button"
            >
              <FormattedMessage id="shared:cancel" />
            </button>
            <Button
              isDisabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length < 1}
              type="submit"
            >
              <FormattedMessage id={"shared:save"} />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
