import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import styles from './CompanyBillingListing.module.scss';
import {
  Button, Spinner, spinnerSize,
  Dropdown, DropdownItem, DropdownToggle,
  InputGroup
} from '@patternfly/react-core';
import {
  useIntl, FormattedMessage
} from 'react-intl';
import clsx from 'classnames'
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
  SortByDirection
} from '@patternfly/react-table';
import { Link } from 'react-router-dom'
import {
  useAccordionData, useNotificationData,
  useUserRoleData, useUserInfoData,
  useFolderStatuses, useProvinceData,
} from 'hooks'
import { RouteComponentProps } from 'react-router-dom'
import { BillingService } from 'services'
import { useCandidatesData } from 'hooks'
import { ArrowsAltVIcon, CloseIcon, FilterAltIcon, SearchIcon } from '@patternfly/react-icons';
import { ListEmpty, CompanyPopover, SearchField, AccordionContext } from 'components';
import { TABLE_COLUMN } from 'constants/table_columns';
import moment from 'moment';

export interface CompanyBillingListingProps extends RouteComponentProps {
}

export interface IBilling {
  amount: number;
  total_price: number;
  company_debt: number;
  demands_number: number;
  id: string;
  is_paid: string;
  last_request: string;
  name: string;
}

const CompanyBillingListingComponent: React.FC<CompanyBillingListingProps> = ({
  match: { url },
  history,
  ...props
}) => {
  let {
    start: storeStart, limit: storeLimit,
    candidates: storeCandidates,
  } = useCandidatesData()
  const { currentRole } = useUserRoleData()
  const { userInfo } = useUserInfoData() as any
  const { setBottomComponent } = React.useContext(AccordionContext);
  const [
    observerTriggerState, setObserverTriggerState
  ] = useState<any>({})

  const { formatMessage } = useIntl()
  const [dateFilterOpen, setDateFilterOpen] = useState<string>('')
  const [
    dateFilter, setDateFilter
  ] = useState<string>(
    `${new Date().getFullYear()}&month=${new Date().getMonth()}`
  )
  const table_header_columns: TABLE_COLUMN[] = [
    {
      title: formatMessage({ id: 'shared:company' }),
      sortable: false,
      field: 'name',
    },
    {
      title: formatMessage({ id: "billing:number_of_requests" }),
      field: 'demands_number',
      type: 'number',
    },
    {
      title: formatMessage({ id: "shared:total_price" }),
      field: 'amount',
      type: 'number',
    },
    {
      title: formatMessage({ id: "shared:payment_status" }),
      field: 'is_paid',
      searcheable: false,
    },
    {
      title: formatMessage({ id: "shared:amount_indebted" }),
      field: 'amount',
      searcheable: false,
    },
    {
      title: formatMessage({ id: "shared:last_demand_date" }),
      field: 'last_demand',
      type: 'date',
    },
    {
      title: formatMessage({ id: "shared:actions" }),
      searcheable: false,
    },
  ]

  const [order, setOrder] = useState<string>('')


  const onSelect = (title) => {
    setSelected(title)
    setFilterOpen(false)
  }

  const orders = [
    {
      title: formatMessage({ id: "shared:asc" }),
      value: 'asc',
    },
    {
      title: formatMessage({ id: "shared:desc" }),
      value: 'desc',
    },
  ]

  const dateFields = table_header_columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'date'
  )
    .map(({ title }) => title)

  const numberFields = table_header_columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'number'
  )
    .map(({ title }) => title)

  const searcheableFields = [
    ...table_header_columns,
  ]
    .filter(({ searcheable = true }) => searcheable)
    .map(({ title }) => title)
    .map(title => (
      <DropdownItem
        onClick={_ => {
          onSelect(title)
          setQuery('')
        }}
        key={title} value={title}
        component="button"
      >
        {title}
      </DropdownItem>
    ))

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [orderOpen, setOrderOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>('')

  if (currentRole !== "Contact") {
    //Empty column for details
    table_header_columns.push(
      { title: '', searcheable: false }
    )
  }

  const [loading, setLoading] = useState<boolean>(false)
  const centinelRef = useRef<any>(null)

  const { new_notification } = useNotificationData()

  const [rows, setRows] = useState<any[]>([])

  const [sortBy, setSortBy] = useState<any>({})
  const { resetAccordion } = useAccordionData()
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  const [filterState, setFilterState] = useState<any>(
    JSON.parse(localStorage.filterState || "{}")
  )

  const years = [
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
  ]

  const months = [
    {
      value: 0,
      name: formatMessage({ id: "shared:janurary" }),
    },
    {
      value: 1,
      name: formatMessage({ id: "shared:february" }),
    },
    {
      value: 2,
      name: formatMessage({ id: "shared:march" }),
    },
    {
      value: 3,
      name: formatMessage({ id: "shared:april" }),
    },
    {
      value: 4,
      name: formatMessage({ id: "shared:may" }),
    },
    {
      value: 5,
      name: formatMessage({ id: "shared:june" }),
    },
    {
      value: 6,
      name: formatMessage({ id: "shared:jully" }),
    },
    {
      value: 7,
      name: formatMessage({ id: "shared:august" }),
    },
    {
      value: 8,
      name: formatMessage({ id: "shared:september" }),
    },
    {
      value: 9,
      name: formatMessage({ id: "shared:october" }),
    },
    {
      value: 10,
      name: formatMessage({ id: "shared:november" }),
    },
    {
      value: 11,
      name: formatMessage({ id: "shared:december" }),
    },
  ]


  useEffect(() => {
    resetAccordion({
      title: formatMessage({ id: 'billing:used_service_billing' }),
      sub_title: ''
    })
    configureInfiniteScroll()
  }, [])

  useEffect(
    () => {
      localStorage.setItem('billing_query', query)
      localStorage.setItem('filterState', JSON.stringify(filterState))
    },
    [query, filterState]
  )

  const configureBottom = () => {
    setBottomComponent(
      <div className={styles.toolbar}>
        <InputGroup
          className={styles.search}
        >
          <Dropdown
            toggle={
              <DropdownToggle
                icon={<FilterAltIcon />}
                onToggle={setFilterOpen}
              >
                {selected ? selected : formatMessage({ id: 'shared:filter' })}
              </DropdownToggle>
            }
            isOpen={filterOpen}
            dropdownItems={searcheableFields}
          />

          <Dropdown
            toggle={
              <DropdownToggle
                icon={<ArrowsAltVIcon />}
                onToggle={setOrderOpen}
              >
                {order ?
                  orders.find(jk => jk.value === order)!.title
                  : formatMessage({ id: 'shared:order' })}
              </DropdownToggle>
            }
            isOpen={orderOpen}
            dropdownItems={
              orders
                .map(orderIn => (
                  <DropdownItem
                    onClick={_ => {
                      setOrder(orderIn.value)
                      setOrderOpen(false)
                    }}
                    key={orderIn.value}
                    value={orderIn.value}
                    component="button"
                  >
                    {orderIn.title}
                  </DropdownItem>
                ))
            }
          />

          <SearchField
            value={query}
            dateFields={dateFields}
            numberFields={numberFields}
            removeArchivedStatus={true}
            field={selected}
            onAction={setQuery}
          />

          <Button
            variant="control"
            className={styles.btn_nothing}
          >
            <SearchIcon />
          </Button>

        </InputGroup>
        <InputGroup
          className={styles.month}
        >
          <Dropdown
            // className={className}
            isOpen={dateFilterOpen.indexOf('year') !== -1}
            toggle={
              <DropdownToggle
                onToggle={
                  (isOpen) => setDateFilterOpen(isOpen ? 'year' : '')
                }
              >
                {
                  dateFilter ?
                    years.find(jk => jk === `${dateFilter}`.split('&month=')[0])
                    : formatMessage({ id: 'shared:year' })
                }
              </DropdownToggle>
            }

            dropdownItems={
              years.map(it => (
                <DropdownItem
                  onClick={_ => {
                    const month = `${dateFilter}`.split('&month=').pop()
                    setDateFilter(`${it}&month=${month || (new Date().getMonth())}`)
                    setDateFilterOpen('')
                  }}
                  key={it}
                  value={it}
                  component="button"
                >
                  {it}
                </DropdownItem>
              ))
            }
          />
          <Dropdown
            isOpen={dateFilterOpen.indexOf('month') !== -1}
            toggle={
              <DropdownToggle
                onToggle={
                  isOpen => setDateFilterOpen(isOpen ? 'month' : '')
                }
              >
                {
                  dateFilter ?
                    months.find(
                      _ => _.value === +`${dateFilter}`.split('&month=').pop()!
                    )!.name
                    : formatMessage({ id: 'shared:month' })
                }
              </DropdownToggle>
            }

            dropdownItems={
              months
                .map(it => (
                  <DropdownItem
                    onClick={_ => {
                      const year = `${dateFilter}`.split('&month=')[0] || (new Date()).getFullYear()
                      setDateFilter(`${year}&month=${it.value}`)
                      setDateFilterOpen('')
                    }}
                    key={it.value}
                    value={it.value}
                    component="button"
                  >
                    {it.name}
                  </DropdownItem>
                ))
            }
          />
        </InputGroup>
        <Link to="/me/billing/-1" >
          <Button>
            <FormattedMessage id="shared:external_demands" />
          </Button>
        </Link>
        {loading && <Spinner size={spinnerSize.md} />}

      </div>
    )
  }

  useEffect(() => {
    fetchBillingInfo(0)
  }, [userInfo, currentRole])

  useEffect(
    () => {
      if (storeCandidates && storeCandidates.length > 0 && !query) {
        setRows(storeCandidates)
      }
    },
    [storeCandidates]
  )

  useEffect(
    () => {
      const column = [
        ...table_header_columns,
      ].find(({ title }) => title === selected)
      if (!Boolean(column)) return
      let field = column!.field

      setFilterState(
        {
          order, order_field: field,
          [field!]: query, year: dateFilter
        }
      )
      fetchBillingInfo(
        0,
        {
          order, order_field: field,
          [field!]: query, year: dateFilter
        }
      )

    },
    [selected, query, order, dateFilter]
  )

  useEffect(() => {
    configureBottom()
    return () => setBottomComponent(<></>)
  },
    [
      order, query, orderOpen,
      selected, filterOpen, loading,
      dateFilter, dateFilterOpen,
    ])

  const fetchBillingInfo = (
    paramStart: number,
    filter?: any,
  ) => {
    if (!Boolean(userInfo) || !Boolean(currentRole)) { return }
    setLoading(true)
    let suffix = `?start=${paramStart}&limit=${30}`


    if (Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (
          Boolean((filter || filterState)[field])
          || (filter || filterState)[field] === 0
        ) {
          console.log({ field })
          suffix = `${suffix}&${field == 'undefined' ? 'keyword' : field}=${(filter || filterState)[field]}`
        }
      })
    }


    BillingService.list_billing(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const {
            results, start, total,
          } = await response.json()
          setRows([...(start > 0 ? rows : []), ...results])
          setSearchTotal(total)
          setSearchStart(start)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const parseCompanyBillingListing = (data: IBilling[]) => {
    return data.map(
      datum => ([
        `${datum.name} `,
        `${datum.demands_number} `,
        `${datum.amount} `,
        <>
          <span
            style={{
              color: datum.is_paid ? 'inherit' : 'red'
            }}
          >
            <FormattedMessage
              id={datum.is_paid ? 'shared:paid' : 'shared:payment_pending'}
            />
          </span >
        </>,
        <>
          <span
            style={{
              color: datum.company_debt > 0 ? 'red' : 'inherit'
            }}
          >
            {datum.company_debt ? (
              datum.company_debt
            ) : (
                <FormattedMessage
                  id={'shared:n_a'}
                />
              )}
          </span >
        </>,
        `${moment.utc(
          (datum.last_request || "")
            .replace("-05:00", "")
        ).utcOffset("-05:00")
          .format('DD-MM-YYYY')} `,
        <>
          <Link
            to={{
              pathname: `${url}/${datum.id}`,
              state: { billing: datum }
            }}
            className={styles.link}
          >
            < FormattedMessage id="shared:more_details" />
          </Link >
        </>
      ])
    )
  }

  const onNextPage = () => {
    if (searchTotal !== 0 && rows.length >= searchTotal) { return }
    fetchBillingInfo(searchTotal === 0 ? 0 : searchStart + storeLimit)
    return
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeCandidates, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  const parsePagination = () => {
    const count = rows.length
    let total = searchTotal
    return `${count} of ${total}`
  }

  return (
    <div className={styles.wrapper} >

      <div className={styles.content}>

        {rows.length === 0 && !loading && (
          <ListEmpty />
        )}

        {rows.length > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            cells={table_header_columns}
            borders={true}
            rows={parseCompanyBillingListing(rows)}
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}

        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{parsePagination()}</span>
        </div>

      </div>

    </div>
  )
}

export {
  CompanyBillingListingComponent as CompanyBillingListingPage,
}
