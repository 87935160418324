export interface ICandidate {
    adress: string,
    attributes: { 
        updated_at: string, 
        last_connection: string, 
        is_archived: 0 | 1 
    },
    birthday: string,
    city: string,
    contact_id: string,
    demand_id: string,
    contact_name: string,
    driving_license_number: string,
    email: string,
    firstname: string,
    folder_number: string,
    updated_at: string,
    company:{
        id:string,
        name:string,
        number:string,
    },
    applicant:any,
    folder_status: string,
    id: string,
    is_admin: boolean,
    is_archived: boolean,
    is_candidate: boolean,
    is_contact: boolean,
    is_deleted: boolean,
    is_publique: boolean,
    is_technicien: boolean,
    lastname: string,
    nas: string,
    phone_number: string,
    post_number: string,
    postal_code: string,
    province: string,
    request_email: string,
    search_folder_number: string,
    address: string,
    phonenumber: string,
    is_idqc_request?: boolean
}


export const CANDIDATE_STORE_ACTIONS = {
    APPEND_CANDIDATES:'CANDIDATE:APPEND',
    RESET_CANDIDATES:'CANDIDATE:RESET',
    DELETE_CANDIDATE:'CANDIDATE:DELETE',
    UPDATE_CANDIDATE:'CANDIDATE:UPDATE',
}