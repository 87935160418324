import React, { useState, useEffect } from 'react'
import styles from './CompanyFilter.module.scss'
import {
  Modal, Button, TextInput,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Spinner, Checkbox } from '@patternfly/react-core';
import { useFormik } from 'formik';
import { useUserData, useProvinceData, useFolderStatuses } from 'hooks';
import { maskNumber } from 'utilities';

export interface CompanyFilterProps {
  readonly renderTrigger: any;
  readonly onDone: any;
}

export interface ICompany {
  phone: string,
  post_number: string,
  address: string,
  postal_code: string,
  city: string,
  name: string,
  number: string,
}

const validationSchema = yup.object().shape({
  phone: yup.string(),
  post_number: yup.string()
    .matches(
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
      "shared:invalid_phonenumber"
    ),
  address: yup.string(),
  postal_code: yup.string(),
  city: yup.string(),
  name: yup.string(),
  number: yup.string(),
})

export const CompanyFilter: React.FC<CompanyFilterProps> = ({
  renderTrigger,
  onDone,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [_, setIsExapanded] = useState<string>('')
  const [loading] = useState<boolean>(false)
  // const { loading: loadingGroups, message: messageGroups, groups } = useQueryGroups()
  const { users } = useUserData()
  const { provinces } = useProvinceData()
  const { folderStatus } = useFolderStatuses()

  const onFilter = (data, { resetForm }) => {
    let province
    if (data.province) province = provinces.find(prov => prov.name === data.province)!.id
    let contact_id
    if (data.contact_id) contact_id = users.find(user => user.username === data.contact_id)!.id
    let folder_status
    if (data.folder_status) folder_status = folderStatus.find(status => status.name === data.folder_status)!.id
    onDone({ ...data, province, folder_status, contact_id })
    handleModalToggle()
  }

  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    handleReset,
    errors,
    handleSubmit,
  } = useFormik<ICompany>({
    validationSchema,
    onSubmit: onFilter,
    initialValues: {
      phone: '',
      post_number: '',
      address: '',
      postal_code: '',
      city: '',
      name: '',
      number: '',
    }
  })

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  };

  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: "shared:company_filter" })}
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        isFooterLeftAligned={false}
        className={styles.modal_root}
      >
        <form
          className={styles.content}
          onSubmit={handleSubmit}
        >
          <label className={styles.field}>
            <span
              className={errors.name && touched.name ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.name && touched.name ? errors.name : "shared:name"}
              />
            </span>
            <TextInput
              name='name'
              value={values.name}
              type="text"
              //css={{}}
              onChange={text => setFieldValue('name', text)}
              onBlur={handleBlur}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.name && touched.name ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.number && touched.number ? errors.number : "shared:number"}
              />
            </span>
            <TextInput
              name='number'
              value={values.number}
              type="text"
              //css={{}}
              onChange={text => setFieldValue('number', text)}
              onBlur={handleBlur}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.phone && touched.phone ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.phone && touched.phone ? errors.phone : "shared:tel_and_post_number"}
              />
            </span>
            <div className="telephone_field">
              <TextInput
                name='phone'
                value={values.phone}
                type="text"
                //css={{}}
                onChange={text => setFieldValue('phone', text)}
                onBlur={e => {
                  handleBlur(e)
                  setFieldValue(
                    'phone',
                    maskNumber(values.phone)
                  )
                }}
                aria-label="text input example"
              />
              <TextInput
                name='post_number'
                value={values.post_number}
                type="number"
                //css={{}}
                onChange={text => setFieldValue('post_number', text)}
                onBlur={handleBlur}
                placeholder={formatMessage({ id: 'shared:post_number' })}
              />
            </div>
          </label>
          <label className={styles.field}>
            <span
              className={errors.address && touched.address ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.address && touched.address ? errors.address : "shared:address"}
              />
            </span>
            <TextInput
              name={'address'}
              value={values.address}
              onChange={text => setFieldValue('address', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.postal_code && touched.postal_code ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.postal_code && touched.postal_code ? errors.postal_code : "shared:postal_code"}
              />
            </span>
            <TextInput
              name={'postal_code'}
              value={values.postal_code}
              onChange={text => setFieldValue('postal_code', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.city && touched.city ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.city && touched.city ? errors.city : "shared:city"}
              />
            </span>
            <TextInput
              name={'city'}
              value={values.city}
              onChange={text => setFieldValue('city', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={e => {
                handleReset(e)
                onDone(undefined)
                handleModalToggle()
              }}
              type="button"
            >
              <FormattedMessage id="shared:cancel_filter" />
            </button>
            <Button
              // isDisabled={Object.keys(touched).length < 1}
              type="submit"
            >
              <FormattedMessage id="shared:filter" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
