import React, { useState } from 'react';
import styles from './Container.module.scss'
import { Role } from 'constants/roles';
import { SideNav } from 'components/';
import { EntryPageRoute } from 'routes';
import {
  Avatar, Brand, Button, ButtonVariant,
  Dropdown, DropdownToggle, DropdownItem,
  KebabToggle,
  Page, PageHeader, PageSection,
  PageSidebar, SkipToContent, Toolbar,
  ToolbarGroup, ToolbarItem, PageHeaderTools, PageHeaderToolsGroup, PageHeaderToolsItem
} from '@patternfly/react-core';
// make sure you've installed @patternfly/patternfly
import accessibleStyles from '@patternfly/react-styles/css/utilities/Accessibility/accessibility';
import spacingStyles from '@patternfly/react-styles/css/utilities/Spacing/spacing';
import { css } from '@patternfly/react-styles';
import { BellIcon, EdgeIcon, CogIcon } from '@patternfly/react-icons';
import imgBrand from 'assets/imgs/logo.svg';
import imgAvatar from 'assets/imgs/logo.svg';
import { Accordion } from 'components';
import { NotificationComponent } from '../../components/notification/Notification'
import { LanguageSwitcher } from 'components'
import { HelpIcon } from '@patternfly/react-icons'
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useUserInfoData, } from 'hooks';
import { AuthStore, AppStore, CandidateStore, CategoryStore, CompanyStore, ActivatedCompanyStore } from 'stores';
import { ILoginInfo } from 'stores/auth.store';
import { AppInfo } from 'dialogs';

export interface containerPageProps {
  readonly url: string;
}

const ContainerComponent: React.FC<containerPageProps> = (
  { url, ...props }
) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [isKebabDropdownOpen, setisKebabDropdownOpen] = useState<boolean>(false)
  const { userInfo } = useUserInfoData()

  const [oneModalOpen, setOneModalOpen] = React.useState<boolean>(false)
  const [click, setClick] = React.useState<number>(0)

  const onDropdownSelect = event => setIsDropdownOpen(!isDropdownOpen)
  const onKebabDropdownSelect = event => setisKebabDropdownOpen(!isKebabDropdownOpen)

  // const onNavSelect = result => {
  //   setactiveGroup(result.groupId)
  //   setactiveItem(result.itemId)
  // }

  const handleLogOut = e => {
    AuthStore.logout()
    AppStore.logout()
    CandidateStore.logout()
    CategoryStore.logout()
    CompanyStore.logout()
    ActivatedCompanyStore.logout()
  }

  const kebabDropdownItems = [
    <NotificationComponent
      setisKebabDropdownOpen={setisKebabDropdownOpen}
      setOneModalOpen={setOneModalOpen}
    >
      {
        (setIsOpen, notifCount) => (
          <DropdownItem
            style={{ color: 'black' }}
            onClick={i => { setIsOpen(true) }}
            className={notifCount > 0 ? styles.notify_class : ''}
          >
            {notifCount > 0 ? (
              <CountIndicator count={notifCount} />
            ) : (
                <BellIcon />
              )}
            <FormattedMessage id="shared:notifications" />
          </DropdownItem>
        )
      }
    </NotificationComponent>,
    <LanguageSwitcher showlang />,
    <AppInfo
      renderTrigger={trigger => (
        <DropdownItem
          onClick={trigger}
          style={{ color: 'black' }}
        >
          <HelpIcon /> <FormattedMessage id="shared:help" />
        </DropdownItem>
      )}
    />,
    <Link
      to='/auth/login'
      style={{ color: 'black' }}
      onClick={handleLogOut}
    >
      <DropdownItem>
        <FormattedMessage id="shared:logout" />
      </DropdownItem>
    </Link>
  ]

  const userDropdownItems = [
    <Link to='/me/profile' style={{ color: 'black' }}>
      <DropdownItem component="button">
        <FormattedMessage id="routes.profile" />
      </DropdownItem>
    </Link>,
    <Link
      to='/auth/login'
      style={{ color: 'black' }}
      onClick={handleLogOut}
    >
      <DropdownItem>
        <FormattedMessage id="shared:logout" />
      </DropdownItem>
    </Link>
  ]

  const CountIndicator = ({ count }) => (
    <div className={styles.counter}>
      {count}
    </div>
  )

  const PageToolbar = (
    <Toolbar>
      <ToolbarGroup className={css(accessibleStyles.screenReader, accessibleStyles.visibleOnLg)}>
        <ToolbarItem>
          <NotificationComponent>
            {
              (setIsOpen, notifCount) => (
                <DropdownToggle
                  // iconComponent={null}
                  onToggle={setIsOpen}
                  aria-label="Applications"
                  id="toggle-id-7"
                  className={notifCount > 0 ? styles.notify_class : ''}
                >
                  {notifCount > 0 ? (
                    <CountIndicator count={notifCount} />
                  ) : (
                      <BellIcon />
                    )}
                </DropdownToggle>
              )
            }
          </NotificationComponent>
        </ToolbarItem>
        <ToolbarItem>
          <LanguageSwitcher />
        </ToolbarItem>
        <AppInfo
          renderTrigger={trigger => (
            <ToolbarItem>
              <Button
                onClick={trigger}
                id="expanded-example-uid-02"
                aria-label="Settings actions"
                variant={ButtonVariant.plain}
              >
                <HelpIcon />
              </Button>
            </ToolbarItem>
          )}
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <ToolbarItem className={css(accessibleStyles.hiddenOnLg, spacingStyles.mr_0)}>
          <Dropdown
            isPlain
            position="right"
            onSelect={onKebabDropdownSelect}
            toggle={<KebabToggle onToggle={
              i => {
                if (click == 0) {
                  setisKebabDropdownOpen(true);
                  setClick(click + 1);
                }
                else if (click >= 1 && !oneModalOpen) {
                  setisKebabDropdownOpen(false);
                  setClick(0);
                }
                else setisKebabDropdownOpen(true)
              }
            } />}
            isOpen={isKebabDropdownOpen}
            dropdownItems={kebabDropdownItems}
          />
        </ToolbarItem>
        <ToolbarItem className={css(accessibleStyles.screenReader, accessibleStyles.visibleOnMd)}>
          <Dropdown
            isPlain
            position="right"
            onSelect={onDropdownSelect}
            isOpen={isDropdownOpen}
            toggle={<DropdownToggle onToggle={setIsDropdownOpen}>{(userInfo as ILoginInfo).username}</DropdownToggle>}
            dropdownItems={userDropdownItems}
          />
        </ToolbarItem>
      </ToolbarGroup>
    </Toolbar>
  )

  const headerTools = (
    <PageHeaderTools>
      <PageHeaderToolsGroup
        /** the settings and help icon buttons are only visible on desktop sizes and replaced by a kebab dropdown for other sizes */
        //@ts-ignore
        visibility={{ default: 'hidden', lg: 'visible' }}
      >
        <PageHeaderToolsItem>
          <NotificationComponent>
            {
              (setIsOpen, notifCount) => (
                <DropdownToggle
                  // iconComponent={null}
                  onToggle={setIsOpen}
                  aria-label="Applications"
                  id="toggle-id-7"
                  className={notifCount > 0 ? styles.notify_class : ''}
                >
                  {notifCount > 0 ? (
                    <CountIndicator count={notifCount} />
                  ) : (
                      <BellIcon />
                    )}
                </DropdownToggle>
              )
            }
          </NotificationComponent>
        </PageHeaderToolsItem>
        <PageHeaderToolsItem>
          <LanguageSwitcher />
        </PageHeaderToolsItem>
        <PageHeaderToolsItem>
          <AppInfo
            renderTrigger={trigger => (
              <ToolbarItem>
                <Button
                  onClick={trigger}
                  id="expanded-example-uid-02"
                  aria-label="Settings actions"
                  variant={ButtonVariant.plain}
                >
                  <HelpIcon />
                </Button>
              </ToolbarItem>
            )}
          />
        </PageHeaderToolsItem>
      </PageHeaderToolsGroup>
      <PageHeaderToolsGroup>
        <PageHeaderToolsItem
          /** this kebab dropdown replaces the icon buttons and is hidden for desktop sizes */
          //@ts-ignore
          visibility={{ lg: 'hidden' }}
        >
          <Dropdown
            isPlain
            position="right"
            onSelect={onKebabDropdownSelect}
            toggle={<KebabToggle onToggle={
              i => {
                if (click == 0) {
                  setisKebabDropdownOpen(true);
                  setClick(click + 1);
                }
                else if (click >= 1 && !oneModalOpen) {
                  setisKebabDropdownOpen(false);
                  setClick(0);
                }
                else setisKebabDropdownOpen(true)
              }
            } />}
            isOpen={isKebabDropdownOpen}
            dropdownItems={kebabDropdownItems}
          />
        </PageHeaderToolsItem>
        <PageHeaderToolsItem
          /** this user dropdown is hidden on mobile sizes */
          //@ts-ignore
          visibility={{ default: 'hidden', md: 'visible' }}
        >
          <Dropdown
            isPlain
            position="right"
            onSelect={onDropdownSelect}
            isOpen={isDropdownOpen}
            toggle={<DropdownToggle onToggle={setIsDropdownOpen}>{(userInfo as ILoginInfo).username}</DropdownToggle>}
            dropdownItems={userDropdownItems}
          />
        </PageHeaderToolsItem>
      </PageHeaderToolsGroup>
      <Avatar src={imgAvatar} alt="Avatar image" />
    </PageHeaderTools>
  )

  const Header = (
    <PageHeader
      logo={<Brand src={imgBrand} alt="Patternfly Logo" />}
      headerTools={headerTools}
      // avatar={<Avatar src={imgAvatar} alt="Avatar image" />}
      showNavToggle
    />
  )

  const Sidebar = <PageSidebar nav={SideNav(url)} theme="dark" />

  const pageId = 'main-content-page-layout-expandable-nav'

  const PageSkipToContent = <SkipToContent href={`#${pageId}`}>
    Skip to content
    </SkipToContent>;

  return (
    <Page
      header={Header}
      sidebar={Sidebar}
      isManagedSidebar
      skipToContent={PageSkipToContent}
      mainContainerId={pageId}
    >
      <Accordion
      />
      <PageSection className={styles.section}>
        <div className={styles.wrapper}>
          <EntryPageRoute
            url={url}
          />
        </div>
      </PageSection>
    </Page>
  )
}

export {
  ContainerComponent as ContainerPage
}