import BaseService from './BaseService'
import { companyUrls } from './urls'

class CompanyService {
    static list_companies = (suffix) => {
        return BaseService.getRequest(companyUrls.COMPANIES(suffix), true)
    }
    static list_activated_companies = (suffix) => {
        return BaseService.getRequest(companyUrls.ACTIVE_COMPANIES(suffix), true)
    }
    static new_company = (data) => {
        return BaseService.postRequest(
            companyUrls.NEW_COMPANY, data, true
        )
    }
    static send_company_creation_email = (data) => {
        return BaseService.postRequest(
            companyUrls.COMPANY_CREATION_EMAIL, data, true
        )
    }
    static new_external_company = (data) => {
        return BaseService.postRequest(
            companyUrls.NEW_EXTERNAL_COMPANY, data, false
        )
    }
    static delete_companies = (data) => {
        return BaseService.deleteRequest(
            companyUrls.DELETE_COMPANIES, data, true
        )
    }
    static update_company = (id, data) => {
        return BaseService.putRequest(
            companyUrls.UPDATE_COMPANY(id), data, true
        )
    }
    static update_company_activation_status = (id, data) => {
        return BaseService.putRequest(
            companyUrls.ACTIVATE_COMPANY(id), data, true
        )
    }

}

export default CompanyService;