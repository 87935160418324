import React, { useState, useEffect } from 'react';
import styles from './ListCategories.module.scss'
import {
  Modal, Button, Spinner, spinnerSize
} from '@patternfly/react-core';
import {
  CloseIcon,
} from '@patternfly/react-icons'

import {
  FormattedMessage, useIntl
} from 'react-intl'
import { ListEmpty } from 'components';
import { useNotificationData } from 'hooks';
import { QuestionService } from 'services';
import { RouteComponentProps } from 'react-router';

export interface ConfirmDialogProps {
  // categories: any;
  isModalOpen: boolean
  onClose: any;
  question: any;
}

export const ListCategories: React.FC<ConfirmDialogProps> = ({
  isModalOpen,
  question: {
    title,
    id: questionId,
    properties: categories = [],
  },
  onClose,
  ...props
}) => {

  const [loading, setLoading] = useState<boolean>(false)
  const [toDelete, setToDelete] = useState<string>('')


  const { formatMessage } = useIntl()
  const { new_notification } = useNotificationData()
  const [stateCategories, setStateCategories] = useState<any[]>(categories)

  const removeCategory = (id) => {
    setLoading(true)
    setToDelete(id)
    QuestionService.removeCategoryFromQuestion(
      id, { question_ids: [questionId], }
    )
      .catch(err => {
        setToDelete('')
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          setToDelete('')
          setStateCategories(
            stateCategories.filter(
              _ => _.category_id !== id
            )
          )
          new_notification({
            message,
            title: formatMessage({ id: "shared:success" }),
            variant: 'success'
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

          setLoading(false)
        }
      })
  }

  useEffect(() => {
    if (categories.length > 0)
      setStateCategories(categories)
  }, [categories])


  return (
    <>
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage(
              { id: 'list_categories:select_category_prompt' }
            )}
          </span>
        }
        isOpen={isModalOpen}
        onClose={() => onClose(stateCategories)}
        className={styles.modal_root}
        width={'50%'}
        actions={[
          <div className={styles.footer}>
            <Button
              key="confirm"
              variant="primary"
              onClick={() => onClose(stateCategories)}
              isDisabled={loading}
            >
              <FormattedMessage id={'shared:close'} />
              {loading && (
                <Spinner
                  size={spinnerSize.md}
                />
              )}
            </Button>
          </div>
        ]}
      >
        <div className={styles.wrapper}>
          <div className={styles.question}>
            {title}
          </div>
          {stateCategories.length > 0 ? (
            <ul className={styles.list}>
              {stateCategories.map(({ category_name, category_id }) => (
                <li className={styles.list__item}>
                  <span>{category_name}</span>
                  {toDelete === category_id && loading ? (
                    <Spinner size={spinnerSize.md} />
                  ) : (
                      <button
                        className={styles.btn_close}
                        onClick={_ => removeCategory(category_id)}
                      >
                        <CloseIcon />
                      </button>
                    )}
                </li>
              ))}
            </ul>
          ) : (
              <ListEmpty
                title={
                  formatMessage({ id: 'shared:no_categories' })
                }
                message={
                  formatMessage({ id: "shared:no_categories" })
                }
              />
            )}
        </div>
      </Modal>
    </>
  );
}
