import React, { useState, useEffect } from 'react';
import styles from './DepartmentNewUser.module.scss'
import {
  Modal, Button, TextInput, Form, Checkbox
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
import {
  useUserDataH, useNotificationData,
} from 'hooks';
import { UserService, DepartmentService } from 'services';
import { maskNumber } from 'utilities';


export interface DepartmentNewUserProps {
  readonly renderTrigger?: any;
  userToUpdate?: any;
  setUserToUpdate?: any
  onCreate: any
  onUpdate: any
  department: any,
}

export interface IUser {
  username: string,
  email: string,
  family_name: string,
  lastname: string,
  send_email: boolean,
  phone: string,
  post_number: string,
  fax: string,
}

const validationSchema = yup.object().shape({
  username: yup.string().required('shared:username_required'),
  lastname: yup.string().required('shared:lsatname_required'),
  email: yup.string().email("shared:invalid_email")
    .required("shared:email_required"),
  family_name: yup.string().required('shared:firstname_required'),
})

export const DepartmentNewUser: React.FC<DepartmentNewUserProps> = ({
  renderTrigger, userToUpdate, setUserToUpdate,
  onCreate, onUpdate, department: { id: department_id, company_id },
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(Boolean(userToUpdate) ?? false)
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const { new_notification, } = useNotificationData()

  const saveMemberToApi = e => {
    const post_data = {
      username: values.username,
      email: values.email,
      firstName: values.family_name,
      lastName: values.lastname,
      phone: values.phone,
      send_email: values.send_email,
      post_number: values.post_number,
      user_id: userToUpdate ? userToUpdate.id : undefined,
      fax: values.fax,
      department_id,
      company_id,
    }

    setLoading(true)

    userToUpdate ?
      UserService.update_user(post_data)
        .catch(err => {
          setLoading(false)
          new_notification({
            message: formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        })
        .then(async response => {
          setLoading(false)
          if ([200, 201].includes(response.status)) {
            const { data, message } = await response.json()
            setIsModalOpen(false)
            onUpdate(data)
            new_notification({
              message,
              variant: 'success',
              title: formatMessage({ id: 'shared:success' }),
            })
            onUpdate(data)
            resetForm()
            setIsModalOpen(false)
            setUserToUpdate(null)
          } else if (response.json) {
            const { message } = await response.json()
            new_notification({
              message,
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          } else {
            new_notification({
              message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          }
        })
      : DepartmentService.new_user(department_id, post_data)
        .catch(err => {
          setLoading(false)
          new_notification({
            message: formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        })
        .then(async response => {
          setLoading(false)
          if ([200, 201].includes(response.status)) {
            const { data, message } = await response.json()
            setIsModalOpen(false)
            onCreate(data)
            new_notification({
              message,
              variant: 'success',
              title: formatMessage({ id: 'shared:success' }),
            })
            resetForm()
            setIsModalOpen(false)
          } else if (response.json) {
            const { message } = await response.json()
            new_notification({
              message,
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          } else {
            new_notification({
              message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
              variant: 'error',
              title: formatMessage({ id: 'shared:error' }),
            })
          }
        })
  }

  const [initialValues, setInitialValues] = useState<any>({
    username: '',
    email: '',
    family_name: '',
    lastname: '',
    phone: '',
    post_number: '',
    fax: '',
    send_email: false,
  })


  useEffect(() => {
    if (Boolean(userToUpdate)) {
      setIsModalOpen(true)
      setInitialValues({
        username: userToUpdate.username || '',
        email: userToUpdate.email || '',
        send_email: userToUpdate.send_email || false,
        family_name: userToUpdate.firstName || '',
        lastname: userToUpdate.lastName || '',
        phone: userToUpdate.phone ? userToUpdate.phone : '',
        post_number: userToUpdate.post_number ? userToUpdate.post_number : '',
        fax: userToUpdate.fax ? userToUpdate.fax : '',
      })
    }
  }, [userToUpdate])


  // for form processing
  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    errors,
    handleSubmit,
    resetForm,
  } = useFormik<IUser>({
    validationSchema,
    onSubmit: saveMemberToApi,
    enableReinitialize: true,
    initialValues,
  })

  // Open/Close modal
  const handleModalToggle = () => {
    if (isModalOpen && userToUpdate) setUserToUpdate(null)
    setIsModalOpen(!isModalOpen)
  };

  const handleCancel = e => {
    handleModalToggle();
    values.username = ''
    values.email = ''
    values.family_name = ''
    values.lastname = ''
    values.phone = ''
    values.post_number = ''
    values.fax = ''
    resetForm();
    if (userToUpdate)
      setUserToUpdate(null)
  }

  return (
    <>
      {renderTrigger ? renderTrigger(handleModalToggle) : ''}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            <FormattedMessage
              id={userToUpdate ? "user:update_user" : "user:add_new_user"}
            />
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        //isFooterLeftAligned
        className={styles.modal_root}
      >
        <Form
          className={styles.content}
          onSubmit={handleSubmit}
        >

          <label className={styles.field}>
            <span
              className={errors.username && touched.username ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.username && touched.username ? errors.username : "shared:username"}
              />
              <span className={styles.c_error}>*</span>
            </span>

            <TextInput
              name='username'
              value={values.username}
              type="text"
              onChange={text => setFieldValue('username', text)}
              onBlur={handleBlur}
              aria-label="text input example"
              isDisabled={userToUpdate ? true : false}
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.email && touched.email ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.email && touched.email ? errors.email : "shared:email"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'email'}
              value={values.email}
              onChange={text => setFieldValue('email', text)}
              onBlur={handleBlur}
              type="text"
              isDisabled={userToUpdate ? true : false}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.family_name && touched.family_name ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.family_name && touched.family_name ? errors.family_name : "shared:family_name"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'family_name'}
              value={values.family_name}
              onChange={text => setFieldValue('family_name', text)}
              onBlur={handleBlur}
              type="text"
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:lastname" />
            </span>
            <TextInput
              name={'lastname'}
              value={values.lastname}
              onChange={text => setFieldValue('lastname', text)}
              onBlur={handleBlur}
              type="text"
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:fax" />
            </span>
            <TextInput
              name={'fax'}
              value={values.fax}
              onChange={text => setFieldValue('fax', text)}
              onBlur={handleBlur}
              type="text"
              aria-label="text input example"
            />
          </label>
          <label htmlFor="post_number" className={styles.field}>
            <span>
              <FormattedMessage id="shared:tel_and_post_number" />
            </span>
            <div className="telephone_field">
              <TextInput
                name={'phone'}
                value={values.phone}
                onChange={text => setFieldValue('phone', text)}
                onBlur={e => {
                  handleBlur(e)
                  setFieldValue(
                    'phone',
                    maskNumber(values.phone)
                  )
                }} type="text"
                aria-label="text input example"
              />
              {Boolean(values.phone) && (
                <TextInput
                  name={'post_number'}
                  id={'post_number'}
                  value={values.post_number}
                  onChange={text => setFieldValue('post_number', text)}
                  onBlur={handleBlur}
                  type="number"
                  placeholder={formatMessage({ id: "shared:post_number" })}
                  aria-label="text input example"
                />
              )}
            </div>
          </label>
          <label className={styles.field}>
            <Checkbox
              id="c"
              label={formatMessage({ id: errors.send_email && touched.send_email ? errors.send_email : "shared:send_email" })}
              isChecked={values.send_email}
              onChange={_ => setFieldValue('send_email', _)}
              aria-label="controlled checkbox example"
              name={'send_email'}
              onBlur={handleBlur}
              className={errors.send_email && touched.send_email ? styles.c_error : ''}
            />
          </label>

          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={handleCancel}
              type="button"
            >
              <FormattedMessage id="shared:cancel" />
            </button>
            <Button
              disabled={loading}
              type="submit"
              isDisabled={loading || Object.keys(errors).length > 0}
            >
              <FormattedMessage id={userToUpdate ? "shared:save" : "user:create_user"} />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
