import
React,
{
    useState, useEffect, useRef, useCallback
} from 'react';
import styles from './ActivatedCompany.module.scss'
import {
    useAccordionData, useNotificationData,
    useActivatedCompanyData, usePricingPlanData,
} from 'hooks';
import {
    useIntl, FormattedMessage
} from 'react-intl';
import {
    Button, ButtonVariant, TextInput, Spinner, spinnerSize
} from '@patternfly/react-core';
import { CloseIcon } from '@patternfly/react-icons';
import {
    CompanyService, DepartmentService,
} from 'services';
import { ConfirmDialog, ActivatedCompanyFilter } from 'dialogs';
import clsx from 'classnames'
import { IActivatedCompany } from 'stores';
import moment from 'moment'
import { ListEmpty } from 'components';


export const ActivatedCompany = ({ is_embeded, ...props }) => {

    const [
        selectedCompanies, setSelectedCompanies,
    ] = useState<any[]>([])

    const [
        selectedDepartments, setSelectedDepartments,
    ] = useState<any[]>([])
    const [
        selectedCompany, setSelectedCompany,
    ] = useState<string>('')
    const { pricing_plan } = usePricingPlanData()
    const { resetAccordion } = useAccordionData()
    const { formatMessage } = useIntl()
    const { new_notification } = useNotificationData()
    const [loading, setLoading] = useState<boolean>(false)
    const columns = [
        formatMessage({ id: "active_company:departments" }),
        formatMessage({ id: "active_company:users" }),
        formatMessage({ id: "active_company:complete_demands" }),
        formatMessage({ id: "active_company:candidates" }),
        formatMessage({ id: "active_company:incomplete_demands" }),
    ]
    const {
        appendCompanies, start: storeStart,
        companies: storeCompanies, resetCompanies,
        limit: storeLimit, total: storeTotal,
        deleteCompany,
    } = useActivatedCompanyData()
    const [toDeactivate, setToDeactivate] = useState<string[]>([])
    const [rows, setRows] = useState<any[]>([])
    const [query, setQuery] = useState<string>('')
    const [searchStart, setSearchStart] = useState<number>(0)
    const [searchTotal, setSearchTotal] = useState<number>(0)
    let timeout;
    const [nameNewDep, setNameNewDep] = useState<string>('')
    const [toDelete, setToDelete] = useState<string[]>([])
    const [filterState, setFilterState] = useState<any>()
    const centinelRef = useRef<any>(null)
    const [observerTriggerState, setObserverTriggerState] = useState<any>({})
    const [companyIdNewDepartment, setTriggerCompanyIdNewDepartment] = useState<string>('')

    useEffect(() => {
        configureInfiniteScroll()
        if (is_embeded) { return }
        resetAccordion({
            title: formatMessage({ id: 'routes.activated_companies' }),
            sub_title: ''
        })
    }, [])

    useEffect(() => {
        clearTimeout(timeout)
        setFilterState(undefined)
        //text in search field has been emptied
        if (query === '') {
            setRows(storeCompanies)
            return
        }
        //On each new search, searchStart goes to zero
        setSearchStart(0)
        timeout = setTimeout(() => {
            searchCompanies()
        }, 300);
    }, [query])

    const fetchCompanies = (
        paramStart: number,
        paramSearching: boolean,
        filter?: any,
        absolute?: any,
    ) => {
        setLoading(true)
        let suffix = `?start=${paramStart}&limit=${30}`
        if (absolute !== null && Object.keys(filter || filterState || {}).length > 0) {
            Object.keys(
                filter || filterState
            ).forEach(field => {
                if (Boolean((filter || filterState)[field])) {
                    suffix = `${suffix}&${field}=${(filter || filterState)[field]}`
                }
            })
        } else if (Boolean(paramSearching)) {
            suffix = `${suffix}&keyword=${query}`
        }
        CompanyService.list_activated_companies(suffix)
            .catch(_ => {
                setLoading(false)
            })
            .then(async response => {
                setLoading(false)
                if ([200, 201].includes(response.status)) {
                    const { results, start, total, limit } = await response.json()
                    //if we are not searching nore filtering
                    if (!paramSearching && (absolute !== null && !Boolean(filter || filterState))) { //Not searching
                        if (start < 1) {
                            console.log('start less than one')
                            resetCompanies({ data: results, start, total, limit, })
                        }
                        else {
                            console.log('start greater than one')
                            appendCompanies({ data: results, start, limit, total })
                        }
                    } else { //Searching 
                        setRows([...(start > 0 ? rows : []), ...results])
                        console.log('start searching')
                        setSearchTotal(total)
                        setSearchStart(start)
                    }
                } else if (response.json) {
                    const { message } = await response.json()
                    setLoading(false)

                    new_notification({
                        message,
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                } else {
                    setLoading(false)

                    new_notification({
                        message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                }
            })
    }

    useEffect(() => {
        if (storeCompanies && !query) {
            setRows(storeCompanies)
        }
    }, [storeCompanies])

    const deactivateEnterprise = (company_id) => {
        setLoading(true)
        const data = {
            status: false,
        }
        CompanyService.update_company_activation_status(company_id, data)
            .catch(_ => {
                setLoading(false)
                new_notification({
                    message: formatMessage({ id: "shared:unkown_error" }),
                    title: 'success',
                    variant: 'success'
                })
            })
            .then(async response => {
                setLoading(false)
                if ([200, 201].includes(response.status)) {
                    const { message } = await response.json()
                    new_notification({
                        message,
                        title: 'success',
                        variant: 'success'
                    })
                    deleteCompany(company_id)
                    setToDeactivate([])
                    //   setSelectedCandidtesIds([])
                    setLoading(false)
                } else if (response.json) {
                    const { message } = await response.json()
                    setLoading(false)

                    new_notification({
                        message,
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                } else {
                    setLoading(false)

                    new_notification({
                        message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                }
            })
    }


    const parsePricingPlan = id => {
        if (`${id}` === '1') {
            return formatMessage({ id: 'shared:pricing_per_unit' })
        } else if (`${id}` === '2') {
            return formatMessage({ id: "shared:pricing_per_month" })
        } else {
            return (pricing_plan.find(_ => _.id === id) || { name: '' })!.name
        }
    }


    const parseCompanies = (data: IActivatedCompany[]) => {
        return data.map(datum => ({
            one: {
                name: datum.name || formatMessage({ id: "shared:n_a" }),
                matricule: datum.number || formatMessage({ id: "shared:n_a" }),
                status: formatMessage({
                    id: datum.is_active ? "shared:active" : "shared:in_inactive"
                }),
            },
            two: {
                last_update: moment.utc(
                    datum.updated_at.replace("-05:00", "")
                )
                    .utcOffset("-05:00")
                    .format('YYYY-MM-DD'),
                pricing_plan: parsePricingPlan(datum.pricing_plan_id),
            },
            three: {
                departments_count: datum.number_of_departments || formatMessage({ id: "shared:n_a" }),
                address: datum.address || formatMessage({ id: "shared:n_a" }),
                balance: datum.balance,
            },
            id: datum.id,
            departments: datum.departments.map(
                ({ id, ...department }) => ({
                    id,
                    fields: [
                        department.name,
                        department.total_users,
                        department.total_complete_demands,
                        department.total_candidates,
                        department.total_incomplete_demands,
                    ]
                })
            )
        })
        )
    }

    const onNextPage = () => {
        if (query !== '') {
            if (searchTotal !== 0 && rows.length >= searchTotal) { return }
            fetchCompanies(searchTotal === 0 ? 0 : searchStart + storeLimit, true)
            return
        }
        if (storeTotal !== 0 && storeCompanies.length >= storeTotal) { return }
        fetchCompanies(storeTotal === 0 ? 0 : storeStart + storeLimit, false)
        // setStart(storeStart + storeLimit)
    }

    const searchCompanies = (filter?) => {
        fetchCompanies(0, filter ? false : true, filter)
    }

    const handleDeactivate = id => setToDeactivate(id)

    const handleSelectCompany = id => () => {
        const idx = selectedCompanies.findIndex(id_company => id_company === id)
        let newCompanies = [...selectedCompanies]
        if (idx !== -1) {
            newCompanies.splice(idx, 1)
            setSelectedCompanies(newCompanies)
        } else {
            setSelectedCompanies([...selectedCompanies, id])
        }
    }

    const handleSelectDepartment = id => () => {
        const idx = selectedDepartments.findIndex(
            id_department => id_department === id
        )
        let newDepartment = [...selectedDepartments]
        if (idx !== -1) {
            newDepartment.splice(idx, 1)
            setSelectedDepartments(newDepartment)
        } else {
            setSelectedDepartments([...selectedDepartments, id])
        }
    }

    const showCompanyDepartments = id => () => {
        setSelectedDepartments([])
        if (selectedCompany === id) {
            setSelectedCompany('')
            return
        }
        setSelectedCompany(id)
    }

    const handleSelectAllDepartments = id => () => {
        let company = rows.find(row => row.id === id)
        if (Boolean(company)) {
            let departments = company.departments.map(({ id }) => id)
            if (selectedDepartments.length === departments.length) {
                setSelectedDepartments([])
            } else {
                setSelectedDepartments(departments)
            }
        }
    }

    const deleteDepartmentFormFromApi = (...department_ids: string[]) => {
        setLoading(true)
        DepartmentService.delete_departments(
            { company_id: selectedCompany, department_ids }
        )
            .catch(_ => {
                setLoading(false)
            })
            .then(async response => {
                setLoading(false)
                if ([200, 201].includes(response.status)) {
                    const { message } = await response.json()
                    new_notification({
                        message,
                        title: 'success',
                        variant: 'success'
                    })
                    deleteDepartments(selectedCompany, ...department_ids)
                    setToDelete([])
                    setSelectedDepartments([])
                    setLoading(false)
                } else if (response.json) {
                    const { message } = await response.json()
                    setLoading(false)

                    new_notification({
                        message,
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                } else {
                    setLoading(false)

                    new_notification({
                        message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                }
            })
    }

    const loadMore = useCallback((entries) => {
        const target = entries[0]
        if (target.isIntersecting && !loading) {

            //The following call will trigger the useEffect that depends on observerTriggerState variable.
            setObserverTriggerState({})
        }
    }, [loading, onNextPage, storeLimit, storeCompanies, storeStart])


    useEffect(() => {
        onNextPage()
    }, [observerTriggerState])


    const configureInfiniteScroll = () => {
        const options = {
            root: null, //use window as intersection box
            rootMargin: '0px',
            threshold: 0.25,
        }

        //create observer 
        const observer = new IntersectionObserver(loadMore, options)
        if (centinelRef && centinelRef.current)
            observer.observe(centinelRef.current)

        // cleanup everything on UnMount
        return () => observer && observer.disconnect()
    }

    const parsePagination = () => {
        const count = rows.length
        let total = storeTotal
        if (Boolean(query) || Boolean(filterState)) {
            total = searchTotal
        }
        return `${count} ${formatMessage({ id: "shared:of" })} ${total}`
    }

    const appendDepartments = (company_id, data) => {
        const newCompanies = [...rows]
        const foundIdx = newCompanies.findIndex(
            _ => _.id === company_id
        )!
        newCompanies[foundIdx].number_of_departments++
        const deps = [
            ...(newCompanies[foundIdx].departments || []),
            data
        ]
        newCompanies[foundIdx].departments = deps
        setRows(newCompanies)
    }
    const deleteDepartments = (company_id, ...depId: string[]) => {
        const newCompanies = [...rows]
        const foundIdx = newCompanies.findIndex(
            _ => _.id === company_id
        )!
        newCompanies[foundIdx].departments = (
            newCompanies[foundIdx].departments || []
        ).filter(_ => !depId.includes(_.id))
        newCompanies[foundIdx].number_of_departments = newCompanies[foundIdx].departments.length
        setRows(newCompanies)
    }

    const handleDepartmentCreated = () => {
        let company_id = companyIdNewDepartment
        setLoading(true)
        DepartmentService.new_department(
            {
                company_id,
                name: nameNewDep, description: '',
            }
        )
            .catch(_ => {
                setLoading(false)
            })
            .then(async response => {
                setLoading(false)
                if ([200, 201].includes(response.status)) {
                    const { message, data } = await response.json()
                    new_notification({
                        message,
                        title: 'success',
                        variant: 'success'
                    })
                    appendDepartments(company_id, data)
                    setTriggerCompanyIdNewDepartment('')
                    setLoading(false)
                } else if (response.json) {
                    const { message } = await response.json()
                    setLoading(false)

                    new_notification({
                        message,
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                } else {
                    setLoading(false)

                    new_notification({
                        message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                }
            })
    }

    const handleFilter = state => {
        setSelectedCompanies([])
        setQuery('')
        setFilterState(state)
        if (state === undefined) {
            // searchCandidates(undefined, null)
            setRows(storeCompanies)
            return
        }
        searchCompanies(state)
    }

    return (
        <div className={is_embeded ? "" : styles.container}>

            <ConfirmDialog
                isModalOpen={toDeactivate.length > 0}
                onAction={() => deactivateEnterprise(toDeactivate)}
                actionAlignLeft={false}
                loading={loading}
                actionText={formatMessage({ id: "shared:deactivate" })}
                title_message={formatMessage({ id: "shared:deactivate_enterprise" })}
                confirmation_message={"shared:deactivate_enterprise_prompt"}
                onModalClose={() => setToDeactivate([])}
            />

            <ConfirmDialog
                isModalOpen={toDelete.length > 0}
                onAction={() => deleteDepartmentFormFromApi(...toDelete)}
                actionAlignLeft={false}
                loading={loading}
                actionText={formatMessage({ id: "shared:delete" })}
                title_message={formatMessage({ id: "shared:delete_department" })}
                confirmation_message={"shared:delete_company_department"}
                onModalClose={() => setToDelete([])}
            />

            <ConfirmDialog
                isModalOpen={Boolean(companyIdNewDepartment)}
                onAction={handleDepartmentCreated}
                actionAlignLeft={false}
                loading={loading}
                actionText={formatMessage({ id: "shared:create" })}
                title_message={formatMessage({ id: "shared:create_department" })}
                onModalClose={() => setTriggerCompanyIdNewDepartment('')}
            >
                <div className={styles.new_dep_container}>
                    <div className={styles.inline}>
                        <span>
                            <FormattedMessage id={"shared:create_department_prompt"} />
                        </span> : {' '}
                        <br />
                        <span>{
                            rows.find(row => row.id === companyIdNewDepartment) && (
                                rows.find(row => row.id === companyIdNewDepartment)
                                    .name
                            )
                        }</span>
                    </div>
                    <TextInput
                        style={{ width: '100%' }}
                        onChange={setNameNewDep}
                        placeholder={formatMessage({ id: "new_dep:enter_dep_name" })}
                    />
                </div>
            </ConfirmDialog>

            <section className={styles.table}>
                <header className={styles.table_header}>
                    <TextInput
                        type="search"
                        placeholder={formatMessage({ id: "shared:search" })}
                        onChange={setQuery}
                        className={styles.input_search}
                    />
                    <ActivatedCompanyFilter
                        onDone={handleFilter}
                        renderTrigger={trigger => (
                            <Button variant={"secondary"} onClick={trigger}>
                                {Boolean(filterState) ? (
                                    <FormattedMessage id="shared:cancel_filter" />
                                ) : (
                                        <FormattedMessage id="shared:filter" />
                                    )}
                            </Button>
                        )}
                    />
                    {selectedDepartments.length > 0 && (
                        <Button
                            onClick={i => {
                                setToDelete(selectedDepartments)
                                setSelectedDepartments([])
                            }}
                            variant={"secondary"}
                        >
                            <CloseIcon />
                            <FormattedMessage id="shared:delete_department" />
                        </Button>
                    )}
                    {selectedCompanies.length > 0 && (
                        <Button
                            onClick={i => {
                                setToDeactivate(selectedCompanies)
                                setSelectedCompanies([])
                            }}
                            variant={"secondary"}
                        >
                            <CloseIcon />
                            <FormattedMessage id="shared:deactivate_company" />
                        </Button>
                    )}
                    <div className={styles.counter}>
                        <span>{parsePagination()}</span>
                        <span>
                            {loading && <Spinner size={spinnerSize.md} />}
                        </span>

                    </div>
                </header>
                {rows.length === 0 && !loading && (
                    <ListEmpty />
                )}
                {rows.length > 0 && (
                    <table
                        className={
                            "pf-c-table pf-m-compact pf-m-grid-md pf-m-no-border-rows pf-m-expandable table__no-border"
                        }
                        role="grid"
                    >
                        {parseCompanies(rows).map(row => (
                            <tbody role="rowgroup">
                                <tr
                                    role="row"
                                    className={clsx(
                                        selectedCompany === row.id ? (
                                            "pf-c-table__expandable-row pf-m-expanded pf-m-no-border-rows"
                                        )
                                            : (
                                                "pf-m-no-border-rows"
                                            )
                                    )}
                                >
                                    <td className="pf-c-table__toggle" role="cell">
                                        <button
                                            onClick={showCompanyDepartments(row.id)}
                                            className="pf-c-button pf-m-plain pf-m-expanded"
                                            aria-labelledby="table-compact-expandable-node4 table-compact-expandable-expandable-toggle4"
                                            id="table-compact-expandable-expandable-toggle4" aria-label="Details"
                                            aria-controls="table-compact-expandable-content4" aria-expanded="true"
                                        >
                                            <div className="pf-c-table__toggle-icon">
                                                <i
                                                    className={clsx(
                                                        "fas",
                                                        row.id === selectedCompany ? "fa-angle-down" : "fa-angle-right"
                                                    )}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </button>
                                    </td>
                                    <td className="pf-c-table__check" role="cell">
                                        <input
                                            type="checkbox"
                                            name="table-compact-expandable-checkrow4"
                                            aria-labelledby="table-compact-expandable-node4"
                                            onChange={handleSelectCompany(row.id)}
                                            checked={selectedCompanies.includes(row.id)}
                                        />
                                    </td>
                                    <th role="columnheader" >
                                        <div className={styles.three_row}>
                                            <span>{row.one.name}</span>
                                            <span>
                                                <FormattedMessage id="shared:matricule" />: {row.one.matricule}
                                            </span>
                                            <span>{row.one.status}</span>
                                        </div>
                                    </th>
                                    <td role="cell" >
                                        <div className={styles.one_row}>
                                            <span>
                                                <FormattedMessage id="shared:last_update" />: {row.two.last_update}
                                            </span>
                                            <span>
                                                <FormattedMessage id="shared:pricing_plan" />: {row.two.pricing_plan}
                                            </span>
                                        </div>
                                    </td>
                                    <td role="cell" >
                                        <div className={styles.one_row}>
                                            <span>
                                                <FormattedMessage id="shared:department" />:
                                        {row.three.departments_count}
                                            </span>
                                            <span>
                                                <FormattedMessage id="shared:address" />:
                                                {row.three.address}
                                            </span>
                                            <span
                                                style={{
                                                    color: +row.three.balance! > 100 ? 'inherit' : 'red'
                                                }}
                                            >
                                                <FormattedMessage id="shared:balance" />:
                                                ${row.three.balance}
                                            </span>
                                        </div>
                                    </td>
                                    <td role="cell" >
                                        <Button
                                            onClick={_ => setTriggerCompanyIdNewDepartment(row.id)}
                                            variant={ButtonVariant.secondary}
                                            className={styles.btn_new_department}
                                        >
                                            <FormattedMessage id="shared:new_department" />
                                        </Button>
                                    </td>
                                    <td role="cell" >
                                        <Button
                                            variant="link"
                                            onClick={_ => handleDeactivate(row.id)}
                                            className={styles.btn_delete_company}
                                        >
                                            <FormattedMessage id="shared:deactivate_enterprise" />
                                        </Button>
                                    </td>
                                </tr>
                                {row.id === selectedCompany ? (
                                    <tr
                                        className="pf-c-table__expandable-row pf-m-expanded pf-m-no-border-rows"
                                        role="row"
                                    >
                                        <td className="pf-m-no-padding" role="cell" colSpan={7} id="table-compact-expandable-content4">
                                            {/* start section of inner table*/}
                                            <table
                                                className="pf-c-table pf-m-compact pf-m-grid-md pf-m-no-border-rows"
                                                role="grid" aria-label="This is a compact table with border rows example"
                                                id="table-compact-no-borders"
                                            >
                                                <thead>
                                                    <tr role="row">
                                                        <td className="pf-c-table__check" role="cell">
                                                            <input
                                                                type="checkbox"
                                                                name="table-compact-no-borders-check-all"
                                                                aria-label="Select all rows"
                                                                checked={
                                                                    rows
                                                                        .find(r => r.id === selectedCompany)
                                                                        .departments.length > 0 &&
                                                                    rows
                                                                        .find(r => r.id === selectedCompany)
                                                                        .departments.map(d => d.id)
                                                                        .every(id => selectedDepartments.includes(id))
                                                                }
                                                                onChange={handleSelectAllDepartments(row.id)}
                                                            />
                                                        </td>
                                                        {columns.map(
                                                            column => (
                                                                <th role="columnheader" scope="col">{column}</th>
                                                            )
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody role="rowgroup">
                                                    {row.departments.map(({ fields, id: depId }) => (
                                                        <tr role="row">
                                                            <td className="pf-c-table__check" role="cell">
                                                                <input
                                                                    type="checkbox"
                                                                    name="table-compact-no-borders-checkrow3"
                                                                    aria-labelledby="table-compact-no-borders-name3"
                                                                    checked={selectedDepartments.includes(depId)}
                                                                    onChange={handleSelectDepartment(depId)}
                                                                />
                                                            </td>
                                                            {fields.map(field => (
                                                                <td role="cell" >{field}</td>
                                                            ))}

                                                            <td role="cell" >
                                                                <Button
                                                                    variant="link"
                                                                    component="a"
                                                                    onClick={_ => setToDelete([depId])}
                                                                >
                                                                    <FormattedMessage id="shared:delete" />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {/* end section of inner table*/}
                                        </td>
                                    </tr>
                                ) : (
                                        <>
                                        </>
                                    )}
                                <tr
                                    ref={centinelRef}
                                    className={clsx(
                                        "pf-c-table__expandable-row",
                                        styles.counter
                                    )}
                                    role="row"
                                >
                                    <span>
                                        {loading && <Spinner size={spinnerSize.md} />}
                                    </span>
                                    <span>{parsePagination()}</span>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                )}
            </section>
        </div>
    )
}
