import React, { useState, useEffect } from 'react';
import styles from './NewCandidate.module.scss'
import {
  Modal, Button, TextInput,
  Select, SelectOption, SelectVariant, TextArea,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
// import DayPicker from 'react-day-picker';
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
import { ICandidate } from 'stores';
import { useCandidatesData, useUserData, useProvinceData, useNotificationData, useUserRoleData } from 'hooks';
import { CandidateService } from 'services';
import { maskNumber } from 'utilities';



export interface NewCandidateProps {
  readonly renderTrigger?: any;
  readonly onClose: any;
  readonly onUpdate?: any;
  readonly candidate?: ICandidate,
  setToBeModifiedCandidate?: any,
}

const validationSchema = yup.object().shape({
  firstname: yup.string().required("shared:firstname_required"),
  email: yup.string().email("shared:invalid_email").required("shared:email_required"),
  phone_number: yup.string()
  .max(20, "shared:phonenumber_too_long")
    .matches(
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
      "shared:invalid_phonenumber"
    ),
  province: yup.string().required("shared:province_required"),
  request_email: yup.string().email("shared:invalid_email").required('shared:request_email_required'),
  contact_id: yup.string().required("shared:contact_id_required"),
})

export const NewCandidate: React.FC<NewCandidateProps> = ({
  renderTrigger,
  onClose,
  candidate,
  setToBeModifiedCandidate,
  onUpdate,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [isOpenProvince, setIsExapandedProvince] = useState<boolean>(false)
  const [isOpenContact, setisOpenContact] = useState<boolean>(false)
  const { currentRole } = useUserRoleData()
  const [loading, setLoading] = useState<boolean>(false)
  const { loading: provincesLoading, provinces } = useProvinceData()
  const { loading: usersLoading, users } = useUserData()

  const defaultInitialValue = {
    lastname: '',
    firstname: '',
    email: '',
    adress: '',
    province: '',
    city: '',
    driving_license_number: '',
    birthday: '',
    nas: '',
    request_email: '',
    phone_number: '',
    post_number: '',
    postal_code: '',
    contact_id: '',
  }

  const [initialValues, setInitialValues] = useState<any>(defaultInitialValue)

  const { appendCandidate, updateCandidate, } = useCandidatesData()
  const { new_notification, } = useNotificationData()

  const onNewMember = (value, { resetForm }) => {
    const data = {
      ...value,
      contact_id: users.find(d => `${d.firstName} ${d.lastName}` === value.contact_id)!.id,
      province: provinces.find(d => d.name === value.province)!.id,
    }

    saveMemberToApi(data, resetForm)
  }


  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    setFieldTouched,
    errors,
    handleSubmit,
  } = useFormik<ICandidate>({
    validationSchema,
    onSubmit: onNewMember,
    initialValues,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (!Boolean(candidate)) { return }
    const user = users.find(d => d.id === candidate!.contact_id)
    console.log({candidate,user})
    setInitialValues({
      lastname: candidate!.lastname || '',
      firstname: candidate!.firstname || '',
      email: candidate!.email || '',
      province: (provinces.find(d => d.id === candidate!.province) || { name: '' }).name,
      city: candidate!.city || '',
      driving_license_number: candidate!.driving_license_number || '',
      birthday: candidate!.birthday || '',
      nas: candidate!.nas || '',
      request_email: candidate!.request_email || '',
      phone_number: candidate!.phone_number || '',
      post_number: candidate!.post_number || '',
      adress: candidate!.adress || '',
      postal_code: candidate!.postal_code || '',
      contact_id: user ? `${user.firstName} ${user.lastName}`: "",
    })
    setIsModalOpen(true)
  }, [candidate])

  const handleModalToggle = () => {
    if (isModalOpen) {
      setToBeModifiedCandidate(undefined);
      onClose();
    }
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    if (!isModalOpen) {
      setInitialValues({ defaultInitialValue })
    }
  }, [isModalOpen])

  const handleSelectToggleProvince = is_expanded => setIsExapandedProvince(is_expanded)
  const handleSelectToggleContact = is_expanded => setisOpenContact(is_expanded)

  const onSelect = field => (event, selection, isPlaceholder) => {
    setisOpenContact(false)
    setIsExapandedProvince(false)

    if (isPlaceholder) {
      clearSelection(field)
      return
    }

    if (field !== 'province') {
      const user = users.find(
        ({ firstName, lastName }) => `${firstName} ${lastName}` === selection
      )
      if (user) {
        setFieldValue('request_email', user.email)
      }
    }
    setFieldValue(
      field,
      selection
    )
  }

  const clearSelection = field => () => {
    setFieldValue(field, [])
    setIsExapandedProvince(false)
  }

  const saveMemberToApi = (value, resetForm) => {
    const new_candidate = {
      ...value,
      nas: value.nas,
      adress: value.adress,

    }
    console.log({ new_candidate })
    setLoading(true)
    let method
    if (Boolean(candidate)) {
      method = CandidateService.update_candidate(
        candidate!.id, new_candidate
      )
    } else {
      const new_candidate = {
        lastname: value.lastname ? value.lastname : '',
        firstname: value.firstname ? value.firstname : '',
        email: value.email ? value.email : '',
        province: value.province ? value.province : '',
        city: value.city ? value.city : '',
        driving_license_number: value.driving_license_number ? value.driving_license_number : '',
        birthday: value.birthday ? value.birthday : '',
        request_email: value.request_email ? value.request_email : '',
        phone_number: value.phone_number ? value.phone_number : '',
        post_number: value.post_number ? value.post_number : '',
        postal_code: value.postal_code ? value.postal_code : '',
        contact_id: value.contact_id ? value.contact_id : '',
        nas: value.nas ? value.nas : '',
        adress: value.adress ? value.adress : '',
      }
      method = CandidateService.new_candidate(new_candidate)
    }

    method
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { payload, message } = await response.json()
          setIsModalOpen(false)
          if (Boolean(candidate)) {
            updateCandidate(payload)
            if (onUpdate) {
              onUpdate(payload)
            }
          } else {
            appendCandidate(payload)
          }
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          resetForm(defaultInitialValue)
          setIsModalOpen(false);
          setToBeModifiedCandidate(undefined);
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }
  const SelectPlaceHolder = (
    <SelectOption
      value={
        formatMessage({ id: "shared:select" })
      }
    />
  )

  const options_provinces = provinces.map(({ id, name, abbrev }) => (
    <SelectOption key={id} value={name} name={`${name} ${abbrev}`} />
  ))

  const options_contacts = users.map(({ id, firstName, lastName }) => (
    <SelectOption key={id} value={`${firstName} ${lastName}`} name={firstName} />
  ))

  options_provinces.push(SelectPlaceHolder)
  options_contacts.push(SelectPlaceHolder)


  return (
    <>
      {renderTrigger && renderTrigger(() => setIsModalOpen(true))}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            <FormattedMessage
              id={Boolean(candidate) ? "new_candidate:edit_candidate" : "candidate:new_candidate"}
            />
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        //isFooterLeftAligned
        className={styles.modal_root}
      >
        <form
          className={styles.content}
          onSubmit={handleSubmit}
        >
          <label className={styles.field}>
            <span
              className={errors.firstname && touched.firstname ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.firstname && touched.firstname ? errors.firstname : "shared:firstname"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'firstname'}
              value={values.firstname}
              onChange={text => setFieldValue('firstname', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:lastname" />
            </span>
            <TextInput
              name='lastname'
              value={values.lastname}
              type="text"
              //css={{}}
              onChange={text => setFieldValue('lastname', text)}
              onBlur={handleBlur}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.email && touched.email ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.email && touched.email ? errors.email : "shared:email"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'email'}
              value={values.email}
              onChange={text => setFieldValue('email', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:address" />
            </span>
            <TextInput
              name={'adress'}
              value={values.adress}
              onChange={text => setFieldValue('adress', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.contact_id && touched.contact_id ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.contact_id && touched.contact_id ? errors.contact_id : "shared:contact"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <Select
              variant={SelectVariant.typeahead}
              onToggle={handleSelectToggleContact}
              name="contact_id"
              onSelect={onSelect('contact_id')}
              onBlur={e => setFieldTouched('contact_id')}
              selections={values.contact_id}
              isOpen={isOpenContact}
              // placeholderText={formatMessage({ id: "shared:member_of_entity" })}
              // ariaLabelledBy={'contact_id'}
              onClear={clearSelection('contact_id')}
            >
              {options_contacts}
            </Select>
          </label>
          <label className={styles.field}>
            <span
              className={errors.request_email && touched.request_email ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.request_email && touched.request_email ? errors.request_email : "shared:request_email"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <TextInput
              name={'request_email'}
              value={values.request_email}
              onChange={text => setFieldValue('request_email', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label
            htmlFor="province"
            className={styles.field}
          >
            <span
              className={errors.province && touched.province ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.province && touched.province ? errors.province : "shared:province"}
              />
              <span className={styles.c_error}>*</span>
            </span>
            <Select
              variant={SelectVariant.typeahead}
              onToggle={handleSelectToggleProvince}
              name="province"
              onSelect={onSelect('province')}
              onBlur={e => setFieldTouched('province')}
              selections={values.province}
              isOpen={isOpenProvince}
              // ariaLabelledBy={'province'}
              onClear={clearSelection('province')}
            >
              {options_provinces}
            </Select>
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:driving_license_number" />
            </span>
            <TextInput
              name={'driving_license_number'}
              value={values.driving_license_number}
              onChange={text => setFieldValue('driving_license_number', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:nas" />
            </span>
            <TextInput
              name={'nas'}
              value={values.nas}
              onChange={text => setFieldValue('nas', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.birthday && touched.birthday ? styles.c_error : ''}
            >
              <FormattedMessage id="shared:birthday" />
            </span>
            <TextInput
              name={'birthday'}
              value={values.birthday}
              onChange={text => setFieldValue('birthday', text)}
              onBlur={handleBlur}
              type="date"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:tel_and_post_number" />
            </span>
            <div className="telephone_field">
              <TextInput
                name={'phone_number'}
                value={values.phone_number}
                onChange={text => setFieldValue('phone_number', text)}
                onBlur={e => {
                  handleBlur(e)
                  setFieldValue(
                    'phone_number',
                    maskNumber(values.phone_number)
                  )
                }}
                type="tel"
                //css={{}}
                aria-label="text input example"
              />
              {Boolean(values.phone_number) && (
                <TextInput
                  name={'post_number'}
                  value={values.post_number}
                  onChange={text => setFieldValue('post_number', text)}
                  onBlur={handleBlur}
                  type="number"
                  //css={{}}
                  placeholder={formatMessage({ id: 'shared:post_number' })}
                />
              )}
            </div>
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:town" />
            </span>
            <TextInput
              name={'city'}
              value={values.city}
              onChange={text => setFieldValue('city', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.postal_code && touched.postal_code ? styles.c_error : ''}
            >
              <FormattedMessage id="shared:postal_code" />
            </span>
            <TextInput
              name={'postal_code'}
              value={values.postal_code}
              onChange={text => setFieldValue('postal_code', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={handleModalToggle}
              type="button"
            >
              <FormattedMessage id="shared:cancel" />
            </button>
            <Button
              isDisabled={loading || Object.keys(errors).length > 0}
              type="submit"
            >
              <FormattedMessage id="shared:save" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
