import React, { useState, useEffect } from 'react';
import styles from './FormSelector.module.scss';
import { Popover } from '@patternfly/react-core';
import { ReactComponent as DocumentIcon } from 'assets/imgs/icon_document.svg'
import { standard_form_categories, } from 'constants/standard_form'
import "./reset_tooltip.scss"
import {
    TextInput,
    Spinner,
    spinnerSize
} from '@patternfly/react-core';
import { ILoginInfo } from 'stores/auth.store';
import { useUserInfoData, useNotificationData } from 'hooks';
import { FormService } from 'services';
import { useIntl } from 'react-intl';
import { IFormLite } from 'stores/form.store';

export interface FormSelectorProps {
    readonly candidate_id?: string;
    readonly onFormSelected: any;
    readonly onRefreshSelectedForms: any;
    readonly loadedForms: boolean;
    readonly onSaveStandardFormCount: any;
    readonly standardFormCount: number;
    readonly shouldAutoReload: number | undefined;
    readonly alreadyLoadedForms: any[];
    readonly setOneModalOpen?: any // To know if a modal is open or not for close/maintain open the three dots after a click(mobile)
}

const FormSelectorComponent: React.FC<FormSelectorProps> = ({
    candidate_id,
    onFormSelected,
    onRefreshSelectedForms,
    onSaveStandardFormCount,
    // standardFormCount: paramStandardFormCount,
    loadedForms = false,
    shouldAutoReload = false,
    alreadyLoadedForms,
    ...props
}) => {

    // Open/Close dropdown FormSelectors
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { userInfo } = useUserInfoData()
    const { firstName, lastName } = userInfo as ILoginInfo
    const { new_notification } = useNotificationData()
    const { formatMessage } = useIntl()
    const [formsLite, setFormsLite] = useState<IFormLite[]>([])
    const [selectedForm, setSelectedForm] = useState<string>('')
    const [query, setQuery] = useState<string>('')
    const [autoLoadForms, setAutoLoadForms] = useState<any[]>([])
    const [standardFormCount, setStandardFormCount] = useState<number>(0)
    const [autoLoad, setAutoLoad] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    let loadedStandardFormcount = 0

    // useEffect(() => {
    //     setStandardFormCount(paramStandardFormCount)
    // }, [paramStandardFormCount])

    const constructStandardForm = (id, title) => ({
        categories: standard_form_categories,
        id,
        is_standard: true,
        title,
    })

    const onSelect = (event?) => {
        if (props.setOneModalOpen)
            props.setOneModalOpen(isOpen)
        setIsOpen(!isOpen)
    }

    const fetchFormData = id => {
        if (Boolean(selectedForm)) { return }
        setSelectedForm(id)
        FormService.getFormCategoryAndQuestions(
            id, candidate_id
        )
            .catch(err => {
                setSelectedForm('')
            })
            .then(async response => {
                setSelectedForm('')
                if ([200, 201].includes(response.status)) {
                    const data = await response.json()
                    onFormSelected(
                        parseData(data)
                    )
                    onSelect()
                } else if (response.json) {
                    const { message } = await response.json()
                    new_notification({
                        message,
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                    onSelect()
                } else {
                    onSelect()
                    new_notification({
                        message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
                        // message: formatMessage({ id: 'shared:unknown_error' }),
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                }
            })
    }

    const fetchFormDataMultiple = (ids: string[]) => {
        console.log({ ids })
        if (Boolean(selectedForm) || ids.length < 1) { return }
        // setSelectedForm(id)
        Promise.all(
            ids.map(
                form_id => FormService.getFormCategoryAndQuestions(
                    form_id, candidate_id,
                )
            )
        )
            .then(responses => {
                console.log({ responses })
                return Promise.all(
                    responses.filter(
                        response => [200, 201].includes(response.status)
                    ).map(res => res.json())
                )
            })
            .then(
                (params: any) => {
                    console.log({
                        standardFormCount, loadedStandardFormcount,
                    })
                    const nonEditableStandardForms = params.filter(
                        _ => (!_.editable && _.is_standard)
                    ).flatMap(
                        _ => (
                            new Array(
                                (standardFormCount - loadedStandardFormcount) || 1
                            ))
                            .fill(
                                constructStandardForm(_.id, _.title)
                            )
                    )

                    const editableForms = params.filter(
                        _ => (_.editable)
                    )
                        .flatMap(_ => {
                            if (_.is_standard) {
                                return (
                                    new Array(
                                        (standardFormCount - loadedStandardFormcount) || 1
                                    )
                                        .fill(_)
                                )
                            }
                            return [_]
                        })

                    onRefreshSelectedForms(
                        ...[
                            ...nonEditableStandardForms,
                            ...editableForms,
                        ].map(
                            _ => parseData(_)
                        )
                    )

                }
            )
            .catch(err => {
                new_notification({
                    message: formatMessage({ id: 'shared:error_fetching_forms' }),
                    title: formatMessage({ id: 'shared:error' }),
                    variant: 'error'
                })
            })
    }

    const loadStandardForm = (id, title) => {
        const standardForm = constructStandardForm(
            id, title
        )
        onFormSelected(
            parseData(standardForm)
        )

        onSelect()
    }

    useEffect(() => {
        if (!Boolean(candidate_id)) { return }
        // console.log({alreadyLoadedForms})
        setTimeout(() => {
            fetchFormsLite()
        }, 300)
    }, [candidate_id, shouldAutoReload])

    const fetchFormsLite = () => {
        if (loading) { return }
        setLoading(true)
        FormService.fetchFormsAutoFillInfo(candidate_id)
            .catch(err => {
                setLoading(false)
                new_notification({
                    message: formatMessage({ id: "shared:unknown_error" }),
                    title: formatMessage({ id: 'shared:error' }),
                    variant: 'error'
                })
            })
            .then(async response => {
                setLoading(false)
                if ([200, 201].includes(response.status)) {
                    const {
                        allow_forms_ids, all_forms,
                        standard_form_count,
                    } = await response.json()
                    setFormsLite(all_forms)
                    setAutoLoad(false)
                    setStandardFormCount(+standard_form_count)
                    onSaveStandardFormCount(+standard_form_count)
                    //wait for standardFormCount to finish saving
                    setTimeout(() => {
                        setAutoLoadForms(allow_forms_ids)
                    },100)
                } else if (response.json) {
                    const { message } = await response.json()
                    new_notification({
                        message,
                        title: 'success',
                        variant: 'error'
                    })
                } else {
                    new_notification({
                        message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
                        title: formatMessage({ id: 'shared:error' }),
                        variant: 'error'
                    })
                }
            })
    }

    const parseData = data => {
        const interviewrCategory = {
            catName: formatMessage({ id: "shared:interviewer_info" }),
            fields: [
                {
                    name: 'quest.id',
                    type: 'text',
                    label: formatMessage({ id: "shared:technician" }),
                    label_en: formatMessage({ id: "shared:technician" }),
                    multiline: false,
                    rows: 1,
                    value: `${firstName} ${lastName}`,
                    required: true,
                    select: false,
                    switch: false,
                    halfWidth: true,
                    options: [],
                }
            ]
        }

        const questionFields = data.categories.reduce(
            (acc, curr) => {
                const cat = {
                    catName: curr.title,
                    catName_fr: curr.title_fr ? curr.title_fr : curr.title,
                    catName_en: curr.title_en ? curr.title_en : curr.title,
                    fields: curr.questions.flatMap(
                        quest => {
                            const returnValue = [
                                {
                                    name: quest.id,
                                    type_justification: quest.type_justification,
                                    type: quest.data.type,
                                    label: quest.title,
                                    label_en: quest.title_en ? quest.title_en : quest.title,
                                    multiline: quest.data.multiline,
                                    rows: quest.data.rows || 1,
                                    required: quest.data.required,
                                    select: quest.data.type === 'select',
                                    switch: quest.data.type === 'switch',
                                    halfWidth: !quest.data.fullwidth,
                                    // options: quest.answer.map(i => i.value),
                                    options: quest.answer.map(i => formatMessage({ id: i.value })),
                                },
                            ]

                            /**
                             * parse question to know if justification is required
                             * and add the corresponding question field to be parsed out
                             * in the pdf form through the unique attribute added here.
                             */
                            if (Boolean(quest.type_justification)) {
                                returnValue.push({
                                    name: `${quest.id}_justification`,
                                    type: 'textarea',
                                    label: "shared:justify_above_response",
                                    label_en: "shared:justify_above_response",
                                    multiline: true,
                                    rows: 3,
                                    required: false,
                                    select: false,
                                    switch: false,
                                    halfWidth: true,
                                    options: [],
                                    //@ts-ignore
                                    justification_field: true,
                                })
                            }

                            return returnValue
                        }
                    )
                }
                acc.push(cat)
                return acc
            },
            []
        )

        if (data.is_standard) {
            /**Add interviewer in the list */
            questionFields.push(
                interviewrCategory
            )
        }

        return {
            questionFields,
            name: data.title,
            id: data.id,
        }
    }

    useEffect(() => {
        setAutoLoad(false)
    }, [shouldAutoReload])

    useEffect(() => {
        if (
            autoLoad
            ||
            autoLoadForms.length < 1
            || !loadedForms
        ) { return }
        console.log({ autoLoadForms })
        const alreadyLoadedFormsId = (alreadyLoadedForms || [])
            .map(({ form_id }) => form_id)
        const toLoadForms = autoLoadForms
            .filter(
                _ => !alreadyLoadedFormsId.includes(_)
            )
        const loadedStandardForm = alreadyLoadedForms
            .filter(_ => Boolean(_.is_standard))
        console.log({standardFormCount, toLoadForms, alreadyLoadedForms, loadedStandardForm })
        loadedStandardFormcount = loadedStandardForm.length
        if (
            loadedStandardForm.length > 0
            && standardFormCount > loadedStandardForm.length
        ) {
            toLoadForms.push(loadedStandardForm[0].form_id)
            console.log({ toLoadForms })
        }
        console.log({ toLoadForms })
        fetchFormDataMultiple(toLoadForms)
        setAutoLoad(true)
    },
        [
            autoLoadForms, 
        ]
    )

    return (
        <>
        </>
    )

    // return (

    //     <Popover
    //         className={styles.dropdown}
    //         aria-label="Headless Popover"
    //         bodyContent={
    //             <div >
    //                 <TextInput
    //                     type='text'
    //                     className={styles.input}
    //                     onChange={setQuery}
    //                 />
    //                 {formsLite
    //                     .filter(({ title }) => title.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    //                     .map(
    //                         ({ id, is_standard, editable, title, }) => (
    //                             <div
    //                                 className={styles.form_item}
    //                                 key={id}
    //                                 onClick={i => {
    //                                     if (is_standard && !editable) loadStandardForm(id, title)
    //                                     else fetchFormData(id)
    //                                 }}
    //                             >
    //                                 {selectedForm === id ? (
    //                                     <Spinner size={spinnerSize.md} />
    //                                 ) : (
    //                                         <DocumentIcon />
    //                                     )}
    //                                 <span>{title}</span>
    //                             </div>
    //                         )
    //                     )}
    //             </div>
    //         }
    //     >
    //         {
    //             // @ts-ignore
    //             props.children()
    //         }
    //     </Popover>

    // );
}

export {
    FormSelectorComponent,
}
