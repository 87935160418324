import BaseService from './BaseService'
import { deparmentUrls } from './urls'

class DepartmentService {

    static delete_departments = (data) => {
        return BaseService.deleteRequest(
            deparmentUrls.DEPARTMENT(), data, true
        )
    }

    static list_departments = (suffix) => {
        return BaseService.getRequest(
            deparmentUrls.DEPARTMENT(suffix), true
        )
    }

    static new_department = (data) => {
        return BaseService.postRequest(
            deparmentUrls.DEPARTMENT(), data, true
        )
    }

    static update_user = (dep_id, data) => {
        return BaseService.putRequest(
            deparmentUrls.DEPARTMENT_NEW_USER(dep_id), data, true
        )
    }

    static new_user = (dep_id, data) => {
        return BaseService.postRequest(
            deparmentUrls.DEPARTMENT_NEW_USER(dep_id), data, true
        )
    }

    static update_department = (suffix) => ( data) => {
        return BaseService.putRequest(
            deparmentUrls.DEPARTMENT(suffix), data, true
        )
    }

    static addUsersToDepartment = (suffix, data) => {
        return BaseService.putRequest(
            deparmentUrls.ADD_USERS(suffix) , data, true
        )
    }

}

export default DepartmentService;