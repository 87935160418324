import React, { useState, useEffect } from 'react';
import styles from './SendFormByEmail.module.scss'
import { PopoverPosition, Popover, TextInput, Button, InputGroup, Spinner, spinnerSize } from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl';
import { OutlinedPaperPlaneIcon, PaperPlaneAltIcon, SearchIcon } from '@patternfly/react-icons';
import { useNotificationData, useUserInfoData } from 'hooks';
import { FormService } from 'services';
import { ILoginInfo } from 'stores/auth.store';

export interface SendFormByEmailProps {
  readonly trigger: any;
  readonly currentForm: any;
  readonly candidate_id: string;
}

export const SendFormByEmail: React.FC<SendFormByEmailProps> = ({
  trigger,
  currentForm,
  candidate_id,
  ...props
}) => {

  
  const [show, setShow] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const { userInfo } = useUserInfoData()
  const { firstName, lastName } = userInfo as ILoginInfo
  const [email, setEmail] = useState('')
  const { new_notification } = useNotificationData()
  const [loading, setLoading] = useState<boolean>(false)


  const handleClick = () => {
    setShow(!show)
  }


  const shouldClose = tip => {
    setShow(false)
  }


  const sendEmail = (e) => {
    e.preventDefault()
    setLoading(true)
    FormService.sendFormByEmail(
      {
        email, form_id: currentForm.form_id,
        interviewer: `${firstName} ${lastName}`,
        candidate_id,
      }
    )
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          setShow(false)
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  return (
    <Popover
      isVisible={show}
      shouldClose={shouldClose}
      enableFlip={true}
      headerContent={
        <div>
          <FormattedMessage id="shared:send_form_by_email" />
        </div>
      }
      bodyContent={
        <form onSubmit={sendEmail} className={styles.container}>
          <InputGroup>
            <TextInput
              name="email"
              id="email"
              value={email}
              onChange={setEmail}
              type="email"
              placeholder={formatMessage({ id: 'shared:prompt_email' })}
              aria-label="input to collect email to send form to."
            />
            <Button
              type="button"
              variant="control"
              isDisabled={
                email.length < 1
                || ['@', '.'].some(_ => email.indexOf(_) === -1)
                || loading
              }
              aria-label="button to send mail"
              onClick={sendEmail}
            >
              {loading ? (
                <Spinner size={spinnerSize.md} />
              ) : (
                  <PaperPlaneAltIcon />
                )}
            </Button>
          </InputGroup>
        </form>
      }
    >
      {trigger(handleClick)}
    </Popover>
  );
}
