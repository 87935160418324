import * as React from 'react';
import styles from './SignUp.module.scss'
import { RouteComponentProps } from "react-router"
import signup_img from 'assets/imgs/signup_img.png'
import logo from 'assets/imgs/logo.png'
import { Link } from "react-router-dom";

import {
  LoginFooterItem,
  LoginMainFooterBandItem,
  LoginPage,
  ListItem,
  Form,
  FormGroup,
  TextInput,
  Button,
  Checkbox
} from '@patternfly/react-core';
import { useIntl } from 'react-intl';

export interface SignUpProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const loginMessage = (
  <LoginMainFooterBandItem>
    <span>Vous avez deja un compte? </span>
    <Link to='/auth/login'>
      Se connecter
    </Link>
  </LoginMainFooterBandItem>
);
const forgotCredentials = (
  <>
    <LoginMainFooterBandItem>
      <Link to='/auth/recover'>
        Mot de passe oublié?
    </Link>
    </LoginMainFooterBandItem>
  </>
);

const SignUpComponent: React.FC<SignUpProps> = props => {
  const { formatMessage } = useIntl()
  const signUpForm = (
    <Form className={styles.form_container}>
      <FormGroup label="Prénom" fieldId="signup-firstname">
        <TextInput
          type="text"
          id="signup-email"
          placeholder="Prénom"
          name="firstname"
        />
      </FormGroup>
      <FormGroup label="Nom de famille" isRequired fieldId="signup-lastname">
        <TextInput
          isRequired
          type="text"
          id="signup-lastname"
          placeholder="Nom de famille"
          name="lastname"
        />
      </FormGroup>
      <FormGroup label="Adresse mail" isRequired fieldId="signup-mail">
        <TextInput
          isRequired
          type="email"
          id="signup-mail"
          placeholder="Adresse mail"
          name="password"
        />
      </FormGroup>
      <FormGroup label="Mot de passe" isRequired fieldId="signup-password">
        <TextInput
          isRequired
          type="password"
          id="signup-password"
          placeholder="Mot de passe"
          name="password"
        />
      </FormGroup>
      <FormGroup fieldId="terms-of-use">
        <Checkbox
          label={<p>J'accepte les <a href="#">Termes & Conditions d'utilisation</a></p>}
          id="terms-of-use"
          name="terms-of-use"
          aria-label="accept terms of use" />
      </FormGroup>
      <Button>
        S'enregistrer maintenant
        </Button>
    </Form>
  );

  const listItem = (
    <React.Fragment>
      <ListItem>
        <LoginFooterItem href="#">Conditions d'utilisation </LoginFooterItem>
      </ListItem>
      <ListItem>
        <LoginFooterItem href="#">Aide</LoginFooterItem>
      </ListItem>
      <ListItem>
        <LoginFooterItem href="#">Politique de confidentialité</LoginFooterItem>
      </ListItem>
    </React.Fragment>
  );

  return (
    <div >
      <LoginPage className={styles.login_page_container}
        // footerListVariants="inline"
        brandImgSrc={logo}
        brandImgAlt="Pre Emploi logo"
        backgroundImgSrc={signup_img}
        backgroundImgAlt="Images"
        loginTitle="S'enregistrer à Pré-emploi"
        signUpForAccountMessage={loginMessage}
        forgotCredentials={forgotCredentials}
        textContent={formatMessage({ id: "auth:explanation" })}
        footerListItems={listItem}
      >
        {signUpForm}
      </LoginPage>
    </div>
  );
}

export {
  SignUpComponent as SignUpPage
}
