import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import styles from './FormDetail.module.scss';
import { useAccordionData, useFormsLiteData, useNotificationData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { Gallery, GalleryItem, TextInput, Button, Spinner, spinnerSize } from '@patternfly/react-core';
import { GridCard, AccordionContext, ListEmpty } from 'components';
import { RouteComponentProps, useRouteMatch } from 'react-router';
import { 
   ICategoryType
   } from 'constants/example';
import { IFormsType } from 'stores'
import { AddEditCategoryModal } from 'components/modals';
import { CloseIcon, EllipsisHIcon, EllipsisVIcon } from '@patternfly/react-icons';
import { FormService, CategoriesService } from 'services';
import { ConfirmDialog, ReorderCategories } from 'dialogs';

export interface FormDetailProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

/**
 * Pour les details d'un formulaire
 * @param props 
 */
const FormDetailComponent: React.FC<FormDetailProps> = (props) => {

  const initialCat: ICategoryType = {
    id: undefined,
    title: '',
    questions_counts: 0,
    is_global: false,
    description: '',

  };
  const { params: { id: selectedFormId } } = useRouteMatch()
  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  const { form: selected_form } = (props.location.state || {}) as any


  const [form, setform] = useState<IFormsType>(selected_form);
  const { setBottomComponent } = useContext(AccordionContext);
  const [category, setCategory] = useState<ICategoryType | undefined>(undefined);
  const centinelRef = useRef<any>(null)
  const [categories, setCategories] = useState<any[]>([])
  const [categoriesBackup, setCategoriesBackup] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { notification, new_notification } = useNotificationData()
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  const [toDelete, setToDelete] = useState<string>()
  let timeout;
  const [observerTriggerState, setObserverTriggerState] = useState<any>({})
  const [start, setStart] = useState<number>(0)
  const limit = 30
  //Important to initial total to 1 if you want loader to show on first load.
  const [total, setTotal] = useState<number>(0)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)

  //Pour Ouvrir/Fermer le modal d'edition d'un formulaire
  const onCloseModal = () => {
    setCategory(undefined);
    localStorage.categoryDescription = ""
  }

  useEffect(() => {
    clearTimeout(timeout)
    //text in search field has been emptied
    if (query === '') {
      setCategories(categoriesBackup)
      return
    }
    setSearchStart(0) //On each new search, searchStart goes to zero
    setSearchTotal(0)
    timeout = setTimeout(() => {
      searchCategories()
    }, 300);
  }, [query])

  const configureCrumbs = form => {
    if (!Boolean(form)) { return props.history.push(`/me/forms`) }

    resetAccordion({
      title: form.title,//Titre
      sub_title: form.description,
      show_crumbs: true,//Afficher les niveaux de navigation
      crumbs: [ //Deux niveaux, page des listings des forms et page actuelle
        {
          route_to: "/me/forms", title: formatMessage({ id: 'shared:forms' })
        },

        {
          route_to: "/me/forms/" + selectedFormId, title: form.title || " "
        },
      ],
    });
  }

  React.useEffect(() => {
    setform(selected_form);
    configureCrumbs(selected_form)
    configureInfiniteScroll()

    //unmount
    return () => {
      setBottomComponent(<span></span>)
      resetAccordion({
        sub_title: undefined, show_crumbs: false, crumbs: [],
      })
    }
  }, [])

  const onNewCategory = e => setCategory(initialCat)

  const configureBottom = () => {
    setBottomComponent(
      <div className={styles.bottomComponent}>
        <Button
          className={styles.btn_primary}
          onClick={onNewCategory}
          variant="primary"
        >
          <FormattedMessage id="new_category:new_category" />
        </Button>
        <TextInput
          placeholder={formatMessage({ id: "shared:search" })}
          // value={query}
          type="text"
          onChange={setQuery}
          aria-label="Recherche"
        />
        {categories.length > 1 && (
          <ReorderCategories
            form_id={selectedFormId}
            data={categories}
            onClose={handleReorderCategories}
            renderTrigger={_ => (
              <Button
                className={styles.btn_primary}
                onClick={_}
                variant="primary">
                <FormattedMessage id="shared:reorder" />
              </Button>
            )}
          />
        )}
      </div>
    )
  }

  useEffect(() => {
    configureBottom()
  }, [categories.length])

  const handleReorderCategories = (items: any[]) => {
    const newCategories = items.map(_ => (
      categories.find(it => it.id === _.id)!
    ))
    setCategories(newCategories)
  }

  const fetchFormCategories = (paramStart: number, paramSearching: boolean) => {
    setLoading(true)
    const suffix = `?start=${paramStart}&limit=${3000}${paramSearching ? `&keyword=${query}` : ''}`
    FormService.list_form_categories(selectedFormId, suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { results, start: resStart, total: resTotal } = await response.json()
          if (!paramSearching) { //Not searching
            if (resStart < 1) {
              if (results.length > 0) {
                configureCrumbs(results[0].form)
              }
              setCategories(results)
              setStart(resStart)
              setTotal(resTotal)
            }
            else {

              setCategories([...categories, ...results])
              setStart(resStart)
              setTotal(resTotal)
            }
          } else { //Searching 

            if (resStart === 0) {
              setCategoriesBackup(categories)
            }
            setCategories([...(resStart > 0 ? categories : []), ...results])
            setSearchTotal(resTotal)
            setSearchStart(resStart)
          }
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: 'success',
            variant: 'success'
          })

        }

      })
  }

  const deleteCategory = (...category_ids: string[]) => {
    const newCategories = categories.filter(cat => {
      if (category_ids.includes(cat.id)) {
        return false
      }
      return true
    })

    setCategories(newCategories)
  }

  const deleteCategoryFromApi = (category_ids: string) => {
    setLoadingDelete(true)
    CategoriesService.delete_category(
      { category_ids: [category_ids] }
    )
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          deleteCategory(category_ids)
          setToDelete('')
          setLoadingDelete(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'error'
          })
          setLoadingDelete(false)

        } else {
          setLoadingDelete(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
        }
      })
  }

  const onNextPage = () => {
    if (query !== '') {
      if (searchTotal !== 0 && categories.length >= searchTotal) { return }
      fetchFormCategories(searchTotal === 0 ? 0 : searchStart + limit, true)
      return
    }
    if (total !== 0 && categories.length >= total) { return }
    fetchFormCategories(total === 0 ? 0 : start + limit, false)
  }

  const searchCategories = () => {
    fetchFormCategories(0, true)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, limit, categories, start])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  //Liste des menus actions
  const menus = paramCat => ([
    {
      id: '1',
      action: () => {
        setCategory(paramCat)
        localStorage.categoryDescription = paramCat.description
        console.log({ foundCat: paramCat })
      },
      name: formatMessage({id:'shared:edit'}),
    },
    {
      id: '2',
      action: () => {
        setToDelete(paramCat.id)
        setTimeout(() => {
          setCategory(undefined)
        })
      },
      name: formatMessage({id:'shared:delete'}),
    }
  ])

  const onSelect = id => {
  }

  const appendCategory = cat => {
    if (categoriesBackup.length !== 0) {
      setCategoriesBackup([cat, ...categoriesBackup])
      return
    }
    setCategories([cat, ...categories])
  }

  const updateCategory = cat => {
    if (categoriesBackup.length !== 0) {
      setCategoriesBackup(categoriesBackup.map(paCat => paCat.id === cat.id ? cat : paCat))
      return
    }
    setCategories(categories.map(paCat => paCat.id === cat.id ? cat : paCat))
  }

  return (
    <div className={styles.container} >
      <AddEditCategoryModal
        onCloseModal={onCloseModal}
        //@ts-ignore
        category={category}
        // getCat={() => category!.description}
        open={!Boolean(toDelete) && Boolean(category)}
        form={form}
        appendCategory={appendCategory}
        updateCategory={updateCategory}
      />
      <ConfirmDialog
        confirmation_message="shared:do_you_want_to_continue"
        onAction={_ => deleteCategoryFromApi(toDelete!)}
        isModalOpen={Boolean(toDelete)}
        loading={loadingDelete}
        onModalClose={_ => {
          setToDelete('')
          setCategory(undefined)
        }}
      />
      {categories.length === 0 && !loading && (
        <ListEmpty
          message={"shared:no_categories"}
        />
      )}
      {/**Listing des Formulaires */}
      <Gallery hasGutter>
        {categories.map(category => (
          <GalleryItem span={4}>
            {/**Grid Formulaire*/}
            <GridCard
              form={{
                ...category,
                description_jsx: (
                  <>
                    <h3>
                      <FormattedMessage id="shared:questions_count" /> :&nbsp;{category.questions_counts}
                    </h3>
                    <span>{form.description}</span>
                  </>
                )
              }}
              enableCheckbox={false}
              selectedElements={[]}
              onSelect={onSelect}
              OpenCloseModal={onCloseModal}
              root_url={`${props.location.pathname}/categories/${category.id}`}
              selected={false}
              {...props}
              btn_delete={false}
              menus={menus(category)}
              setform={setCategory}
            />
          </GalleryItem>
        ))}
        {categories.length < total || total === 0 && (
          <GalleryItem span={12} ref={centinelRef}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 200,
              width: '100%',
            }}
          >
            {loading && (
              <Spinner size={spinnerSize.md} />
            )}
          </GalleryItem>
        )}
      </Gallery>
      {/**Fin Listing des Formulaires  */}

    </div>
  );
}

export {
  FormDetailComponent as FormDetailPage,
}
