import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { CurrentRoleStore } from 'stores'
import { takeUntil } from 'rxjs/operators'
import { ILoginInfo } from 'stores/auth.store'

export function useUserRoleData() {
    const [roles, setRoles] = useState<string[]>([])
    const [currentRole, setCurrentRole] = useState<string>('')
    const trigger = new Subject<boolean>()
    const { roles$, resetUserRoles, switchRole } = CurrentRoleStore

    useEffect(() => {
        roles$.pipe(
            takeUntil(trigger.asObservable())
        ).subscribe({
            next: ({ userRoles: roles, current_role }) => {
                setRoles(roles)
                setCurrentRole(current_role)
                localStorage.currentRole=current_role
            }
        })

        return () => trigger.next(false)
    }, [])

    return { roles, currentRole, resetUserRoles, switchRole }
}