import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import styles from './QuestionListing.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import {
  Dropdown, DropdownToggle, DropdownItem,
  Checkbox, Button, InputGroup,
  Spinner, spinnerSize,
  SelectOption,
} from '@patternfly/react-core';
import {
  ArrowsAltVIcon, CloseIcon, FilterAltIcon,
  SearchIcon, EllipsisVIcon,
} from '@patternfly/react-icons';
import { TABLE_COLUMN } from 'constants/table_columns';
import {
  AccordionContext, ListEmpty, SearchField
} from 'components';
import { ConfirmDialog, ListCategories } from 'dialogs';
import { AddEditQuestionModal } from 'components/modals';
import { FormService, QuestionService } from 'services';
import { CategoriesService } from 'services';
import { initial_question } from 'pages/category_detail/CategoryDetail.page';
import { IQuestionType } from 'stores';


export interface QuestionListingProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const QuestionListingComponent: React.FC<QuestionListingProps> = ({
  history, match: { url },
  ...props
}) => {

  const { setBottomComponent } = useContext(AccordionContext)
  const [
    observerTriggerState,
    setObserverTriggerState
  ] = useState<any>({})
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  const [
    filterState,
    setFilterState
  ] = useState<any>({ order_field: 'name', name: '' })
  const [loading, setLoading] = useState<boolean>(false)
  const { new_notification } = useNotificationData()
  const { resetAccordion } = useAccordionData();
  const centinelRef = useRef<any>(null)
  const [itemMenuOpen, setItemMenuOpen] = useState<string>('')
  const [questions, setQuestions] = useState<any[]>([])
  const storeLimit = 30

  useEffect(() => {
    resetAccordion({ title: formatMessage({ id: 'shared:questions' }) })
    configureInfiniteScroll()
    fetchFormsLite()
    fetchCategories()
  }, [])

  const { formatMessage } = useIntl()

  const [columns, setColumns] = useState<TABLE_COLUMN[]>([
    {
      title: formatMessage({ id: 'shared:name' }),
      sortable: false,
      field: 'name',
    },
    {
      title: formatMessage({ id: "shared:date" }),
      field: 'date',
      type: 'date',
    },
    {
      title: formatMessage({ id: "shared:type" }),
      field: 'type',
      type: 'select',
    },
  ])

  const [order, setOrder] = useState<string>('')
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [orderOpen, setOrderOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>('')
  const [
    selectedQuestions,
    setSelectedQuestions
  ] = useState<string[]>([])
  const [toDelete, setToDelete] = useState<string[]>([])
  const [newQuestion, setNewQuestion] = useState<any>()
  const [categories, setCategories] = useState<any[]>([])
  const [forms, setForms] = useState<any[]>([])
  const [toUpdate, setToUpdate] = useState<string>('')
  const [
    showCategories, setShowCategories
  ] = useState<string>('')
  // const [
  //   selectedQuestionCategories, setSelectedQuestionCategories,
  // ] = useState<any[]>([])
  const onFilterSelect = (title) => {
    setSelected(title)
    setFilterOpen(false)
  }

  const orders = [
    {
      title: formatMessage({ id: "shared:asc" }),
      value: 'asc',
    },
    {
      title: formatMessage({ id: "shared:desc" }),
      value: 'desc',
    },
  ]

  const fetchFormsLite = () => {
    FormService.getFormsLite()
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const result = await response.json()
          setForms(
            result
              .filter(({ is_standard }) => !is_standard)
              .map(({ id, title: name }) => ({ id, name }))
          )
          setColumns([
            ...columns,
            {
              title: formatMessage({ id: 'shared:form' }),
              sortable: false,
              field: 'form',
            },
          ])
        }
      })

  }

  const fetchCategories = () => {
    CategoriesService.getCategories()
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const result = await response.json()
          setCategories(result.map(({ id, title: name, form_id }) => ({ id, name, form_id })))
          setColumns([
            ...columns,
            {
              title: formatMessage({ id: 'shared:category' }),
              sortable: false,
              field: 'category',
            },
          ])
        }
      })

  }

  const fetchAllQuestions = (
    paramStart: number,
    filter?: any,
  ) => {
    console.log('during')
    let suffix = `?start=${paramStart}&limit=${storeLimit}`
    if (Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (
          Boolean((filter || filterState)[field])
          || (filter || filterState)[field] === 0
        ) {
          suffix = `${suffix}&${field == 'undefined' ? 'keyword' : field}=${(filter || filterState)[field]}`
        }
      })
    } else {
      if (suffix)
        suffix = `${suffix}&name=${query}`
    }
    setLoading(true)
    QuestionService.fetchQuestions(suffix)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const { results, start, total } = await response.json()
          setSearchTotal(total)
          if (start <= 0) {
            setQuestions(results)
          } else {
            setQuestions([...questions, ...results])
          }
          setSearchStart(start)
          setSearchTotal(total)
          setLoading(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

          setLoading(false)
        }
      })
  }

  const deleteQuestions = () => {
    setLoading(true)

    QuestionService.deleteQuestions({ question_ids: toDelete })
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          setLoading(false)
          const { message } = await response.json()

          setQuestions(questions.filter(({ id }) => !toDelete.includes(id)))
          setToDelete([])
          setSelectedQuestions([])
          new_notification({
            message,
            title: formatMessage({ id: "shared:success" }),
            variant: 'success'
          })

        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

          setLoading(false)
        }
      })
  }

  useEffect(() => {
    const column = columns.find(({ title }) => title === selected)
    let field: any = 'undefined'
    // if (!Boolean(column)) {
    //   fetchAllQuestions(0,)
    //   return
    // }

    if (column) {
      field = column!.field
    }

    // console.log({ selected, query, order })

    setFilterState(
      { order, order_field: field, [field!]: query }
    )
    fetchAllQuestions(
      0,
      { order, order_field: field, [field!]: query }
    )
  },
    [selected, query, order,]
  )

  const onNextPage = () => {
    console.log('before')
    if (questions.length && searchTotal && questions.length >= searchTotal) { return }
    fetchAllQuestions(searchTotal === 0 ? 0 : searchStart + storeLimit)
    console.log('after')
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      /*
       * The following call will trigger the useEffect that 
       * depends on observerTriggerState variable.
       */
      setObserverTriggerState({})
    }
  }, [loading, onNextPage])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(
      loadMore,
      options
    )

    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  const dateFields = columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'date'
  )
    .map(({ title }) => title)

  const searcheableFields = columns
    .filter(({ searcheable = true }) => searcheable)
    .map(({ title }) => title)
    .map(title => (
      <DropdownItem
        onClick={_ => {
          onFilterSelect(title)
          if (title !== 'Name') {
            setQuery('')
          }
        }}
        key={title} value={title}
        component="button"
      >
        {title}
      </DropdownItem>
    ))


  const accordionBottom = () => (
    <div className={styles.toolbar}>

      <div className={styles.left_section}>

        <InputGroup
          className={styles.search}
        >
          <Dropdown
            toggle={
              <DropdownToggle
                icon={<FilterAltIcon />}
                onToggle={setFilterOpen}
              >
                {selected ? selected : formatMessage({ id: 'shared:filter' })}
              </DropdownToggle>
            }
            isOpen={filterOpen}
            dropdownItems={searcheableFields}
          />

          <Dropdown
            toggle={
              <DropdownToggle
                icon={<ArrowsAltVIcon />}
                onToggle={setOrderOpen}
              >
                {order ?
                  orders.find(jk => jk.value === order)!.title
                  : formatMessage({ id: 'shared:order' })}
              </DropdownToggle>
            }
            isOpen={orderOpen}
            dropdownItems={
              orders
                .map(orderIn => (
                  <DropdownItem
                    onClick={_ => {
                      setOrder(orderIn.value)
                      setOrderOpen(false)
                    }}
                    key={orderIn.value}
                    value={orderIn.value}
                    component="button"
                  >
                    {orderIn.title}
                  </DropdownItem>
                ))
            }
          />

          <SearchField
            value={query}
            dateFields={dateFields}
            questionTypeField={'Type'}
            field={selected}
            onAction={setQuery}
            forms={forms}
            categories={categories}
            formsField={"Form"}
            categoriesField={'Category'}
          />

          <Button
            variant="control"
            className={styles.btn_nothing}
          >
            <SearchIcon />
          </Button>

        </InputGroup>

        <Button
          onClick={launchCreateQuestion}
          className={styles.btn_filled}
        >
          <FormattedMessage id="question:new_questions" />
        </Button>

        {selectedQuestions.length > 0 && (
          <>
            <Button
              onClick={i => {
                onAddToCategories()
              }}
              variant={"secondary"}
            >
              <FormattedMessage id="questions:add_to_categories" />
            </Button>
            <Button
              onClick={i => {
                setToDelete(selectedQuestions)
                setSelectedQuestions([])
              }}
              variant={"secondary"}
            >
              <CloseIcon />
              <FormattedMessage id="shared:delete" />
            </Button>
          </>
        )}

      </div>

      <span className={styles.right_section}>
        {questions.length}&nbsp;
        <FormattedMessage id="shared:of" />&nbsp;
        {searchTotal}&nbsp;
        <FormattedMessage id="shared:questions" />
      </span>

    </div>
  )

  const configureAccordion = () => {
    setBottomComponent(accordionBottom)
    resetAccordion({
      title: formatMessage({ id: "questions:all_questions" }),
      sub_title: ""
    })
  }

  useEffect(
    () => {
      configureAccordion()
    },
    [
      columns, selectedQuestions, questions,
      filterOpen, selected, query,
      order, orderOpen
    ]
  )

  const onSelect = id => event => {
    setItemMenuOpen('')
  }

  const handleSelectQuestion = id => change => {
    if (change) {
      setSelectedQuestions([...selectedQuestions, id])
    } else {
      setSelectedQuestions(selectedQuestions.filter(_ => _ !== id))
    }
  }

  const dropdownItems = ({ id, categories: cats }) => [
    <DropdownItem
      key='update'
      component="button" onClick={_ => launchUpdate(id)}
    >
      <FormattedMessage id="shared:update" />
    </DropdownItem>,
    <DropdownItem
      key='list_categories'
      component="button"
      onClick={_ => listCategories(id, cats)}
    >
      <FormattedMessage id="shared:list_categories" />
    </DropdownItem>,
    <DropdownItem
      key={'delete'}
      component="button"
      onClick={_ => setToDelete([id])}
    >
      <FormattedMessage id="shared:delete" />
    </DropdownItem>,
  ];

  const launchUpdate = paramId => {
    setToUpdate(paramId)
    const question = questions.find(({ id }) => paramId === id)

    if (!question) { return }
    //@ts-ignore
    if (Boolean(question.data)) {
      const reversedQuestionStructure = reverseQuestionStructureForform(question)
      //@ts-ignore
      setNewQuestion(reversedQuestionStructure)
      return
    }

    setNewQuestion(question)
  }

  const listCategories = (id, categories) => {
    setShowCategories(id)
    // setSelectedQuestionCategories(categories)
  }

  const reverseQuestionStructureForform = (question: any) => {
    const {
      company_id, form_id,
      category_id, is_global,
      title: value, id,
      data: {
        fullwidth, required,
        description, halfWidth, type,
      },
      answer: type_options,
      ...rest
    } = question

    const parsedQuestion: IQuestionType = {
      type_options, value, id, form_id,
      category_id, is_global, company_id,
      add_description: Boolean(description),
      description, halfWidth, type,
      fullwidth, required, ...rest,
    }

    return parsedQuestion
  }

  useEffect(() => {
    return () => {
      setBottomComponent(<span></span>)
    }
  }, [])


  const ItemMenu = ({ id, categories }) => (
    <Dropdown
      onSelect={onSelect(id)}
      toggle={
        <DropdownToggle
          toggleIndicator={null}
          onToggle={_ => {
            setItemMenuOpen(_ ? id : '')
          }}
          aria-label="Applications"
          id={id}
        >
          <EllipsisVIcon />
        </DropdownToggle>
      }
      isOpen={itemMenuOpen === id}
      isPlain
      dropdownItems={
        dropdownItems({ id, categories })
      }
    />
  )

  const QuestionItem = (
    {
      id, properties = [], title, form_name,
      data: { type }, question,
    }
  ) => (
      <li className={styles.item}>
        <div className={styles.item__header}>
          <span
            className={styles.item__title}
          >
            {title}
          </span>
          <ItemMenu
            id={id}
            categories={
              properties.map(
                ({
                  category_name: name,
                  category_id: id
                }) => ({ id, name })
              )
            }
          />
          <Checkbox
            id="check-8"
            onChange={handleSelectQuestion(id)}
            isChecked={selectedQuestions.includes(id)}
          />
        </div>
        <div className={styles.item__body}>
          <span className={styles.title}>
            <FormattedMessage id="shared:questions" />
          </span>
          <span className={styles.value}>{question}</span>
          <span className={styles.title}>
            <FormattedMessage id="shared:type" />
          </span>
          <span className={styles.value}>{type}</span>
          <span className={styles.title}>
            <FormattedMessage id="shared:categories" /> :
          </span>
          <span className={styles.row}>
            {
              properties.map(
                //@ts-ignore
                _ => _.category_name
              )
                .join(', ')}
          </span>
        </div>
      </li>
    )

  const open_modal = (newQuestion?: any) => {
    if (Boolean(newQuestion.id))
      setQuestions([newQuestion, ...questions.filter(({ id }) => id !== newQuestion.id)])
    setNewQuestion(undefined)
  }

  const handleModalToggle = () => {
    setNewQuestion(undefined)
  }

  const SelectPlaceHolder = (
    <SelectOption
      value={
        formatMessage({ id: "shared:select" })
      }
    />
  )

  const launchCreateQuestion = () => {
    setNewQuestion(true)
  }

  const onAddToCategories = () => {
    const selectedQuestionsHydrated = questions.filter(
      ({ id }) => selectedQuestions.includes(id)
    )
    history.push(
      `${url}/select_categories`,
      { questions: selectedQuestionsHydrated },
    )

    setSelectedQuestions([])

  }


  return (
    <div className={styles.container} >

      <AddEditQuestionModal
        OpenCloseModal={handleModalToggle}
        question={
          newQuestion ? {...newQuestion, value: newQuestion.title_fr ? newQuestion.title_fr : newQuestion.value} 
          :
          {...initial_question, value: initial_question.title_fr ? initial_question.title_fr : initial_question.value}
        }
        onDone={open_modal}
        company_id={undefined}
        open={Boolean(newQuestion)}
      />

      <ConfirmDialog
        isModalOpen={toDelete.length > 0}
        title_message={"shared:delete"}
        onAction={deleteQuestions}
        onModalClose={() => setToDelete([])}
        loading={loading}
        actionText="shared:delete"
        confirmation_message="shared:do_you_want_to_continue"
      />

      <ListCategories
        isModalOpen={Boolean(showCategories)}
        // categories={selectedQuestionCategories}
        question={
          (
            questions.find(
              ({ id }) => id === showCategories
            )
            || { title: '' }
          )
        }
        onClose={(newCat) => {
          setQuestions(
            questions.map(
              _ => _.id === showCategories ? (
                {
                  ..._,
                  properties: _.properties.filter(
                    it => newCat.includes(it.category_id)
                  )
                }
              ) : _
            )
          )
          setShowCategories('')
        }}
      />

      {questions.map((question) => (
        <QuestionItem {...question} />
      ))}

      {
        questions.length < 1 && !loading && (
          <ListEmpty />
        )
      }

      <div className={styles.loader} ref={centinelRef}>
        {loading &&
          <Spinner size={spinnerSize.md} />
        }
      </div>

    </div >
  );
}

export {
  QuestionListingComponent as QuestionListingPage,
}
