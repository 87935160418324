import React, { useState } from 'react';
import styles from './TransferModal.module.scss'
import {
  Modal, Button, TextInput,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import { ArrowLeftIcon } from '@patternfly/react-icons';
import clsx from 'classnames'
// import DayPicker from 'react-day-picker';
import * as yup from 'yup'
import { Spinner, Checkbox } from '@patternfly/react-core';
import { ListCustom, ListEmpty } from 'components';
import { useCandidatesData, useUserData, useNotificationData } from 'hooks';
import { CandidateService } from 'services';


export interface TransferModalProps {
  readonly renderTrigger: any;
  readonly onDone: any;
  readonly setSelectedCandidateIds: any;
  readonly selectedCandidateIds: string[]
}



/**
 * Pour transferer des candidats aux contacts
 * Modal de selection des contacts
 */
export const TransferModal: React.FC<TransferModalProps> = ({
  renderTrigger, onDone,
  selectedCandidateIds,
  setSelectedCandidateIds,
  ...props
}) => {

  const {
    candidates: storeCandidates,
  } = useCandidatesData()

  const {
    users: storeUsers
  } = useUserData()

  const { new_notification } = useNotificationData()
  //Gestion de l'etat du modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>('')
  const { formatMessage } = useIntl()
  //Chargment
  const [loading, setLoading] = useState<boolean>(false);
  //Etape final: en activant mode final, on naffiche que des element deja selectionné
  const [final_mode, setfinal_mode] = React.useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([])

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
    setfinal_mode(false)
    setSelectedContacts([])
  };

  const saveMemberToApi = e => {
    const data = {
      candidates_ids: selectedCandidateIds,
      contacts_ids: selectedContacts
    }
    setLoading(true)
    CandidateService.transfer_candidates(data)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          setIsModalOpen(false)
          onDone(...selectedContacts)
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  const currentElementLength = () => (
    final_mode ?
      storeCandidates.filter(
        c => selectedCandidateIds.includes(c.id)
      ).length
      : storeUsers.length
  )


  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={final_mode ? <span className={styles.link} onClick={() => setfinal_mode(!final_mode)}><ArrowLeftIcon /></span> : <span className={styles.header_title}>{formatMessage({ id: "shared:transfer_of_candidates_to_contact" })}</span>}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        className={styles.modal_root}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <h3 className={styles.selection}>
              {selectedCandidateIds.length}
              <FormattedMessage id="transfer:selected_candidates" />
            </h3>
            {final_mode === false && <span onClick={() => setfinal_mode(!final_mode)} className={styles.action}>
              <FormattedMessage id="transfer:see_list" />
            </span>
            }
          </div>
          <div className={styles.main}>
            {final_mode === false && <h2 className={styles.headline}>
              <FormattedMessage id="transfer:contact_list" />
            </h2>}
            <TextInput
              name='identification_name'
              type="text"
              value={text}
              onChange={setText}
              aria-label="text input example"
              placeholder="Rechercher"
            />
            <div></div>
            {currentElementLength() === 0 && (
              <ListEmpty />
            )}
            {currentElementLength() > 0 && (
              <ListCustom
                single={true}
                searchString={text}
                list={
                  final_mode ?
                    storeCandidates.filter(
                      c => selectedCandidateIds.includes(c.id)
                    )
                    : storeUsers.map(
                      ({ firstName: firstname, lastName: lastname, ...u }) => ({ ...u, firstname, lastname })
                    )
                }
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                checkall={final_mode}
                final_mode={final_mode}
                setSelectedCandidateIds={(param) => {
                  if (selectedCandidateIds.length === 1) {
                    setIsModalOpen(false)
                  }
                  setSelectedCandidateIds(param)
                }}
              />
            )}

          </div>
          <div className={styles.actions}>
            <Button
              className={clsx(
                // styles.btn,
                // styles.btn_primary,
              )}
              isDisabled={loading || selectedContacts.length === 0 || selectedCandidateIds.length === 0}
              type="button"
              onClick={saveMemberToApi}
            >
              <FormattedMessage id="routes.transfer" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
