import { ObservableStore } from '@codewithdan/observable-store';
import {
    pluck,
} from 'rxjs/operators'
import {
    ICandidate, CANDIDATE_STORE_ACTIONS,
} from '.'

class ArchivedCandidateStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        ...CANDIDATE_STORE_ACTIONS,
        INIT_STATE: 'INIT_STATE',
        LOGOUT: 'CANDIDATE_STORE:LOGOUT',
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }

    //#region Candidates
    resetCandidates = (param: IStoreCandidate) => {
        this.setState(
            state => ({
                ...state,
                candidates: { ...param, data: param.data }
            }),
            ArchivedCandidateStore.ACTIONS.RESET_CANDIDATES,
        )
    }

    appendCandidate = (candidate: ICandidate) => {
        this.setState(
            state => ({
                ...state,
                candidates: { ...state.candidates, data: [candidate, ...(state.candidates.data || [])] },
            }),
            ArchivedCandidateStore.ACTIONS.APPEND_CANDIDATES,
        )
    }


    appendCandidates = ({ data, ...param }: IStoreCandidate) => {
        this.setState(
            state => ({
                ...state,
                candidates: { ...param, data: [ ...(state.candidates.data || []), ...data] },
            }),
            ArchivedCandidateStore.ACTIONS.APPEND_CANDIDATES,
        )
    }

    updateCandidate = (candidate: ICandidate) => {
        const oldCandidateId = this.getState().candidates.data!.findIndex(cad => cad.id === candidate.id)
        if (oldCandidateId === -1) { return }

        /**At this point, candidates CANNOT be an empty array no undefined reference */
        const candidates = this.getState().candidates.data
        candidates![oldCandidateId] = candidate

        this.setState(
            state => ({
                ...state,
                candidates: { ...state.candidates, data: candidates },
            }),
            ArchivedCandidateStore.ACTIONS.UPDATE_CANDIDATE,
        )
    }

    deleteCandidate = (...candidateIds: string[]) => {
        const candidatesData = this.getState().candidates.data!.filter(
            cad => !candidateIds.includes(cad.id)
        )
        this.setState(
            state => ({
                ...state,
                candidates: { ...state.candidates, total: candidatesData.length, data: candidatesData },
            }),
            ArchivedCandidateStore.ACTIONS.DELETE_CANDIDATE,
        )
    }

    /**
     * **************getter for everything candidates data.****************
     * Notice the distinctUntilChanged operator
     * used to ensure that it doesn't fire at all
     * https://rxjs-dev.firebaseapp.com/api/operators/distinctUntilChanged
     */
    get candidates$() {
        return this.stateChanged.pipe(
            pluck('candidates'),
        )
    }
    //#endregion candidates

    logout = () => {
        const defaultData = {
            data: [],
            start: 0,
            limit: 30,
            total: 0,
        }
        this.setState({
            candidates: defaultData,
        }, ArchivedCandidateStore.ACTIONS.INIT_STATE)

    }

}

export default new ArchivedCandidateStore()

export interface IStoreCandidate {
    limit?: number,
    start?: number,
    total?: number,
    data: ICandidate[],
}

export interface StoreState {
    candidates: IStoreCandidate,
}