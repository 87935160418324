import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { CategoryStore, ICategoryType } from 'stores'
import { takeUntil } from 'rxjs/operators'



export function useCategoryData() {
    const {
        appendCategories, resetCategories, deleteCategory, updateCategory, categories$
    } = CategoryStore

    const [start, setStart] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [limit, setLimit] = useState<number>(30)
    const [categories, setCategories] = useState<ICategoryType[]>([])

    /**Setup accordion */
    useEffect(() => {
        const trigger = new Subject<boolean>()

        categories$
            .pipe(
                takeUntil(trigger.asObservable())
            )
            .subscribe(
                ({
                    start = 0,
                    limit = 30,
                    total = 0,
                    data,
                }) => {
                    setStart(start)
                    setTotal(total)
                    setLimit(limit)
                    setCategories(data)
                }
            )

        //onUnmount
        return () => trigger.next(false)
    }, [])

    return {
        appendCategories, resetCategories, deleteCategory, updateCategory,
        categories, start, limit, total,
    }
}