import React from 'react'
import { useIntl } from 'react-intl'

export interface IOption{
    masculine:boolean
}

export const useFolderStatuses = (option?:IOption) => {
    const { formatMessage } = useIntl()

    let  folderStatuses = [
        {
            id: 5,
            name: formatMessage({ id: 'shared:archived_and_closed' }),
        },
        {
            id: 4,
            name: formatMessage({ id: 'shared:archived' }),
        },
        {
            id: 0,
            name: formatMessage({ id: 'shared:opened' }),
        },
        {
            id: 3,
            name: formatMessage({ id: 'shared:locked' }),
        },
        {
            id: 1,
            name: formatMessage({ id: 'shared:loading' }),
        },
        {
            id: 2,
            name: formatMessage({ id: 'shared:fax_received' }),
        },
    ]

    if(option && option.masculine){
        folderStatuses = [
            {
                id: 5,
                name: formatMessage({ id: 'shared:archived_and_closed_masculine' }),
            },
            {
                id: 4,
                name: formatMessage({ id: 'shared:archived_masculine' }),
            },
            {
                id: 0,
                name: formatMessage({ id: 'shared:open_masculine' }),
            },
            {
                id: 3,
                name: formatMessage({ id: 'shared:locked_masculine' }),
            },
            {
                id: 1,
                name: formatMessage({ id: 'shared:loading' }),
            },
            {
                id: 2,
                name: formatMessage({ id: 'shared:fax_received' }),
            },
        ]
    }
    return { folderStatus: folderStatuses }
}