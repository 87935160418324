import React, { Component } from 'react';
import styles from './NotificationManager.module.scss'
import { Alert, AlertActionCloseButton } from '@patternfly/react-core';
import { mapTo, tap, takeUntil, map, switchMap, delay, debounceTime, bufferTime, flatMap, mergeMap } from 'rxjs/operators';
import { interval, Subject, race, timer, of } from 'rxjs';
import { NotificationStore } from 'stores';

export interface NotificationManagerProps {
  readonly autoDismiss?: number;
}

export class NotificationManager extends Component<NotificationManagerProps, any> {
  constructor(props) {
    super(props)
    this.state = { notifications: [] }
  }


  notification$ = NotificationStore.notification$
  new_notification = NotificationStore.new_notification

  dismiss$ = new Subject<number>()
  close$ = new Subject<boolean>()
  trigger$ = new Subject<boolean>()


  componentDidMount() {
    let { autoDismiss = 5000, ...props } = this.props
    this.notification$
      .pipe(
        map(notif => ({ ...notif, id: `${Date.now()}_${Math.random()}` })),
        bufferTime(1000),
        takeUntil(this.trigger$.asObservable()),
        tap(notif => {
          // console.log({notif})
          this.setState({
            notifications: [
              ...this.state.notifications, ...notif
            ]
          })
        }),
        flatMap(notifs => of(...notifs)),
        mergeMap(({ id }) => (
          race(
            timer(autoDismiss)
              .pipe(
                mapTo(id),
                tap(id => console.log({ id }))
              ),
            this.dismiss$.asObservable()
              .pipe(
                mapTo(id),
                tap(id => console.log({ id, click:true }))
              ),
          ))
        ),
      )
      .subscribe(
        toBeDeletedId => this.setState({
          notifications: this.state.notifications.filter(
            ({ id }) => id !== toBeDeletedId
          )
        })
      )

  }

  componentWillUnmount() {
    this.trigger$.next(false)
  }

  render() {
    const { notifications } = this.state

    return (
      <>
        {notifications.length > 0 && (
          <div
            // ref={notification_container_ref}
            className={styles.notification_container_ref}
          >
            {notifications.map((notification) => (
              <Alert
                key={notification.id}
                variant={notification!.variant === "error" ? "danger" : notification!.variant}
                title={notification!.title}
                actionClose={
                  <AlertActionCloseButton
                    onClose={
                      () => this.dismiss$.next(notification.id)
                    }
                  />
                }
              >
                {notification!.message}
              </Alert>
            ))}
          </div>
        )}
      </>
    )
  }
}
