import React, { useState } from 'react';
import styles from './SearchField.module.scss'

import {
  TextInput,
  DropdownItem,
  Dropdown, DropdownToggle,
} from '@patternfly/react-core';
import { TABLE_COLUMN } from 'constants/table_columns';
import { useFolderStatuses } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';

export interface SearchFieldProps {
  field: string,
  onAction: any,
  dateFields: string[],
  numberFields?: string[],
  value: string | number,
  className?: string,
  statusField?: string,
  questionTypeField?: string,
  reopened_field?: string,
  forms?: any[],
  categories?: any[],
  categoriesField?: string,
  formsField?: string,
  removeArchivedStatus?: boolean,
}

export const SearchField: React.FC<SearchFieldProps> = ({
  field, onAction, dateFields, value,
  className = '', statusField, questionTypeField = '',
  reopened_field = '',
  categories = [], forms = [], numberFields,
  categoriesField, formsField, removeArchivedStatus = false,
}) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const [open1, setOpen1] = useState<boolean>(false)
  const { folderStatus } = useFolderStatuses()
  const booleans = [
    { name: formatMessage({ id: "shared:true" }), id: '1' },
    { name: formatMessage({ id: "shared:false" }), id: '0' },
  ]
  const fieldTypes = [
    {
      name: formatMessage({ id: 'shared:checkbox' }),
      id: 'checkbox'
    },
    {
      name: formatMessage({ id: 'shared:text' }),
      id: 'text'
    },
    {
      name: formatMessage({ id: 'shared:select' }),
      id: 'select'
    },
    {
      name: formatMessage({ id: 'shared:email' }),
      id: 'email'
    },
    {
      name: formatMessage({ id: 'shared:date' }),
      id: 'date'
    },
    {
      name: formatMessage({ id: 'shared:textarea' }),
      id: 'textarea'
    },
    {
      name: formatMessage({ id: 'shared:radio' }),
      id: 'radio'
    },
  ]

  let returnVal = <TextInput
    placeholder={formatMessage({ id: "shared:search" })}
    type={
      dateFields.includes(field) ? 'date'
        : (numberFields || []).includes(field) ? 'number' : 'search'
    }
    onChange={text => {
      if (text.length > 2 || text.length === 0) {
        onAction(text)
      }
    }}
    // value={value}
    className={className}
    aria-label="search text input"
  />

  if (statusField === field) {
    returnVal = <Dropdown
      className={className}
      isOpen={open}

      toggle={
        <DropdownToggle
          onToggle={setOpen}
        >
          {
            value || value === 0 ?
              folderStatus
                .find(jk => jk.id === value)!.name
              : formatMessage({ id: 'shared:status' })
          }
        </DropdownToggle>
      }

      dropdownItems={
        folderStatus
          .filter(({ id }) => removeArchivedStatus ? id !== 4 : true)
          .map(orderIn => (
            <DropdownItem
              onClick={_ => {
                onAction(orderIn.id)
                setOpen(false)
              }}
              key={orderIn.id}
              value={orderIn.id}
              component="button"
            >
              {orderIn.name}
            </DropdownItem>
          ))
      }

    />
  }

  if (questionTypeField === field && field !== "") {
    returnVal = <Dropdown
      className={className}
      isOpen={open}

      toggle={
        <DropdownToggle
          onToggle={setOpen}
        >
          {
            value || value === 0 ?
              fieldTypes.find(jk => jk.id === value)!.name
              : formatMessage({ id: 'shared:type' })
          }
        </DropdownToggle>
      }

      dropdownItems={
        fieldTypes
          .map(typein => (
            <DropdownItem
              onClick={_ => {
                onAction(typein.id)
                setOpen(false)
              }}
              key={typein.id}
              value={typein.id}
              component="button"
            >
              {typein.name}
            </DropdownItem>
          ))
      }
    />
  }

  if (categories.length > 0 && categoriesField === field) {
    returnVal = <Dropdown
      className={className}
      isOpen={open}

      toggle={
        <DropdownToggle
          onToggle={setOpen}
        >
          {
            value || value === 0 ?
              categories.find(jk => jk.id === value)!.name
              : formatMessage({ id: 'shared:category_type' })
          }
        </DropdownToggle>
      }

      dropdownItems={
        categories
          .map(typein => (
            <DropdownItem
              onClick={_ => {
                onAction(typein.id)
                setOpen(false)
              }}
              key={typein.id}
              value={typein.id}
              component="button"
            >
              {typein.name}
            </DropdownItem>
          ))
      }

    />
  }

  if (forms.length > 0 && formsField === field) {
    returnVal = <Dropdown
      className={className}
      isOpen={open}

      toggle={
        <DropdownToggle
          onToggle={setOpen}
        >
          {
            value || value === 0 ?
              forms.find(jk => jk.id === value)!.name
              : formatMessage({ id: 'shared:form_type' })
          }
        </DropdownToggle>
      }

      dropdownItems={
        forms
          .map(typein => (
            <DropdownItem
              onClick={_ => {
                onAction(typein.id)
                setOpen(false)
              }}
              key={typein.id}
              value={typein.id}
              component="button"
            >
              {typein.name}
            </DropdownItem>
          ))
      }

    />
  }


  if (reopened_field === field && field !== "") {
    returnVal = <Dropdown
      className={className}
      isOpen={open}

      toggle={
        <DropdownToggle
          onToggle={setOpen}
        >
          {
            value || value === 0 ?
              booleans
                .find(jk => jk.id === value)!.name
              : formatMessage({ id: 'shared:reopened' })
          }
        </DropdownToggle>
      }

      dropdownItems={
        booleans.map(it => (
            <DropdownItem
              onClick={_ => {
                onAction(it.id)
                setOpen(false)
              }}
              key={it.id}
              value={it.id}
              component="button"
            >
              {it.name}
            </DropdownItem>
          ))
      }

    />
  }

  return returnVal

}