import React, { useState, useEffect, } from 'react'
import { AppStore, IRole } from 'stores'
import { Subject } from 'rxjs'
import { take, skip } from 'rxjs/operators'
import { UtilitiesService } from 'services'
import { useNotificationData } from 'hooks'
import { useIntl } from 'react-intl'

export const useRoleData = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [fetchError, setFetchError] = useState<boolean>(false)
    const [roles, setRoles] = useState<IRole[]>([])
    const { formatMessage } = useIntl()

    const trigger = new Subject<boolean>()
    const { resetRoles, roles$ } = AppStore
    const { new_notification } = useNotificationData()

    useEffect(() => {
        roles$.pipe(
            // skip(1),
            take(1)
        ).subscribe(
            storeRoles => {
                if (!Boolean(storeRoles)) {
                    fetchRoles()
                    return
                }
                if (storeRoles.length === 0 && !loading && !fetchError) {
                    fetchRoles()
                    return
                }
                setRoles(storeRoles)
            }
        )

        return () => trigger.next(false)
    }, [])

    const fetchRoles = () => {
        setLoading(true)
        UtilitiesService.list_roles()
            .catch(err => {
                new_notification({
                    title: formatMessage({ id: 'shared:error' }),
                    variant: 'error',
                    message: formatMessage({ id: "role:error_fetching_role" }),
                })
                setLoading(false)
                setFetchError(true)
            })
            .then(async response => {
                if (response)
                    if ([200, 201].includes(response.status)) {

                        const payload = await response.json()
                        resetRoles(payload.data)
                        setRoles(payload.data)

                        setLoading(false)

                    } else if ([404, 401, 500, 400, 409].includes(response.status)) {
                        const { message } = await response.json()
                        new_notification({
                            title: formatMessage({ id: "shared:error" }),
                            message,
                            variant: 'error',
                        })
                        setLoading(false)
                        setFetchError(true)
                    } else {
                        new_notification({
                            title: formatMessage({ id: "shared:error" }),
                            message: formatMessage({ id: "role:error_fetching_roles" }),
                            variant: 'error',
                        })
                        setLoading(false)
                        setFetchError(true)
                    }
            })

    }

    return { loading, roles }

}