import React, { useState, useEffect } from 'react';
import styles from './SideNav.module.scss'
import {
  Nav, NavExpandable, NavItem, NavList, List, ListItem, Label,
} from '@patternfly/react-core';
import {
  entryPageRoutes
} from 'routes'
import { Link, NavLink, } from "react-router-dom";
import { useRouteMatch, useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { useUserRoleData } from 'hooks';


export const SideNav = (url = 'lkjsdf') => {
  const [activeGroup, setactiveGroup] = useState<string>('')
  const [activeItem, setactiveItem] = useState<string>('')
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const { roles: serverRoles, currentRole, switchRole } = useUserRoleData()

  const onNavSelect = result => {
    setactiveGroup(result.groupId)
    setactiveItem(result.itemId)
    console.log({ match: result })
  }

  const history = useHistory()

  useEffect(() => {
    const { pathname } = history.location
    const activeRoute = pathname.split('/').pop()
    entryPageRoutes(url, [currentRole],)
      .filter(
        route => route.routes.map(
          r => r.to?.split('/').pop()
        ).includes(activeRoute))
      .forEach(route => {
        setactiveGroup(route.title)
        route.routes.filter(paramRoute => paramRoute.menu_type)
          .filter(r => r.to?.split('/').pop() === activeRoute)
          .forEach(r => {
            setactiveItem(r.title!)
          })
      })
  }, [])

  return (
    <Nav
      onSelect={onNavSelect}
      className={styles.sideBarNav}
      aria-label="Nav" theme="dark"
    >
      <NavList>
        <NavExpandable
          title={
            formatMessage({ id: currentRole || 'shared:determining' })
          }
          groupId="grp-0"
          isActive={activeGroup === 'grp-0'}
          className={styles.sessionNavSelect} aria-hidden={"true"}>
          {/* Session roles custom component */}
          <div className={styles.customRoleDropdown}>
            <List>
              {serverRoles.map((role, index) =>
                <ListItem key={index} onClick={() => switchRole(role)}>
                  {formatMessage({ id: role })}
                </ListItem>)
              }
            </List>
          </div>
        </NavExpandable>


        {
          entryPageRoutes(url, [currentRole],)
            .filter(t => (t.routes.filter(_ => _.menu_type).length > 0))
            .map((route, idx) => (
              <NavExpandable
                className={styles.menu_item}
                //@ts-ignore
                title={(
                  <>
                    {formatMessage({ id: route.title })}
              &nbsp;&nbsp;&nbsp;
                    {route.wip && (
                      <Label color="cyan" variant="outline">Wip</Label>
                    )}
                  </>
                )}
                groupId={route.title}
                isActive={activeGroup === route.title}
                isExpanded={activeGroup === route.title}
              >
                {
                  route.routes
                    .filter(
                      paramRoute => paramRoute.menu_type
                    )
                    .map((r, idx, arr) => (
                      <NavItem groupId={route.title} itemId={r.title} isActive={activeItem === r.title}>
                        <NavLink
                          activeClassName={styles.menu_item_active}
                          to={r.to!}
                          className={styles.menu_item}
                        >
                          <span className={styles.menu_text}>
                            <FormattedMessage
                              id={r.title!}
                            />
                    &nbsp;&nbsp;&nbsp;
                    {r.wip && (
                              <Label color="cyan" variant="outline">Wip</Label>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
              </NavExpandable>
            ))}
      </NavList>
    </Nav>
  )
}
