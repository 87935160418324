import {
    GeneralPage,
    ArchiveListingPage,
    ArchivedCandidateListingPage,
    CandidateListingPage,
    FormDetailPage,
    CompanyListingPage,
    EnterpriseDepartmentsPage,
    ManagerChangePasswordPage,
    ActivatedCompany,
    CandidateDetailsPage,
    CategoryDetailPage,
    TransferPage,
    UsersPage,
    AdminDashboardPage,
    ProfilePage,
    NewDemandPage,
    UserListingPage,
    EnterpriseDashboardPage,
    QuestionListingPage,
    VerifiableSectionPage,
    CompanyBillingListingPage,
    CompanyBillingDetailPage,
    SelectCategoryListingPage,
    FinanceInfoPage,
    DemandDraftListingPage,
} from 'pages'

import {
    parseRoles,
} from 'utilities'
import {
    ROLE_ADMINISTRATOR, ROLE_TECHNICIAN, ROLE_CONTACT,
    COMPANY_ADMINISTRATOR, ACCOUNTANT,
} from "constants/roles"
import { Component } from 'react'
import { FormsListingPage } from 'pages/forms_listing_page/FormsListing.page'
import { CategoryListingPage } from 'pages/category_listing_page/CategoryListing.page'
import { DemandListingPage } from 'pages/demand_listing_page/DemandListing.page'

interface Route {
    to?: string;
    title_type?: boolean;
    data?: any;
    title?: string;
    component?: any;
    roles?: string[];
    menu_type?: boolean;
    wip?: boolean;
    exact?: boolean;
}

export interface MenuRoute {
    title: string,
    wip?: boolean,
    routes: Route[],
}

type Routes = (url: string, roles: string[]) => MenuRoute[]

const getRoutesPerRoles: Routes = (baseRoute = '/me', roles: string[]) => {
    const routes: MenuRoute[] = [
        {
            title: 'routes.none',
            routes: [
                {
                    menu_type: false,
                    to: '/profile',
                    title: "routes.profile",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        COMPANY_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                        ROLE_CONTACT,
                        ACCOUNTANT,
                    ]),
                    component: ProfilePage,
                }, /* ProfilePage section */

            ]
        },
        {
            title: 'shared:administration',
            routes: [
                {
                    menu_type: true,
                    to: '/dashboard',
                    title: "routes.enterprise_dashboard",
                    wip: false,
                    roles: parseRoles([
                        COMPANY_ADMINISTRATOR,
                    ]),
                    component: EnterpriseDashboardPage,
                    exact: true
                },
                {
                    menu_type: true,
                    to: '/dashboard',
                    title: "routes.dashboard",
                    wip: false,
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                    ]),
                    component: AdminDashboardPage,
                    exact: true
                },
                {
                    menu_type: true,
                    to: '/companies',
                    title: "routes.companies",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                    ]),
                    component: CompanyListingPage,
                }, /* CompanyListingPage section */
                {
                    menu_type: true,
                    wip: false,
                    to: '/activated_companies',
                    title: "routes.activated_companies",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                    ]),
                    component: ActivatedCompany,
                }, /* CompanyListingPage section */
                {
                    menu_type: true,
                    to: '/forms',
                    title: "routes:forms",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        // ROLE_TECHNICIAN,
                    ]),
                    component: FormsListingPage,
                    exact: true,
                }, /* Formulaires list section */
                {
                    menu_type: true,
                    to: '/verifiable_section',
                    title: "routes:verifiable_section",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        // COMPANY_ADMINISTRATOR,
                        // ROLE_TECHNICIAN,
                        // ROLE_CONTACT,
                    ]),
                    component: VerifiableSectionPage,
                },
                {
                    menu_type: false,
                    to: '/forms/:id',
                    title: "routes:forms",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                    ]),
                    component: FormDetailPage,
                    exact: true
                },
                {
                    menu_type: false,
                    to: '/forms/:id/categories',
                    title: "routes:forms",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                    ]),
                    component: FormDetailPage,
                    exact: true
                },
                {
                    menu_type: false,
                    to: '/forms/:id/categories/:category_id',
                    title: "routes:categories",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                    ]),
                    component: CategoryDetailPage,
                    exact: true
                },
                {
                    menu_type: true,
                    to: '/categories',
                    title: "routes:categories",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                    ]),
                    component: CategoryListingPage,
                    exact: true
                },/* CategoryListingPage section */
                {
                    menu_type: true,
                    to: '/questions',
                    title: "routes:questions",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                    ]),
                    component: QuestionListingPage,
                    exact: true,
                },/* QuestionListingPage section */
                {
                    menu_type: false,
                    to: '/questions/select_categories',
                    title: "routes:questions",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                    ]),
                    component: SelectCategoryListingPage,
                    exact: true,
                },/* SelectCategoryListingPage section */
                {
                    menu_type: true,
                    to: '/billing',
                    title: "routes:billing",
                    wip: false,
                    roles: parseRoles([
                        ACCOUNTANT,
                        ROLE_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                    ]),
                    component: CompanyBillingListingPage,
                    exact: true
                },
                {
                    menu_type: false,
                    to: '/billing/:company_id',
                    title: "routes:billing",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        ACCOUNTANT,
                        ROLE_TECHNICIAN,
                    ]),
                    component: CompanyBillingDetailPage,
                    exact: true
                },
                {
                    menu_type: true,
                    to: '/billing',
                    title: "routes:billing",
                    wip: false,
                    roles: parseRoles([
                        COMPANY_ADMINISTRATOR,
                    ]),
                    component: CompanyBillingDetailPage,
                    // exact: true
                },
            ]
        },
        {
            title: 'shared:client',
            routes: [
                {
                    menu_type: true,
                    to: '/departments',
                    title: "routes.enterprise_departments",
                    wip: false,
                    roles: parseRoles([
                        COMPANY_ADMINISTRATOR,
                    ]),
                    component: EnterpriseDepartmentsPage,
                    exact: true
                },
                {
                    menu_type: false,
                    to: '/departments/:dep_id/users',
                    title: "routes.users",
                    wip: false,
                    roles: parseRoles([
                        COMPANY_ADMINISTRATOR,
                    ]),
                    component: UsersPage,
                    exact: true
                },
                {
                    title_type: false,
                    to: '/candidates/:candidate_id',
                    title: "routes.candidates",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        COMPANY_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                        ROLE_CONTACT,
                    ]),
                    component: CandidateDetailsPage,
                }, /* CandidateDetailsPage section */
                {
                    menu_type: true,
                    to: '/candidates',
                    title: "routes.candidates",
                    wip: false,
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        COMPANY_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                        ROLE_CONTACT,
                    ]),
                    component: CandidateListingPage,
                }, /* CandidateListingPage section */
                {
                    menu_type: true,
                    to: '/archives',
                    title: "routes.archives",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        COMPANY_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                        ROLE_CONTACT,
                    ]),
                    component: ArchivedCandidateListingPage,
                }, /* ArchivedCandidateListingPage section */
                {
                    menu_type: false,
                    to: '/demands/new',
                    title: "routes:demands",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        COMPANY_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                        ROLE_CONTACT,
                    ]),
                    component: NewDemandPage,
                }, /* NewDemandPage section */
                {
                    menu_type: true,
                    to: '/demands',
                    title: "routes:demands",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        COMPANY_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                        ROLE_CONTACT,
                    ]),
                    component: DemandListingPage,
                }, /* DemandListingPage section */
                {
                    menu_type: true,
                    wip: false,
                    exact: true,
                    to: '/draft_demands',
                    title: "routes:draft_demands",
                    roles: parseRoles([
                        COMPANY_ADMINISTRATOR,
                        ROLE_CONTACT,
                    ]),
                    component: DemandDraftListingPage,
                }, /* DemandDraftListingPage section */
                {
                    menu_type: false,
                    wip: true,
                    to: '/draft_demands/:draft_id',
                    title: "routes:draft_demands",
                    roles: parseRoles([
                        COMPANY_ADMINISTRATOR,
                        ROLE_CONTACT,
                    ]),
                    component: FinanceInfoPage,
                }, /* DemandDraftListingPage section */
                {
                    menu_type: false,
                    to: '/general',
                    title: "routes.general",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        COMPANY_ADMINISTRATOR,
                        ROLE_CONTACT,
                    ]),
                    component: GeneralPage,
                }, /* General section */
            ]
        },
        {
            title: 'routes.users',
            routes: [
                {
                    menu_type: false,
                    to: '/users/:company_id',
                    title: "routes.users",
                    roles: parseRoles([
                        ROLE_TECHNICIAN,
                        ROLE_ADMINISTRATOR,
                    ]),
                    component: UserListingPage,
                    data: { company_users: true },
                    exact: true
                },
                {
                    menu_type: true,
                    to: '/users',
                    title: "routes.users",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        COMPANY_ADMINISTRATOR,
                    ]),
                    component: UserListingPage,
                    exact: true
                },
                {
                    menu_type: true,
                    to: '/transfer',
                    title: "routes.transfer",
                    roles: parseRoles([
                        ROLE_ADMINISTRATOR,
                        ROLE_TECHNICIAN,
                    ]),
                    component: TransferPage,
                    exact: true
                },
            ]
        },
    ]

    const parsedRoutes = routes.map(
        route => ({
            ...route,
            routes: route.routes.map(
                t => ({ ...t, to: `${baseRoute}${t.to}` })
            )
        })
    )

    const filteredRoutesPerRole = parsedRoutes.map(
        t => ({
            ...t,
            routes: t.routes.filter(
                route => roles.some(
                    role => {
                        return route.roles!.includes(
                            role.toLowerCase()
                        )
                    }
                )
            )
        })
    )
    // .filter(t => (t.routes.length > 1))

    return filteredRoutesPerRole
}

export {
    getRoutesPerRoles as entryPageRoutes
}