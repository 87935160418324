import React, { useState, useEffect } from 'react'
import { useUserInfoData } from 'hooks/data_hook/use_user_info_data.hook'
import { ILoginInfo } from 'stores/auth.store'
import io from 'socket.io-client'
import config from 'config';
export const socket_url = config.socket_url;

export interface WSNotification {
    candidate: {
        email: "ljsd@me.com",
        firstname: "Magic",
        id: "040f802a-ee64-4a39-a989-4aecfd0855bb",
        lastname: "jlweiwep"
    },
    length: 1
    content: "New verification request"
    date: "2020-06-09 12:06:20.542213"
    id: "97659c40-c4ab-48ea-8f3f-11269d055e2e"
    initiated_by: {
        email: "pefin57093@lywenw.com",
        firstname: "Pefin",
        id: "657a194b-fa35-486e-913d-f3b1ef544bea",
        lastname: "",
        username: "pefin57093"
    },
    notif_data: { id: "040f802a-ee64-4a39-a989-4aecfd0855bb" }
    receiver_id: "ddd0be1c-c5cf-460c-9bf9-0f251b3b1836"
    status_id: 1
    title: string,
}

export function useWebSocketIOData(should_listen) {
    const [notifications, setNotifications] = useState<WSNotification[]>([])
    const [notificationTrigger, setNotificationTrigger] = useState<any>()

    const { userInfo } = useUserInfoData()
    let [nns, setNNS] = useState<any>()
    let onReadNotification = (param_notif) => {
        console.log({ param_notif, notifications })
        nns.emit('read notifications', { data: [param_notif.id] })
        setNotifications(
            notifications.filter(
                ({ id }) => id !== param_notif.id
            )
        )
    }

    useEffect(() => {
        if (Boolean(userInfo) && should_listen) {
            connectToWebsocket()
        }
    }, [userInfo])

    useEffect(() => {
        if (!Boolean(notificationTrigger)) { return }
        setNotifications([notificationTrigger, ...notifications])
    }, [notificationTrigger])


    const connectToWebsocket = () => {
        if (!Boolean(userInfo)) {
            return
        }
        if (!Boolean((userInfo as ILoginInfo).id)) {
            return
        }
        const room = (userInfo as ILoginInfo).id
        const namespace = io.connect(
            socket_url,
            { transports: ['polling'] }
        )

        setNNS(namespace)

        namespace.on('connect', () => {
            namespace.emit('room', { data: room });
            namespace.emit('first_connect', { data: room });
            namespace.on('new_notification', (new_notification) => {
                console.log({new_notification})
                setNotificationTrigger(new_notification)
            })
        })

        namespace.emit('get unread notifications', { data: room })
        namespace.on('unread notifications', (unread_notifications) => {
            console.log({ unread_notifications })
            setNotifications(unread_notifications.data)
        })

    }

    return { notifications, onReadNotification }

}