import React, { useState, useEffect, } from 'react';
import styles from './AddEditCategory.module.scss'
import { Button, Modal, Spinner, spinnerSize, ModalVariant } from '@patternfly/react-core';
import { CustomForm } from 'components/forms/CustomForm';
import { categories_fields, FieldType, ICategoryType } from 'constants/example';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNotificationData, useUserInfoData } from 'hooks';
import { CategoriesService } from 'services';
import { IFormsType } from 'stores'
import { useRouteMatch } from 'react-router';
import { ILoginInfo } from 'stores/auth.store';

export interface AddEditCategoryProps {
  readonly dumm?: boolean;
  readonly open: boolean;
  readonly onCloseModal: () => void;
  readonly category: ICategoryType;
  readonly appendCategory: any;
  readonly updateCategory?: any;
  readonly form: IFormsType;
}

/**
 * Modal Pour l'edition d'un formulaire
 * @param open: variable, vrai si modal est ouvert/ et faux si fermé
 * @param onCloseModal: Fonction, permet de fermer/ouvrir le modal
 * @param form: variable, contient le formulaire à editer 
 * @param updateCategory: variable, contient le formulaire à editer 
 */
const AddEditCategory: React.FC<AddEditCategoryProps> = props => {

  const { open, onCloseModal, category, appendCategory, updateCategory } = props;
  const [fields,] = React.useState<FieldType[]>(categories_fields);
  //const [category, setcategory] = React.useState<CategoryType>();
  const { formatMessage } = useIntl();
  const { new_notification } = useNotificationData()
  const [stateInitialValues, setInitialValues] = useState<any>({})
  const [catId, setCatId] = useState<string | undefined>(undefined)
  const { params } = useRouteMatch()
  const { userInfo: { company_id } } = useUserInfoData() as { userInfo: ILoginInfo }

  //Ouvrir/Fermer le modal
  const handleModalToggle = () => {
    onCloseModal();
  }

  let validationSchema: any = {}

  useEffect(() => {
    let initialValues: any = {};
    fields.map(field => {
      if (field.required) {
        validationSchema[field.name] = yup.string().required("Champs requis!")
      }
      initialValues[field.name] = '';
    })

    setInitialValues(initialValues)
  }, [])

  const saveCattoApi = (new_cat, { setSubmitting }) => {
    const data = {
      //@ts-ignore
      form_id: params.id,
      // title: new_cat.name_fr,
      title: new_cat.name_fr ? new_cat.name_fr : new_cat.name,
      title_en: new_cat.name_en,
      description: new_cat.description,
      company_id,
      is_global: Boolean(new_cat.is_global),
    }
    setSubmitting(true)
    let method;
    if (Boolean(catId)) {
      method = CategoriesService.update_category(catId, data)
    } else {
      method = CategoriesService.new_category(data)
    }

    method
      .catch(err => {
        setSubmitting(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setSubmitting(false)
        if ([200, 201].includes(response.status)) {
          const { data, message } = await response.json()
          handleModalToggle()
          if (Boolean(catId)) {
            updateCategory(data)
          } else {
            appendCategory(data)
          }
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          // @ts-ignore
          formikValues.handleReset()
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  React.useEffect(() => {
    if (!Boolean(category)) {
      const initialValues = fields
        .map(field => field.name)
        .reduce(
          (acc, field) => ({ ...acc, [field]: '' }),
          {}
        )
      setInitialValues(initialValues)
      return
    }
    let description = localStorage.categoryDescription
    console.log({ category: description })
    const effectInitialValues = {
      name: category.title_fr ? category.title_fr : category.title,
      name_en: category.title_en,
      description,
      is_global: category.is_global,
    }
    setCatId(category!.id)
    setInitialValues(effectInitialValues)
  }, [category]);


  //Pour soummettre le formulaire values,actions
  const onSubmit = (values, actions) => {
    saveCattoApi(values, actions)
  }

  const formikValues = useFormik<{}>({
    enableReinitialize: true,
    validationSchema: yup.object().shape(validationSchema),
    onSubmit: (values, actions) => onSubmit(values, actions),
    initialValues: stateInitialValues,
  });

  return (
    <React.Fragment>
      {category && <Modal
        variant={ModalVariant.small}
        name={''}
        title={
          formatMessage({
            id: Boolean(catId) ? 'new_category:edit_category' : 'new_category:new_category'
          })
        }
        isOpen={open}
        onClose={handleModalToggle}
        actions={[
          <Button key="confirm" variant="primary"
            isDisabled={formikValues.isSubmitting}
            onClick={() => {
              formikValues.submitForm();
            }}
          >
            <FormattedMessage id="shared:save" />
            {formikValues.isSubmitting && (
              <Spinner size={spinnerSize.md} />
            )}
          </Button>,
          <Button className={styles.color_primary} key="cancel" variant="link" onClick={handleModalToggle}>
            <FormattedMessage id="shared:cancel" />
          </Button>
        ]}
        //isFooterLeftAligned
      >
        <div className={styles.content}>
          <form
            className={styles.theme}
            onSubmit={formikValues.handleSubmit}>
            <CustomForm
              fields={categories_fields}
              {...formikValues}
            />
          </form>
        </div>
      </Modal>}
    </React.Fragment>
  );
}

export { AddEditCategory as AddEditCategoryModal }
