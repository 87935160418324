import React, { useState, useRef, useEffect } from 'react';
import styles from './BarnerNotifier.module.scss'
import clsx from 'classnames'
import {  useUserRoleData } from 'hooks';
import { CloseIcon } from '@patternfly/react-icons';
import { FormattedMessage } from 'react-intl';
import gsap from 'gsap'
import { race, Subject, fromEvent } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'

export interface BarnerNotifierProps {
  readonly dumm?: boolean;
}

export const BarnerNotifier: React.FC<BarnerNotifierProps> = props => {
  const notif_container_ref = useRef<any>()
  const notif_title_ref = useRef<any>()
  const notif_close_btn_ref = useRef<any>()
  const close_trigger = new Subject<string>()
  const change = new Subject<boolean>()
  const [open, setOpen] = useState<boolean>(true)

  const { currentRole } = useUserRoleData()



  useEffect(() => {
    if (notif_close_btn_ref.current) {
      const triggerBtnStream$ = fromEvent(notif_close_btn_ref.current, 'click')
        .pipe(takeUntil(change.asObservable()))
      raceClose(triggerBtnStream$, 10000)
    }
  }, [notif_close_btn_ref.current])

  const raceClose = (triggerBtnStream$, duration: number = 4000) => {
    race(
      // timer(duration),
      triggerBtnStream$,
    )
      .pipe(
        take(1),
      )
      .subscribe(closeNotification())
  }

  const parseUserRole = () => {
    let role = 'shared:determining'
    switch (currentRole) {
      case 'Administrator':
        role = "shared:admin"
        break;
      case 'Contact':
        role = "shared:contact"
        break;
      case 'Technician':
        role = "shared:technician"
        break;
      case 'Accountant':
        role = "shared:accountant"
        break;
      case 'Company_Administrator':
        role = "Company_Administrator"
        break;
    }
    return role
  }


  const closeNotification = (
  ) => () => {
    gsap.timeline()
      .to(
        notif_container_ref.current,
        { autoAlpha: 1, height: 4, duration: 0.3, }
      )
      .to(
        [
          notif_title_ref.current,
        ],
        { top: -20, autoAlpha: 0, duration: 0.2 },
        '-0.1'
      )
  }


  const openNotification = (
  ) => () => {
    gsap.timeline()
      .set(
        notif_container_ref.current,
        { height: 'auto', }
      )
      .to(
        notif_container_ref.current,
        { autoAlpha: 1, height: '100%', duration: 0.3, }
      )
      .to(
        [
          notif_title_ref.current,
        ],
        { top: 0, autoAlpha: 1, duration: 0.2 },
        '-0.1'
      )
  }

  const triggerClose = () => {
    close_trigger.next('false')
  }

  return (
    <>
      <div
        className={clsx(
          styles.notification_container,
        )}
        ref={notif_container_ref}
        onClick={openNotification}
        id="top_element"
      >
        <h3
          className={styles.notification_title}
          ref={notif_title_ref}
        >
          <FormattedMessage id="shared:connected_as" />
        &nbsp;
          <FormattedMessage id={parseUserRole()} />
        </h3>
        {/* {Object.keys(userInfo).length > 0 && userInfo.realm_access!.roles[0]} */}
        {open && (
          <button
            className={styles.btn_close}
            onClick={_ => {
              triggerClose()
              setOpen(false)
            }}
            ref={notif_close_btn_ref}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    </>
  );
}
