import * as React from 'react';
import styles from './MenuAction.module.scss'
import { OptionsMenu, OptionsMenuItem, OptionsMenuToggle } from '@patternfly/react-core';
import { EllipsisHIcon } from '@patternfly/react-icons';

export interface MenuActionProps {
  readonly dumm?: boolean;
  readonly options: any[];
  readonly handleClick: ()=> void;
}
/**
 * Pour la creation d'un menu en dropdown
 * @param props options: listes des menus a afficher
 */
export const MenuAction : React.FC<MenuActionProps> =props => {

  const [ open, setopen ] = React.useState(false);
  const { options, handleClick } = props;

  //Pour ouvrir et fermer le menus
  const onToggle = () => {
    setopen(!open);
  };
  
  //Creation et Personnalisation du menus options avec la liste recu
  const menuItems = options.map(option =>{
    return (
      <OptionsMenuItem onSelect={()=>{
        option.action(option);
        handleClick();
      }
      } id={option.id} key={option.id}>{option.name}</OptionsMenuItem>
    )
  });

  //L'icon a afficher du btn a cliquer (par defaut les trois pointiers verticale)
  const toggleTemplate = <EllipsisHIcon className={styles.icon} aria-hidden="true"/>
  //Gestion des menus, ouverture/fermeture
  const toggle = <OptionsMenuToggle onToggle={onToggle} toggleTemplate={toggleTemplate} aria-label="Sort by" hideCaret/>
  
    return (
      <React.Fragment>
        {menuItems.length > 0 && <OptionsMenu id="options-menu-plain-example" 
          isPlain
          menuItems={menuItems}  
          isOpen={open}
          toggle={toggle}/>}
      </React.Fragment>
    );
}
