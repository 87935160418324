import React, { useState, useEffect } from 'react';
import styles from './NotificationTransfer.module.scss'
import { Modal } from '@patternfly/react-core';
import { Accordion, AccordionItem, AccordionContent, AccordionToggle } from '@patternfly/react-core';
import {
    Table,
    TableHeader,
    TableBody,
    TableVariant,
} from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

export interface NotificationTransferProps {
    readonly clickedId: string;
    readonly setisKebabDropdownOpen?: any;
}

export const NotificationTransfer: React.FC<NotificationTransferProps> = ({
    clickedId, setisKebabDropdownOpen,
    ...props
}) => {

    const { formatMessage } = useIntl()
    const [expanded, setExpanded] = useState("ex-toggle1") // Expand / Close Accordion(See more detail on a notification insidee modal) 
    const [toggleModal, setToggleModal] = useState(false) // Open/Close Modal

    // Open modal
    useEffect(() => {
        if (clickedId === "candidate_transfer")
            setToggleModal(true)
        else console.log(clickedId)
    }, [clickedId])

    // Expand/ Close accordion item
    const onToggle = id => {
        if (id === expanded) {
            setExpanded('');
        } else {
            setExpanded(id);
        }
    }
    // Table header
    const columns = [
        'Candidats',
        'Adresse mail',
        ''
    ]
    // Table body
    const rows = [
        {
            cells: [
                'Janvier grégory',
                'janviergreory@mail.com',
                {
                    title: <Link to='#'> Afficher</Link>,
                    // props: { textCenter: false }
                }
            ]
        },
        {
            cells: [
                'Janvier grégory',
                'janviergreory@mail.com',
                {
                    title: <Link to='#'> Afficher</Link>,
                    // props: { textCenter: false }
                }
            ]
        },
        {
            cells: [
                'Janvier grégory',
                'janviergreory@mail.com',
                {
                    title: <Link to='#'> Afficher</Link>,
                    // props: { textCenter: false }
                }]
        }
    ]

    return (
        <Modal
            name=""
            className={styles.modal}
            title="Notifications"
            isOpen={toggleModal}
            onClose={
                () => {
                    setToggleModal(false);
                    // Also close the three dots which led us to notifications(Mobile)
                    if (setisKebabDropdownOpen)
                        setisKebabDropdownOpen(false);
                }
            }
        >
            <span className={styles.notif_title}>
                {formatMessage({ id: "notification:new_candidate_tranfer" })}
                {/* The little arrow in front of the notification title */}
                <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 320 512" aria-hidden="true" role="img" className={styles.icon}>
                    <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" transform=""></path>
                </svg>
            </span>

            <Accordion asDefinitionList  >
                <AccordionItem>
                    <AccordionToggle
                        isExpanded={expanded === 'ex-toggle1'}
                        id="ex-toggle1"
                        className={styles.accordion_toggle}
                        onClick={
                            () => { onToggle('ex-toggle1') }
                        }
                    >
                        Initié par : Olivier Bruguier
                    </AccordionToggle>
                    <p> 04 Candidats</p>
                    <AccordionContent
                        id="ex-expand1"
                        isHidden={expanded !== 'ex-toggle1'}
                    >
                        <Table borders={false} variant={TableVariant.compact} aria-label="Simple Table" cells={columns} rows={rows}>
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem>
                    <AccordionToggle
                        onClick={() => { onToggle('ex-toggle2') }}
                        isExpanded={expanded === 'ex-toggle2'}
                        id="ex-toggle2"
                        className={styles.accordion_toggle}
                    >
                        Initié par : Sébastien Morissette
                    </AccordionToggle>
                    <p> 07 Candidats</p>
                    <AccordionContent
                        id="ex-expand2"
                        isHidden={expanded !== 'ex-toggle2'}
                    >
                        <Table borders={false} variant={TableVariant.compact} aria-label="Simple Table" cells={columns} rows={rows}>
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem>
                    <AccordionToggle
                        onClick={() => { onToggle('ex-toggle3') }}
                        isExpanded={expanded === 'ex-toggle3'}
                        id="ex-toggle3"
                        className={styles.accordion_toggle}
                    >
                        Initié par : Sébastien Morissette
                    </AccordionToggle>
                    <p> 07 Candidats</p>
                    <AccordionContent
                        id="ex-expand2"
                        isHidden={expanded !== 'ex-toggle3'}
                    >
                        <Table borders={false} variant={TableVariant.compact} aria-label="Simple Table" cells={columns} rows={rows}>
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem>
                    <AccordionToggle
                        onClick={() => { onToggle('ex-toggle4') }}
                        isExpanded={expanded === 'ex-toggle4'}
                        id="ex-toggle4"
                        className={styles.accordion_toggle}
                    >
                        Initié par : Sébastien Morissette
                    </AccordionToggle>
                    <p> 07 Candidats</p>
                    <AccordionContent
                        id="ex-expand2"
                        isHidden={expanded !== 'ex-toggle4'}
                    >
                        <Table borders={false} variant={TableVariant.compact} aria-label="Simple Table" cells={columns} rows={rows}>
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </AccordionContent>
                </AccordionItem>

            </Accordion>


        </Modal>
    );
}
