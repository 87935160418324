import React, { useState, } from 'react';
import styles from './Recover.module.scss'
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom";

import {
  LoginFooterItem,
  LoginMainFooterBandItem,
  LoginPage,
  ListItem,
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button,
  Spinner,
  spinnerSize
} from '@patternfly/react-core';
import * as yup from 'yup'

import auth_img from 'assets/imgs/auth_img.png'
import logo from 'assets/imgs/logo.png'
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNotificationData } from 'hooks';
import { AuthService } from 'services';

import { LanguageSwitcher } from 'components'

export interface RecoverProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const loginMessage = (
  <>
    <LoginMainFooterBandItem>
      <Link to='/auth/login'>
        <FormattedMessage id="auth:login" />
    </Link>
    </LoginMainFooterBandItem>
  </>
);

const listItem = (
  <React.Fragment>
    <ListItem>
      <LoginFooterItem href="#">
        <FormattedMessage id="auth:terms_of_use" />
         </LoginFooterItem>
    </ListItem>
    <ListItem>
      <LoginFooterItem href="#">
        <FormattedMessage id="auth:help" />
      </LoginFooterItem>
    </ListItem>
    <ListItem>
      <LoginFooterItem href="#">
        <FormattedMessage id="auth:confidentiality" />
      </LoginFooterItem>
    </ListItem>
  </React.Fragment>
);

const RecoverComponent: React.FC<RecoverProps> = props => {
  const [loading, setLoading] = useState<boolean>(false)
  const { new_notification } = useNotificationData()
  const { formatMessage } = useIntl()
  const validationSchema = yup.object().shape({
    email: yup.string().email('shared:email_invalid')
  })

  const submitToApi = (values, { resetForm }: any) => {
    setLoading(true)
    AuthService.recover_password(values)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { data, message } = await response.json()
          resetForm({ email: "" })
          new_notification({
            message: formatMessage({ id: "auth:recover_password_instructions" }),
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message: message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  const {
    setFieldValue,
    values,
    handleSubmit,
    handleBlur,
    errors,
  } = useFormik<{ email: string }>({
    initialValues: { email: '' },
    onSubmit: submitToApi,
    validationSchema,
  })


  return (
    <div className={styles.container}>
      <div className={styles.language}>
        <LanguageSwitcher/>
      </div>
      <LoginPage className={styles.login_page_container}
        // footerListVariants="inline"
        brandImgSrc={logo}
        brandImgAlt="Pre Emploi logo"
        backgroundImgSrc={auth_img}
        backgroundImgAlt="Images"
        loginTitle={formatMessage({ id: 'auth:recover_password' })}
        loginSubtitle={formatMessage({ id: "auth:enter_email_prompt" })}
        // signUpForAccountMessage={signUpForAccountMessage}
        forgotCredentials={loginMessage}
        textContent={formatMessage({ id: "auth:explanation" })}
        footerListItems={listItem}
      >
        <Form
          onSubmit={handleSubmit}
          className={styles.form_container}
        >
          <FormGroup
            label={
              formatMessage({ id: errors.email ? errors.email : "shared:email" })
            }
            isRequired
            fieldId="revover-email"
          >
            <TextInput
              isRequired
              type="email"
              id="revover-email"
              placeholder={
                formatMessage({ id: 'shared:email' })
              }
              name="email"
              onBlur={handleBlur}
              value={values.email}
              onChange={t => setFieldValue('email', t)}
            />
          </FormGroup>
          <ActionGroup>
            <Button
              type="submit"
              isDisabled={
                Object.keys(errors).length > 0 || loading
              }
            >
              <FormattedMessage id="shared:send" />
              {loading && <Spinner size={spinnerSize.md} />}
            </Button>
          </ActionGroup>
        </Form>

      </LoginPage>
    </div >
  );
}

export {
  RecoverComponent as RecoverPage,
}