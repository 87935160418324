import React, { useState, useEffect } from 'react';
import styles from './DepartmentAddUser.module.scss'
import {
  Modal, Button, Checkbox, spinnerSize
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
import {
  useCompanyUserData,
  useNotificationData, useUserInfoData,
} from 'hooks';
import { UserService, DepartmentService } from 'services';
import { maskNumber } from 'utilities';
import { UsersAltIcon } from '@patternfly/react-icons';
import { ListEmpty } from 'components';


export interface DepartmentNewUserProps {
  readonly renderTrigger?: any;
  onDone: any
  department: any,
}

export const DepartmentAddUser: React.FC<DepartmentNewUserProps> = ({
  renderTrigger,
  onDone, department: { id: department_id, company_id },
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const { users, loading: user_loading } = useCompanyUserData(company_id)
  const [loading, setLoading] = useState<boolean>(false)
  const { new_notification, } = useNotificationData()
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])

  const addUsersToDepartment = (ids: string[]) => {
    setLoading(true)
    DepartmentService.addUsersToDepartment(department_id, {id_list:ids})
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setIsModalOpen(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          onDone(users.filter(({id}) => selectedUsers.includes(id)))
          setSelectedUsers([])
          setLoading(false)
          setIsModalOpen(false)
        } else if (response.json) {
          setLoading(false)
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          setLoading(false)
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }


  // Open/Close modal
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  };

  const handleCancel = e => {
    handleModalToggle();
  }

  const handleChange = (id) => (checked) => {
    if (checked) {
      setSelectedUsers([...selectedUsers, id])
    } else {
      setSelectedUsers(selectedUsers.filter(_ => _ !== id))
    }
  }

  return (
    <>
      {renderTrigger ? renderTrigger(handleModalToggle) : ''}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            <FormattedMessage
              id={"shared:add_user_to_department"}
            />
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        className={styles.modal_root}
      >

        {user_loading && (
          <div className={styles.loader_container}>
            <Spinner size={spinnerSize.md} />
          </div>
        )}

        {users.length === 0 && !user_loading && (
          <ListEmpty />
        )}

        <ul className={styles.list}>
          {users.map(user => (
            <li>
              <Checkbox
                label={`${user.firstName} ${user.lastName}`}
                isChecked={selectedUsers.includes(user.id)}
                onChange={handleChange(user.id)}
                aria-label="controlled checkbox example"
                id={user.id}
                name={user.id}
              />
            </li>
          ))}
        </ul>

        <div className={styles.actions}>
          <button
            className={styles.btn}
            onClick={handleCancel}
            type="button"
          >
            <FormattedMessage id="shared:cancel" />
          </button>
          <Button
            type="submit"
            onClick={() => addUsersToDepartment(selectedUsers)}
            isDisabled={loading || user_loading}
          >
            <FormattedMessage id={"user:add_users"} />
            {loading && (
              <Spinner size="sm" />
            )}
          </Button>
        </div>

      </Modal>
    </>
  );
}
