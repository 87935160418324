import React, { useState, useEffect } from 'react'
import styles from './ChangeBalance.module.scss'
import {
  Modal, Button, TextInput, spinnerSize
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useNotificationData } from 'hooks';
import { BillingService } from 'services';

export interface ChangeBalanceProps {
  readonly balance: number;
  readonly company_id: string;
  readonly renderTrigger: any;
  readonly onDone: any;
}


// const validationSchema = yup.object().shape({
//   questions_count: yup.number(),
//   forms_counts: yup.number(),
//   title: yup.string(),
// })

export const ChangeBalance: React.FC<ChangeBalanceProps> = ({
  renderTrigger,
  onDone,
  balance,
  company_id,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [amount, setAmount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const { new_notification } = useNotificationData()

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleSaveBalance = () => {
    setLoading(true)
    BillingService.updateBalance(
      company_id, { balance: balance + amount }
    )
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
          onDone(balance + amount)
          handleModalToggle()
          setLoading(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }


  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: "shared:update_balance" })}
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <div className={styles.footer}>
            <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
              <FormattedMessage id="shared:cancel" />
            </Button>
            <Button
              key="confirm"
              variant="primary"
              onClick={handleSaveBalance}
              isDisabled={loading}
            >
              <FormattedMessage id={'shared:update_balance'} />
              {loading && (
                <Spinner
                  size={spinnerSize.md}
                />
              )}
            </Button>
          </div>
        ]}
        className={styles.modal_root}
      >
        <div className={styles.wrapper}>
          <h1
            style={{
              color: balance + amount < 100 ? 'red' : 'inherit'
            }}
          >New Balance: ${balance + amount}</h1>
          
          <span className="instructions">
            <FormattedMessage id='shared:update_balance_prompt' />
          </span>
          
          <TextInput
            value={amount}
            onChange={it => setAmount(+it)}
            type="number"
          />
        </div>
      </Modal>
    </>
  );
}
