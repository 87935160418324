import React, { useState, useEffect } from 'react';
import styles from './ReorderPdf.module.scss'
import { Modal, Button, } from '@patternfly/react-core';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormattedMessage, useIntl } from 'react-intl'

export interface ReorderPdfProps {
  readonly data: any[];
  readonly onClose: any;
  readonly renderTrigger: any;
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex, endIndex) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "var(--pf-c-button--m-primary--BackgroundColor)" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: 'white',
  padding: grid,
  width: '100%'
});

export const ReorderPdf: React.FC<ReorderPdfProps> = ({
  data, renderTrigger, onClose,
  ...props
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const { formatMessage } = useIntl()

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSave = () => {
    setIsOpen(false)
    onClose(items)
  }

  useEffect(() => {
    if (!Boolean(data)) { return }
    setItems(data)
  }, [data])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const var_items = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(var_items)
  }

  return (
    <>
      {
        items.length > 1
        && renderTrigger(_ => setIsOpen(true))
      }
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: "pdf:reorder_pdf" })}
          </span>
        }
        isOpen={isOpen}
        onClose={handleClose}
        //isFooterLeftAligned={false}
        className={styles.modal_root}
        width={'50%'}
        actions={[
          <Button
            key="confirm"
            variant="primary"
            onClick={handleSave}
          // isDisabled={loading}
          >
            <FormattedMessage id="shared:save" />
          </Button>,
          <Button key="cancel" variant="link" onClick={handleClose}>
            <FormattedMessage id="shared:cancel" />
          </Button>
        ]}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.container}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className={styles.dragable_item}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <span>{item.title}</span><br />
                        {item.data.questionFields[0] && item.data.questionFields[0].fields[0] && (
                          <>
                            <span>
                              {item.data.questionFields[0] &&
                                item.data.questionFields[0].fields[0].label
                              }
                            </span><br />
                          </>
                        )}
                        {
                          item.data.questionFields[0]
                          && item.data.questionFields[0].fields[0] && (
                            <>
                              <span>
                                {item.data.questionFields[0]
                                  && item.data.questionFields[0].fields[0].value
                                }
                              </span><br />
                            </>
                          )}
                        <span>
                          {`
                            ${formatMessage({ id: "shared:is_standard" })} 
                            ${formatMessage({ id: item.is_standard ? "shared:yes" : "shared:false" })}
                          `}
                        </span><br />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

      </Modal>
    </>
  );
}
