import BaseService from './BaseService'
import { categoriesUrls } from './urls'

class CategoriesService {
    static new_category = (data) => BaseService.postRequest(categoriesUrls.NEW_CATEGORY, data, true);
    static update_category = (id, data) => BaseService.putRequest(categoriesUrls.UPDATE_CATEGORY(id), data, true);
    static reorder_categories = (form_id, data) => BaseService.putRequest(categoriesUrls.REORDER_CATEGORIES(form_id), data, true);
    static list_categories = (suffix) => BaseService.getRequest(categoriesUrls.CATEGORIES(suffix), true);
    static list_category_questions = (cat_id) => BaseService.getRequest(categoriesUrls.CATEGORY_QUESTIONS(cat_id), true);
    static getCategories = () => BaseService.getRequest(categoriesUrls.ALL_CATEGORIES, true);
    static delete_category = (data) => BaseService.deleteRequest(categoriesUrls.DELETE_CATEGORY, data, true);
    static delete_question = (data) => BaseService.deleteRequest(categoriesUrls.DELETE_QUESTION, data, true);
}

export default CategoriesService;