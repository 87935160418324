import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import styles from './CategoryListing.module.scss';
import { Button, TextInput, KebabToggle, Dropdown, spinnerSize, Spinner, Checkbox } from '@patternfly/react-core';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
  sortable,
  SortByDirection
} from '@patternfly/react-table';
import { Link, RouteComponentProps } from 'react-router-dom'
import { CloseIcon } from '@patternfly/react-icons'
import { useAccordionData, useNotificationData, useCategoryData } from 'hooks'
import { ConfirmDialog, CategoryFilter } from 'dialogs';
import { AccordionContext, ListEmpty } from 'components'
import { AddEditCategoryModal } from 'components/modals';
import { ICategoryType } from 'stores';
import { CategoriesService } from 'services';

export interface CategoryListingProps extends RouteComponentProps {
}

const CategoryListingComponent: React.FC<CategoryListingProps> = ({
  match: { url },
  history,
  ...props
}) => {

  const [observerTriggerState, setObserverTriggerState] = useState<any>({})
  const centinelRef = useRef<any>(null)
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  let timeout;
  const [selectedCategoriesIds, setSelectedCandidtesIds] = useState<string[]>([])
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [toDelete, setToDelete] = useState<string[]>([])
  const [toBeModifiedCompany, setToBeModifiedCompany] = useState<ICategoryType | undefined>(undefined)

  const [loading, setLoading] = useState<boolean>(false)
  const { setBottomComponent } = useContext(AccordionContext)
  const { formatMessage } = useIntl()
  const [columns, setColumns] = useState<any[]>([
    formatMessage({ id: 'category:category_name' }),
    { title: formatMessage({ id: 'category:number_of_questions' }), sortable: false },
    // formatMessage({ id: 'category:number_of_forms' }),
  ])
  const [filterState, setFilterState] = useState<any>(undefined)

  const {
    categories: storeCategories, start: storeStart,
    limit: storeLimit, total: storeTotal, resetCategories,
    appendCategories, updateCategory, deleteCategory,
  } = useCategoryData()


  useEffect(() => {
    clearTimeout(timeout)
    setFilterState(undefined)
    //text in search field has been emptied
    if (query === '') {
      // const companysParsed = parseCompanies(storeCategories)
      setRows(storeCategories)
      return
    }
    setSearchStart(0) //On each new search, searchStart goes to zero
    timeout = setTimeout(() => {
      searchCandidates()
    }, 300);
  }, [query])

  const handleFilter = state => {
    setSelectedCandidtesIds([])
    setQuery('')
    setFilterState(state)
    if (state === undefined) {
      // searchCandidates(undefined, null)
      setRows(storeCategories)
      return
    }
    searchCandidates(state)
  }


  const { notification, new_notification } = useNotificationData()

  const initial: ICategoryType = {
    id: '',
    title: '',
    is_global: true,
    description: '',
    questions_counts: 0
  };
  // const [category, setcategory] = useState<ICategoryType>(initial);

  const [rows, setRows] = useState<ICategoryType[]>([])
  const actions = [
    {
      title: <span className={styles.link}>
        <FormattedMessage id="shared:more_details" />
      </span>,
      onClick: (_, row_id) => {
        history.push(`/me/forms/${rows[row_id].form_id}/categories/${rows[row_id].id}`)
      }
    },
    {
      title: <span className={styles.link}>
        <FormattedMessage id="shared:delete" />
      </span>,
      onClick: (_, row_id) => {
        setToDelete([rows[row_id].id!])
      }
    },
    // {
    //   isSeparator: true
    // },
  ]

  const [sortBy, setSortBy] = useState<any>({})
  const { resetAccordion } = useAccordionData()

  const onSort = (_event, index, direction) => {
    const sortedRows = rows.sort((a, b) => (a[index] < b[index] ? -1 : a[index] > b[index] ? 1 : 0));
    setSortBy({
      index,
      direction
    })
    setRows(direction === SortByDirection.asc ? sortedRows : sortedRows.reverse())
  }

  const accordionBottom = () => (
    <span></span>
  )


  const handleSelectCategory = (isSelected, rowId) => {

    if (rowId === -1) {
      if (isSelected)
        setSelectedCandidtesIds(
          rows.map(_ => _.id!)
        )
      else setSelectedCandidtesIds([])
      return
    }

    const company_id = rows[rowId].id!
    const idx = selectedCategoriesIds.findIndex(id => id === company_id)
    if (idx === -1) {
      setSelectedCandidtesIds([...selectedCategoriesIds, company_id])
      return
    }
    const newList = [...selectedCategoriesIds]
    newList.splice(idx, 1)
    setSelectedCandidtesIds(newList)

  }

  useEffect(() => {
    setBottomComponent(accordionBottom)
    resetAccordion({
      title: formatMessage({ id: "category:list_of_categories" }),
      sub_title: ""
    })
    configureInfiniteScroll()
  }, [])

  const [modifyTrigger, setModifyTrigger] = useState<string>('')

  useEffect(() => {
    console.log('modifyTrigger')
    if (modifyTrigger !== "") {
      console.log({ modifyTrigger })
      setToBeModifiedCompany({ ...rows[modifyTrigger] })
    }
  }, [modifyTrigger])

  useEffect(() => {
    if (storeCategories && storeCategories.length > 0 && !query) {
      setRows(storeCategories)
    }
  }, [storeCategories])


  const fetchCategories = (
    paramStart: number,
    paramSearching: boolean,
    filter?: any,
    absolute?: any,
  ) => {
    setLoading(true)
    let suffix = `?start=${paramStart}&limit=${30}`
    if (absolute !== null && Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (Boolean((filter || filterState)[field])) {
          suffix = `${suffix}&${field}=${(filter || filterState)[field]}`
        }
      })
    } else if (Boolean(paramSearching)) {
      suffix = `${suffix}&title=${query}`
    }
    CategoriesService.list_categories(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { results, start, total, limit } = await response.json()
          //if we are not searching nore filtering
          if (!paramSearching && (absolute !== null && !Boolean(filter || filterState))) { //Not searching
            if (start < 1) {
              resetCategories({ data: results, start, total, limit, })
            }
            else {
              appendCategories({ data: results, start, limit, total })
            }
          } else { //Searching 
            setRows([...(start > 0 ? rows : []), ...results])
            setSearchTotal(total)
            setSearchStart(start)
          }
        } else if (response.json) {
          const { message } = await response.json()
          setLoading(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoading(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const deleteFormFromApi = (...category_ids: string[]) => {
    setLoadingDelete(true)
    CategoriesService.delete_category({ category_ids })
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          deleteCategory(...category_ids)
          setToDelete([])
          setLoadingDelete(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)
          setSelectedCandidtesIds([])
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingDelete(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const parseCategories = (data: ICategoryType[]) => {
    return data.map(datum => ({
      cells: [
        datum.title,
        datum.questions_counts,
      ],
      selected: selectedCategoriesIds.includes(datum.id!)
    }))
  }

  const handleSelect = (_, isSelected, rowId) => {
    handleSelectCategory(isSelected, rowId)
  }

  const onNextPage = () => {
    if (query !== '') {
      if (searchTotal !== 0 && rows.length >= searchTotal) { return }
      fetchCategories(searchTotal === 0 ? 0 : searchStart + storeLimit, true)
      return
    }
    if (storeTotal !== 0 && storeCategories.length >= storeTotal) { return }
    fetchCategories(storeTotal === 0 ? 0 : storeStart + storeLimit, false)
    // setStart(storeStart + storeLimit)
  }

  const searchCandidates = (filter?) => {
    fetchCategories(0, filter ? false : true, filter)
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeCategories, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    // cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }


  const parsePagination = () => {
    const count = rows.length
    let total = storeTotal
    if (Boolean(query) || Boolean(filterState)) {
      total = searchTotal
    }
    return `${count} of ${total}`
  }


  return (
    <div className={styles.wrapper} >
      <ConfirmDialog
        confirmation_message="shared:do_you_want_to_continue"
        onAction={_ => deleteFormFromApi(...toDelete)}
        isModalOpen={toDelete.length > 0}
        loading={loadingDelete}
        onModalClose={_ => setToDelete([])}
      />
      <div className={styles.content}>
        <div className={styles.toolbar}>
          <TextInput
            placeholder={formatMessage({ id: 'shared:search' })}
            className={styles.search}
            type="search"
            onChange={setQuery}
            aria-label="search text input"
          />
          {selectedCategoriesIds.length > 0 && (
            <Button
              onClick={i => setToDelete(selectedCategoriesIds)}
              variant={"secondary"}
            >
              <CloseIcon />
              <FormattedMessage id="shared:delete" />
            </Button>
          )}
          <span>{parsePagination()}</span>
        </div>
        {rows.length === 0 && !loading && (
          <ListEmpty />
        )}

        {rows.length > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            onSort={onSort}
            cells={columns}
            onSelect={handleSelect}
            canSelectAll={true}
            borders={false}
            rows={parseCategories(rows)}
            actions={actions}
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}
        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{parsePagination()}</span>
        </div>
      </div>
    </div>
  );
}

export {
  CategoryListingComponent as CategoryListingPage,
}
