import React, { useRef, useState, useEffect } from 'react';
import styles from './NewDemand.module.scss';
import {
  useAccordionData, useUserRoleData, useUserInfoData
} from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  TextInput, Button, ButtonVariant,
  TextArea, SelectOption, Select,
  SelectVariant, Spinner
} from '@patternfly/react-core';
import { useFormik } from 'formik';
import clsx from 'classnames'
import { Checkbox, Form } from '@patternfly/react-core';
import { CloseIcon } from '@patternfly/react-icons';
import {
  useUserData, useDemandData, useProvinceData,
  useNotificationData
} from 'hooks';
import { DemandService, VerifiableSectionService } from 'services';
import * as yup from 'yup';
import { maskNumber } from 'utilities';
import { StripePaymentDialog } from 'dialogs';

export interface NewDemandProps {
  readonly dumm?: boolean;
}


export interface INewDemandForm {
  contact_id: string,
  request_email: string,
  employment: string,
  comments: string,
  verifiable_sections: string[],
  cv: any,
  diplomas_copy: any,
  references: any,
  lastname: string,
  firstname: string,
  email: string,
  province: string,
  city: string,
  driving_license_number: string,
  authorization_form: any,
  id_papers: any,
  birthday: string,
  phone_number: string,
  post_number: string,
  postal_code: string,
  nas: string,
  standard_form_count?: number,
  adress: string,
}


export const validationSchema = yup.object().shape({
  standard_form_count: yup.number(),
  request_email: yup.string().email('shared:invalid_email')
    .required('shared:request_email_required'),
  employment: yup.string()
    .required('shared:job_requested_equired'),
  contact_id: yup.string()
    .required('shared:contact_id_required'),
  lastname: yup.string()
    .required('shared:lastname_required'),
  firstname: yup.string()
    .required('shared:firstname_required'),
  email: yup.string().email('shared:invalid_email'),
  province: yup.string()
    .required('shared:province_required'),
  city: yup.string(),
  driving_license_number: yup.string(),
  // .required('shared:driving_license_number_required'),
  birthday: yup.string(),//.required('shared:birthday_required'),
  phone_number: yup.string()
    .max(20, "shared:phonenumber_too_long")
    .matches(
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
      "shared:invalid_phonenumber"
    ),
  verifiable_sections: yup
    .array().min(1, 'shared:verifiable_sections_required'),
  postal_code: yup.string(),
  nas: yup.string(),//.required('shared:nas_required'),
  adress: yup.string(),
})

export const contactValidationSchema = yup.object().shape({
  standard_form_count: yup.number(),
  request_email: yup.string().email('shared:invalid_email')
    .required('shared:request_email_required'),
  employment: yup.string()
    .required('shared:job_requested_equired'),
  contact_id: yup.string()
    .required('shared:contact_id_required'),
  lastname: yup.string()
    .required('shared:lastname_required'),
  firstname: yup.string()
    .required('shared:firstname_required'),
  email: yup.string().email('shared:invalid_email'),
  province: yup.string()
    .required('shared:province_required'),
  city: yup.string().required("shared:city_required"),
  driving_license_number: yup.string(),
  //.required('shared:driving_license_number_required'),
  birthday: yup.string().required('shared:birthday_required'),
  phone_number: yup.string()
    .max(20, "shared:phonenumber_too_long")
    .matches(
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
      "shared:invalid_phonenumber"
    ),
  verifiable_sections: yup
    .array().min(1, 'shared:verifiable_sections_required'),
  postal_code: yup.string().required("shared:postal_code_required"),
  nas: yup.string(), //.required('shared:nas_required'),
  adress: yup.string().required("shared:address_required"),
})


const NewDemandComponent: React.FC<NewDemandProps> = props => {
  const { users } = useUserData()
  const [isOpenContact, setisOpenContact] = useState<boolean>(false)
  const [isOpenProvinces, setisOpenProvince] = useState<boolean>(false)
  const { appendDemand, } = useDemandData()
  const { provinces } = useProvinceData()

  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  const authorization_form_ref = useRef<any>()
  const id_papers_ref = useRef<any>()
  const cv_ref = useRef<any>()
  const diplomat_copy_ref = useRef<any>()
  const reference_ref = useRef<any>()
  const { new_notification, } = useNotificationData()

  useEffect(() => {
    resetAccordion({
      title: formatMessage({ id: 'demands:new_demands' }),
      sub_title: undefined
    })
    fetchSections()
  }, [])
  const { userInfo } = useUserInfoData()
  const { currentRole } = useUserRoleData()
  const [loading, setLoading] = useState<boolean>(false)
  const [contId, setcontId] = useState('')
  const [showStripe, setShowStripe] = useState<boolean>(false)
  const [statePayload, setPayload] = useState<any>()

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    if (!Boolean(values.authorization_form)) {
      new_notification({
        message: formatMessage({ id: 'shared:authorization_form_required' }),
        variant: 'error',
        title: formatMessage({ id: 'shared:error' }),
      })
      return
    }

    let data = {
      // @ts-ignore
      contact_id: (users.find(d => d.id === contId) || userInfo).id,
      status: 0
    }
    Object.keys(values)
      .filter(
        key => Boolean(values[key]) || typeof (values[key]) === "boolean"
      )
      .forEach(key => data[key] = values[key])

    saveDemandtoApi(data, resetForm)
  }

  const initialValues: INewDemandForm = {
    contact_id: '',
    request_email: '',
    employment: '',
    comments: '',
    cv: '',
    verifiable_sections: [],
    diplomas_copy: '',
    references: '',
    lastname: '',
    firstname: '',
    email: '',
    province: '',
    city: '',
    driving_license_number: '',
    authorization_form: '',
    id_papers: '',
    birthday: '',
    phone_number: '',
    postal_code: '',
    post_number: '',
    nas: '',
    adress: '',
  }

  if (
    ["Contact", "Company_Administrator"].includes(currentRole)
  ) {
    // @ts-ignore
    initialValues.contact_id = (userInfo || { id: '' }).id
    // @ts-ignore
    initialValues.request_email = (userInfo || { email: '' }).email
  }

  const {
    setFieldValue,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
    errors,
    touched,
  } = useFormik<INewDemandForm>({
    validationSchema: "Contact" === currentRole ? contactValidationSchema : validationSchema,
    initialValues: initialValues,
    onSubmit,
    enableReinitialize: true,
  })

  const handleChangeStandardFormQuantity = section => value => {
    setFieldValue(
      'standard_form_count', value
    )
    if (+value < 1) {
      setFieldValue(
        'verifiable_sections',
        values.verifiable_sections
          .filter(_ => section.id !== _)
      )
    } else if (
      !values.verifiable_sections
        .includes(section.id)
    ) {
      setFieldValue(
        'verifiable_sections',
        [
          ...values.verifiable_sections,
          section.id
        ]
      )
    }
  }


  const saveDemandtoApi = (paramDemand, resetForm) => {
    const new_demand = { ...paramDemand }

    let user = users.find(
      ({ firstName, lastName }) => `${firstName} ${lastName}` === paramDemand.contact_id
    )

    let province = provinces.find(({ name }) => name === new_demand.province)

    if (province) {
      new_demand.province = province.id
    }

    if (user) {
      new_demand.contact_id = user.id
    }

    const formData = new FormData()
    console.log({ new_demand })
    for (let item in new_demand) {
      formData.append(item, new_demand[item])
    }

    setLoading(true)
    DemandService.new_demand(formData)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const {
            payload, message,
            is_draft, company_pricing_plan,
          } = await response.json()
          appendDemand(payload)
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          if (is_draft) {
            setShowStripe(true)
            setPayload(payload)
          }
          resetForm(initialValues)
          setFieldValue('full_plumitif', false)
          setFieldValue('criminal_penal_plumitif', false)
          setFieldValue('academic_training', false)
          setFieldValue('credit_verification', false)
          setFieldValue('old_job_references', false)
          setFieldValue('driver_license', false)
        // } else if ([400, 500, 422, 401, 409, 404].includes(response.status)) {
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })

  }

  const onStripeAction = (token) => {
    setLoading(true)
    const datum = {
      payment_token: token,
    }
    console.log({ datum })
    DemandService.switchDraftedDemand(statePayload.id, datum)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const {
            payload, message,
          } = await response.json()
          appendDemand(statePayload)
          setShowStripe(false)
          setPayload(undefined)
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })

  }

  const onSaveAsDraft = () => {
    setShowStripe(false)
    setPayload(undefined)
  }

  const onCloseStripeModal = () => {
    onSaveAsDraft()
  }

  const [sections, setSections] = useState<any[]>([])

  const fetchSections = () => {
    setLoading(true)

    VerifiableSectionService.fetchSections()
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const results = await response.json()
          setSections(
            results
          )
          setLoading(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

          setLoading(false)
        }
      })
  }

  const selectPart = <SelectOption
    isDisabled={true}
    value={formatMessage({ id: "shared:select" })}
  />

  const options_contacts = users.map(
    ({ id, firstName, lastName, email }) => (
      <SelectOption
        key={id} value={firstName + ' ' + lastName}
        name={lastName}
        onClick={
          e => {
            setFieldValue('request_email', email);
            setcontId(id)
          }
        }
      />
    ))


  const options_provinces = provinces.map(({ name }) => (
    <SelectOption value={name} name={name} />
  ))

  options_contacts.unshift(selectPart)
  options_provinces.unshift(selectPart)

  const handleInputChange = field => (val) => setFieldValue(field, val)
  const handleSelectToggleContact = is_expanded => setisOpenContact(is_expanded)
  const handleSelectToggleProvinces = is_expanded => setisOpenProvince(is_expanded)

  const clearSelection = field => () => {
    setFieldValue(field, [])
  }

  const onSelect = field => (event, selection, isPlaceholder) => {
    console.log({ selection })
    setisOpenContact(false)
    setisOpenProvince(false)
    setFieldValue(
      field,
      selection
    )
  }

  return (
    <div className={styles.wrapper} >
      <StripePaymentDialog
        onSaveAsDraft={onSaveAsDraft}
        onAction={onStripeAction}
        isOpen={showStripe}
        loading={loading}
        onModalClose={onCloseStripeModal}
      />
      <Form
        onSubmit={handleSubmit}
        className={styles.content}
      >
        <div className={styles.first_section} >
          <div className={styles.form}>
            <h5 className={styles.title}>
              <FormattedMessage id="demand:requester" />
            </h5>
            <div className={styles.row}>
              {!["Contact", "Company_Administrator"].includes(currentRole) && (
                <label htmlFor="contact_id" className={styles.formfield}>
                  <span
                    className={errors.contact_id && touched.contact_id ? styles.c_error : ''}
                  >
                    <FormattedMessage id={errors.contact_id && touched.contact_id ? errors.contact_id : "new_demand:requester_name"} />
                    <span className={styles.c_error}>*</span>
                  </span>
                  <Select
                    variant={SelectVariant.typeahead}
                    onToggle={handleSelectToggleContact}
                    name="contact_id"
                    onSelect={onSelect('contact_id')}
                    onBlur={handleBlur}
                    selections={values.contact_id}
                    isOpen={isOpenContact}
                    // ariaLabelledBy={'contact_id'}
                    onClear={clearSelection('contact_id')}
                  >
                    {options_contacts}
                  </Select>
                </label>
              )}
              <label htmlFor="request_email" className={styles.formfield}>
                <span
                  className={errors.request_email && touched.request_email ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.request_email && touched.request_email ? errors.request_email : "shared:request_email"}
                  />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  id="request_email"
                  name="request_email"
                  onBlur={handleBlur}
                  isDisabled={["Contact", "Company_Administrator"].includes(currentRole)}
                  onChange={handleInputChange('request_email')}
                  value={values.request_email}
                  label={formatMessage({ id: "shared:request_email" })}
                />
              </label>
            </div>
            <h5 className={styles.title}>
              <FormattedMessage id="new_demand:application_and_job" />
            </h5>
            <div className={styles.row}>
              <label htmlFor="firstname" className={styles.formfield}>
                <span
                  className={errors.firstname && touched.firstname ? styles.c_error : ''}
                >
                  <FormattedMessage id={errors.firstname && touched.firstname ? errors.firstname : "shared:firstname"} />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  id="firstname"
                  name="firstname"
                  onBlur={handleBlur}
                  label={formatMessage({ id: "shared:firstname" })}
                  onChange={handleInputChange('firstname')}
                  value={values.firstname}
                />
              </label>
              <label htmlFor="lastname" className={styles.formfield}>
                <span
                  className={errors.lastname && touched.lastname ? styles.c_error : ''}
                >
                  <FormattedMessage id={errors.lastname && touched.lastname ? errors.lastname : "shared:lastname"} />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  id="lastname"
                  name="lastname"
                  onBlur={handleBlur}
                  label="nom du demandeur"
                  onChange={handleInputChange('lastname')}
                  value={values.lastname}
                />
              </label>
              <label htmlFor="adress" className={styles.formfield}>
                {"Contact" === currentRole ? (
                  <span
                    className={errors.adress && touched.adress ? styles.c_error : ''}
                  >
                    <FormattedMessage id={errors.adress && touched.adress ? errors.adress : "shared:address"} />
                    <span className={styles.c_error}>*</span>
                  </span>

                ) : (
                    <span><FormattedMessage id="shared:address" /></span>
                  )}
                <TextInput
                  id="adress"
                  name="adress"
                  onBlur={handleBlur}
                  onChange={handleInputChange('adress')}
                  value={values.adress}
                  label={formatMessage({ id: "shared:address" })}
                />
              </label>
              <label htmlFor="city" className={styles.formfield}>
                {"Contact" === currentRole ? (
                  <span
                    className={errors.city && touched.city ? styles.c_error : ''}
                  >
                    <FormattedMessage id={errors.city && touched.city ? errors.city : "shared:city"} />
                    <span className={styles.c_error}>*</span>
                  </span>

                ) : (
                    <span><FormattedMessage id="shared:city" /></span>
                  )}
                <TextInput
                  id="city"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleInputChange('city')}
                  value={values.city}
                  label="nom du demandeur"
                />
              </label>
              <label htmlFor="province" className={styles.formfield}>
                <span
                  className={errors.province && touched.province ? styles.c_error : ''}
                >
                  <FormattedMessage id={errors.province && touched.province ? errors.province : "shared:province"} />
                  <span className={styles.c_error}>*</span>
                </span>
                <Select
                  variant={SelectVariant.typeahead}
                  onToggle={handleSelectToggleProvinces}
                  name="province"
                  onSelect={onSelect('province')}
                  onBlur={handleBlur}
                  selections={values.province}
                  isOpen={isOpenProvinces}
                  // ariaLabelledBy={'province'}
                  onClear={clearSelection('province')}
                >
                  {options_provinces}
                </Select>
              </label>
              <label htmlFor="postal_code" className={styles.formfield}>
                <span
                  className={errors.postal_code && touched.postal_code ? styles.c_error : ''}
                >
                  <FormattedMessage id="shared:postal_code" />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  id="postal_code"
                  name="postal_code"
                  onBlur={handleBlur}
                  label={formatMessage({ id: "shared:postal_code" })}
                  onChange={handleInputChange('postal_code')}
                  value={values.postal_code}
                />
              </label>
              <label htmlFor="phone_number" className={styles.formfield}>
                {"Contact" === currentRole ? (
                  <span
                    className={(errors.phone_number || errors.post_number) && touched.phone_number ? styles.c_error : ''}
                  >
                    <FormattedMessage id={errors.phone_number && touched.phone_number ? errors.phone_number : "shared:tel_and_post_number"} />
                    <span className={styles.c_error}>*</span>
                  </span>

                ) : (
                    <FormattedMessage id="shared:tel_and_post_number" />
                  )}
                <div className="telephone_field">
                  <TextInput
                    label={formatMessage({ id: "shared:phone_number" })}
                    onChange={handleInputChange('phone_number')}
                    name="phone_number"
                    value={values.phone_number}
                    onBlur={e => {
                      handleBlur(e)
                      handleInputChange('phone_number')(
                        maskNumber(values.phone_number)
                      )
                    }}
                  />
                  {Boolean(values.phone_number) && (
                    <TextInput
                      id="post_number"
                      name="post_number"
                      onBlur={handleBlur}
                      label={formatMessage({ id: "shared:post_number" })}
                      onChange={handleInputChange('post_number')}
                      value={values.post_number}
                      placeholder={formatMessage({ id: 'shared:post_number' })}
                      type="number"
                    />
                  )}
                </div>
              </label>
            </div>
            <div className={styles.row}>
              <label htmlFor="email" className={styles.formfield}>
                <span
                  className={errors.email && touched.email ? styles.c_error : ''}
                >
                  <FormattedMessage
                    id={errors.email && touched.email ? errors.email : "shared:email"}
                  />
                </span>
                <TextInput
                  id="email"
                  name="email"
                  onBlur={handleBlur}
                  label="nom du demandeur"
                  onChange={handleInputChange('email')}
                  value={values.email}
                />

              </label>
            </div>
            <div className={styles.row}>
              <label htmlFor="driving_license_number" className={styles.formfield}>
                <span
                  className={errors.driving_license_number && touched.driving_license_number ? styles.c_error : ''}
                >
                  <FormattedMessage id="shared:driving_license_number" />
                  {false && <span className={styles.c_error}>*</span>}                </span>
                <TextInput
                  id={'driving_license_number'}
                  name={'driving_license_number'}
                  onBlur={handleBlur}
                  label="nom du demandeur"
                  onChange={handleInputChange('driving_license_number')}
                  value={values.driving_license_number}
                />
              </label>
            </div>
            <div className={styles.row}>
              <label htmlFor="nas" className={styles.formfield}>
                {"Contact" === currentRole ? (
                  <span
                    className={errors.nas && touched.nas ? styles.c_error : ''}
                  >
                    <FormattedMessage id={errors.nas && touched.nas ? errors.nas : "shared:nas"} />
                  </span>

                ) : (
                    <span><FormattedMessage id="shared:nas" /></span>
                  )}
                <TextInput
                  id="nas"
                  name="nas"
                  onBlur={handleBlur}
                  onChange={handleInputChange('nas')}
                  value={values.nas}
                  label={formatMessage({ id: 'shared:nas' })}
                />
              </label>
            </div>
            <div className={styles.row}>
              <label htmlFor="birthday" className={styles.formfield}>
                {"Contact" === currentRole ? (
                  <span
                    className={errors.birthday && touched.birthday ? styles.c_error : ''}
                  >
                    <FormattedMessage id={errors.birthday && touched.birthday ? errors.birthday : "shared:birthday"} />
                    <span className={styles.c_error}>*</span>
                  </span>

                ) : (
                    <span><FormattedMessage id="shared:birthday" /></span>
                  )}
                <TextInput
                  id="birthday"
                  name="birthday"
                  onBlur={handleBlur}
                  onChange={handleInputChange('birthday')}
                  value={values.birthday}
                  label={formatMessage({ id: 'shared:birthday' })}
                  type="date"
                />
              </label>
            </div>
            <div className={styles.row}>
              <label htmlFor="employment" className={styles.formfield}>
                <span
                  className={errors.employment && touched.employment ? styles.c_error : ''}
                >
                  <FormattedMessage id={errors.employment && touched.employment ? errors.employment : "shared:job_requested"} />
                  <span className={styles.c_error}>*</span>
                </span>
                <TextInput
                  id="employment"
                  name="employment"
                  onBlur={handleBlur}
                  onChange={handleInputChange('employment')}
                  value={values.employment}
                  label="nom du demandeur"
                />
              </label>
            </div>
            <div className={styles.row}>
              <label htmlFor="comments"
                className={clsx(
                  styles.formfield,
                  styles.fullWidth,
                )}
              >
                <span><FormattedMessage id="shared:comments" /></span>
                <TextArea
                  id="comments"
                  name="comments"
                  onBlur={handleBlur}
                  placeholder={formatMessage({ id: 'shared:w_r_t_demand' })}
                  onChange={handleInputChange('comments')}
                  value={values.comments}
                  label="nom du demandeur"
                />
              </label>
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              variant={ButtonVariant.secondary}
              onClick={handleReset}
            >
              <FormattedMessage id="shared:cancel" />
            </Button>
            <Button
              isDisabled={!Boolean(values.authorization_form) && (loading || Object.keys(errors).length > 0)}
              type="submit"
            >
              <FormattedMessage id="shared:save" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </div>
        <div className={styles.second_section}>
          <h5 className={styles.title}>
            <span
              className={errors.verifiable_sections && touched.verifiable_sections ? styles.c_error : ''}
            >
              {/* <FormattedMessage id="new_demand:requester_name" /> */}
              <FormattedMessage
                id={
                  errors.verifiable_sections && touched.verifiable_sections ? `${errors.verifiable_sections}` : "demand:info_to_verified"
                }
              />
              <span className={styles.c_error}>*</span>
            </span>
          </h5>
          {sections.length > 0 && (
            <div className={styles.requested_infos}>
              {sections
                .filter(_ => _.is_active)
                .map(section => (
                  <div className={styles.verifiable_section}>
                    <Checkbox
                      id={section.id}
                      isChecked={values.verifiable_sections.includes(section.id)}
                      label={<span>{section.name}</span>}
                      onClick={_ => {
                        let newSections = [...values.verifiable_sections]
                        const idx = newSections.findIndex(sec => sec === section.id)
                        if (idx === -1) {
                          newSections.push(section.id)
                          if (section.standard_form) {
                            setFieldValue('standard_form_count', 1)
                          }
                        }
                        else {
                          newSections.splice(idx, 1)
                          if (section.standard_form) {
                            setFieldValue('standard_form_count', 0)
                          }
                        }
                        setFieldValue('verifiable_sections', newSections)
                      }}
                    />
                    {section.standard_form && (
                      <TextInput
                        id="standard_form_count"
                        name="standard_form_count"
                        onBlur={handleBlur}
                        className={styles.count_input}
                        type="number"
                        onChange={
                          handleChangeStandardFormQuantity(
                            section
                          )
                        }
                        value={
                          values.standard_form_count || 0
                        }
                        label={
                          formatMessage(
                            { id: "shared:count" }
                          )
                        }
                      />
                    )}
                  </div>
                ))}
            </div>
          )}

          <h5 className={styles.title}>
            <span>
              <FormattedMessage id="demand:attachments" />
            </span>
          </h5>
          <label
            htmlFor="authorization_form"
            className={styles.file_field}>
            <span
              className={errors.authorization_form && touched.authorization_form ? styles.c_error : ''}
            >
              <FormattedMessage id="demand:authorization_form" />
              <span className={styles.c_error}>*</span>
            </span>
            <div className={styles.cover}>
              <Button
                onClick={e => authorization_form_ref.current.click()}
                variant={'secondary'}
              >
                {values.authorization_form ?
                  values.authorization_form.name
                  : formatMessage({ id: "shared:upload" })
                }
              </Button>
              <input
                id="authorization_form"
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                ref={authorization_form_ref}
                className={styles.input_file}
                onChange={e => {
                  setFieldValue('authorization_form', authorization_form_ref.current.files[0]);
                  authorization_form_ref.current.click();
                }}
              />
              <button
                className={clsx(
                  styles.btn_delete,
                  "btn btn-icon btn-flat ",
                )}
                type='button'
              >
                <CloseIcon
                  onClick={e => {
                    setFieldValue('authorization_form', '');
                    if (authorization_form_ref.current.value) {
                      authorization_form_ref.current.type = "text"
                      authorization_form_ref.current.type = "file"
                    }
                  }}
                />
              </button>
            </div>
          </label>
          <label
            htmlFor="id_papers"
            className={styles.file_field}>
            <span
              className={errors.id_papers && touched.id_papers ? styles.c_error : ''}
            >
              <FormattedMessage id="demand:id_papers" />
              {/* <span className={styles.c_error}>*</span> */}
            </span>
            <div className={styles.cover}>
              <Button
                onClick={e => id_papers_ref.current.click()}
                variant={'secondary'}
              >
                {values.id_papers ?
                  values.id_papers.name
                  : formatMessage({ id: "shared:upload" })
                }
              </Button>
              <input
                id="id_papers"
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                ref={id_papers_ref}
                className={styles.input_file}
                onChange={e => {
                  setFieldValue('id_papers', id_papers_ref.current.files[0]);
                  id_papers_ref.current.click();
                }}
              />
              <button
                className={clsx(
                  styles.btn_delete,
                  "btn btn-icon btn-flat ",
                )}
                type='button'
              >
                <CloseIcon
                  onClick={e => {
                    setFieldValue('id_papers', '');
                    if (id_papers_ref.current.value) {
                      id_papers_ref.current.type = "text"
                      id_papers_ref.current.type = "file"
                    }
                  }}
                />
              </button>
            </div>
          </label>
          <label
            htmlFor="cv"
            className={styles.file_field}>
            <span><FormattedMessage id="demand:cv" /></span>
            <div className={styles.cover}>
              <Button
                onClick={e => cv_ref.current.click()}
                variant={'secondary'}
              >
                {values.cv ? values.cv.name : formatMessage({ id: "shared:upload" })}
              </Button>
              <input
                id="cv"
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                className={styles.input_file}
                ref={cv_ref}
                onChange={e => {
                  setFieldValue('cv', cv_ref.current.files[0]);
                  cv_ref.current.click();
                }}
              />
              <button
                className={clsx(
                  styles.btn_delete,
                  "btn btn-icon btn-flat ",
                )}
                type="button"
              >
                <CloseIcon
                  onClick={e => {
                    setFieldValue('cv', '');
                    if (cv_ref.current.value) {
                      cv_ref.current.type = "text"
                      cv_ref.current.type = "file"
                    }
                  }}
                />
              </button>
            </div>
          </label>
          <label
            htmlFor="diplomas_copy"
            className={styles.file_field}>
            <span><FormattedMessage id="demand:diplomas_copy" /></span>
            <div className={styles.cover}>
              <Button
                onClick={e => diplomat_copy_ref.current.click()}
                variant={'secondary'}
              >
                {values.diplomas_copy ? values.diplomas_copy.name : formatMessage({ id: "shared:upload" })}
              </Button>

              <input
                id="diplomas_copy"
                ref={diplomat_copy_ref}
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                className={styles.input_file}
                onChange={e => {
                  setFieldValue('diplomas_copy', diplomat_copy_ref.current.files[0]);
                  diplomat_copy_ref.current.click();
                }}
              />
              <button
                className={clsx(
                  styles.btn_delete,
                  "btn btn-icon btn-flat ",
                )}
                type="button"
              >
                <CloseIcon
                  onClick={e => {
                    setFieldValue('diplomas_copy', '');
                    if (diplomat_copy_ref.current.value) {
                      diplomat_copy_ref.current.type = "text"
                      diplomat_copy_ref.current.type = "file"
                    }
                  }}
                />
              </button>
            </div>
          </label>
          <label
            htmlFor="reference"
            className={styles.file_field}>
            <span><FormattedMessage id="demand:references" /></span>
            <div className={styles.cover}>
              <Button
                onClick={e => reference_ref.current.click()}
                variant={'secondary'}
              >
                {values.references ? values.references.name : formatMessage({ id: "shared:upload" })}
              </Button>
              <input
                name="reference"
                ref={reference_ref}
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                className={styles.input_file}
                onChange={e => {
                  setFieldValue('references', reference_ref.current.files[0]);
                  reference_ref.current.click();
                }}
              />
              <button
                className={clsx(
                  styles.btn_delete,
                  "btn btn-icon btn-flat ",
                )}
                type="button"
              >
                <CloseIcon
                  onClick={e => {
                    setFieldValue('references', '');
                    if (reference_ref.current.value) {
                      reference_ref.current.type = "text"
                      reference_ref.current.type = "file"
                    }
                  }}
                />
              </button>
            </div>
          </label>
        </div>
      </Form>
    </div >
  );
}

export {
  NewDemandComponent as NewDemandPage,
}
