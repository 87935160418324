import { IFormsType } from "constants/example";

export interface ICategoryType {
    description: string,
    form?: IFormsType,
    form_id?: string,
    id?: string,
    is_global: boolean,
    questions_counts?: number,
    title: string,
    title_en?: string,
    title_fr?: string,
    update_at?: string,
    
}


export const CATEGORY_STORE_ACTIONS = {
    APPEND_CATEGORY: 'CATEGORY:APPEND',
    RESET_CATEGORIES: 'CATEGORY:RESET',
    DELETE_CATEGORY: 'CATEGORY:DELETE',
    UPDATE_CATEGORY: 'CATEGORY:UPDATE',
}