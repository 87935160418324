import React, { useState } from 'react';
import styles from './RecoverPassword.module.scss'
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom";

import { LanguageSwitcher } from 'components'

import {
  LoginFooterItem,
  LoginMainFooterBandItem,
  LoginPage,
  ListItem,
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button,
  Spinner,
  spinnerSize
} from '@patternfly/react-core';

import auth_img from 'assets/imgs/auth_img.png'
import logo from 'assets/imgs/logo.png'
import { useIntl, FormattedMessage } from 'react-intl';
import { AuthService } from 'services';
import { useNotificationData } from 'hooks';
import * as yup from 'yup'
import { useFormik } from 'formik'

export interface RecoverProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

interface RecoverState {
  readonly dumm: boolean;
}

const loginMessage = (
  <>
    <LoginMainFooterBandItem>
      <Link to='/auth/login'>
        <FormattedMessage id="auth:login" />
      </Link>
    </LoginMainFooterBandItem>
  </>
);

const listItem = (
  <React.Fragment>
    <ListItem>
      <LoginFooterItem href="#">
        <FormattedMessage id="auth:terms_of_use" />
      </LoginFooterItem>
    </ListItem>
    <ListItem>
      <LoginFooterItem href="#">
        <FormattedMessage id="auth:help" />
      </LoginFooterItem>
    </ListItem>
    <ListItem>
      <LoginFooterItem href="#">
        <FormattedMessage id="auth:confidentiality" />
      </LoginFooterItem>
    </ListItem>
  </React.Fragment>
);

const RecoverPasswordComponent: React.FC<RecoverProps> = ({
  match: { params }, history,
  ...props
}) => {

  const { token } = params as any
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const { new_notification } = useNotificationData()
  const validationSchema = yup.object().shape({
    password: yup.string().required('shared:password_required'),
    confirm_password: yup.string()
      .oneOf([yup.ref('password'), null], 'shared:does_not_match')
      .required('shared:password_required')
  })
  const submitToApi = (values, { resetForm }: any) => {
    setLoading(true)
    AuthService.change_password({ token, password: values.password })
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { data, message } = await response.json()
          history.push("/auth/login")
          new_notification({
            message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message: message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  const {
    setFieldValue,
    values,
    handleSubmit,
    handleBlur,
    errors,
  } = useFormik<{ password: string, confirm_password: string }>({
    initialValues: { password: '', confirm_password: '' },
    onSubmit: submitToApi,
    validationSchema,
  })

  return (
    <div className={styles.container}>
      <div className={styles.language}>
        <LanguageSwitcher/>
      </div>
      <LoginPage className={styles.login_page_container}
        // footerListVariants="inline"
        brandImgSrc={logo}
        brandImgAlt="Pre Emploi logo"
        backgroundImgSrc={auth_img}
        backgroundImgAlt="Images"
        loginTitle={formatMessage({ id: 'auth:update_password' })}
        loginSubtitle={formatMessage({ id: 'auth:enter_new_password_prompt' })}
        // signUpForAccountMessage={signUpForAccountMessage}
        forgotCredentials={loginMessage}
        textContent={formatMessage({ id: "auth:explanation" })}
        footerListItems={listItem}
      >
        <Form onSubmit={handleSubmit} className={styles.form_container}>
          <FormGroup
            label={
              formatMessage({ id: errors.password ? errors.password : "shared:password" })
            }
            isRequired
            fieldId="revover-password-password"
          >
            <TextInput
              isRequired
              type="password"
              id="revover-password-password"
              placeholder={formatMessage({ id: 'shared:password' })}
              name="password"
              onBlur={handleBlur}
              value={values.password}
              onChange={t => setFieldValue('password', t)}

            />
          </FormGroup>
          <FormGroup
            label={
              formatMessage({ id: errors.confirm_password ? errors.confirm_password : "shared:confirm_password" })
            }
            isRequired
            fieldId="revover-password-password-confirmation"
          >
            <TextInput
              isRequired
              type="password"
              id="revover-password-password-confirmation"
              placeholder={formatMessage({ id: "shared:confirm_password" })}
              name="confirm_password"
              onBlur={handleBlur}
              value={values.confirm_password}
              onChange={t => setFieldValue('confirm_password', t)}
            />
          </FormGroup>

          <ActionGroup>
            <Button
              type="submit"
              isDisabled={Object.keys(errors).length > 0 || loading}
            >
              <FormattedMessage id="shared:save" />
              {loading && <Spinner size={spinnerSize.md} />}
            </Button>
          </ActionGroup>
        </Form>
      </LoginPage>
    </div>
  );
}

export {
  RecoverPasswordComponent as RecoverPasswordPage,
}