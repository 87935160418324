
import { ObservableStore } from '@codewithdan/observable-store';
import {
    map,
    distinctUntilChanged,
} from 'rxjs/operators'
import {
    IFormsType, FORM_STORE_ACTIONS,
} from '.'


class AppStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        INIT_STATE: 'INIT_STATE',
        ...FORM_STORE_ACTIONS,
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }
    
    //#region forms
    resetForms = (param: IStoreForm) => {
        this.setState(
            state => ({
                ...state,
                forms: { ...param, data: param.data }
            }),
            AppStore.ACTIONS.RESET_FORMS,
        )
    }

    resetFormsLite = (formslite: IFormLite[]) => {
        this.setState(
            state => ({
                ...state,
                forms_lite: formslite
            }),
            AppStore.ACTIONS.RESET_FORMS,
        )
    }

    appendForms = (param: IStoreForm) => {
        this.setState(
            state => ({
                ...state,
                forms: { ...param, data: [...param.data, ...(state.forms.data || [])] },
            }),
            AppStore.ACTIONS.APPEND_FORMS,
        )
    }

    updateForm = (form: IFormsType) => {
        const oldFormId = this.getState().forms.data!.findIndex(dem => dem.id === form.id)
        if (oldFormId === -1) { return }

        /**At this point, forms CANNOT be an empty array no undefined reference */
        const forms = this.getState().forms.data
        forms![oldFormId] = form

        this.setState(
            state => ({
                ...state,
                forms: { ...state.forms, data: forms },
            }),
            AppStore.ACTIONS.UPDATE_FORM,
        )
    }

    deleteForm = (...formIds: string[]) => {
        const newforms = this.getState().forms.data!.filter(f => !formIds.includes(f.id))

        this.setState(
            state => ({
                ...state,
                forms: { ...state.forms, data: newforms },
                forms_lite: [],
            }),
            AppStore.ACTIONS.DELETE_FORM,
        )
    }

    /**
     * **************getter for everything forms data.****************
     * Notice the distinctUntilChanged operator
     * used to ensure that it doesn't fire at all
     * https://rxjs-dev.firebaseapp.com/api/operators/distinctUntilChanged
     */
    get forms$() {
        return this.stateChanged.pipe(
            map(({ forms }) => forms),
            distinctUntilChanged(),
        )
    }

    get forms_lite$() {
        return this.stateChanged.pipe(
            map(({ forms_lite }) => forms_lite),
            distinctUntilChanged(),
        )
    }

    //#endregion forms
    logout = () => {
        const defaultData = {
            data: [],
            start: 0,
            limit: 30,
            total: 0,
        }
        this.setState({
            forms: defaultData,
        }, AppStore.ACTIONS.INIT_STATE)

    }

}

export default new AppStore()




export interface IStoreForm {
    limit?: number,
    start?: number,
    total?: number,
    data: IFormsType[],
}


export interface IFormLite {
    is_standard:boolean,
    editable:boolean,
    id: string,
    title: string,
}


export interface StoreState {
    forms: IStoreForm,
    forms_lite: IFormLite[],
}