import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { AppStore } from 'stores'
import { takeUntil } from 'rxjs/operators'
import { Crumb } from 'stores/app.store'


export interface AccordionProps {
    readonly title?: string;
    readonly sub_title?: string;
    readonly reset_crumbs: boolean;
}


export function useAccordionData(
    props: AccordionProps = {
        title: '', sub_title: '', reset_crumbs: false
    }
) {
    const {
        title: props_title = '',
        sub_title: props_sub_title = '',
        reset_crumbs,
    } = props

    const { resetAccordion, appendCrumbs } = AppStore

    const [title, setTitle] = useState<string>(props_title)
    const [sub_title, setSubTitle] = useState<string>(props_sub_title)
    const [crumbs, setCrumbs] = useState<Crumb[]>([])

    /**Setup accordion */
    useEffect(() => {
        const trigger = new Subject<boolean>()

        AppStore.accordion$
            .pipe(
                takeUntil(trigger.asObservable())
            )
            .subscribe(
                ({
                    title: storeTitle,
                    sub_title: storeSubTitle,
                    crumbs: storeCrumbs,
                }) => {
                    setTitle(storeTitle)
                    setSubTitle(storeSubTitle)
                    setCrumbs(storeCrumbs)
                }
            )

        //onUnmount
        return () => trigger.next(false)
    }, [])

    return {
        resetAccordion,
        title,
        sub_title,
        appendCrumbs,
        crumbs,
    }
}