import BaseService from './BaseService'
import { utilitiesUrls } from './urls'

class UtilitiesService {
    static list_provinces = () => BaseService.getRequest(utilitiesUrls.PROVINCES, true);
    static list_users = () => BaseService.getRequest(utilitiesUrls.CONTACTS, true);
    static list_all_categories = () => BaseService.getRequest(utilitiesUrls.CATEGORIES, true);
    static list_pricing_plans = () => BaseService.getRequest(utilitiesUrls.PRICING_PLAN, true);
    static list_company_users = (company_id) => BaseService.getRequest(utilitiesUrls.COMPANY_USERS(company_id), true);
    static list_roles = () => BaseService.getRequest(utilitiesUrls.ROLES, true);
    static list_companies = () => BaseService.getRequest(utilitiesUrls.COMPANIES, true);
    static list_standard_forms = () => BaseService.getRequest(utilitiesUrls.STANDARD_FORMS, true);
}

export default UtilitiesService;