import React, { useState, useEffect } from 'react';
import styles from './UpdateRequestServices.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Checkbox, Modal, Spinner, spinnerSize, TextInput } from '@patternfly/react-core';
import { VerifiableSectionService } from 'services';
import { useNotificationData } from 'hooks';

export interface UpdateRequestServicesProps {
  readonly trigger: (e: any, deactivate: boolean) => void;
  readonly onDone: () => void;
  readonly onSaveStandardFormCount: (_: any) => void;
  readonly verifiable_sections: ISection[];
  readonly candidate_id: string;
  standard_form_count: number;
}

export interface ISection {
  name: string;
  id: string;
  standard_form: boolean;
}

export const UpdateRequestServices: React.FC<UpdateRequestServicesProps> = ({
  trigger, onDone, candidate_id,
  verifiable_sections,
  standard_form_count = 0,
  onSaveStandardFormCount,
}) => {

  const { formatMessage } = useIntl()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const { new_notification } = useNotificationData()
  const [
    sections, setSections
  ] = useState<ISection[]>([])
  const [
    standardFormCount,
    setStandardFormCount,
  ] = useState<number>()

  const [
    selectedSections,
    setSelectedSections
  ] = useState<string[]>([])

  useEffect(() => {

    setStandardFormCount(
      standard_form_count
    )

    console.log({
      standard_form_count
    })

  }, [standard_form_count])

  useEffect(() => {
    setSelectedSections(
      verifiable_sections.map(_ => _.id)
    )
  }, [verifiable_sections])

  const handleModalToggle = () => {
    setIsOpen(false)
  }

  const fetchSections = () => {
    setLoading(true)

    VerifiableSectionService.fetchSections()
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const results = await response.json()
          setSections(
            results
          )
          setLoading(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

          setLoading(false)
        }
      })
  }

  useEffect(
    fetchSections,
    []
  )

  const handleSelection = ({ id, standard_form }) => isChecked => {
    if (isChecked) {
      if (standard_form) {
        setStandardFormCount(1)
      }
      setSelectedSections([...selectedSections, id])
    } else {
      if (standard_form) {
        setStandardFormCount(0)
      }
      if (selectedSections.length < 2) {
        new_notification({
          title: formatMessage({ id: 'shared:error' }),
          message: formatMessage({ id: "update_request:must_select_a_section" }),
          variant: 'error'
        })
        return
      }
      setSelectedSections(
        selectedSections.filter(_ => _ !== id)
      )
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setSelectedSections(
        verifiable_sections.map(_ => _.id)
      )
    }
  }, [isOpen])

  const onSubmit = () => {
    setLoading(true)
    VerifiableSectionService.updateRequestVerifiableSections(
      candidate_id,
      {
        ids: selectedSections,
        standard_form_count: standardFormCount || 0
      }
    )
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const results = await response.json()
          setTimeout(() => {
            setLoading(false)
            setIsOpen(false)
            onDone()
            onSaveStandardFormCount(standardFormCount)
          }, 1000)
        } else if (
          response.json
        ) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

          setLoading(false)
        }
      })

  }

  return (
    <>
      {trigger(
        () => setIsOpen(true),
        loading
      )}

      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            <FormattedMessage
              id="candidateDetails:update_services"
            />
          </span>
        }
        isOpen={isOpen}
        onClose={handleModalToggle}
        className={styles.modal_root}
        actions={[
          <div className={styles.footer}>
            <Button
              key="cancel"
              variant="secondary"
              onClick={handleModalToggle}
            >
              <FormattedMessage id="shared:cancel" />
            </Button>
            <Button
              key="confirm"
              variant="primary"
              onClick={onSubmit}
              isDisabled={loading}
            >
              <FormattedMessage id={'shared:update'} />
              {loading && (
                <Spinner
                  size={spinnerSize.md}
                />
              )}
            </Button>
          </div>
        ]}
      >
        <ul className={styles.container}>
          {sections.map(_ =>
            <li className={styles.column}>
              <Checkbox
                label={_.name}
                key={_.id}
                isChecked={selectedSections.includes(_.id)}
                onChange={handleSelection(_)}
                aria-label="controlled checkbox example"
                id={_.id}
                name={_.name}
              />
              {_.standard_form && (
                <TextInput
                  type="number"
                  min="0"
                  className={styles.input}
                  value={standardFormCount}
                  onChange={val => {
                    setStandardFormCount(+val)
                    if (+val < 1) {
                      setSelectedSections(
                        selectedSections.filter(it => (
                          it !== _.id
                        ))
                      )
                    } else {
                      if (!selectedSections.includes(_.id))
                        setSelectedSections(
                          [...selectedSections, _.id]
                        )
                    }
                  }}
                />
              )}
            </li>
          )}
        </ul>
      </Modal>
    </>
  );
}
