import React, { useState, useEffect } from 'react';
import styles from './CompanyPopover.module.scss'
import { PopoverPosition, Popover } from '@patternfly/react-core';
import { FormattedMessage } from 'react-intl';

export interface CompanyPopoverProps {
  readonly trigger: any;
  readonly applicant: any;
}

export const CompanyPopover: React.FC<CompanyPopoverProps> = ({
  trigger,
  applicant,
  ...props
}) => {

  const [position, setPosition] = useState<any>(PopoverPosition.top)
  const [show, setShow] = useState<boolean>(false)
  const [keepInViewChecked, setInViewChecked] = useState<boolean>(false)

  const handleClick = () => {
    setShow(!show)
  };
  const handleKeepInViewChange = setInViewChecked

  const handleProgrammaticChange = setShow

  const shouldClose = tip => {
    setShow(false)
  };

  const containerStyle = {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'row',
    justifyItems: 'flex-start',
  }

  return (
    <Popover
      // position={position}
      isVisible={show}
      shouldClose={shouldClose}
      enableFlip={keepInViewChecked}
      // appendTo={() => document.getElementById('___gatsby')}
      headerContent={
        <div>
          <FormattedMessage id="shared:company_correspondent" />
        </div>
      }
      bodyContent={
        <div style={containerStyle}>
          {applicant && (
            <>
              <span>
                <FormattedMessage id="shared:name" />
            &nbsp;:&nbsp;
            {applicant.firstName} {applicant.lastName}
              </span>
              <span>
                <FormattedMessage id="shared:username" />
            &nbsp;:&nbsp;
            {applicant.username}
              </span>
              <span>
                <FormattedMessage id="shared:email" />
            &nbsp;:&nbsp;
            {applicant.email}
              </span>
            </>
          )}
        </div>
      }
    >
      <>
        {trigger(handleClick)}
      </>
    </Popover>
  );
}
