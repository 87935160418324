import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { sortRoles, parseRoles } from 'utilities'
import { entryPageRoutes } from 'routes'

import {
  Role, ROLE_ADMINISTRATOR,
  ROLE_TECHNICIAN,
  ROLE_CONTACT, COMPANY_ADMINISTRATOR, ACCOUNTANT,
} from 'constants/roles'

import { useUserRoleData } from 'hooks'

interface EntryPageRouteProps {
  readonly url: string
}

const EntryPageRouteComponent: React.FC<EntryPageRouteProps> = (props) => {
  const {
    url,
  } = props

  let roles = [
    ROLE_ADMINISTRATOR, ROLE_TECHNICIAN,
    ROLE_CONTACT, COMPANY_ADMINISTRATOR, ACCOUNTANT,
  ]
  const { currentRole } = useUserRoleData()
  const currentRoleParsed = currentRole.split('_').map(e => e.toLowerCase()).join('_')
  let role =
    roles.filter(role => {
      return role.name === currentRoleParsed
    })
  const defaultRoutes = (
    role.length > 0 ? role[0] : sortRoles(roles)[0]
  ).default_route

  return (
    <Switch>
      {entryPageRoutes(
        url,
        parseRoles(
          role
        )
      ).flatMap(r => r.routes).map(route => (
        <Route
          path={route.to}
          exact={route.exact}
          render={props => <route.component
            {...props}
            data={
              route.data ? { ...route.data } : undefined
            }
          />
          }
        />
      ))}
      {role.length > 0 && (
        <Redirect to={`${url}/${defaultRoutes}`} />
      )}
    </Switch>
  );
}

export {
  EntryPageRouteComponent as EntryPageRoute
}