import React from 'react';
import './App.scss';
import { 
  BrowserRouter as Router,
 } from "react-router-dom";
import {
  EntryPage,
} from './pages';
import { useLanguageData } from 'hooks'
import {
  Internationalisation,
  AccordionProvider,
} from './components';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config'
import {
  Elements,
} from '@stripe/react-stripe-js';

import { NotificationManager } from 'components';
// start app
const App: React.FC = () => {
  const { language } = useLanguageData()
  const stripePromise = loadStripe(
    config.stripe_pub_key
  );
  return (
    <Internationalisation locale={language}>
      <Elements stripe={stripePromise}>
        <Router>
          <AccordionProvider>
            <EntryPage />
          </AccordionProvider>
        </Router>
        <NotificationManager />
      </Elements>
    </Internationalisation>
  );
}

export default App;
