import React, { useState, useEffect } from 'react';
import styles from './DenyFillingForm.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { FormField } from 'pages/candidate/candidate_details_page/CandidateDetails.page';
import { FormService } from 'services';
import { Spinner, spinnerSize } from '@patternfly/react-core';
import {  ListEmpty } from 'components';
import { ReactComponent as PreEmploiLogo } from 'assets/imgs/logo.svg'
import { Link } from 'react-router-dom';

export interface DenyFillingFormProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const DenyFillingFormComponent: React.FC<DenyFillingFormProps> = ({
  //@ts-ignore
  match: { url, params: { token } },
  location: { search },
}) => {

  const { formatMessage } = useIntl();

  const { new_notification } = useNotificationData()

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(true)
  const [message, setMessage] = useState<string>('')


  useEffect(() => {
    // console.log({ can_id })
    submitToApi()
  }, [])



  const submitToApi = () => {
    setLoading(true)
    FormService.denyForm({token})
      .catch(err => {
        setLoading(false)
        setMessage(formatMessage({ id: 'shared:unknown_error' }))
        setError(true)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        if (
          [200, 201].includes(response.status)
        ) {
          const {
            message: paramMessage
          } = await response.json()
          setMessage(paramMessage)
          setLoading(false)
          setError(false)
        } else if (
          response.json
        ) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'error'
          })
          setMessage(message)
          setError(true)
          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'success'
          })
          setMessage(formatMessage({ id: 'shared:unknown_error' }))
          setError(true)
          setLoading(false)
        }
      })
  }



  return (
    <div className={styles.wrapper} >
      <Link className={styles.header} to={"/auth/login"}>
        <PreEmploiLogo className={styles.logo} />
      </Link>
      <div className={styles.container} >
        {loading && (
          <div className={styles.container_loader} >
            <Spinner size={spinnerSize.lg}></Spinner>
          </div>
        )}

        {!loading && !error && (
          <ListEmpty
            title="deny_form:update_thanks"
            message={message}
          />
        )}

        {!loading && error && (
          <ListEmpty
            title="shared:error"
            message={message}
          />
        )}

      </div>
    </div>
  )
}

export {
  DenyFillingFormComponent as DenyFillingFormPage,
}
