//@ts-ignore
let host_url = global.location.host;
// let host_url = `https://api.dev.pre-emploi.gcloud.bara.ca/`;
// let host_url = `192.168.100.3:3500/`;

let host_var = "DEV"

// determine env
if (process.env.REACT_APP_PROD_SERVER?.indexOf(host_url) !== -1) {
  host_var = "PROD"
}

else if (process.env.REACT_APP_PREPROD_SERVER?.indexOf(host_url) !== -1) {
  host_var = "PREPROD"
}

else if (process.env.REACT_APP_LOCAL_SERVER?.indexOf(host_url) !== -1) {
  host_var = "DEV"; //LOCAL
}

else if (process.env.REACT_APP_DEV_SERVER?.indexOf(host_url) !== -1) {
  host_var = "DEV"
}

export default {
  // api_url: `http://192.168.100.9:3500/`,
  stripe_pub_key: process.env[`REACT_APP_${host_var}_STRIPE_PUB_KEY`]!,
  api_url: process.env[`REACT_APP_${host_var}_API_URL`],
  ui_url: process.env[`REACT_APP_${host_var}_UI_URL`],
  socket_url: process.env[`REACT_APP_${host_var}_SOCKET_URL`],
};
