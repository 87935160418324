import React, { useState } from 'react';
import styles from './CustomForm.module.scss'
import {
  Button, TextInput, Checkbox,
  Select, SelectOption,
  Switch, TextArea, Radio, SelectVariant,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import { CloseIcon, AddCircleOIcon } from '@patternfly/react-icons';
import clsx from 'classnames'
// import DayPicker from 'react-day-picker';
import * as yup from 'yup'
import { useFormik, FormikProps, FormikConfig, FormikValues } from 'formik';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';
import { AppStore } from 'stores';
import { FieldType, OptionType } from 'constants/example';
import classNames from 'classnames';
import UtilsService from 'utilities/utils_utilities';
import { useLanguageData } from 'hooks'

export interface CustomFormProps extends FormikValues {
  //readonly onSubmit: () => void;//values,actions
  readonly fields?: FieldType[];
  readonly IsInpreviewMode?: boolean;
  // readonly data?: any;
  // readonly myForm?: any;
}

export const CustomForm: React.FC<CustomFormProps> = ({
  onSubmit,
  fields = [],
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  handleBlur,
  errors,
  handleSubmit,
  IsInpreviewMode = false,
}) => {
  const { formatMessage } = useIntl()
  const [isOpen, setisOpen] = useState<string>('')

  const { language } = useLanguageData()
  const onToggle = name => should_expand => {
    if (!should_expand || isOpen === name) {
      setisOpen('')
      return
    }
    setisOpen(name);

  };

  const clearSelection = (field, select_multi = false) => () => {
    setisOpen('');
    setFieldValue(field, '')
    setTimeout(() => {
      setFieldTouched(field, true)
    },1)
    if (select_multi) {
      setFieldValue(field, [])
    }
  };

  const onSelect = (name, select_multi) => (event, selection) => {
    setTimeout(() => {
      setisOpen('')
    })
    if (select_multi) {
      const selections = values[name] || []
      if (selections.includes(selection)) {
        setFieldValue(name, selections.filter(_ => _ === selection))
      } else {
        setFieldValue(name, [selection, ...values[name]])
      }
    } else {
      setFieldValue(name, selection)
    }
    setTimeout(() => {
      setFieldTouched(name, true)
    },1)

  };

  const add_option = (field) => {
    let selections = values[`${field.name}_options`] || [];
    setFieldValue(
      `${field.name}_options`,
      [
        ...selections,
        {
          name: UtilsService.guidGenerator(),
          value: ""
        }
      ]);
  }
  const handle_request_justification = (field) => (isChecked: boolean) => {
    setFieldValue(
      `${field.name}_justification`,
      isChecked
    );
  }

  const remove_option = (field, name) => {
    let selections = values[`${field.name}_options`] || [];
    selections = selections.filter(op => op.name !== name);
    setFieldValue(`${field.name}_options`, selections);
  }

  const onAddOptionField = (field, option, text) => {
    let selections = values[`${field.name}_options`] || [];
    if (selections.filter(select => select.name === option.name)[0]) {
      selections = selections.map(select => {
        if (select.name === option.name) {
          return {
            ...select,
            value: text
          }
        } else {
          return select;
        }
      });
    } else {
      selections.push({
        ...option,
        value: text
      });
    }
    setFieldValue(`${field.name}_options`, selections);
  }

  // Use to translate standard form options
  const selectOptionsValue = {
    "Excellent": formatMessage({ id: "standard_form:excellent" }),
    "Très bon": formatMessage({ id: "standard_form:very_good" }),
    "Bon": formatMessage({ id: "standard_form:good" }),
    "Passable": formatMessage({ id: "standard_form:fair" }),
    "Faible": formatMessage({ id: "standard_form:low" }),
    "Very good": formatMessage({ id: "standard_form:very_good" }),
    "Good": formatMessage({ id: "standard_form:good" }),
    "Fair": formatMessage({ id: "standard_form:fair" }),
    "Low": formatMessage({ id: "standard_form:low" }),
    "Oui": formatMessage({ id: "shared:yes" }),
    "Non": formatMessage({ id: "shared:false" }),
    "Yes": formatMessage({ id: "shared:yes" }),
    "No": formatMessage({ id: "shared:false" }),
  }

  const parseSelectOptions = (options: any[] = [], name) => {
    let parsedOptions = options.map((option, index) => (
      <SelectOption
        key={index}
        value={selectOptionsValue[option] ? selectOptionsValue[option] : option}
      />
    ))

    parsedOptions.unshift(
      <SelectOption
        isPlaceholder={true}
        isDisabled={true}
        key={'ksdf'}
        value={formatMessage({ id: 'shared:select' })}
      />
    )

    return parsedOptions
  }

  const formatLabel = (label, label_en) => language=="en" ? label_en ? label_en : label : label

  const parseFieldToCorrectType = field => {
    let toBeShownField = field.type === 'textarea' ?
      <TextArea
        name={field.name}
        value={values[field.name]}
        type={field.type}
        placeholder={formatMessage({ id: formatLabel(field.label, field.label_en) })}
        onChange={text => {
          setFieldValue(field.name, text)
          setTimeout(() => {
            setFieldTouched(field.name, true)
          },1)
        }}
        onBlur={handleBlur}
        aria-label={formatMessage({ id: formatLabel(field.label, field.label_en) })}
        className={styles.field}
        rows={3 || field.rows}
      />
      :
      <TextInput
        name={field.name}
        value={values[field.name]}
        type={field.type}
        placeholder={formatMessage({ id: formatLabel(field.label, field.label_en) })}
        onChange={text => {
          setFieldValue(field.name, text)
          setTimeout(() => {
            setFieldTouched(field.name, true)
          },1)
        }}
        onBlur={handleBlur}
        aria-label={formatMessage({ id: formatLabel(field.label, field.label_en) })}
        className={styles.field}
        rows={field.rows}
      />

    if (IsInpreviewMode) {

      switch (field.type) {
        case 'radio':
          toBeShownField = field.options.map(option => (
            <Radio
              isChecked={values[field.name] === (option)}
              name={option}
              onChange={(checked) => {
                setFieldValue(field.name, option)
                setTimeout(() => {
                  setFieldTouched(field.name, true)
                },1)
              }}
              label={option}
              id={option}
              value="check1"
            />
          ))


          break;
        case 'checkbox':
          toBeShownField = field.options.map(option => (
            <Checkbox
              label={option}
              key={option}
              isChecked={(values[field.name] || []).includes(option)}
              onChange={() => {
                const idx = (values[field.name] || []).findIndex(i => i === option)
                if (idx === -1) {
                  setTimeout(() => {
                    setFieldTouched(field.name, true)
                  },1)
                  setFieldValue(
                    field.name,
                    [...(values[field.name] || []), option]
                  )
                  return
                }
                const newState = values[field.name].filter(e => e !== option)
                setFieldValue(field.name, newState)
                setTimeout(() => {
                  setFieldTouched(field.name, true)
                },1)
              }}
              aria-label="controlled checkbox example"
              id={option}
              name={option}
            />
          ))

          break;
      }

    }

    return (
      <label className={styles.field}>
        <span
          className={errors[field.name] && touched[field.name] ? styles.c_error : ''}
        >
          <FormattedMessage
            id={errors[field.name] && touched[field.name] ? errors[field.name] : formatLabel(field.label, field.label_en)}
          />
          {field.required && <span className={styles.c_error}>*</span>}
        </span>
        {toBeShownField}
      </label>
    )
  }

  return (
    <React.Fragment>
      {fields.map(field => (
        <div className={!field.halfWidth ? styles.fullWidth : ''}>
          {(!field.verify || (field.verify && values[field.verify])) //Gestion du paramete verify
            && <div className={styles.grid}>
              {!field.select ?
                <React.Fragment>
                  {field.switch === true ?
                    <label htmlFor={field.label} className={styles.field}>
                      <Switch
                        // id={field.label}
                        // label={formatMessage({ id: field.label })}
                        label={formatMessage({ id: formatLabel(field.label, field.label_en) })}
                        labelOff={errors[field.name]}
                        isChecked={values[field.name]}
                        onChange={(val) => {
                          setFieldValue(field.name, val)
                          setTimeout(() => {
                            setFieldTouched(field.name, true)
                          },1)
                        }}
                        className={styles.switch_primary}
                      />
                      <span
                        className={errors[field.name] && touched[field.name] ? styles.c_error : ''}
                      >
                        {field.required && <span className={styles.c_error}>*</span>}
                      </span>
                    </label>
                    : (
                      <>
                        {parseFieldToCorrectType(field)}
                      </>
                    )
                  }
                </React.Fragment>
                :
                <React.Fragment>
                  <label className={styles.field}>
                    <span
                      className={errors[field.name] && touched[field.name] ? styles.c_error : ''}
                    >
                      <FormattedMessage
                        id={errors[field.name] && touched[field.name] ? errors[field.name] : formatLabel(field.label, field.label_en)}
                      />
                      <span className={styles.c_error}>*</span>
                    </span>
                    <Select
                      id={field.name}
                      variant={
                        field.select_multi ? SelectVariant.typeaheadMulti :
                          SelectVariant.typeahead
                      }
                      value={values[field.name]}
                      className={
                        classNames(
                          styles.NativeSelect,
                          styles.field,
                          (
                            touched[field.name] &&
                            errors[field.name]) &&
                          styles.NativeSelectError
                        )
                      }
                      placeholder={(formatLabel(field.label, field.label_en))}
                      onClear={clearSelection(field.name, field.select_multi)}
                      name={field.name}
                      onToggle={onToggle(field.name)}
                      onSelect={(e, b) => {
                        onSelect(field.name, field.select_multi)(e, b)
                        setTimeout(() => {
                          setFieldTouched(field.name)
                        },1)
                      }}
                      isOpen={
                        isOpen === field.name
                      }
                      // selections={values[field.name]}
                      selections={selectOptionsValue[values[field.name]] ? selectOptionsValue[values[field.name]] : values[field.name]}
                    >
                      {parseSelectOptions(field.options, field.name)}
                    </Select>

                  </label>
                  {["select", "radio", "checkbox"].includes(values[field.name]) &&
                    <div className={styles.create_options}>
                      {
                        values[`${field.name}_options`] && values[`${field.name}_options`].map(
                          (option, index) => (
                            <div className={styles.item}>
                              {values[field.name] === "select" && (
                                <span>{index}.</span>
                              )}
                              {values[field.name] === "radio" && (
                                <span>
                                  <Radio name={`${option.name}_radio`} id="radio-controlled" />
                                </span>
                              )}
                              {values[field.name] === "checkbox" && (
                                <span>
                                  <Checkbox name={`${option.name}_radio`} id="radio-controlled" />
                                </span>
                              )}
                              <TextInput
                                name={option.name}
                                value={option.value}
                                type={'text'}
                                placeholder={formatMessage({ id: "shared:option" })}
                                onChange={text => onAddOptionField(field, option, text)}
                                onBlur={handleBlur}
                                aria-label={formatMessage({ id: "shared:option" })}
                                className={styles.field}
                              />
                              <Button
                                onClick={() => remove_option(field, option.name)}
                                variant="plain"
                              >
                                <CloseIcon />
                              </Button>
                            </div>
                          )
                        )
                      }
                      <Button
                        onClick={() => add_option(field)}
                        className={styles.add_option}
                        variant="link"
                      >
                        <AddCircleOIcon />
                        <FormattedMessage id="shared:add_option" />
                      </Button>
                      <Switch
                        id="simple-switch"
                        label={formatMessage({ id: "shared:request_justification_prompt" })}
                        isChecked={Boolean(values[`${field.name}_justification`])}
                        onChange={handle_request_justification(field)}
                      />
                    </div>
                  }
                </React.Fragment>
              }
            </div>
          }
        </div>
      ))}
    </React.Fragment>
  )
}
