import React, { useState, Fragment, useEffect } from 'react';
import styles from './NotificationCandidateStatus.module.scss'
import { Modal } from '@patternfly/react-core';
import { Accordion, AccordionItem, AccordionContent, AccordionToggle } from '@patternfly/react-core';
import {
    Table,
    TableHeader,
    TableBody,
    TableVariant,
  } from '@patternfly/react-table';
import { Link } from 'react-router-dom';

export interface NotificationCandidateStatusProps {
    readonly clickedId: string;
    readonly setisKebabDropdownOpen?: any;
}

export const NotificationCandidateStatus : React.FC<NotificationCandidateStatusProps> = ({
    clickedId, setisKebabDropdownOpen,
    ...props
  }) => {


    const [expanded, setExpanded] = useState("ex-toggle1")
    const [toggleModal, setToggleModal] = useState(false)
    
    useEffect(() => {
        if(clickedId=="candidat_status")
        setToggleModal(true)
        else console.log(clickedId)
    }, [clickedId])

    const onToggle = id => {
        if (id === expanded) {
            setExpanded('');
        } else {
          setExpanded(id);
        }
    }
    const columns = [
        'Candidats',
        'Adresse mail',
        'Statut',
        ''
    ]
    const rows= [
        {
          cells: [
              'Janvier grégory',
              'janviergreory@mail.com',
              'Fermé',
              {
                title: <Link to='#'> Afficher</Link>,
                // props: { textCenter: false }
              }
            ]
        },
        {
          cells: [
              'Janvier grégory',
              'janviergreory@mail.com',
              'Fermé',
              {
                title: <Link to='#'> Afficher</Link>,
                // props: { textCenter: false }
              }
            ]
        }
      ]


    return (
        <Modal
            className={styles.modal}
            title="Notifications"
            name=""
            isOpen={toggleModal}
            onClose={
                () => {
                    setToggleModal(false);
                    if(setisKebabDropdownOpen)
                    setisKebabDropdownOpen(false);
                }
            }
        >
            <span className={styles.notif_title}>Nouveau Statut de candidat
                <svg fill="currentColor" height="1em" width="1em" viewBox="0 0 320 512" aria-hidden="true" role="img" className={styles.icon}>
                    <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" transform=""></path>
                </svg>
            </span>


            <Accordion asDefinitionList >
                <AccordionItem>
                    <AccordionToggle
                        onClick={() => {onToggle('ex-toggle1')}}
                        isExpanded={expanded==='ex-toggle1'}
                        id="ex-toggle1"
                        className={styles.accordion_toggle}
                    >
                        Statut changé par : Olivier Bruguier
                    </AccordionToggle>
                    <p> 02 Candidats</p>
                    <AccordionContent
                        id="ex-expand1"
                        isHidden={expanded !== 'ex-toggle1'}
                    >
                        <Table borders={false} variant={TableVariant.compact} aria-label="Simple Table" cells={columns} rows={rows}>
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem>
                    <AccordionToggle
                        onClick={() => {onToggle('ex-toggle2')}}
                        isExpanded={expanded === 'ex-toggle2'}
                        id="ex-toggle2"
                        className={styles.accordion_toggle}
                    >
                        Statut changé par : Sébastien Morissette
                    </AccordionToggle>
                    <p> 07 Candidats</p>
                    <AccordionContent
                        id="ex-expand2"
                        isHidden={expanded !=='ex-toggle2'}
                    >
                        <Table borders={false} variant={TableVariant.compact} aria-label="Simple Table" cells={columns} rows={rows}>
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </AccordionContent>
                </AccordionItem>
                
            </Accordion>

        </Modal>
    );
}
