import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import styles from './CandidateListing.module.scss';
import {
  Button, TextInput, Spinner, spinnerSize,
  Checkbox, SelectOption, Select, SelectVariant,
  Dropdown, DropdownItem, DropdownToggle,
  InputGroup
} from '@patternfly/react-core';
import {
  useIntl, FormattedMessage
} from 'react-intl';
import clsx from 'classnames'
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
  SortByDirection
} from '@patternfly/react-table';
import { Link } from 'react-router-dom'
import {
  useAccordionData, useNotificationData,
  useUserRoleData, useUserInfoData,
  useFolderStatuses, useProvinceData, useLanguageData,
} from 'hooks'
import {
  NewCandidate, ConfirmDialog, CandidateFilter
} from 'dialogs'
import { ReactComponent as StatusArchivedIcon } from 'assets/imgs/status_archived.svg'
import { ReactComponent as StatusLockedIcon } from 'assets/imgs/status_locked.svg'
import { ReactComponent as StatusOpenIcon } from 'assets/imgs/status_open.svg'
import { ReactComponent as StatusLoadingIcon } from 'assets/imgs/status_loading.svg'
import { ReactComponent as StatusFaxReceivedIcon } from 'assets/imgs/status_fax_received.svg'
import { RouteComponentProps } from 'react-router-dom'
import { CandidateService, DemandService } from 'services'
import { useCandidatesData } from 'hooks'
import { ICandidate, IDemand } from 'stores';
import { ArrowsAltVIcon, CloseIcon, FilterAltIcon, SearchIcon } from '@patternfly/react-icons';
import { ListEmpty, CompanyPopover, SearchField } from 'components';
import { TABLE_COLUMN } from 'constants/table_columns';

export interface CandidateListingProps extends RouteComponentProps {
}

const CandidateListingComponent: React.FC<CandidateListingProps> = ({
  match: { url },
  history,
  ...props
}) => {
  let {
    start: storeStart, limit: storeLimit,
    candidates: storeCandidates,
  } = useCandidatesData()
  const { language } = useLanguageData()
  const { currentRole } = useUserRoleData()
  const { userInfo } = useUserInfoData() as any
  const [
    observerTriggerState, setObserverTriggerState
  ] = useState<any>({})
  const { formatMessage } = useIntl()
  const table_header_columns: TABLE_COLUMN[] = [
    {
      title: '', sortable: false,
      searcheable: false,
    },
    {
      title: formatMessage({ id: 'candidate:candidates' }),
      sortable: false,
      field: 'name',
    },
    {
      title: formatMessage({ id: "shared:manager" }),
      field: 'contact_name',
    },
    {
      title: formatMessage({ id: "shared:company" }),
      field: 'company_name',
    },
    {
      title: formatMessage({ id: "shared:tel" }),
      field: 'phone',
      type: 'number',
    },
    {
      title: formatMessage({ id: "shared:postNumber" }),
      // type:'number',
      searcheable: false,
    },
    {
      title: formatMessage({ id: "shared:folder_number" }),
      field: 'folder_number',
    },
    {
      title: formatMessage({ id: "shared:nas" }),
      field: 'nas',
    },
    {
      title: formatMessage({ id: "shared:status" }),
      field: 'status',
      type: 'select',
    },
  ]
  const [order, setOrder] = useState<string>('')


  const onSelect = (title) => {
    setSelected(title)
    setFilterOpen(false)
  }

  const orders = [
    {
      title: formatMessage({ id: "shared:asc" }),
      value: 'asc',
    },
    {
      title: formatMessage({ id: "shared:desc" }),
      value: 'desc',
    },
  ]

  const deleteCandidate = (...candidate_ids: string[]) => {
    const oldRowsLength = rows.length
    setRows(rows.filter(
      ({ id }) => !candidate_ids.includes(id)
    ))

    /*
    * trigger fetching of candidates in case he deletes more than
    * can be shown on the current page
    */
    if (oldRowsLength - candidate_ids.length > 14) {
      setTimeout(() => {
        setObserverTriggerState({})
      })
    }
  }

  const updateCandidate = (paramCandidate: ICandidate) => {
    setRows(rows.map((candidate) => {
      if (candidate.id === paramCandidate.id)
        return paramCandidate
      return candidate
    }))
  }

  const dateFields = table_header_columns.filter(
    ({ searcheable = true, type }) => searcheable && type === 'date'
  )
    .map(({ title }) => title)

  const searcheableFields = table_header_columns
    .filter(({ searcheable = true }) => searcheable)
    .map(({ title }) => title)
    .map(title => (
      <DropdownItem
        onClick={_ => {
          onSelect(title)
          setQuery('')
        }}
        key={title} value={title}
        component="button"
      >
        {title}
      </DropdownItem>
    ))

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [orderOpen, setOrderOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>('')
  const [status, setStatus] = useState<string>('')

  if (currentRole !== "Contact") {
    //Empty column for details
    table_header_columns.push(
      { title: '', searcheable: false }
    )
  }

  const [selectedCandidatesIds, setSelectedCandidtesIds] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const centinelRef = useRef<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [loadingArchive, setLoadingArchive] = useState<boolean>(false)
  const [toDelete, setToDelete] = useState<string[]>([])
  const [toArchive, setToArchive] = useState<string[]>([])
  const [toBeModifiedCandidate, setToBeModifiedCandidate] = useState<ICandidate | undefined>(undefined)
  const [toChangeStatus, setToChangeStatus] = useState<string>('')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const { folderStatus } = useFolderStatuses({masculine:true})

  const status_options = folderStatus.map(({ id, name }) => (
    <SelectOption key={id} name={name} value={name} />
  ))

  status_options.unshift(
    <SelectOption isDisabled={true} value={formatMessage({ id: "shared:select" })} />
  )

  const [loadingChangeStatus, setLoadingChangeStatus] = useState<boolean>(false)

  const { new_notification } = useNotificationData()
  const { provinces } = useProvinceData()

  const translateStatus = (folder_status: number) => {
    let returnVal = 'locked'

    if (folder_status === 4) returnVal = 'archived'
    else if (folder_status === 0) returnVal = 'open'
    else if (folder_status === 3) returnVal = 'locked'
    else if (folder_status === 1) returnVal = 'loading'
    else if (folder_status === 2) returnVal = 'fax_received'

    return returnVal
  }

  const parseStatus = (status) => {

    let StatusIcon = StatusLockedIcon

    switch (status) {
      case 5:
        StatusIcon = StatusLockedIcon
        break;
      case 4:
        StatusIcon = StatusArchivedIcon
        break;
      case 0:
        StatusIcon = StatusOpenIcon
        break;
      case 3:
        StatusIcon = StatusLockedIcon
        break;
      case 1:
        StatusIcon = StatusLoadingIcon
        break;
      case 2:
        StatusIcon = StatusFaxReceivedIcon
        break;
    }

    return (
      <div>
        <div
          className={clsx(
            styles.status_box,
            styles[status],
          )}
        >
          <StatusIcon />
          <span>{formatMessage({ id: `shared:${status}` })}</span>
        </div>
      </div>
    )
  }

  const deleteCandidateFromApi = (...candidate_ids: string[]) => {
    setLoadingDelete(true)
    CandidateService.delete_candidates({ candidate_ids })
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
          deleteCandidate(...candidate_ids)
          setToDelete([])
          setSelectedCandidtesIds([])
          setLoadingDelete(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingDelete(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  useEffect(() => {
    if (!Boolean(toChangeStatus)) return
    const currentStatus = folderStatus.find(
      stat => stat.id === rows.find(
        ({ id }) => id === toChangeStatus
      )!.folder_status
    )
    let currentStatusName = ''
    if (currentStatus && currentStatus.name) {
      currentStatusName = currentStatus.name
    }
    setStatus(currentStatusName)
  }, [toChangeStatus])

  const updateCandidateStatus = (candidate: ICandidate, status_name) => {
    const ARCHIVED_STATUS_ID = 4
    setLoadingChangeStatus(true)
    setIsExpanded(false)
    const status = folderStatus.find(stat => stat.name === status_name)!.id
    DemandService.update_demand(candidate.demand_id, { status })
      .catch(err => {
        setLoadingChangeStatus(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setLoadingChangeStatus(false)
        if ([200, 201].includes(response.status)) {
          let { data, message } = await response.json()
          new_notification({
            message: message,
            variant: 'success',
            title: formatMessage({ id: 'shared:success' }),
          })
          if (status === ARCHIVED_STATUS_ID) {
            setRows(rows.filter(({ id }) => id !== candidate.id))
          } else {
            updateCandidate({
              ...candidate,
              folder_status: `${status}`,
            })
          }
          setToChangeStatus('')
          setStatus(status_name)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }

  const archiveCandidateOnApi = (...candidate_ids: string[]) => {
    setLoadingArchive(true)
    CandidateService.archive_candidates({
      is_archived: true,
      candidate_ids,
    })
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: "shared:unknown_error" }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
          deleteCandidate(...candidate_ids)
          setToArchive([])
          setLoadingArchive(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingArchive(false)

          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setLoadingArchive(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const [rows, setRows] = useState<any[]>([])

  let actions: any[] = [
    // {
    //   title: <div className={styles.menu_link}>
    //     <span>
    //       <FormattedMessage id="shared:update" />
    //     </span>
    //   </div>,
    //   onClick: (_, rowId,) => setToBeModifiedCandidate({ ...rows[rowId] })
    // },
    {
      title: <div className={styles.menu_disabled}>
        <span>
          <FormattedMessage id="shared:download_pdf" />
        </span>
      </div>,
      onClick: (event, rowId, rowData, extra) => window.open(
        `/report/${rows[rowId].id}/${language}`,
        "_blank"
      )
    },

  ]

  if (["Administrator", "Technician"].includes(currentRole)) {
    actions = [
      ...actions,
      {
        title: <div className={styles.menu_link}>
          <span>
            <FormattedMessage id="shared:update_status" />
          </span>
        </div>,
        onClick: (_, rowId,) => setToChangeStatus(rows[rowId].id)
      },
      {
        title: <div className={styles.menu_link}>
          <span>
            <FormattedMessage id="shared:delete_candidate" />
          </span>
        </div>,
        onClick: (_, rowId,) => setToDelete([rows[rowId].id])
      },
    ]
  }

  const [sortBy, setSortBy] = useState<any>({})
  const { resetAccordion } = useAccordionData()
  const [query, setQuery] = useState<string>('')
  const [searchStart, setSearchStart] = useState<number>(0)
  const [searchTotal, setSearchTotal] = useState<number>(0)
  const [filterState, setFilterState] = useState<any>(undefined)

  useEffect(() => {
    resetAccordion({
      title: formatMessage({ id: 'candidate:candidate_list' }),
      sub_title: ''
    })
    return configureInfiniteScroll()
  }, [])

  useEffect(() => {
    fetchCandidates(0)
  }, [userInfo, currentRole])

  useEffect(
    () => {
      if (storeCandidates && storeCandidates.length > 0 && !query) {
        setRows(storeCandidates)
      }
    },
    [storeCandidates]
  )

  useEffect(() => {
    const column = table_header_columns.find(({ title }) => title === selected)
    if (!Boolean(column)) return
    let field = column!.field

    setFilterState(
      { order, order_field: field, [field!]: query }
    )
    fetchCandidates(
      0,
      { order, order_field: field, [field!]: query }
    )
  }, [selected, query, order,])

  const fetchCandidates = (
    paramStart: number,
    filter?: any,
  ) => {
    if (!Boolean(userInfo) || !Boolean(currentRole)) { return }
    setLoading(true)
    let suffix = `?start=${paramStart}&limit=${30}`
    if (currentRole === "Company_Administrator" || currentRole === "Contact") {
      suffix = `${suffix}&company_id=${(userInfo as any).company_id}`
    }

    if (Object.keys(filter || filterState || {}).length > 0) {
      Object.keys(
        filter || filterState
      ).forEach(field => {
        if (
          Boolean((filter || filterState)[field])
          || (filter || filterState)[field] === 0
        ) {
          suffix = `${suffix}&${field == 'undefined' ? 'keyword' : field}=${(filter || filterState)[field]}`
        }
      })
    }


    CandidateService.list_candidates(suffix)
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const {
            results, start, total,
          } = await response.json()
          setRows([...(start > 0 ? rows : []), ...results])
          setSearchTotal(total)
          setSearchStart(start)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const handleSelectCandidate = candidate_id => {
    const idx = selectedCandidatesIds.findIndex(id => id === candidate_id)
    if (idx === -1) {
      setSelectedCandidtesIds([...selectedCandidatesIds, candidate_id])
      return
    }
    const newList = [...selectedCandidatesIds]
    newList.splice(idx, 1)
    setSelectedCandidtesIds(newList)

  }

  const parseCandidates = (data: ICandidate[]) => {
    return data.map(datum => {
      const returnVal = [
        <>
          <Checkbox
            id="e"
            onClick={i => handleSelectCandidate(datum.id)}
            isChecked={selectedCandidatesIds.includes(datum.id)}
          />
        </>,
        <span>
          {datum.is_idqc_request &&
            <span className={styles.number}> {"IDQC"} </span>
          }
          {/* {' '} */}
          {datum.firstname} {datum.lastname}
        </span>,
        `${datum.contact_name} `,
        // leave this like this
        <>
          <CompanyPopover
            trigger={fn => (
              <span onMouseEnter={fn} onMouseLeave={fn} >
                {(datum.company || { name: '' }).name}
              </span>
            )}
            applicant={datum.applicant}
          />
        </>,
        datum.phone_number,
        datum.post_number,
        datum.folder_number,
        datum.nas,
        <span>
          {parseStatus(translateStatus(parseInt(datum.folder_status)))}
        </span>,
      ]
      if (["Administrator", "Technician"].includes(currentRole)) {
        let cand = storeCandidates.find(d => d.id === datum.id)
        returnVal.push(
          <span>
            <Link
              to={{
                pathname: `${url}/${datum.id}`,
                state: { cand, provinces }
              }}
              className={styles.link}
            >
              < FormattedMessage id="shared:more_details" />
            </Link >
          </span >
        )
      }

      return returnVal
    })
  }

  const onNextPage = () => {
    if (searchTotal !== 0 && rows.length >= searchTotal) { return }
    fetchCandidates(searchTotal === 0 ? 0 : searchStart + storeLimit)
    return
  }

  const loadMore = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !loading) {

      //The following call will trigger the useEffect that depends on observerTriggerState variable.
      setObserverTriggerState({})
    }
  }, [loading, onNextPage, storeLimit, storeCandidates, storeStart])

  useEffect(() => {
    onNextPage()
  }, [observerTriggerState])

  const configureInfiniteScroll = () => {
    const options = {
      root: null, //use window as intersection box
      rootMargin: '0px',
      threshold: 0.25,
    }

    //create observer 
    const observer = new IntersectionObserver(loadMore, options)
    if (centinelRef && centinelRef.current)
      observer.observe(centinelRef.current)

    //cleanup everything on UnMount
    return () => observer && observer.disconnect()
  }

  const parsePagination = () => {
    const count = rows.length
    let total = searchTotal
    return `${count} of ${total}`
  }

  return (
    <div className={styles.wrapper} >

      <div className={styles.content}>

        <ConfirmDialog
          confirmation_message="shared:do_you_want_to_continue"
          onAction={_ => deleteCandidateFromApi(...toDelete)}
          isModalOpen={toDelete.length > 0}
          loading={loadingDelete}
          onModalClose={_ => setToDelete([])}
        />

        <ConfirmDialog
          confirmation_message="candidate:prompt_archive_candidates"
          title_message="shared:archive"
          onAction={_ => archiveCandidateOnApi(...toArchive)}
          actionText={"shared:archive"}
          isModalOpen={toArchive.length > 0}
          loading={loadingArchive}
          onModalClose={_ => setToArchive([])}
        >

          <ul className={styles.list}>
            {rows
              .filter(({ id }) => toArchive.includes(id))
              .map(({ firstname, lastname }) => (
                <li className={styles.list_itemm}>
                  {`${firstname} ${lastname}`}
                </li>
              ))
            }
          </ul>

        </ConfirmDialog>

        <ConfirmDialog
          confirmation_message="candidate:update_status_prompt"
          title_message="shared:update_candidate"
          onAction={_ => {
            setToChangeStatus('')
            setStatus('')
          }}
          actionText={"shared:close"}
          isModalOpen={Boolean(toChangeStatus)}
          loading={loadingChangeStatus}
          onModalClose={_ => {
            setToChangeStatus('')
            setStatus('')
          }}
        >
          <div
            style={{
              marginBottom: 200
            }}
          >
            <Select
              variant={SelectVariant.typeahead}
              onToggle={
                open => setIsExpanded(open)
              }
              name="status"
              onSelect={(_, status_name) =>
                updateCandidateStatus(
                  rows.find(({ id }) => toChangeStatus === id),
                  status_name
                )
              }
              selections={status}
              isOpen={isExpanded}
            >
              {status_options}
            </Select>
          </div>
        </ConfirmDialog>

        <div className={styles.toolbar}>

          <InputGroup
            className={styles.search}
          >
            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<FilterAltIcon />}
                  onToggle={setFilterOpen}
                >
                  {selected ? selected : formatMessage({ id: 'shared:filter' })}
                </DropdownToggle>
              }
              isOpen={filterOpen}
              dropdownItems={searcheableFields}
            />

            <Dropdown
              toggle={
                <DropdownToggle
                  icon={<ArrowsAltVIcon />}
                  onToggle={setOrderOpen}
                >
                  {order ?
                    orders.find(jk => jk.value === order)!.title
                    : formatMessage({ id: 'shared:order' })}
                </DropdownToggle>
              }
              isOpen={orderOpen}
              dropdownItems={
                orders
                  .map(orderIn => (
                    <DropdownItem
                      onClick={_ => {
                        setOrder(orderIn.value)
                        setOrderOpen(false)
                      }}
                      key={orderIn.value}
                      value={orderIn.value}
                      component="button"
                    >
                      {orderIn.title}
                    </DropdownItem>
                  ))
              }
            />

            <SearchField
              value={query}
              dateFields={dateFields}
              statusField={formatMessage({ id: "shared:status" })}
              removeArchivedStatus={true}
              field={selected} onAction={setQuery}
            />

            <Button
              variant="control"
              className={styles.btn_nothing}
            >
              <SearchIcon />
            </Button>

          </InputGroup>

          {/* <NewCandidate
            onClose={e => setToBeModifiedCandidate(undefined)}
            candidate={toBeModifiedCandidate}
            setToBeModifiedCandidate={setToBeModifiedCandidate}
            renderTrigger={trigger => (
              <Button onClick={trigger} className={styles.btn_filled}>
                <FormattedMessage id="candidate:new_candidate" />
              </Button>
            )}
          /> */}

          {selectedCandidatesIds.length > 0 && (
            <>
              {
                ["Administrator", "Technician"].includes(currentRole) && (
                  <Button
                    onClick={i => {
                      setToDelete(selectedCandidatesIds)
                      setSelectedCandidtesIds([])
                    }}
                    variant={"secondary"}
                  >
                    <CloseIcon />
                    <FormattedMessage id="shared:delete" />
                  </Button>
                )
              }

              <Button
                onClick={i => {
                  setToArchive(selectedCandidatesIds)
                  setSelectedCandidtesIds([])
                }}
                className={styles.btn_filled}
              >
                <FormattedMessage id="shared:archive" />
              </Button>

            </>
          )}

          {loading && <Spinner size={spinnerSize.md} />}

        </div>

        {rows.length === 0 && !loading && (
          <ListEmpty />
        )}

        {rows.length > 0 && (
          <Table
            aria-label="Sortable Table"
            sortBy={sortBy}
            variant={TableVariant.compact}
            cells={table_header_columns}
            borders={false}
            rows={parseCandidates(rows)}
            actions={actions}
          >
            <TableHeader />
            <TableBody />
          </Table>
        )}

        <div className={styles.counter} ref={centinelRef}>
          <span>
            {loading && <Spinner size={spinnerSize.md} />}
          </span>
          <span>{parsePagination()}</span>
        </div>

      </div>

    </div>
  )
}

export {
  CandidateListingComponent as CandidateListingPage,
}
