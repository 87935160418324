import { ObservableStore } from '@codewithdan/observable-store';
import {
    map,
    distinctUntilChanged,
} from 'rxjs/operators'
import {
    IActivatedCompany, COMPANY_STORE_ACTIONS,
    ICompanyDepartment,
} from '.'


class ActivatedCompanyStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        INIT_STATE: 'INIT_STATE',
        ...COMPANY_STORE_ACTIONS,
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }


    //#region companies
    resetCompanies = (param: IStoreActivatedCompany) => {
        this.setState(
            state => ({
                ...state,
                companies: { ...param, data: param.data }
            }),
            ActivatedCompanyStore.ACTIONS.RESET_COMPANIES,
        )
    }

    appendCompanies = (param: IStoreActivatedCompany) => {
        this.setState(
            state => ({
                ...state,
                companies: {
                    ...param,
                    data: [
                        ...param.data,
                        ...(state.companies.data || [])
                    ]
                },
            }),
            ActivatedCompanyStore.ACTIONS.APPEND_COMPANIES,
        )
    }

    updateCompany = (company: IActivatedCompany) => {
        const oldCompanyId = this.getState()
            .companies.data!.findIndex(cad => cad.id === company.id)
        if (oldCompanyId === -1) { return }

        /**At this point, companies CANNOT be an empty array no undefined reference */
        const companies = this.getState().companies.data
        companies![oldCompanyId] = company

        this.setState(
            state => ({
                ...state,
                companies: { ...state.companies, data: companies },
            }),
            ActivatedCompanyStore.ACTIONS.UPDATE_COMPANY,
        )
    }

    deleteCompany = (...companyIds: string[]) => {
        // const newCompanies = this.getState().companies.data

        this.setState(
            state => {

                let finalData = state.companies.data.filter(
                    company => {
                        return !Boolean(companyIds.includes(company.id))
                    }
                )
                console.log({
                    companyIds,
                    finalData
                })

                return {
                    ...state,
                    companies: {
                        ...state.companies,
                        data: finalData
                    },
                }
            },
            ActivatedCompanyStore.ACTIONS.DELETE_COMPANY,
        )
    }

    deleteDepartments = (company_id, ...depIds: string[]) => {
        const companyIdx = this.getState().companies.data.findIndex(
            company => company.id === company_id
        )
        //company not found
        if (companyIdx === -1) { return }
        const newCompanies = this.getState().companies.data
        let depList = newCompanies[companyIdx].departments
        newCompanies[companyIdx].departments = depList.filter(
            ({ id }) => !depIds.includes(id)
        )
        this.setState(
            state => ({
                ...state,
                companies: { ...state.companies, data: newCompanies }
            }),
            ActivatedCompanyStore.ACTIONS.DELETE_DEPARTMENT
        )
    }

    updateDepartment = (
        company_id: string, newDepartment: ICompanyDepartment
    ) => {
        const companyIdx = this.getState().companies.data.findIndex(
            company => company.id === company_id
        )
        //company not found
        if (companyIdx === -1) { return }
        const newCompanies = this.getState().companies.data
        const newDepartments = [
            ...newCompanies[companyIdx].departments
        ]
        let depIdx = newDepartments.findIndex(
            ({ id }) => id === newDepartment.id
        )

        //department not found.
        if (depIdx === -1) { return }
        newDepartments[depIdx] = newDepartment
        newCompanies[companyIdx].departments = newDepartments

        this.setState(
            state => ({
                ...state,
                companies: {
                    ...state.companies, data: newCompanies
                }
            }),
            ActivatedCompanyStore.ACTIONS.APPEND_DEPARTMENT
        )
    }

    appendDepartments = (
        company_id: string,
        ...department: ICompanyDepartment[]

    ) => {
        const companyIdx = this.getState().companies.data.findIndex(
            company => company.id === company_id
        )
        //company not found
        if (companyIdx === -1) { return }
        const newCompanies = this.getState().companies.data
        newCompanies[companyIdx].departments.unshift(...department)
        this.setState(
            state => ({
                ...state,
                companies: { ...state.companies, data: newCompanies }
            }),
            ActivatedCompanyStore.ACTIONS.APPEND_DEPARTMENT
        )
    }

    resetDepartments = (
        company_id: string, ...department: ICompanyDepartment[]
    ) => {
        const companyIdx = this.getState().companies.data.findIndex(
            company => company.id === company_id
        )
        //company not found
        if (companyIdx === -1) { return }
        const newCompanies = this.getState().companies.data
        newCompanies[companyIdx].departments = department
        this.setState(
            state => ({
                ...state,
                companies: { ...state.companies, data: newCompanies }
            }),
            ActivatedCompanyStore.ACTIONS.RESET_DEPARTMENTS
        )
    }

    /**
     * **************getter for everything companies data.****************
     * Notice the distinctUntilChanged operator
     * used to ensure that it doesn't fire at all
     * https://rxjs-dev.firebaseapp.com/api/operators/distinctUntilChanged
     */
    get companies$() {
        return this.stateChanged.pipe(
            map(({ companies }) => companies),
            distinctUntilChanged(),
        )
    }

    //#endregion companies

    logout = () => {
        const defaultData = {
            data: [
            ],
            start: 0,
            limit: 30,
            total: 0,
        }
        this.setState({
            companies: defaultData,
        }, ActivatedCompanyStore.ACTIONS.INIT_STATE)

    }

}

export default new ActivatedCompanyStore()



export interface IStoreActivatedCompany {
    limit?: number,
    start?: number,
    total?: number,
    data: IActivatedCompany[],
}

export interface StoreState {
    companies: IStoreActivatedCompany,
}