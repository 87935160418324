
import styles from './LanguageSwitcher.module.scss'
import React, { useState } from 'react';
import { JSXElement } from '@babel/types';
import { useLanguageData } from 'hooks'
import { AppStore } from 'stores'
import { useIntl } from 'react-intl';
import { ReactComponent as France } from 'assets/imgs/france.svg';
import { ReactComponent as UnitedKingdom } from 'assets/imgs/united_kingdom.svg';
import { Dropdown, DropdownToggle, DropdownItem } from '@patternfly/react-core';


export interface ComponentSwitcherProps {
  readonly render?: (toggleEn, toggleFr) => JSXElement;
  readonly showlang?: boolean;
}

export const LanguageSwitcherComponent: React.FC<ComponentSwitcherProps> = props => {
  const { language } = useLanguageData()
  const { toggleLanguage } = AppStore
  const { formatMessage } = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const onSelect = event => {
    console.log(props.showlang);
    setIsOpen(!isOpen)
  };

  const defaultRender = (toggleEn, toggleFr) => (
    <div className={styles.container}>
      <Dropdown
        position="right"
        onSelect={onSelect}
        toggle={
          <DropdownToggle
            // iconComponent={null}
            onToggle={
              (isOpen) => setIsOpen(isOpen)
            }
            aria-label="Applications"
            id="toggle-id-7"
          >
            {language === 'en'
              ? props.showlang
                ? <span>
                  <UnitedKingdom className={styles.flag} />
                  <span
                    style={{ color: 'black' }}
                    className={styles.mobile_lang_title}
                  >
                    {formatMessage({ id: 'en' })}
                  </span>
                </span>
                : <UnitedKingdom className={styles.flag} />
              : props.showlang
                ? <span>
                  <France className={styles.flag} />
                  <span
                    style={{ color: 'black' }}
                    className={styles.mobile_lang_title}
                  >
                    {formatMessage({ id: 'fr' })}
                  </span>
                </span>
                : <France className={styles.flag} />
            }
          </DropdownToggle>
        }
        isOpen={isOpen}
        isPlain
        dropdownItems={[
          <DropdownItem
            key="en"
            component="button"
            onClick={toggleEn}
            className={styles.lang_title}
          >
            {formatMessage({ id: 'en' })}
          </DropdownItem>,
          <DropdownItem
            key="fr"
            component="button"
            onClick={toggleFr}
            className={styles.lang_title}
          >
            {formatMessage({ id: 'fr' })}
          </DropdownItem>
        ]}
      />
    </div>
  )

  const {
    render = defaultRender,
  } = props


  return (
    <>
      {render(
        e => toggleLanguage('en'),
        e => toggleLanguage('fr')
      )}
    </>
  );
}


export {
  LanguageSwitcherComponent as LanguageSwitcher
}