import React, { useEffect, useState, useCallback } from 'react';
import styles from './Users.module.scss';
import { useAccordionData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
} from '@patternfly/react-table';
import { ExpandableTable } from 'components';
import { Button, InputGroup, TextInput, ButtonVariant } from '@patternfly/react-core';
import clsx from 'classnames'
import { SearchIcon } from '@patternfly/react-icons';


export interface UsersProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const UsersComponent: React.FC<UsersProps> = props => {

  const { formatMessage } = useIntl()
  const { resetAccordion } = useAccordionData()

  const [columns, setColumns] = React.useState<any[]>([
    formatMessage({ id: "shared:users" }),
    formatMessage({ id: "shared:mail" }),
    formatMessage({ id: "shared:created_on" }),
    formatMessage({ id: "shared:modified_on" }),
    formatMessage({ id: "shared:latest_connexion" }),
    "",
  ])

  const [rows, setRows] = useState<any[]>([
    {
      cells: [
        'one', 'two', 'three', 'four', 'four',
        <Button
          variant={ButtonVariant.link}
          className={styles.link}
        >
          <FormattedMessage id="shared:update" />
        </Button>
      ]
    },
    {
      isOpen: false,
      cells: [
        'parent - 1', 'two', 'three', 'four', 'four',
        <Button
          variant={ButtonVariant.link}
          className={styles.link}
        >
          <FormattedMessage id="shared:update" />
        </Button>

      ]
    },
    {
      parent: 1,
      fullWidth: true,
      noPadding: true,
      cells: [
        {
          padding: false,
          title: (
            <React.Fragment>
              <div className={styles.grid_column}>
                {[
                  ['Role', 'Administrateur, contact'],
                  ['Compagnie', 'Habitation Trigone'],
                ].map(([title, message]) => (
                  <div className={styles.flex_col}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.message}>{message}</span>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )
        }
      ]
    },
    {
      isOpen: false,
      cells: [
        'parent - 1', 'two', 'three', 'four', 'four',
        <Button
          variant={ButtonVariant.link}
          className={styles.link}
        >
          <FormattedMessage id="shared:update" />
        </Button>

      ]
    },
    {
      parent: 1,
      fullWidth: true,
      noPadding: true,
      cells: [
        {
          padding: false,
          title: (
            <React.Fragment>
              <div className={styles.grid_column}>
                {[
                  ['Role', 'Administrateur, contact'],
                  ['Compagnie', 'Habitation Trigone'],
                ].map(([title, message]) => (
                  <div className={styles.flex_col}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.message}>{message}</span>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )
        }
      ]
    },
  ])

  const onSelect = (event, isSelected, rowId) => {
    let newRows;
    if (rowId === -1) {
      newRows = rows.map(oneRow => {
        oneRow.selected = isSelected;
        return oneRow;
      });
    } else {
      newRows = [...rows];
      newRows[rowId].selected = isSelected;
    }
    setRows(newRows)
  }

  const onCollapse = (event, rowKey, isOpen) => {
    /**
     * Please do not use rowKey as row index for more complex tables.
     * Rather use some kind of identifier like ID passed with each row.
     */
    let newRows = [...rows]
    newRows[rowKey].isOpen = isOpen;
    setRows(newRows)
  }

  const actions = [
    {title:formatMessage({id:'shared:delete'})}
  ]



  useEffect(() => {
    resetAccordion({ title: formatMessage({ id: 'users:user_listing' }) })
  }, [])

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.card
        )}
      >
        <header className={styles.header}>
          <InputGroup className={styles.search_field}>
            <TextInput
              placeholder={formatMessage({ id: 'shared:search' })}
              id="textInput11" type="search"
              aria-label="search input example"
            />
            <Button
              variant="control"
              aria-label="search button for search input"
            >
              <SearchIcon />
            </Button>
          </InputGroup>
          <Button>
            <FormattedMessage id="shared:new_user" />
          </Button>
          <Button variant={ButtonVariant.secondary}>
            <FormattedMessage id="shared:filter" />
          </Button>
        </header>
        <div className={styles.table_container}>
          <Table
            aria-label="Compact expandable table"
            variant={TableVariant.compact}
            onCollapse={onCollapse}
            rows={rows}
            actions={actions}
            onSelect={onSelect}
            canSelectAll={true}
            cells={columns}
            borders={false}
          >
            <TableHeader />
            <TableBody />
          </Table>
        </div>

      </div>
    </div>

  )

}

export {
  UsersComponent as UsersPage,
}
