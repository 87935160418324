import React, { useState, useEffect } from 'react'
import { Subject } from 'rxjs'
import { AppStore, IUser } from 'stores'
import { takeUntil, filter } from 'rxjs/operators'



export function useUserDataH() {
    const { appendUsers, resetUsers, deleteUsers, updateUser } = AppStore

    const [start, setStart] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [limit, setLimit] = useState<number>(30)
    const [users, setUsers] = useState<IUser[]>([])

    /**Setup accordion */
    useEffect(() => {
        const trigger = new Subject<boolean>()

        AppStore.users$
            .pipe(
                takeUntil(trigger.asObservable()),
                filter(data => Boolean(data) || Boolean(data.limit!>0))
            )
            .subscribe(
                ({
                    start=0,
                    limit=30,
                    total,
                    data,
                }) => {
                    setStart(start!)
                    setTotal(total!)
                    setLimit(limit!)
                    setUsers(data)
                }
            )

        //onUnmount
        return () => trigger.next(false)
    }, [])

    return {
        appendUsers, resetUsers,
        deleteUsers, updateUser,
        users, start, limit, total,
    }
}