import { ObservableStore } from '@codewithdan/observable-store';
import {
    map,
} from 'rxjs/operators'
import {
    ICategoryType, CATEGORY_STORE_ACTIONS,
} from '.'


class CategoryStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        INIT_STATE: 'INIT_STATE',
        ...CATEGORY_STORE_ACTIONS,
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }

    //#region Categories
    resetCategories = (param: IStoreCategory) => {
        this.setState(
            state => ({
                ...state,
                categories: { ...param, data: param.data }
            }),
            CategoryStore.ACTIONS.RESET_CATEGORIES,
        )
    }

    appendCategories = (param: IStoreCategory) => {
        this.setState(
            state => ({
                ...state,
                categories: { ...param, data: [...(state.categories.data || []), ...param.data] },
            }),
            CategoryStore.ACTIONS.APPEND_CATEGORY,
        )
    }

    appendCategory = (param: ICategoryType) => {
        this.setState(
            state => ({
                ...state,
                categories: { ...state.categories, data: [param, ...(state.categories.data || [])] },
            }),
            CategoryStore.ACTIONS.APPEND_CATEGORY,
        )
    }

    updateCategory = (paramCategory: ICategoryType) => {
        const oldCategoryId = this.getState().categories.data!.findIndex(cad => cad.id === paramCategory.id)
        if (oldCategoryId === -1) { return }

        /**At this point, categories CANNOT be an empty array no undefined reference */
        const categories = this.getState().categories.data
        categories![oldCategoryId] = paramCategory

        this.setState(
            state => ({
                ...state,
                categories: { ...state.categories, data: categories },
            }),
            CategoryStore.ACTIONS.UPDATE_CATEGORY,
        )
    }


    deleteCategory = (...candidateIds: string[]) => {
        /**At this point, categories CANNOT be an empty array nor undefined reference */
        const candidatesData = this.getState().categories.data.filter(cat => !candidateIds.includes(cat.id!))
        this.setState(
            state => ({
                ...state,
                categories: { ...state.categories, data: candidatesData },
            }),
            CategoryStore.ACTIONS.DELETE_CATEGORY,
        )
    }

    /**
     * **************getter for everything categories data.****************
     * used to ensure that it doesn't fire at all
     */
    get categories$() {
        return this.stateChanged.pipe(
            map(({ categories }) => categories),
        )
    }
    //#endregion categories


    logout = () => {
        const defaultData = {
            data: [],
            start: 0,
            limit: 30,
            total: 0,
        }
        this.setState({
            categories: defaultData,
        }, CategoryStore.ACTIONS.INIT_STATE)

    }

}

export default new CategoryStore()



export interface IStoreCategory {
    limit?: number,
    start?: number,
    total?: number,
    data: ICategoryType[],
}


export interface StoreState {
    categories: IStoreCategory,
}