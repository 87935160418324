import * as React from 'react';
import styles from './FinanceInfo.module.scss';
import { useAccordionData } from 'hooks';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';

export interface FinanceInfoProps extends RouteComponentProps{
  readonly dumm?: boolean;
}

const FinanceInfoComponent: React.FC<FinanceInfoProps> = props => {

  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();

  React.useEffect(() => {
    resetAccordion({ title: formatMessage({ id: 'archives:list_of_archived_candidates' }) })
  }, [])

  return (
    <div className={styles.container} >
      hello world
      </div>
  );
}

export {
  FinanceInfoComponent as FinanceInfoPage,
}
