import React, { useEffect, useState } from 'react';
import styles from './EditForm.module.scss'
import {
  Button, Modal, Spinner,
  spinnerSize, ModalVariant, SelectOption
} from '@patternfly/react-core';
import { form_fields } from 'constants/example';
import { CustomForm } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormService
} from 'services';
import {
  useNotificationData,
  useFormData, useUserInfoData, useFetchCompanies
} from 'hooks';
import { ILoginInfo } from 'stores/auth.store';

export interface EditFormProps {
  readonly open: boolean;
  readonly onCloseModal: (val) => void;
  readonly form: any;
  readonly verifiable_sections: any[];
  readonly onClose: any;
}

/**
 * Modal Pour l'edition d'un formulaire
 * @param open: variable, vrai si modal est ouvert/ et faux si fermé
 * @param onCloseModal: Fonction, permet de fermer/ouvrir le modal
 * @param form: variable, contient le formulaire à editer 
 */
const EditForm: React.FC<EditFormProps> = props => {

  const {
    open, onCloseModal,
    form, verifiable_sections,
  } = props

  const { formatMessage } = useIntl()
  const { new_notification } = useNotificationData()
  const { appendForms, updateForm } = useFormData()
  const { userInfo: { company_id } } = useUserInfoData() as { userInfo: ILoginInfo }
  const [form_id, setFormId] = useState<string | undefined>(undefined)
  const [
    updatedFormFields, setUpdatedFormFields
  ] = useState<any[]>(form_fields)

  //Ouvrir/Fermer le modal
  const handleModalToggle = () => {
    onCloseModal(form);
  };

  //Pour soummettre le formulaire values,actions
  const onSubmit = (values, actions) => {
    saveFormToApi(values, actions)
  }

  const [
    initialValues, setInitivalValues
  ] = useState<any>({})

  const formikValues = useFormik<{}>({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      is_standard: yup.boolean(),
      // amount: yup.number().required("shared:price_required"),
      /**
      * https://krzysztofzuraw.com/blog/2020/yup-validation-two-fields
      * make field optional or required depending on another field.
      */
      verifiable_section_id: yup.string()
        .when(
          ['is_standard'],
          {
            is: is_standard => !Boolean(is_standard),
            then: yup.string()
              .required(
                'shared:verifiable_section_required'
              ),
          },
        ),
      name: yup.string().required("shared:field_required"),
      name_en: yup.string(),
      description: yup.string(),//.required('shared:field_required'),
    }),
    onSubmit: (values, actions) => onSubmit(values, actions),
    initialValues
  })

  useEffect(() => {
    if (!Boolean(form)) { return }
    setFormId(form.id)
    handleModalToggle()
    setInitivalValues({
      is_standard: form.is_standard || false,
      // name: form.title,
      name: form.title_fr ? form.title_fr : form.title,
      name_en: form.title_en,
      public: !!form.public,
      description: form.description,
      verifiable_section_id: (
        verifiable_sections.find(
          ({ id }) => id === form.verifiable_section_id
        ) || { name: '' }
      ).name,
    })

  }, [form])


  const saveFormToApi = (
    new_form, { setSubmitting, resetForm }
  ) => {

    const data: any = {
      title: new_form.name,
      title_en: new_form.name_en,
      amount: new_form.amount,
      description: new_form.description,
      // company_id,
      is_global: new_form.public,
      verifiable_section_id: new_form.is_standard ? undefined
        : verifiable_sections.find(
          ({ name }) => new_form.verifiable_section_id === name
        ).id
    }

    if (new_form.is_standard) {
      data.is_standard = new_form.is_standard
    }

    setSubmitting(true)
    let method
    if (Boolean(form_id)) {
      method = FormService.update_form(form_id, data)
    } else {
      method = FormService.new_form(data)
    }

    method
      .catch(err => {
        setSubmitting(false)
        new_notification({
          message: formatMessage({
            id: 'shared:unknown_error'
          }),
          variant: 'error',
          title: formatMessage({ id: 'shared:error' }),
        })
      })
      .then(async response => {
        setSubmitting(false)
        if (
          [200, 201]
            .includes(response.status)
        ) {
          const {
            data, message
          } = await response.json()
          handleModalToggle()
          if (Boolean(form_id)) {
            updateForm(data)
          } else {
            appendForms({ data: [data] })
          }
          new_notification({
            message,
            variant: 'success',
            title: formatMessage(
              { id: 'shared:success' }
            ),
          })
          resetForm(initialValues)
        } else if (response.json) {
          const {
            message
          } = await response.json()
          new_notification({
            message,
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            variant: 'error',
            title: formatMessage({ id: 'shared:error' }),
          })
        }
      })
  }


  const configureVerifiableSections = (
    sections: VerifiableSection[] = []
  ) => {
    if (form)
      setInitivalValues({
        ...initialValues,
        verifiable_section_id: sections.filter(
          ({ id }) => form.verifiable_section_id
            .includes(id)
        ).map(({ name }) => name)
      })
    const newFormFields = [...form_fields]
    newFormFields
      .splice(
        2,
        0,
        {
          name: "verifiable_section_id",
          type: "text",
          label: "verification:verifiable_sections",
          multiline: false,
          rows: 1,
          select_multi: false,
          required: true,
          select: true,
          options: sections.map(({ name }) => name)
        }
      )
    setUpdatedFormFields(newFormFields)
  }

  useEffect(() => {
    if (verifiable_sections.length < 1) { return }
    configureVerifiableSections(
      verifiable_sections
        .map(
          ({ name, id, is_active }) => ({ is_active, name, id })
        )
    )
  }, [verifiable_sections])

  return (
    <React.Fragment>
      {form && <Modal
        variant={ModalVariant.small}
        name={''}
        title={
          formatMessage({
            id: Boolean(form_id) ? "edit_form:edit_form" : "edit_form:add_form"
          })
        }
        isOpen={open}
        onClose={handleModalToggle}
        actions={[
          <Button
            isDisabled={
              Object.keys(formikValues.errors).length !== 0
              || formikValues.isSubmitting
              || Object.keys(formikValues.touched).length < 1
            }
            key="confirm" variant="primary"
            onClick={() => formikValues.submitForm()}
          >
            <FormattedMessage id="shared:save" />
            {formikValues.isSubmitting && <Spinner size={spinnerSize.md} />}
          </Button>,
          <Button
            key="cancel"
            variant="link"
            onClick={i => {
              handleModalToggle()
              formikValues.handleReset(i)
            }}
          >
            <FormattedMessage id="shared:cancel" />
          </Button>
        ]}
      //isFooterLeftAligned
      >
        <div className={styles.content}>
          <form
            className={styles.theme}
            onSubmit={formikValues.handleSubmit}>
            <CustomForm
              fields={updatedFormFields}
              {...formikValues}
            />
          </form>
        </div>
      </Modal>}
    </React.Fragment>
  );
}

export { EditForm as EditFormModal }

interface VerifiableSection {
  id: string,
  name: string,
  is_active: boolean,
}