import React from 'react';
import { Switch, Route } from "react-router-dom";

import {
  ContainerPage,
} from 'pages';
import {
  ROLE_ADMINISTRATOR,
} from 'constants/roles';

interface ContainerPageRouteProps {
}

const ContainerPageRoute: React.FC<ContainerPageRouteProps> = (props) => {
//@todo 
  return (
    <ContainerPage
      url='/me'
    />
  );
}

export default ContainerPageRoute