import React, { useState, useEffect } from 'react'
import styles from './DemandFilter.module.scss'
import {
  Modal, Button, TextInput,
  Select, SelectOption, SelectVariant, 
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
import { useUserData, useProvinceData, useFolderStatuses } from 'hooks';

export interface DemandFilterProps {
  readonly renderTrigger: any;
  readonly onDone: any;
}

export interface IMember {
  applicant: string,
  candidate_id: string,
  employment: string,
  status: boolean,
}

const validationSchema = yup.object().shape({
  applicant: yup.string(),
  candidate_id: yup.string(),
  employment: yup.string(),
  status: yup.string(),
})

export const DemandFilter: React.FC<DemandFilterProps> = ({
  renderTrigger,
  onDone,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [expandedField, setIsExapanded] = useState<string>('')
  const [loading] = useState<boolean>(false)
  const { users } = useUserData()
  const { provinces } = useProvinceData()
  const { folderStatus } = useFolderStatuses()

  const onFilter = (data, { resetForm }) => {
    let province
    if (data.province) province = provinces.find(prov => prov.name === data.province)!.id
    let contact_id
    if (data.contact_id) contact_id = users.find(user => user.username === data.contact_id)!.id
    let status
    if (data.status) status = folderStatus.find(status => status.name === data.status)!.id
    onDone({ ...data, province, status, contact_id })
    handleModalToggle()
  }

  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    handleReset,
    errors,
    handleSubmit,
  } = useFormik<IMember>({
    validationSchema,
    onSubmit: onFilter,
    initialValues: {
      applicant: '',
      candidate_id: '',
      employment: '',
      status: false,
    }
  })

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  };

  const handleSelectToggle = field => () => setIsExapanded(field)

  const onSelect = field => (event, selection) => {
    setIsExapanded('')
    setFieldValue(field, selection)
  }

  const clearSelection = field => () => {
    setFieldValue(field, [])
  }


  const options_status = folderStatus.map(({ id, name }) => (
    <SelectOption key={id} value={name} name={name} />
  ))

  options_status.unshift(
    <SelectOption isDisabled={true} isPlaceholder={true} key={'lksdflk'} value={formatMessage({ id: 'shared:select' })} name={'username'} />
  )

  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: "shared:demand_filter" })}
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        //isFooterLeftAligned
        className={styles.modal_root}
      >
        <form
          className={styles.content}
          onSubmit={handleSubmit}
        >
          <label className={styles.field}>
            <span
              className={errors.applicant && touched.applicant ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.applicant && touched.applicant ? errors.applicant : "shared:applicant"}
              />
            </span>
            <TextInput
              name='applicant'
              value={values.applicant}
              type="text"
              //css={{}}
              onChange={text => setFieldValue('applicant', text)}
              onBlur={handleBlur}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.candidate_id && touched.candidate_id ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.candidate_id && touched.candidate_id ? errors.candidate_id : "shared:candidate"}
              />
            </span>
            <TextInput
              name={'candidate_id'}
              value={values.candidate_id}
              onChange={text => setFieldValue('candidate_id', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.employment && touched.employment ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.employment && touched.employment ? errors.employment : "shared:employment"}
              />
            </span>
            <TextInput
              name={'employment'}
              value={values.employment}
              onChange={text => setFieldValue('employment', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
            />
          </label>
          <label
            htmlFor="status"
            className={styles.field}
          // style={{ gridColumn: 'span 2' }}
          >
            <span
              className={errors.status && touched.status ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.status && touched.status ? errors.status : "shared:status"}
              />
            </span>
            <Select
              variant={SelectVariant.single}
              onToggle={handleSelectToggle("status")}
              name="status"
              onSelect={onSelect("status")}
              onBlur={handleBlur}
              selections={values.status}
              isOpen={expandedField === "status"}
              placeholderText={formatMessage({ id: "shared:status" })}
              // ariaLabelledBy={'status'}
              onClear={clearSelection}
            >
              {options_status}
            </Select>
          </label>

          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={e => {
                handleReset(e)
                onDone(undefined)
                handleModalToggle()
              }}
              type="button"
            >
              <FormattedMessage id="shared:cancel_filter" />
            </button>
            <Button
              // isDisabled={Object.keys(touched).length < 1}
              type="submit"
            >
              <FormattedMessage id="shared:filter" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
