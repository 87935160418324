import { ObservableStore } from '@codewithdan/observable-store';
import {
    map,
    distinctUntilChanged,
} from 'rxjs/operators'
import {
    IProvince, PROVINCE_STORE_ACTIONS,
    IUser, USER_STORE_ACTIONS,
    IRole, ROLE_STORE_ACTIONS,
} from '.'


class AppStore extends ObservableStore<StoreState> {

    public static ACTIONS = {
        SWITCH_LANGUAGE: 'APP_STORE:SWITCH_LANGUAGE',
        INIT_STATE: 'INIT_STATE',
        CRUMB_RESET: 'CRUMB:RESET',

        ACCORDION_TITLES: 'CRUMB:ACCORDION_TITLES',
        APPEND: 'CRUMB:APPEND',
        ...PROVINCE_STORE_ACTIONS,
        ...USER_STORE_ACTIONS,
        ...ROLE_STORE_ACTIONS,
    }

    constructor() {
        super({ trackStateHistory: true });
        this.logout()
    }

    toggleLanguage = (language: 'en' | 'fr') => {
        localStorage['lang'] = language
        this.setState({ language }, AppStore.ACTIONS.SWITCH_LANGUAGE)
    }


    get language$() {
        return this.stateChanged.pipe(
            map(({ language }) => language),
            distinctUntilChanged(),
        )
    }


    //#region crumbs/accordion
    resetCrumbs = (crumbs: Crumb[]) => {
        this.setState(
            state => ({
                ...state,
                accordion: { ...state.accordion, crumbs, }
            }),
            AppStore.ACTIONS.CRUMB_RESET,
        )
    }

    resetAccordion = (data: Partial<StoreState["accordion"]>) => {
        this.setState(
            state => ({
                ...state,
                accordion: { ...state.accordion, ...data }
            }),
            AppStore.ACTIONS.CRUMB_RESET,
        )
    }


    appendCrumbs = (...crumbs: Crumb[]) => {
        this.setState(
            state => ({
                ...state,
                accordion: {
                    ...state.accordion,
                    crumbs: [...state.accordion.crumbs, ...crumbs],
                }
            }),
            AppStore.ACTIONS.CRUMB_RESET,
        )
    }

    /**
     * **************getter for everything accordion data.****************
     * Notice the distinctUntilChanged operator
     * used to ensure that it doesn't fire at all
     * https://rxjs-dev.firebaseapp.com/api/operators/distinctUntilChanged
     */
    get accordion$() {
        return this.stateChanged.pipe(
            map(({ accordion }) => accordion),
            distinctUntilChanged(),
        )
    }
    //#endregion crumbs and accordion


    //#region User
    resetUsers = (param: IStoreUser) => {
        this.setState(
            state => ({
                ...state,
                users: { ...param, data: param.data }
            }),
            AppStore.ACTIONS.RESET_USERS,
        )
    }

    appendUsers = (param: IStoreUser) => {
        this.setState(
            state => ({
                ...state,
                users: { ...param, data: [...param.data, ...(state.users.data || [])] },
            }),
            AppStore.ACTIONS.APPEND_USERS,
        )
    }

    updateUser = (user: IUser) => {
        const oldUserId = this.getState().users.data!.findIndex(cad => cad.id === user.id)
        if (oldUserId === -1) { return }

        /**At this point, users CANNOT be an empty array no undefined reference */
        const users = this.getState().users.data
        users![oldUserId] = user

        this.setState(
            state => ({
                ...state,
                users: { ...state.users, data: users },
            }),
            AppStore.ACTIONS.UPDATE_USER,
        )
    }


    deleteUsers = (...user_ids: string[]) => {

        /**At this point, users CANNOT be an empty array nor undefined reference */
        const usersData = this.getState().users.data.filter(
            ({ id }) => !user_ids.includes(id)
        )
        // usersData.splice(oldUserId, 1)

        this.setState(
            state => ({
                ...state,
                users: { ...state.users, data: usersData },
            }),
            AppStore.ACTIONS.DELETE_USER,
        )
    }

    /**
     * **************getter for everything users data.****************
     * Notice the distinctUntilChanged operator
     * used to ensure that it doesn't fire at all
     * https://rxjs-dev.firebaseapp.com/api/operators/distinctUntilChanged
     */
    get users$() {
        return this.stateChanged.pipe(
            map(({ users }) => users),
            distinctUntilChanged(),
        )
    }
    //#endregion candidates

    //#region provinces
    resetProvinces = (provinces: IProvince[]) => {
        this.setState(
            state => ({
                ...state,
                provinces,
            }),
            AppStore.ACTIONS.RESET_PROVINCES,
        )
    }

    //#region roles
    get roles$() {
        return this.stateChanged.pipe(
            map(({ roles }) => roles),
            distinctUntilChanged(),
        )
    }

    resetRoles = (roles: IRole[]) => {
        this.setState(
            state => ({
                ...state,
                roles,
            }),
            AppStore.ACTIONS.RESET_ROLES,
        )
    }

    /**
     * **************getter for everything provinces data.****************
     * Notice the distinctUntilChanged operator
     * used to ensure that it doesn't fire at all
     * https://rxjs-dev.firebaseapp.com/api/operators/distinctUntilChanged
     */
    get provinces$() {
        return this.stateChanged.pipe(
            map(({ provinces }) => provinces),
            distinctUntilChanged(),
        )
    }
    //#endregion candidates

    logout = () => {
        const defaultData = {
            data: [],
            start: 0,
            limit: 30,
            total: 0,
        }
        this.setState({
            accordion: {
                title: '',
                sub_title: '',
                show_crumbs: false,
                crumbs: []
            },
            language: localStorage['lang'] || 'en',
            users: defaultData,
            provinces: [],
        }, AppStore.ACTIONS.INIT_STATE)

    }

}

export default new AppStore()



export interface INotification {
    message: string;
    title: string;
    variant: 'warning' | 'error' | 'default' | 'success';
    duration?: number;
}

export interface Crumb {
    title: string;
    route_to?: string;
}

export interface IStoreUser {
    limit?: number,
    start?: number,
    total?: number,
    data: IUser[],
}


export interface StoreState {
    language: 'en' | 'fr',
    provinces: IProvince[],
    users: IStoreUser,
    accordion: {
        title: string,
        sub_title: string,
        crumbs: Crumb[],
        show_crumbs: boolean,
        shadow?:boolean,
    },
    roles: IRole[],
}