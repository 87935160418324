import React, { useState, useEffect } from 'react';
import styles from './ExternalForm.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { FormField } from 'pages/candidate/candidate_details_page/CandidateDetails.page';
import { FormService } from 'services';
import { Spinner, spinnerSize } from '@patternfly/react-core';
import { standard_form_categories, } from 'constants/standard_form'
import { CandidateForm, ListEmpty } from 'components';
import { ReactComponent as PreEmploiLogo } from 'assets/imgs/logo.svg'
import { Link } from 'react-router-dom';
import { useLanguageData } from 'hooks'

export interface ExternalFormProps extends RouteComponentProps {
  readonly dumm?: boolean;
}

const ExternalFormComponent: React.FC<ExternalFormProps> = ({
  //@ts-ignore
  match: { url, params: { form_id, token, candidate_id } },
  location: { search },
}) => {

  const { language } = useLanguageData()
  const { formatMessage } = useIntl();
  const { resetAccordion } = useAccordionData();
  const [
    questions, setQuestions
  ] = React.useState<FormField[]>([])
  const params = new URLSearchParams(search);
  const candidate_name = params.get('candidate_name')
  const interviewer = params.get('interviewer')
  const is_standard = ['True', 'true'].includes((params.get('is_standard') || 'false'))
  const editable = ['True', 'true'].includes((params.get('editable') || 'false'))

  const [
    forms, setForms
  ] = useState<
    {
      questionFields?: any, name: string, id: string
    }[]
  >([])
  const { new_notification } = useNotificationData()

  const [
    isNotEditing, setIsNotEditing
  ] = useState<boolean>(false)

  const [
    showSuccessMessage, setShowSuccessMessage
  ] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(true)
  const [message, setMessage] = useState<string>('')
  const [
    currentForm, setCurrentForm
  ] = React.useState<any>()

  useEffect(() => {
    resetAccordion({ title: formatMessage({ id: 'archives:list_of_archived_candidates' }) })
  }, [])

  useEffect(() => {
    // console.log({ can_id })
    fetchFormData(form_id)
  }, [])


  const switchCurrentForm = (form: any) => {
    setCurrentForm(form)
    const parsedQuestions = form.questionFields
      .flatMap(({ fields }) => fields)
      .map(
        ({ label: name, label_en:name_en, name: fieldName, value }) => {
          let returnVal = { name, name_en, fieldName, value }

          return returnVal
        })
    setQuestions(parsedQuestions)
  }


  const onFormSubmittion = (data, setSubmitting) => {
    let interviewee
    const updatedForm = {
      ...currentForm,
      questionFields: currentForm.questionFields!.map(
        (questionField) => ({
          ...questionField,
          fields: questionField.fields.map(
            field => {
              const key = Object.keys(data).find(k => k === field.name)
              if (key) {
                if (field.name === "interviewee") {
                  interviewee = data[key]
                }
                return { ...field, value: data[key] || "" }
              }
              return field
            }
          )
        })
      ),
      interviewee,
      completed: true,
    }

    // if (!Boolean(interviewee)) {
    //   new_notification({
    //     message: formatMessage({ id: 'shared:no_interviewee_prompt' }),
    //     title: formatMessage({ id: 'shared:no_interviewee' }),
    //     variant: 'error'
    //   })
    //   return
    // }

    submitCandidateFormToApi(
      {
        target_form_id: form_id,
        candidate_id: candidate_id,
        data: {
          ...updatedForm,
          // interviewee,
        },
        token,
      },
      (form_instance_id) => {
        setSubmitting(false)
        setIsNotEditing(!isNotEditing)
        //Update current questions and insert value
        setQuestions(
          questions.map(question => ({
            ...question,
            value: data[question.fieldName]
          }))
        )
        const formidx = forms.findIndex(({ id }) => id === currentForm.id)
        const newforms = [...forms]
        updatedForm.id = form_instance_id
        /**
         * As id has changed, update the currentForm var to the new currentForm 
         * with the new id, no need to update questions
         * as questions are identical
         */
        setCurrentForm(updatedForm)
        setShowSuccessMessage(true)
        newforms.splice(formidx, 1, updatedForm)
        setForms(newforms)
      },
      setSubmitting
    )
  }

  const submitCandidateFormToApi = (
    form, onDone, setSubmitting
  ) => {

    FormService.create_candidate_form_external(
      { ...form, data: { ...form.data, external: true, } }
    )
      .catch(err => {
        setSubmitting(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const {
            data: { id: restInstanceId }, message
          } = await response.json()
          setLoading(false)
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
          onDone(restInstanceId)
          setMessage(message)
          setShowSuccessMessage(true)
        } else if (
          response.json
        ) {
          setSubmitting(false)
          setLoading(false)
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          setSubmitting(false)
          setLoading(false)
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const fetchFormData = id => {
    if (is_standard && !editable) {
      loadStandardForm(
        id,
        formatMessage({ id: 'shared:standard_form' })
      )
      console.log({ is_standard })
      return
    }
    setLoading(true)
    FormService.getFormCategoryAndQuestions(id, candidate_id)
      .catch(err => {
        setLoading(false)
        setMessage(formatMessage({ id: 'shared:unknown_error' }))
        setError(true)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: 'shared:error' }),
          variant: 'error'
        })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const data = await response.json()
          switchCurrentForm(
            parseData(data)
          )
          setLoading(false)
          setMessage('')
          setError(false)
        } else if (response.json) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'error'
          })
          setMessage(message)
          setError(true)

          setLoading(false)
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: 'success',
            variant: 'success'
          })
          setMessage(formatMessage({ id: 'shared:unknown_error' }))
          setError(true)
          setLoading(false)
        }
      })
  }

  const loadStandardForm = (id, title) => {
    const standardForm = {
      categories: standard_form_categories,
      id,
      is_standard: true,
      title,
    }
    switchCurrentForm(
      parseData(standardForm)
    )
    setLoading(false)
    setError(false)
  }

  const parseData = data => {
    const interviewrCategory = {
      catName: formatMessage({ id: "shared:interviewer_info" }),
      fields: [
        {
          name: 'interviewee',
          type: 'text',
          label: "shared:interviewee" ,
          label_en: "shared:interviewee" ,
          multiline: false,
          rows: 1,
          value: '',
          required: true,
          select: false,
          switch: false,
          halfWidth: true,
          options: [],
        },
        {
          name: 'quest.id',
          type: 'text',
          label: "shared:technician" ,
          label_en: "shared:technician" ,
          multiline: false,
          rows: 1,
          value: interviewer,
          required: true,
          select: false,
          switch: false,
          halfWidth: true,
          options: [],
        }
      ]
    }

    const questionFields = data.categories.reduce(
      (acc, curr) => {
        const cat = {
          catName: curr.title,
          fields: curr.questions.flatMap(
            quest => {
              const returnValue = [
                {
                  name: quest.id,
                  type_justification: quest.type_justification,
                  type: quest.data.type,
                  label: quest.title,
                  label_en: quest.title_en ? quest.title_en : quest.title,
                  multiline: quest.data.multiline,
                  rows: quest.data.rows || 1,
                  required: quest.data.required,
                  select: quest.data.type === 'select',
                  switch: quest.data.type === 'switch',
                  halfWidth: !quest.data.fullwidth,
                  options: quest.answer.map(i => formatMessage({ id: i.value })),
                },
              ]

              /**
               * parse question to know if justification is required
               * and add the corresponding question field to be parsed out
               * in the pdf form through the unique attribute added here.
               */
              if (Boolean(quest.type_justification)) {
                returnValue.push({
                  name: `${quest.id}_justification`,
                  type: 'textarea',
                  label: "shared:justify_above_response",
                  multiline: true,
                  rows: 3,
                  required: false,
                  select: false,
                  switch: false,
                  halfWidth: true,
                  options: [],
                  //@ts-ignore
                  justification_field: true,
                })
              }

              return returnValue
            })
        }
        acc.push(cat)
        return acc
      },
      []
    )

    /**Add interviewer in the list */
    questionFields.push(
      interviewrCategory
    )

    return {
      questionFields,
      name: data.title,
      id: data.id,
    }
  }

  return (
    <div className={styles.wrapper} >
      <Link className={styles.header} to={"/auth/login"}>
        <PreEmploiLogo className={styles.logo} />
      </Link>
      <div className={styles.container} >
        {!error && !loading && !showSuccessMessage && (
          <>
            {Boolean(candidate_name) && (
              <h1 className={styles.candidate_name}>
                <FormattedMessage id="shared:candidate_name" /> : {candidate_name}
              </h1>
            )}
            <CandidateForm
              onSave={onFormSubmittion}
              minimalist={true}
              onlyEditingMode={true}
              fields={currentForm.questionFields.filter(question => question.fields.length > 0)}
              name={currentForm.name}
              questions={
                questions.reduce((acc, field, idx, arr) => ({
                  ...acc,
                  [field.fieldName]: field.value,
                  [field.name]: language == "en" ? (field.name_en ? field.name_en : field.name) : field.name
                }), {})
              }
            />
          </>
        )}

        {loading && !showSuccessMessage && (
          <div className={styles.container_loader} >
            <Spinner size={spinnerSize.lg}></Spinner>
          </div>
        )}

        {showSuccessMessage && !loading && (
          <ListEmpty
            title="shared:form_submitted"
            message={message}
          />
        )}

        {error && !loading && !showSuccessMessage && (
          <ListEmpty
            title="shared:error_prompt"
            message={message || "shared:error"}
          />
        )}

      </div>
    </div>
  )
}

export {
  ExternalFormComponent as ExternalFormPage,
}
