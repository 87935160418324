export interface IDemand {
    id: string,
    email: string,
    is_reopened?: boolean,
    reopened_info?: { username: string, date: Date },
    employment: string,
    applicant_id: string,
    applicant: {
        firstName: string,
        lastName: string,
    },
    candidate: {
        firstname: string,
        lastname: string,
        name: string,
        contact_name: string,
        id: string,
        phone_number: string,
        post_number: string,
        driving_license_number: string,
        adress: string,
        province: string,
    },
    status: number,
    comments: string,
    pdf_link: string,
    date: number,
    update_at: number,
    full_plumitif: boolean,
    criminal_penal_plumitif: boolean,
    academic_training: boolean,
    credit_verification: boolean,
    old_job_references: boolean,
    professional_associations: boolean,
    authorization_form: string,
    id_papers: string,
    diplomas_copy: string,

    driver_license: boolean,
    cipc: boolean,
    autorizations: string,
    cv: string,
    references: string,
    demand_pdf: string,
    is_idqc_request?: boolean,

}


export const DEMAND_STORE_ACTIONS = {
    APPEND_DEMANDS: 'DEMAND:APPEND',
    RESET_DEMANDS: 'DEMAND:RESET',
    DELETE_DEMAND: 'DEMAND:DELETE',
    UPDATE_DEMAND: 'DEMAND:UPDATE',
}