import React, { useState, useEffect } from 'react'
import styles from './ActivatedCompanyFilter.module.scss'
import {
  Modal, Button, TextInput, Switch,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
import { useUserData, useProvinceData, useFolderStatuses } from 'hooks';

export interface ActivatedCompanyFilterProps {
  readonly renderTrigger: any;
  readonly onDone: any;
}

export interface IActivatedCompanyFilter {
  name: string,
  has_departments: boolean,
  has_candidates: boolean,
  has_incomplete_demands: boolean,
  has_complete_demands: boolean,
}

export const ActivatedCompanyFilter: React.FC<ActivatedCompanyFilterProps> = ({
  renderTrigger,
  onDone,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const [_, setIsExapanded] = useState<string>('')
  const [loading] = useState<boolean>(false)
  // const { loading: loadingGroups, message: messageGroups, groups } = useQueryGroups()
  const { users } = useUserData()
  const { provinces } = useProvinceData()
  const { folderStatus } = useFolderStatuses()

  const onFilter = (data, { resetForm }) => {
    let province
    if (data.province) province = provinces
      .find(prov => prov.name === data.province)!.id
    let contact_id
    if (data.contact_id) contact_id = users
      .find(user => user.username === data.contact_id)!.id
    let folder_status
    if (data.folder_status) folder_status = folderStatus
      .find(status => status.name === data.folder_status)!.id
    onDone({ ...data, province, folder_status, contact_id })
    handleModalToggle()
  }

  const {
    setFieldValue,
    values,
    touched,
    handleBlur,
    handleReset,
    errors,
    handleSubmit,
  } = useFormik<IActivatedCompanyFilter>({
    // validationSchema,
    onSubmit: onFilter,
    initialValues: {
      name: '',
      has_departments: false,
      has_complete_demands: false,
      has_incomplete_demands: false,
      has_candidates: false,
    }
  })

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  };

  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={
          <span className={styles.header_title}>
            {formatMessage({ id: "shared:activated_company_filter" })}
          </span>
        }
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        isFooterLeftAligned={false}
        className={styles.modal_root}
      >
        <form
          className={styles.content}
          onSubmit={handleSubmit}
        >
          <label className={styles.field}>
            <span
              className={errors.name && touched.name ? styles.c_error : ''}
            >
            </span>
            <TextInput
              name='name'
              value={values.name}
              placeholder={formatMessage({ id: "shared:name" })}
              type="text"
              //css={{}}
              onChange={text => setFieldValue('name', text)}
              onBlur={handleBlur}
            />
          </label>
          <label className={styles.field} >
            <span
              className={errors.has_departments && touched.has_departments ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.has_departments && touched.has_departments ? errors.has_departments : "shared:has_departments"}
              />
            </span>
            <Switch
              isChecked={values.has_departments}
              onChange={checked => setFieldValue('has_departments', checked)}
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.has_candidates && touched.has_candidates ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.has_candidates && touched.has_candidates ? errors.has_candidates : "shared:has_candidates"}
              />
            </span>
            <Switch
              // id="no-label-switch-on"
              aria-label="Message when on"
              isChecked={values.has_candidates}
              onChange={checked => setFieldValue('has_candidates', checked)}
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.has_incomplete_demands && touched.has_incomplete_demands ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.has_incomplete_demands && touched.has_incomplete_demands ? errors.has_departments : "shared:has_incomplete_demands"}
              />
            </span>
            <Switch
              // id="no-label-switch-on"
              aria-label="Message when on"
              isChecked={values.has_incomplete_demands}
              onChange={checked => setFieldValue('has_incomplete_demands', checked)}
            />
          </label>
          <label className={styles.field}>
            <span
              className={errors.has_complete_demands && touched.has_complete_demands ? styles.c_error : ''}
            >
              <FormattedMessage
                id={errors.has_complete_demands && touched.has_complete_demands ? errors.has_departments : "shared:has_complete_demands"}
              />
            </span>
            <Switch
              // id="no-label-switch-on"
              aria-label="Message when on"
              isChecked={values.has_complete_demands}
              onChange={checked => setFieldValue('has_complete_demands', checked)}
            />
          </label>
          <div className={styles.actions}>
            <button
              className={styles.btn}
              onClick={e => {
                handleReset(e)
                onDone(undefined)
                handleModalToggle()
              }}
              type="button"
            >
              <FormattedMessage id="shared:cancel_filter" />
            </button>
            <Button
              // isDisabled={Object.keys(touched).length < 1}
              type="submit"
            >
              <FormattedMessage id="shared:filter" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>

        </form>
      </Modal>
    </>
  );
}
