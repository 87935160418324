import React, { } from 'react';
import styles from './ConfirmDialog.module.scss'
import { Modal, Button, Spinner, spinnerSize } from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'

export interface ConfirmDialogProps {
  title_message?: string
  confirmation_message?: string;
  onModalClose: any
  isModalOpen: boolean
  loading?: boolean,
  disabled?: boolean,
  actionAlignLeft?: boolean,
  onAction: any,
  actionText?: string

}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  confirmation_message,
  isModalOpen,
  title_message = "shared:delete",
  onModalClose,
  loading = false,
  onAction,
  children,
  disabled = false,
  actionAlignLeft = true,
  actionText = "shared:delete",
  ...props
}) => {

  const { formatMessage } = useIntl()

  return (
    <>
      <Modal
        //@ts-ignore
        title={<span className={styles.header_title}>{formatMessage({ id: title_message })}</span>}
        isOpen={isModalOpen}
        onClose={onModalClose}
        //isFooterLeftAligned={actionAlignLeft}
        className={styles.modal_root}
        width={'70%'}
        actions={[
          <div className={styles.footer}>
            <Button key="cancel" variant="secondary" onClick={onModalClose}>
              <FormattedMessage id="shared:cancel" />
            </Button>
            <Button
              key="confirm"
              variant="primary"
              onClick={onAction}
              isDisabled={loading || disabled}
            >
              <FormattedMessage id={actionText} />
              {loading && (
                <Spinner
                  size={spinnerSize.md}
                />
              )}
            </Button>
          </div>
        ]}
      >
        {confirmation_message && formatMessage({ id: confirmation_message })}
        {children && children}
      </Modal>
    </>
  );
}
