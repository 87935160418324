import * as React from 'react';
import styles from './AddEditQuestion.module.scss'
import { Button, Modal, Spinner, spinnerSize, ModalVariant } from '@patternfly/react-core';
import { CustomForm } from 'components/forms/CustomForm';
import { IQuestionType, questions_fields } from 'constants/example';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNotificationData, useUserInfoData } from 'hooks';
import { QuestionService } from 'services';
import { ILoginInfo } from 'stores/auth.store';

export interface AddEditQuestionProps {
  readonly open: boolean;
  readonly OpenCloseModal: (val) => void;
  readonly question: IQuestionType;
  readonly form_id?: string;
  readonly category_id?: string;
  readonly category_name?: string;
  readonly form_name?: string;
  readonly onDone: any;
  readonly company_id?: string;
}

/**
 * Modal Pour l'edition d'un formulaire
 * @param open: variable, vrai si modal est ouvert/ et faux si fermé
 * @param OpenCloseModal: Fonction, permet de fermer/ouvrir le modal
 * @param question: variable, contient le formulaire à editer 
 */
const AddEditQuestion: React.FC<AddEditQuestionProps> = ({
  form_id,
  category_id,
  // category_name, form_name,
  onDone,
  ...props
}) => {

  const { open, OpenCloseModal, question } = props;
  const fields = questions_fields
  const { formatMessage } = useIntl()
  const { new_notification } = useNotificationData()
  const { userInfo: { company_id } } = useUserInfoData() as { userInfo: ILoginInfo }

  //Ouvrir/Fermer le modal
  const handleModalToggle = () => {
    OpenCloseModal(question);
  };

  let validationSchema: any = {}
  let initialValues: any = {}

  fields.forEach(field => {
    if (field.required) {
      validationSchema[field.name] = yup.string().required(formatMessage({ id: "shared:field_required" }))
    }
    initialValues[field.name] = (question && question[field.name]) ? question[field.name] : '';
  })

  const saveQuestionToApi = (
    data,
    { resetForm }
  ) => {
    const {
      type_options, value, title_en, add_description, description,
      halfWidth, type, required = true, fullwidth = true,
      type_justification = false,
      ...rest
    } = data
    console.log({ rest })
    const question_data: any = {
      // ...data, 
      company_id,
      is_global: false,
      title: value,
      title_en: title_en,
      data: {
        description: add_description ? description : undefined,
        halfWidth, type, fullwidth, required,
      },
      answer: type_options,
      type_justification,//: Boolean(type_justification)
    }

    if (`${category_id}` === `0` || Boolean(category_id)) {
      question_data.category_id = category_id
      question_data.form_id = form_id
    }

    let method
    if (question.id === '' || question.id === undefined) {
      method = QuestionService.new_category_question(question_data)
    } else {
      method = QuestionService.update_question(question.id, question_data)
    }

    method
      .catch(err => {
        formikValues.setSubmitting(false)
      })
      .then(async response => {
        formikValues.setSubmitting(false)
        if ([200, 201].includes(response.status)) {
          const { message, data } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: 'shared:success' }),
            variant: 'success'
          })
          formikValues.setSubmitting(false)
          onDone(data)
          resetForm(initialValues)

          if (question.id !== '') { handleModalToggle() }
        } else if (response.json) {
          const { message } = await response.json()
          formikValues.setSubmitting(false)
          new_notification({
            message,
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        } else {
          formikValues.setSubmitting(false)

          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: 'shared:error' }),
            variant: 'error'
          })
        }
      })
  }

  const formikValues = useFormik<{}>({
    enableReinitialize: true,
    validationSchema: yup.object().shape(validationSchema),
    onSubmit: saveQuestionToApi,
    initialValues: {
      ...question,
      description: "",
      add_description: false,
      halfWidth: false
    }
  })

  return (
    <React.Fragment>
      {question && (
        <Modal
          variant={ModalVariant.small}
          name={''}
          title={formatMessage({ id: (question && question.id) ? "new_question:edit" : "new_questions:add" })}
          isOpen={open}
          onClose={handleModalToggle}
          actions={[
            <Button key="confirm" variant="primary"
              type={'submit'}
              onClick={() => formikValues.submitForm()}
              isDisabled={formikValues.isSubmitting}
            >
              <FormattedMessage id="shared:save" />
              {formikValues.isSubmitting && <Spinner size={spinnerSize.md} />}
            </Button>,
            <Button
              //  className={styles.color_primary} 
              key="cancel" variant="link" onClick={handleModalToggle}>
              <FormattedMessage id="shared:cancel" />
            </Button>
          ]}
          //isFooterLeftAligned
          height={500}
        >
          <div className={styles.content}>
            <form
              className={styles.theme}
              onSubmit={formikValues.handleSubmit}>
              <CustomForm
                fields={questions_fields}
                {...formikValues}
              />
            </form>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}

export { AddEditQuestion as AddEditQuestionModal }
