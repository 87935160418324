import React, { useState, useContext, useEffect } from 'react';
import styles from './category_detail.module.scss';
import { useAccordionData, useNotificationData } from 'hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { Gallery, GalleryItem, Button, TextInput, Spinner, spinnerSize } from '@patternfly/react-core';
import { GridCard, AccordionContext, ListEmpty } from 'components';
import { RouteComponentProps } from 'react-router';
import { IQuestionType, } from 'constants/example';
import { EllipsisVIcon } from '@patternfly/react-icons';
import { AddEditQuestionModal } from 'components/modals/add_edit_question/AddEditQuestion.modal';
import { CategoriesService, QuestionService } from 'services';
import { ConfirmDialog, ReorderQuestions } from 'dialogs';

export interface CategoryDetailProps extends RouteComponentProps {
  readonly dumm?: boolean;
}


export const initial_question: IQuestionType = {
  id: '',
  description: '',
  value: '',
  title_fr: '',
  type: '',
  required: false,
}

/**
 * Pour les details d'un formulaire
 * @param props 
 */
const CategoryDetailComponent: React.FC<CategoryDetailProps> = ({
  // match:{params},
  ...props
}) => {

  const { formatMessage } = useIntl();
  const { resetAccordion, } = useAccordionData();
  //@ts-ignore
  const { id, category_id } = props.match.params;
  const [open_modal, setopen_modal] = useState(false);
  const { setBottomComponent } = useContext(AccordionContext);
  const [question, setquestion] = useState<IQuestionType>(initial_question);
  const [questions, setQuestions] = useState<IQuestionType[]>([])
  const [selectedElements, setSelectedElements] = useState<string[]>([])
  const [query, setQuery] = useState<string>('')
  const { new_notification } = useNotificationData()
  const [loading, setLoading] = useState<boolean>(false)
  const [toDelete, setToDelete] = useState<string[]>([])
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)

  //Pour Ouvrir/Fermer le modal d'edition d'un formulaire
  const OpenCloseModal = (new_question = initial_question as any) => {
    setopen_modal(Boolean(!open_modal));
    console.log({ open_modal })
    setTimeout(() => {
      console.log({ open_modal })

    })

    //@ts-ignore
    if (Boolean(question.data)) {
      const reversedQuestionStructure = reverseQuestionStructureForform(new_question)
      //@ts-ignore
      setquestion(reversedQuestionStructure)

    }
    //Selection d'une question
    setquestion(new_question);
  }

  const configureAccordion = (
    { title, description, form_id, form_name }
  ) => {
    let crumbs = [
      {
        route_to: "/me/forms", title: formatMessage({ id: 'shared:forms' })
      },
      {
        route_to: "/me/forms/" + form_id, title: form_name || "dummy"
      },
      {
        route_to: "/me/forms/" + id + "/categories/" + category_id, title: title || "dummy"
      },
    ]
    if (!Boolean(form_name))
      crumbs = [
        {
          route_to: "/me/forms/" + id + "/categories/" + category_id, title: title || "dummy"
        },
      ]
    //Pour l'en tete d'une page du dashboard
    resetAccordion({
      title: title,//Titre
      sub_title: description,
      show_crumbs: true,//Afficher les niveaux de navigation
      crumbs,
    })
  }

  useEffect(() => {
    if (questions.length > 0) {
      const question = questions[questions.length - 1]
      configureAccordion({
        title: question.category_name,
        form_name: question.form_name,
        form_id: question.form_id,
        description: ''
      })
    }

    return () => {
      resetAccordion({
        title: '',//Titre
        sub_title: '',
        show_crumbs: false,//Afficher les niveaux de navigation
        crumbs: []
      })

    }
  }, [questions])

  React.useEffect(() => {
    if (!loading && !loadingDelete && category_id) {
      fetchCategoryQuestions(category_id)
    }

  }, [])

  React.useEffect(() => {
    if (id && category_id) {
      setBottomComponent(
        <div className={styles.bottomComponent}>
          <Button
            className={styles.btn_primary}
            onClick={() => setopen_modal(true)}
            variant="primary">
            <FormattedMessage id="shared:new_question" />
          </Button>
          <EllipsisVIcon />
          <TextInput
            placeholder={formatMessage({ id: "shared:search" })}
            type="text"
            onChange={setQuery}
            aria-label="Recherche"
          />
          {questions.length > 1 && (
            <ReorderQuestions
              category_id={category_id}
              data={questions}
              onClose={handleReorderQuestions}
              renderTrigger={_ => (
                <Button
                  className={styles.btn_primary}
                  onClick={_}
                  variant="primary">
                  <FormattedMessage id="shared:reorder" />
                </Button>
              )}
            />
          )}
        </div>
      )
    }

    return () => {
      setBottomComponent(<span></span>)
    }
  }, [questions]);

  const handleReorderQuestions = (items: any[]) => {
    const newQuestions = items.map(_ => (
      questions.find(it => it.id === _.id)!
    ))
    setQuestions(newQuestions)
  }

  //Liste des menus actions
  const menus = paramQuestion => ([
    {
      id: '1',
      action: () => OpenCloseModal(paramQuestion),
      name: formatMessage({ id: "shared:edit" }),
    },
    {
      id: '2',
      action: () => setToDelete([paramQuestion.id]),
      name: formatMessage({ id: 'shared:delete' }),
    },
  ])


  const fetchCategoryQuestions = (catId) => {
    setLoading(true)
    CategoriesService.list_category_questions(catId)
      .catch(err => {
        setLoading(false)
        new_notification({
          message: formatMessage({ id: 'shared:unknown_error' }),
          title: formatMessage({ id: "shared:error" }),
          variant: 'error',
        })
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const results = await response.json()
          addNewQuestions(...results)
        } else if ([400, 500, 422, 401, 409, 404, 405].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

        }

      })
  }



  const onSelect = id => {
    const idx = selectedElements.findIndex(f => f === id)

    if (idx !== -1) {
      const newList = [...selectedElements]
      newList.splice(idx, 1)
      setSelectedElements(newList)
      return
    }
    setSelectedElements([...selectedElements, id])
  }

  const addNewQuestions = (...newQuestions: any[]) => {
    const parsedQuestions = newQuestions.flatMap(newQuestion => {
      console.log({ newCat: newQuestion })
      const {
        company_id, form_id,
        category_id, is_global,
        title: value, id,
        data: {
          fullwidth, required,
          description, halfWidth, type,
        },
        answer: type_options,
        ...rest
      } = newQuestion

      const parsedQuestion: IQuestionType = {
        type_options, value, id, form_id,
        category_id, is_global, company_id,
        add_description: Boolean(description),
        description, halfWidth, type,
        fullwidth, required, ...rest,
      }
      return parsedQuestion
    })
    setQuestions(
      parsedQuestions
        .concat(
          questions.filter(
            quest => !newQuestions.map(newQuest => newQuest.id).includes(quest.id)
          )
        )
    )
  }

  const reverseQuestionStructureForform = (question: IQuestionType) => {
    const {
      type_options, value, id, form_id,
      category_id, is_global, company_id,
      add_description,
      description, halfWidth, type,
      fullwidth, required, ...rest
    } = question

    const parsedQuestion = {
      company_id, form_id,
      category_id, is_global,
      value, id,
      data: {
        fullwidth, required,
        description, halfWidth, type,
        add_description,
      },
      answer: type_options,
      ...rest
    }

    return parsedQuestion
  }


  const deleteQuestionFromApi = (...question_ids: string[]) => {
    setLoadingDelete(true)
    QuestionService.removeCategoryFromQuestion(
      category_id, { question_ids }
    )
      .catch(err => {
        setLoading(false)
      })
      .then(async response => {
        setLoading(false)
        if ([200, 201].includes(response.status)) {
          const { message } = await response.json()
          new_notification({
            message,
            title: 'success',
            variant: 'success'
          })
          setQuestions(
            questions.filter(({ id }) => !question_ids.includes(id))
          )
          setToDelete([])
          setLoadingDelete(false)
        } else if (response.json) {
          const { message } = await response.json()
          setLoadingDelete(false)
          new_notification({
            message,
            title: formatMessage({ id: "shared:error" }),
            variant: 'error'
          })
        } else {
          new_notification({
            message: response.message ? response.message : formatMessage({ id: 'shared:unknown_error' }),
            title: formatMessage({ id: "shared:error" }),
            variant: 'error',
          })

        }

      })
  }




  return (
    <div className={styles.container} >
      <AddEditQuestionModal
        OpenCloseModal={OpenCloseModal}
        // question={question}
        question={{...question, value: question.title_fr ? question.title_fr : question.value}}
        form_id={id}
        onDone={newQuestion => addNewQuestions(newQuestion)}
        category_id={category_id}
        company_id={undefined}
        open={open_modal}
      />

      <ConfirmDialog
        confirmation_message="shared:do_you_want_to_continue"
        onAction={_ => deleteQuestionFromApi(...toDelete)}
        isModalOpen={toDelete.length > 0}
        loading={loadingDelete}
        onModalClose={_ => setToDelete([])}
      />

      {!loading && questions.filter(quest => quest.value.indexOf(query) !== -1).length === 0 && (
        <ListEmpty />
      )}
      {loading && (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Spinner size={spinnerSize.md} />
        </div>
      )}
      {<Gallery
        hasGutter
      >
        {questions
          .filter(quest => quest.value.indexOf(query) !== -1)
          .map(question => (
            <GalleryItem span={4}>
              {/**Grid Formulaire*/}
              <GridCard
                form={{
                  ...question,
                  title: question.value,
                  description_jsx: (<>
                    <br />
                    <h3>
                      <FormattedMessage id="shared:type" />
                    </h3>
                    <span>{question.type}</span>
                    <br /><br />
                    <h3>
                      <FormattedMessage id="shared:description" />
                    </h3>
                    <span>{question.description || formatMessage({ id: "shared:n_a" })}</span>
                  </>)
                }}
                enableCheckbox={false}
                selectedElements={selectedElements}
                OpenCloseModal={OpenCloseModal}
                root_url={`${props.location.pathname}/categories/${question.id}`}
                selected={true}
                {...props}
                btn_delete={false}
                header_border={true}
                footer={false}
                menus={menus(question)}
                setform={setquestion}
                onSelect={onSelect}
              />
            </GalleryItem>
          ))}
      </Gallery>}
      {/**Fin Listing des Formulaires  */}
    </div>
  );
}

export {
  CategoryDetailComponent as CategoryDetailPage,
}
