import * as React from 'react';
import styles from './List.module.scss'
import { SimpleList, SimpleListItem, Checkbox } from '@patternfly/react-core';

export interface ListProps {
  readonly dumm?: boolean;
  readonly checkall?: boolean;
  readonly list: any[];
  readonly searchString: string;
  readonly final_mode: boolean,
  readonly single?: boolean,
  readonly setSelectedCandidateIds: any,
  readonly selectedContacts: string[],
  readonly setSelectedContacts: any,
}

/**
 * List Component
 */
const List: React.FC<ListProps> = ({
  setSelectedCandidateIds,
  final_mode,
  selectedContacts,
  setSelectedContacts,
  searchString,
  single,
  ...props
}) => {

  const { checkall, list } = props;

  //Pour selectionner un element
  const handleSelect = (item, currentItemProps) => {
    if (single) {
      setSelectedContacts([item.id])
      return
    }
    let tab = selectedContacts.filter(cur => cur === item.id);
    if (tab.length > 0) {
      setSelectedContacts(selectedContacts.filter(cur => cur !== item.id))
    } else {
      setSelectedContacts([...selectedContacts, item.id]);
    }
  }

  //Construction de la liste
  const items = list.filter(i => {
    if (searchString !== '' && !final_mode) {
      return `${i.firstname} ${i.lastname}`.indexOf(searchString) !== -1
    }
    return true
  }).map((item, i) => {
    return (
      <SimpleListItem className={styles.simpleListItem} key={`${item.id}`} >
        <div
          className={styles.item}
          onClick={e => {
            console.log({ item })
            if (final_mode) {
              setSelectedCandidateIds(item.id)
            } else {
              handleSelect(item, undefined)
            }
          }}
        >
          <Checkbox
            checked={Boolean(checkall || selectedContacts.includes(item.id))}
            className={styles.checkbox} id={`id${item.id}`}

          />
          <span className={styles.title}>{`${item.firstname} ${item.lastname}`}</span>
        </div>
      </SimpleListItem>
    )
  })

  return (
    <React.Fragment>
      <SimpleList className={styles.list} aria-label="Simple List Example">
        {items}
      </SimpleList>
    </React.Fragment>
  );
}

export { List as ListCustom }; 