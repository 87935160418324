import * as React from 'react';
import {
  ContainerPageRoute,
  AuthPageRoute,
} from 'routes';
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  PdfPagePage, NewCompanyPage,
  NewDemandExternalPage, DenyFillingFormPage,
  ExternalFormPage, ExternalPdfPage
} from 'pages';
import { AuthService } from 'services';
import { useHistory } from 'react-router';
import { useUserInfoData, useUserRoleData } from 'hooks';

export interface EntryPageProps {
}

export const EntryPage: React.FC<EntryPageProps> = props => {
  const history = useHistory()

  const { saveUserInfo } = useUserInfoData()
  const { resetUserRoles } = useUserRoleData()


  /**
   * For all redirects
   * https://codedaily.io/tutorials/78/Create-a-Query-Param-Login-Modal-Route-with-React-Router
   */
  const loadCurrentUserData = () => {
    AuthService.currentUser(
      localStorage['refresh_token'] || " "
    )
      .catch(err => {
        history.push({ search: `?redirect=${history.location.pathname}`, pathname: `/auth/login` })
      })
      .then(async response => {
        if ([200, 201].includes(response.status)) {
          const {
            is_admin,
            is_contact,
            is_technicien,
            is_manager,
            is_accountant,
            token,
            ...results
          } = await response.json()

          // if(Boolean(token))

          if (!Boolean(results)) {
            history.push({ search: `?redirect=${history.location.pathname}`, pathname: `/auth/login` })
          }

          let resRoles: string[] = []
          if (is_admin) resRoles.push('Administrator')
          if (is_accountant) resRoles.push("Accountant")

          if (is_technicien) resRoles.push('Technician')
          if (is_manager) resRoles.push("Company_Administrator")
          else if (is_contact) resRoles.push('Contact')

          resetUserRoles(resRoles)
          saveUserInfo({
            roles: resRoles,
            is_admin, is_contact, is_manager,
            is_technicien, ...results
          })
        } else {
          history.push({ search: `?redirect=${history.location.pathname}`, pathname: `/auth/login` })
        }
      })
  }

  // Here is where we define if a route can be accessed without authorization()
  React.useEffect(() => {
    console.log({ location: history.location.pathname, idx: history.location.pathname.indexOf('new') })
    if (
      history.location.pathname.indexOf('new') === 1 ||
      history.location.pathname.indexOf('recover') !== -1
    || history.location.pathname.indexOf('external') !== -1
    ) { return }
    loadCurrentUserData()
  }, [])

  return (
    <>
      <Switch>
        <Route
          path={'/me'}
          render={renderProps => <ContainerPageRoute {...renderProps} />}
        />
        <Route
          path={'/auth'}
          render={renderProps => <AuthPageRoute url='/auth' {...renderProps} />}
        />
        <Route
          path={'/report/:candidate_id/:language'}
          render={renderProps => <PdfPagePage {...renderProps} />}
        />
        <Route
          path={'/external/report/:candidate_id/:language/:token'}
          render={renderProps => <ExternalPdfPage {...renderProps} />}
          exact={true}
        />
        <Route
          path={'/new/demand_form/:candidate_id/:form_id/:token'}
          render={renderProps => <ExternalFormPage {...renderProps} />}
        />
        <Route
          path={'/new/demand_form/deny/:token'}
          render={renderProps => <DenyFillingFormPage {...renderProps} />}
        />
        <Route
          path={'/new/company/:token'}
          render={renderProps => <NewCompanyPage {...renderProps} />}
        />
        <Route
          path={'/new/demand/:token'}
          exact={true}
          render={renderProps => <NewDemandExternalPage {...renderProps} />}
        />
        <Redirect
          to="/auth"
        />
      </Switch>
    </>
  );
}
