import React, { useState } from 'react';
import styles from './UserFilter.module.scss'
import {
  Modal, TextInput, Form, Radio,
  Button,
} from '@patternfly/react-core';
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'classnames'
import * as yup from 'yup'
import { Spinner } from '@patternfly/react-core';
import { useFormik } from 'formik';
// import 'react-day-picker/lib/style.css';
import { useNotificationData } from 'hooks';

export interface UserFilterProps {
  readonly renderTrigger: any;
  formState: IMember;
  setFormState: (state: IMember) => void;
}

export interface IMember {
  search_name: string,
  email: string,
  is_administrator: boolean,
  is_technician: boolean,
  is_contact: boolean,

}

const validationSchema = yup.object().shape({
  search_name: yup.string(),
  email: yup.string(),
  is_administrator: yup.boolean(),
  is_technician: yup.boolean(),
  is_contact: yup.boolean(),
})

export const UserFilter: React.FC<UserFilterProps> = ({
  renderTrigger,
  formState,
  setFormState,
  ...props
}) => {
  // Open/Close modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  // Use for translation
  const { formatMessage } = useIntl()
  // use to show loader
  const [loading] = useState<boolean>(false)

  const onNewMember = (data, { resetForm }) => {
  }

  // handle changes on form
  const {
    setFieldValue,
    values,
    handleBlur,
    handleSubmit,
    resetForm
  } = useFormik<IMember>({
    validationSchema,
    onSubmit: onNewMember,
    initialValues: formState
  })

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  };

  // Handle radio click
  const onFieldChanged = field => checked => {
    setFieldValue(field, checked);
    if(field !== "is_administrator") setFieldValue("is_administrator", false);
    if(field !== "is_technician") setFieldValue("is_technician", false);
    if(field !== "is_contact") setFieldValue("is_contact", false);
  }

  // handle form submission
  const saveMemberToApi = e => {
    setFormState(values)
    handleModalToggle()
  }

  const handleCancel = e => {
    handleModalToggle();
    values.search_name = ''
    values.email = ''
    values.is_administrator = false
    values.is_technician = false
    values.is_contact = false
    setFormState({
      search_name: '',
      email: '',
      is_administrator: false,
      is_technician: false,
      is_contact: false,
    })
    resetForm();
  }

  return (
    <>
      {renderTrigger(handleModalToggle)}
      <Modal
        //@ts-ignore
        title={<span className={styles.header_title}>{formatMessage({ id: "shared:filter" })}</span>}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        //isFooterLeftAligned
        className={styles.modal_root}
      >
        <Form
          className={styles.content}
          onSubmit={handleSubmit}
        >
          <span className={styles.group}>
            <FormattedMessage id="profile:basic_info" />
          </span> 
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:email"/>
            </span>
            <TextInput
              name={'email'}
              value={values.email}
              onChange={text => setFieldValue('email', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
              placeholder={formatMessage({ id: "shared:email" })}
            />
          </label>
          <label className={styles.field}>
            <span>
              <FormattedMessage id="shared:search_name"/>
            </span>
            <TextInput
              name={'search_name'}
              value={values.search_name}
              onChange={text => setFieldValue('search_name', text)}
              onBlur={handleBlur}
              type="text"
              //css={{}}
              aria-label="text input example"
              placeholder={formatMessage({ id: "shared:find" })}
            />
          </label>
          <span className={styles.group}>
            <FormattedMessage id="user_filter:user_type" />
          </span>
          <div className={styles.radio}>
            <Radio isLabelBeforeButton id="is_administrator" isChecked={values.is_administrator}
              onChange={onFieldChanged('is_administrator')} label={formatMessage({ id: 'shared:administrator' })}
              name={formatMessage({ id: 'user_filter:user_type' })} 
            />
          </div>
          
          <div className={styles.radio}>
            <Radio isLabelBeforeButton id="is_technician" isChecked={values.is_technician}
              onChange={onFieldChanged('is_technician')} label={formatMessage({ id: 'shared:technician' })}
              name={formatMessage({ id: 'user_filter:user_type' })}
            />
          </div>

          <div className={styles.radio}>
            <Radio isLabelBeforeButton id="is_contact" isChecked={values.is_contact}
              onChange={onFieldChanged('is_contact')} label={formatMessage({ id: 'shared:contact' })}
              name={formatMessage({ id: 'user_filter:user_type' })}
            />
          </div>

          <div className={styles.actions}>
            <button className={styles.btn} onClick={handleCancel} type="button">
              <FormattedMessage id="shared:cancel_filter" />
            </button>
            <Button
              disabled={loading}
              type="submit"
              onClick={saveMemberToApi}
            >
              <FormattedMessage id="shared:filter" />
              {loading && (
                <Spinner size="sm" />
              )}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
