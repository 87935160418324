import BaseService from './BaseService'
import { formsUrls } from './urls'

class FormService {
    static list_forms = (suffix?) => BaseService.getRequest(formsUrls.FORMS(suffix), true);
    static getFormCategoryAndQuestions = (
        form_id, candidate_id,
    ) => BaseService.getRequest(
        formsUrls.TREAT_FORM_DATA(form_id, candidate_id), false
    );
    static list_form_categories = (form_id, suffix) => BaseService.getRequest(formsUrls.FORM_CATEGORIES(form_id, suffix), true);
    static delete_form = (data) => BaseService.deleteRequest(formsUrls.DELETE_FORM, data, true);
    static new_form = (data) => BaseService.postRequest(formsUrls.NEW_FORM, data, true);
    static getFormsLite = () => BaseService.getRequest(formsUrls.FORMS_LITE, true);
    static fetchFormsAutoFillInfo = (can_id) => BaseService.getRequest(formsUrls.FORMS_AUTO_FILL(can_id), true);
    static update_form = (id, data) => BaseService.putRequest(formsUrls.UPDATE_FORM(id), data, true);
    static sendFormByEmail = data => BaseService.postRequest(formsUrls.SEND_FORM_BY_EMAIL, data, true);
    static update_candidate_form = data => BaseService.putRequest(formsUrls.CANDIDATE_FORM, data, true);
    static create_candidate_form = data => BaseService.postRequest(formsUrls.CANDIDATE_FORM, data, true);
    static create_candidate_form_external = data => BaseService.postRequest(formsUrls.CANDIDATE_FORM_EXTERNAL, data, true);
    static get_candidate_form = (id) => BaseService.getRequest(formsUrls.GET_CANDIDATE_FORM(id), true);
    static external_get_candidate_form = (id, token) => BaseService.getRequest(formsUrls.EXTERNAL_GET_CANDIDATE_FORM(id, token), false);
    static delete_candidate_form = (id) => BaseService.deleteRequest(formsUrls.DELETE_CANDIDATE_FORM(id), {}, true);
    static denyForm = (data) => BaseService.postRequest(formsUrls.DENY_FILLING_FORM, data, true);
}

export default FormService;